import React from "react";
import { injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";
import { FieldAPI } from "@ai360/core";

import { getSelectedRecGuidSet } from "~/action-panel/components/rec-module/components/rec-list/selectors";
import { getSelectedEventGuidSet } from "~/action-panel/components/event-module/components/event-list/selectors";
import { selectors as vendorSelectors } from "~/admin/setup/vendor/data";
import { Button } from "~/core";
import { selectors as picklistSelectors, picklistNames } from "~/core/picklist";
import { selectors as cdSelectors } from "~/customer-data";
import { eventsSelectors } from "~/recs-events";
import { recsSelectors } from "~/recs-events";
import Reports from "~/reports/reports";

import { selectors } from "../data";
import { messages } from "../i18n-messages";
import { mergeReportData, setReportDataValue } from "../data/actions";
import { IFileNameFormat, IReportTypePage } from "../data/interfaces";
import { getReportData } from "../data/selectors";
import { IReportData } from "../hocs/with-report";
import ReportOptionsWrapper from "../reports/report-options-wrapper";

import { ReportOptions as ReportOptionsRedux } from "./report-options-redux";
import ReportOptionsAccordionItem from "./report-options-accordion-item";

import { useAppDispatch, useAppSelector } from "~/store/hooks";
import ReportBookReportOptions from "../reports/report-book-report-options";

interface IReportOptionsContainerProps {
    accordionStyle?: boolean;
    alwaysShowDefaultOptions?: boolean;
    createReport: (reportData?: IReportData) => void;
    defaultFileNameFormat?: string;
    defaultMultiFieldRec?: boolean;
    fileNameFormats?: IFileNameFormat[];
    getTestPackageListData?: (guid: string) => void;
    hideCreateButton?: boolean;
    hideDefaultOptions?: boolean;
    hideOptionsHeader?: boolean;
    hideReportOptions?: boolean;
    intl: intlShape;
    legendType?: string;
    onClickCategories?: (type: number) => void;
    onReportOptionsConfigChange?: (config: Record<string, any>) => void;
    reportBooks?: boolean;
    reportPreferences: any;
    reportTypePages: IReportTypePage[];
    selectedFileNameFormat?: string;
    selectedReportTypes: string[];
}

const ReportOptionsContainer = (props: IReportOptionsContainerProps) => {
    const dispatch = useAppDispatch();
    const allReportData = useAppSelector(getReportData);
    const agEventSummaryMap = useAppSelector(eventsSelectors.getEventGeneralGuidToEventSummaryMap);
    const cropPurpose = useAppSelector(
        (state) =>
            picklistSelectors.getPicklistOptionsFromCode(
                state,
                picklistNames.getPickListCode(picklistNames.PICKLIST_CROP_PURPOSE)
            ) as { label: string; value: string }[]
    );
    const crops = useAppSelector(selectors.getCropOptionsData);
    const testingLabVendors = useAppSelector(vendorSelectors.getTestingLabVendors);
    const testPackages = useAppSelector(vendorSelectors.getTestPackages);
    const recGeneralGuidToRecMap = useAppSelector(recsSelectors.getRecGeneralGuidToRecSummaryMap);
    const seasons = useAppSelector(
        (state) =>
            picklistSelectors.getPicklistOptionsFromCode(
                state,
                picklistNames.getPickListCode(picklistNames.PICKLIST_CROPPING_SEASON)
            ) as FieldAPI.ISeason[]
    );
    const selectedFieldCount = useAppSelector(cdSelectors.getSelectedFieldsCount);
    const selectedFields = useAppSelector(selectors.getFields);
    const selectedRecGuidSet = useAppSelector(getSelectedRecGuidSet);
    const selectedEventGuidSet = useAppSelector(getSelectedEventGuidSet);

    const getReportOptionsProps = (reportType: string) => {
        const selectedReportTypes = reportType ? [reportType] : [];
        const effectiveReportType = reportType || "default";
        const reportData = allReportData[effectiveReportType];
        return {
            alwaysShowDefaultOptions: false,
            hideCreateButton: false,
            hideDefaultOptions: false,
            hideOptionsHeader: false,
            hideReportOptions: false,
            showReportBookGroupBy: false,
            showIndividualRBOption: false,
            ...props,
            agEventSummaryMap,
            cropPurpose,
            crops,
            formatMessage: props.intl.formatMessage,
            onInputChange: (key: string, value: any) =>
                dispatch(setReportDataValue({ key, value, reportType: effectiveReportType })),
            mergeReportData: (reportData: Record<string, any>) =>
                dispatch(mergeReportData({ reportType: effectiveReportType, reportData })),
            recGeneralGuidToRecMap,
            reportData,
            seasons,
            selectedEventGuidSet,
            selectedFieldCount,
            selectedFields,
            selectedRecGuidSet,
            selectedReportTypes,
            testingLabVendors,
            testPackages,
        };
    };

    const getReportOptions = () => {
        if (props.reportBooks) {
            return <ReportOptionsWrapper ReportOptions={ReportBookReportOptions} />;
        }
        if (props.selectedReportTypes.length > 1) {
            const reportTypes = [...props.selectedReportTypes].sort();
            return (
                <div className="report-options-accordion-container">
                    {reportTypes.map((selectedReportType) => (
                        <ReportOptionsAccordionItem
                            key={selectedReportType}
                            {...getReportOptionsProps(selectedReportType)}
                        />
                    ))}
                </div>
            );
        } else if (props.selectedReportTypes.length === 1) {
            const CustomOptionsComponent = Reports[props.selectedReportTypes[0]];
            if (CustomOptionsComponent) {
                return (
                    <ReportOptionsWrapper
                        ReportOptions={CustomOptionsComponent}
                        hideDefaultOptions={props.hideDefaultOptions}
                    />
                );
            }
        }
        return <ReportOptionsRedux {...getReportOptionsProps(props.selectedReportTypes[0])} />;
    };

    return (
        <div className="report-options-inner-container">
            <div className="report-options-header">
                {!props.hideOptionsHeader && (
                    <div className="options-header">{`2. ${props.intl.formatMessage(
                        messages.options
                    )}:`}</div>
                )}
            </div>
            {getReportOptions()}
            {!props.hideCreateButton && (
                <Button
                    className="create-button"
                    type="createReports"
                    onClick={props.createReport}
                />
            )}
        </div>
    );
};

ReportOptionsContainer.propTypes = {
    accordionStyle: PropTypes.bool,
    alwaysShowDefaultOptions: PropTypes.bool,
    createReport: PropTypes.func.isRequired,
    defaultFileNameFormat: PropTypes.string,
    defaultMultiFieldRec: PropTypes.bool,
    fileNameFormats: PropTypes.array,
    getTestPackageListData: PropTypes.func,
    hideCreateButton: PropTypes.bool,
    hideDefaultOptions: PropTypes.bool,
    hideOptionsHeader: PropTypes.bool,
    hideReportOptions: PropTypes.bool,
    intl: intlShape,
    legendType: PropTypes.string,
    onClickCategories: PropTypes.func,
    onReportOptionsConfigChange: PropTypes.func,
    reportPreferences: PropTypes.any.isRequired,
    reportTypePages: PropTypes.array.isRequired,
    selectedFileNameFormat: PropTypes.string,
    selectedReportTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    showReportBookGroupBy: PropTypes.bool,
    showIndividualRBOption: PropTypes.bool,
};

export default injectIntl(ReportOptionsContainer);
