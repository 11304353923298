import React, { Component } from "react";
import { connect } from "react-redux";
import {
    ColorPicker,
    DialogBox,
    DialogBoxFooterType,
    NoLink,
    PicklistSelectInput,
    SelectInput,
    ZeroToInfiniteGrid,
} from "~/core";

import { injectIntl } from "react-intl";
import { withEditableGrid } from "~/hocs";
import { IWithEditableGridProps } from "~/hocs/interfaces";
import { createAddLinkLabelText, createAddEditModalTitle } from "~/i18n-messages";

import { messages } from "../i18n-messages";
import * as actions from "./data/actions";
import * as selectors from "./data/selectors";
import { fetchDropdownData } from "~/core/dropdowns/actions";
import {
    actions as picklistActions,
    ACTIVE_YN,
    picklistNames,
    selectors as picklistSelectors,
} from "~/core/picklist";
import { IBrandOrgData, ICropData } from "~/action-panel/components/common/planting/interfaces";
import { IVarietyHybridData } from "./interfaces";
import { CropAPI, ISelectOption } from "@ai360/core";
import "./attribute-color-override.css";

import { getUser } from "~/login";
import {
    PICKLIST_HERBICIDE_TOLERANCE,
    PICKLIST_CROP_PURPOSE,
} from "~/core/picklist/picklist-names";
import { ColorOverrideAPI } from "@ai360/core";

import { v4 as uuid } from "uuid";

interface IAttributeColorOverrideProps {
    cropData: ICropData[];
    brandOrgData: IBrandOrgData[];
    varietyHybridData: IVarietyHybridData[];
    herbicideToleranceData: ISelectOption<string>[];
    cropPurposeData: ISelectOption<string>[];
    colorOverrideTypes: ColorOverrideAPI.IAttributeColorOverrideType[];
    fetchDropdownData?: (data: any) => void;
    fetchPicklistData: (picklists) => void;
    formatMessage: (key: string, params?: any) => null;
}

class AttributeColorOverride_ extends Component<
    IAttributeColorOverrideProps & IWithEditableGridProps<ColorOverrideAPI.IAttributeColorOverride>
> {
    static PROP_COLOR_OVERRIDE_GUID = "colorOverrideGuid";
    static PROP_COLOR_OVERRIDE_TYPE_GUID = "colorOverrideTypeGuid";
    static PROP_COLOR_OVERRIDE_TYPE_NAME = "colorOverrideTypeName";
    static PROP_FOREIGN_KEY_GUID = "foreignKeyGuid";
    static PROP_FOREIGN_KEY_VALUE = "foreignKeyValue";
    static PROP_VARIETY_HYBRID_DATA = "varietyHybridData";
    static PROP_COLOR_VALUE = "colorValue";
    static PROP_CROP_PURPOSE_GUID = "cropPurposeGuid";
    static PROP_CROP_PURPOSE_NAME = "cropPurposeName";

    static keysToUpdate = [
        AttributeColorOverride_.PROP_COLOR_OVERRIDE_GUID,
        AttributeColorOverride_.PROP_COLOR_OVERRIDE_TYPE_GUID,
        AttributeColorOverride_.PROP_COLOR_OVERRIDE_TYPE_NAME,
        AttributeColorOverride_.PROP_FOREIGN_KEY_GUID,
        AttributeColorOverride_.PROP_FOREIGN_KEY_VALUE,
        AttributeColorOverride_.PROP_VARIETY_HYBRID_DATA,
        AttributeColorOverride_.PROP_COLOR_VALUE,
        AttributeColorOverride_.PROP_CROP_PURPOSE_GUID,
        AttributeColorOverride_.PROP_CROP_PURPOSE_NAME,
    ];

    constructor(props) {
        super(props);
    }

    componentDidMount(): void {
        this.getCropOptions();
        this.getHerbicideTolerancePicklist();
        this.getCropPurposePicklist();
    }

    getCropOptions = (): void => {
        this.props.fetchDropdownData({
            cropData: {
                url: CropAPI.REQUEST_CROP,
                model: "Name",
            },
        });
    };

    getHerbicideTolerancePicklist = () => {
        this.props.fetchPicklistData({
            [picklistNames.PICKLIST_HERBICIDE_TOLERANCE]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_HERBICIDE_TOLERANCE
            ),
        });
    };

    getCropPurposePicklist = () => {
        this.props.fetchPicklistData({
            [picklistNames.PICKLIST_CROP_PURPOSE]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_CROP_PURPOSE
            ),
        });
    };

    onEdit(record, index) {
        this.props.onEdit({
            record,
            index,
            keysToUpdate: AttributeColorOverride_.keysToUpdate,
        });

        const { editData } = this.props;

        if (editData.colorOverrideTypeName === "VarietyHybrid") {
            this.props.fetchDropdownData({
                brandOrgData: {
                    url: CropAPI.REQUEST_BRAND_ORG,
                    model: editData.varietyHybridData.varietyHybridCropId,
                },
            });
            this.props.fetchDropdownData({
                varietyHybridData: {
                    url: CropAPI.REQUEST_VARIETY_HYBRID,
                    model: {
                        CropID: editData.varietyHybridData.varietyHybridCropId,
                        BrandOrganization: editData.varietyHybridData.brandOrganizationId,
                    },
                },
            });
        }
    }
    onDelete(record, index) {
        this.props.onDelete({ index });
    }
    onAdd() {
        this.props.onAdd();
    }

    getAttributeTypeOptions(): any[] {
        return [
            { label: "Crop", value: "Crop" },
            { label: "Variety Hybrid", value: "VarietyHybrid" },
            { label: "Herbicide Tolerance", value: "HerbicideTolerance" },
        ];
    }

    getFilteredCropOptions() {
        const { record, editingIndex } = this.props;

        const currentForeignKeyGuids = record.map((r) => r.foreignKeyGuid);
        if (editingIndex != null) {
            currentForeignKeyGuids.splice(editingIndex, 1);
        }

        return this.props.cropData.map(({ guid, name, activeYn }) => ({
            value: `${guid}_${name}`,
            label: name,
            activeYn,
        }));
    }

    #renderCropMenu() {
        const { formatMessage, editData, onChange } = this.props;
        if (editData.cropPurposeName) {
            const replacePattern = ` (${editData.cropPurposeName})`;
            editData.foreignKeyValue = editData.foreignKeyValue.replace(replacePattern, "");
        }
        return (
            <React.Fragment>
                <SelectInput
                    autoFocus
                    openOnFocus={false}
                    optionIsHiddenKey={ACTIVE_YN}
                    value={`${editData.foreignKeyGuid}_${editData.foreignKeyValue}`}
                    options={this.getFilteredCropOptions()}
                    required
                    clearable={false}
                    placeholderText={formatMessage(messages.attribute)}
                    onChange={(value) => {
                        const splitValue = value.split("_");
                        onChange({
                            [AttributeColorOverride_.PROP_FOREIGN_KEY_GUID]: splitValue[0],
                            [AttributeColorOverride_.PROP_FOREIGN_KEY_VALUE]: splitValue[1],
                        });
                    }}
                    disabled={editData.colorOverrideTypeName !== "Crop"}
                />
                {editData.colorOverrideTypeName === "Crop" ? (
                    <PicklistSelectInput
                        picklistName={PICKLIST_CROP_PURPOSE}
                        required={false}
                        placeholderText={formatMessage(messages.cropPurpose)}
                        value={editData.cropPurposeGuid}
                        onChange={(value) => {
                            onChange({
                                [AttributeColorOverride_.PROP_CROP_PURPOSE_GUID]: value,
                                [AttributeColorOverride_.PROP_CROP_PURPOSE_NAME]:
                                    this.props.cropPurposeData.find(
                                        (purpose) => purpose.value === value
                                    ).label,
                            });
                        }}
                    />
                ) : null}
            </React.Fragment>
        );
    }

    getBrandOrganizationOptions() {
        const { editData } = this.props;

        if (
            editData.varietyHybridData == null ||
            editData.varietyHybridData.varietyHybridCropId == null
        ) {
            return [];
        }

        return this.props.brandOrgData?.map(({ guid, name, activeYn }) => ({
            value: guid,
            label: name,
            activeYn,
        }));
    }

    getCropPurposeOptions = () => {
        const { record, editingIndex } = this.props;

        const currentForeignKeyGuids = record.map((r) => r.foreignKeyGuid);
        if (editingIndex != null) {
            currentForeignKeyGuids.splice(editingIndex, 1);
        }
        return this.props.cropPurposeData.map((x) => x.value);
    };

    getFilteredVarietyHybridOptions() {
        const { record, editData, editingIndex } = this.props;

        if (
            editData.varietyHybridData == null ||
            editData.varietyHybridData.brandOrganizationId == null
        ) {
            return [];
        }

        const currentForeignKeyGuids = record.map((r) => r.foreignKeyGuid);
        if (editingIndex != null) {
            currentForeignKeyGuids.splice(editingIndex, 1);
        }
        const filteredVarietyHybridData = this.props.varietyHybridData.filter(
            (variety) => !currentForeignKeyGuids.includes(variety.guid)
        );

        return filteredVarietyHybridData.map(({ guid, name, activeYn }) => ({
            value: `${guid},${name}`,
            label: name,
            activeYn,
        }));
    }

    #renderVarietyHybridMenu() {
        const { formatMessage, editData, onChange } = this.props;
        return (
            <div className="variety-hybrid-menu-container">
                <SelectInput
                    autoFocus
                    openOnFocus={false}
                    optionIsHiddenKey={ACTIVE_YN}
                    value={
                        editData.varietyHybridData !== undefined
                            ? editData.varietyHybridData.varietyHybridCropId
                            : null
                    }
                    options={this.props.cropData.map(({ guid, name, activeYn }) => ({
                        value: guid,
                        label: name,
                        activeYn,
                    }))}
                    required
                    clearable={false}
                    placeholderText={formatMessage(messages.cropPlaceholderText)}
                    onChange={(value) => {
                        onChange({
                            [AttributeColorOverride_.PROP_VARIETY_HYBRID_DATA]: {
                                varietyHybridCropId: value,
                                brandOrganizationId: null,
                            },
                            [AttributeColorOverride_.PROP_FOREIGN_KEY_GUID]: null,
                            [AttributeColorOverride_.PROP_FOREIGN_KEY_VALUE]: null,
                        });
                        if (value) {
                            this.props.fetchDropdownData({
                                brandOrgData: {
                                    url: CropAPI.REQUEST_BRAND_ORG,
                                    model: value,
                                },
                            });
                        }
                    }}
                />
                <SelectInput
                    autoFocus
                    openOnFocus={false}
                    optionIsHiddenKey={ACTIVE_YN}
                    value={
                        editData.varietyHybridData !== undefined
                            ? editData.varietyHybridData.brandOrganizationId
                            : null
                    }
                    options={this.getBrandOrganizationOptions()}
                    required
                    clearable={false}
                    placeholderText={formatMessage(messages.brandPlaceholderText)}
                    onChange={(value) => {
                        onChange({
                            [AttributeColorOverride_.PROP_VARIETY_HYBRID_DATA]: {
                                varietyHybridCropId: editData.varietyHybridData.varietyHybridCropId,
                                brandOrganizationId: value,
                            },
                            [AttributeColorOverride_.PROP_FOREIGN_KEY_GUID]: null,
                            [AttributeColorOverride_.PROP_FOREIGN_KEY_VALUE]: null,
                        });
                        this.props.fetchDropdownData({
                            varietyHybridData: {
                                url: CropAPI.REQUEST_VARIETY_HYBRID,
                                model: {
                                    CropID: editData.varietyHybridData.varietyHybridCropId,
                                    BrandOrganization: value,
                                },
                            },
                        });
                    }}
                />
                <SelectInput
                    autoFocus
                    openOnFocus={false}
                    optionIsHiddenKey={ACTIVE_YN}
                    value={`${editData.foreignKeyGuid},${editData.foreignKeyValue}`}
                    options={this.getFilteredVarietyHybridOptions()}
                    required
                    clearable={false}
                    placeholderText={formatMessage(messages.attribute)}
                    onChange={(value) => {
                        const splitValue = value.split(",");
                        onChange({
                            [AttributeColorOverride_.PROP_FOREIGN_KEY_GUID]: splitValue[0],
                            [AttributeColorOverride_.PROP_FOREIGN_KEY_VALUE]: splitValue[1],
                        });
                    }}
                />
            </div>
        );
    }

    herbicideToleranceOptionsFilter = (item) => {
        const { record, editingIndex } = this.props;

        const currentForeignKeyGuids = record.map((r) => r.foreignKeyGuid);
        if (editingIndex != null) {
            currentForeignKeyGuids.splice(editingIndex, 1);
        }

        return !currentForeignKeyGuids.includes(item.value);
    };

    #renderHerbicideToleranceMenu() {
        const { formatMessage, editData, onChange } = this.props;
        return (
            <PicklistSelectInput
                picklistName={PICKLIST_HERBICIDE_TOLERANCE}
                required
                placeholderText={formatMessage(messages.attribute)}
                value={editData.foreignKeyGuid}
                optionListFilter={this.herbicideToleranceOptionsFilter}
                onChange={(value) => {
                    onChange({
                        [AttributeColorOverride_.PROP_FOREIGN_KEY_GUID]: value,
                        [AttributeColorOverride_.PROP_FOREIGN_KEY_VALUE]:
                            this.props.herbicideToleranceData.find(
                                (tolerance) => tolerance.value === value
                            ).label,
                    });
                }}
            />
        );
    }

    getHexString(defaultMapColor: string) {
        let hexValue = Number(defaultMapColor).toString(16);
        while (hexValue.length < 6) {
            hexValue = "0" + hexValue;
        }
        return "#" + hexValue;
    }

    getColorPickerValue() {
        const { editingIndex, editData, onChange } = this.props;

        let colorValue = editData.colorValue;
        if (
            editingIndex == null &&
            (editData.colorValue == null || editData.colorValue == "#FFFFFF")
        ) {
            const crop = this.props.cropData.find((crop) => crop.guid === editData.foreignKeyGuid);
            if (crop != null) {
                colorValue = this.getHexString(crop.defaultMapColor);
            } else {
                colorValue = "#FFFFFF";
            }
        }

        if (editData.colorValue != colorValue) {
            onChange({
                [AttributeColorOverride_.PROP_COLOR_VALUE]: colorValue,
            });
        }
        return colorValue;
    }

    #renderAddEditModal() {
        const {
            formatMessage,
            isEditing,
            editData,
            editingIndex,
            onAddOrEditItem,
            onChange,
            toggleModal,
        } = this.props;

        return (
            <DialogBox
                className="attribute-color-override-dialog"
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                action="save"
                isOpen={isEditing}
                actionDisabled={!editData.colorOverrideTypeName || !editData.foreignKeyGuid}
                onAction={() => onAddOrEditItem()}
                onClose={() => toggleModal("isEditing", false)}
                title={createAddEditModalTitle(isEditing, formatMessage, messages.colorOverride)}
            >
                <SelectInput
                    autoFocus
                    clearable={false}
                    openOnFocus={false}
                    value={editData.colorOverrideTypeName}
                    options={this.getAttributeTypeOptions()}
                    required
                    placeholderText={formatMessage(messages.attributeType)}
                    onChange={(value) => {
                        onChange({
                            [AttributeColorOverride_.PROP_COLOR_OVERRIDE_GUID]:
                                editingIndex == null ? uuid() : editData.colorOverrideGuid,
                            [AttributeColorOverride_.PROP_COLOR_OVERRIDE_TYPE_GUID]:
                                this.props.colorOverrideTypes.find((type) => type.name === value)
                                    .id,
                            [AttributeColorOverride_.PROP_COLOR_OVERRIDE_TYPE_NAME]: value,
                            [AttributeColorOverride_.PROP_FOREIGN_KEY_GUID]: null,
                            [AttributeColorOverride_.PROP_FOREIGN_KEY_VALUE]: null,
                            [AttributeColorOverride_.PROP_COLOR_VALUE]: null,
                        });
                    }}
                />
                {editData.colorOverrideTypeName !== "VarietyHybrid" &&
                    editData.colorOverrideTypeName !== "HerbicideTolerance" &&
                    this.#renderCropMenu()}
                {editData.colorOverrideTypeName === "VarietyHybrid" &&
                    this.#renderVarietyHybridMenu()}
                {editData.colorOverrideTypeName === "HerbicideTolerance" &&
                    this.#renderHerbicideToleranceMenu()}
                <div className="color-picker-container">
                    <span className="set-color-message-container">
                        {formatMessage(messages.setColorMessage)}
                    </span>
                    <ColorPicker
                        value={this.getColorPickerValue()}
                        onChange={(value) => {
                            onChange({
                                [AttributeColorOverride_.PROP_COLOR_VALUE]: value,
                            });
                        }}
                    />
                </div>
            </DialogBox>
        );
    }

    getColorOverrideRecords() {
        const { record } = this.props;

        record.forEach((colorOverride) => {
            colorOverride.colorOverrideTypeName = this.props.colorOverrideTypes.find(
                (type) => type.id === colorOverride.colorOverrideTypeGuid
            ).name;
        });
        record.sort((a, b) => {
            // Sort by colorOverrideTypeName
            if (a.colorOverrideTypeName < b.colorOverrideTypeName) {
                return -1;
            }
            if (a.colorOverrideTypeName > b.colorOverrideTypeName) {
                return 1;
            }

            // If colorOverrideTypeName is the same, sort by foreignKeyValue
            if (a.foreignKeyValue < b.foreignKeyValue) {
                return -1;
            }
            if (a.foreignKeyValue > b.foreignKeyValue) {
                return 1;
            }

            return 0;
        });

        return record.map(
            ({
                colorOverrideGuid,
                colorOverrideTypeGuid: attributeTypeGuid,
                colorOverrideTypeName: attributeType,
                foreignKeyGuid: attributeGuid,
                foreignKeyValue: attributeValue,
                varietyHybridData,
                colorValue,
                cropPurposeGuid,
                cropPurposeName,
            }) => ({
                [AttributeColorOverride_.PROP_COLOR_OVERRIDE_GUID]: colorOverrideGuid,
                [AttributeColorOverride_.PROP_COLOR_OVERRIDE_TYPE_GUID]: attributeTypeGuid,
                [AttributeColorOverride_.PROP_COLOR_OVERRIDE_TYPE_NAME]: attributeType
                    ? attributeType
                    : this.props.colorOverrideTypes.find((type) => type.id === attributeTypeGuid)
                          .name,
                [AttributeColorOverride_.PROP_FOREIGN_KEY_GUID]: attributeGuid,
                [AttributeColorOverride_.PROP_FOREIGN_KEY_VALUE]: cropPurposeName
                    ? `${attributeValue} (${cropPurposeName})`
                    : attributeValue,
                [AttributeColorOverride_.PROP_VARIETY_HYBRID_DATA]: varietyHybridData,
                [AttributeColorOverride_.PROP_COLOR_VALUE]: colorValue,
                [AttributeColorOverride_.PROP_CROP_PURPOSE_GUID]: cropPurposeGuid,
                [AttributeColorOverride_.PROP_CROP_PURPOSE_NAME]: cropPurposeName,
            })
        );
    }

    public render() {
        const { record, renderDeleteModal, formatMessage } = this.props;
        const hasRows = record && record.length > 0;
        const hasTypes = this.props.colorOverrideTypes && this.props.colorOverrideTypes.length > 0;

        return (
            <div className="setup-attribute-color-override">
                {hasRows && hasTypes && (
                    <ZeroToInfiniteGrid
                        records={this.getColorOverrideRecords()}
                        columns={{
                            [AttributeColorOverride_.PROP_COLOR_OVERRIDE_TYPE_NAME]: {
                                title: formatMessage(messages.attributeType),
                                className: "attribute-type",
                            },
                            [AttributeColorOverride_.PROP_FOREIGN_KEY_VALUE]: {
                                title: formatMessage(messages.attribute),
                                className: "attribute",
                            },
                            [AttributeColorOverride_.PROP_COLOR_VALUE]: {
                                title: formatMessage(messages.empty),
                                className: "color",
                            },
                        }}
                        className={"attribute-color-override-grid"} // cell-stretch
                        onEdit={this.onEdit.bind(this)}
                        onDelete={this.onDelete.bind(this)}
                        showHeader={true}
                        formatCellValue={(value: string) => {
                            return (
                                <div
                                    className="color-value"
                                    style={{
                                        backgroundColor: value,
                                        minHeight: "16px",
                                        height: "100%",
                                    }}
                                ></div>
                            );
                        }}
                        formatColumnKeys={["colorValue"]}
                    />
                )}
                {this.#renderAddEditModal()}
                {renderDeleteModal()}
                <div className={`add-link-container ${hasRows ? "" : "no-table"}`}>
                    <NoLink
                        className={"add-link"}
                        label={createAddLinkLabelText(formatMessage, messages.colorOverride)}
                        onClick={this.onAdd.bind(this)}
                    ></NoLink>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const userInfo = getUser(state);
    const cropData = selectors.getCropData(state);
    const varietyHybridData = selectors.getVarietyHybridData(state);
    const brandOrgData = selectors.getBrandOrgData(state);
    const herbicideToleranceData = picklistSelectors.getPicklistOptionsFromCode(
        state,
        picklistNames.getPickListCode(picklistNames.PICKLIST_HERBICIDE_TOLERANCE)
    );
    const cropPurposeData = picklistSelectors.getPicklistOptionsFromCode(
        state,
        picklistNames.getPickListCode(picklistNames.PICKLIST_CROP_PURPOSE)
    );

    return {
        analysisTypeList: userInfo.layerTypesAccess,
        cropData,
        brandOrgData,
        herbicideToleranceData: herbicideToleranceData,
        cropPurposeData: cropPurposeData,
        varietyHybridData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchDropdownData: (dropdowns) =>
            dispatch(
                fetchDropdownData({
                    ...dropdowns,
                    action: actions.fetchDropdownDataSuccess,
                })
            ),
        fetchPicklistData: (pickLists) => dispatch(picklistActions.fetchPicklistData(pickLists)),
    };
};

export default withEditableGrid(
    connect(mapStateToProps, mapDispatchToProps)(injectIntl(AttributeColorOverride_))
);
