import React, { Component, ReactElement } from "react";
import { injectIntl } from "react-intl";
import moment from "moment";
import { withUser } from "~/hocs";
import { messages } from "../i18n-messages";
import {
    Checkbox,
    SelectInput,
    TextInput,
    DateInput,
    NoLink,
    Section,
    SubSection,
    NumericInput,
    PicklistSelectInput,
} from "~/core";
import { model, categoryTypes, constants } from "../data";
import { keywords } from "../keywords";
import { getReportDataFromPreferences } from "../utils";
import { UserAPI } from "@ai360/core";
import { FieldAPI, LayerAPI, LayerUtilsAPI, ReportAPI, ReportsModels } from "@ai360/core";
import { ACTIVE_YN, picklistNames } from "~/core/picklist";
import CropYieldList from "./crop-yield-list";
import BreakEvenYieldList from "./break-even-yield-list";
import { ReportFieldSymbologyOptions } from "./report-field-symbology-options";
import { ReportPageSelection } from "./report-page-selection";
import {
    ICropYieldData,
    IFileNameFormat,
    IMessageOptions,
    IReportTypePage,
} from "../data/interfaces";
import { ISoilTypeAttribute } from "~/admin/setup/report-books/data/interfaces";
import { ITestingLabVendor } from "~/vendor/model";
import { AgEventSummary } from "~/recs-events/events/models";
import { ISelectorCropDataReturn } from "../data/selectors";
import { RecSummary } from "~/recs-events/recs/model";
import {
    getBasemapOptions,
    getFieldsPerPageOptions,
    getLabelSizeOptions,
    getPageSizeOptions,
    getZoomLevelOptions,
} from "~/reports/utils";
import { IOption } from "../reports";

interface IGetOptionSettings {
    seasonOptionsNA: boolean;
    showLoadsheetOptions: boolean;
    showOrientationOptions: boolean;
    showNumberOfSeasons: boolean;
    showNutrientTargetColors: boolean;
    showDisplayParameters: boolean;
    showOriginalFormat: boolean;
    showPlantingRecCostDetail: boolean;
    allOptionsNA: boolean;
    scoutingOptions: boolean;
    showSoilTypeAttributeSelection: boolean;
    scoutingSummaryOptions: boolean;
    showUseOverviewMapAfterPageSelection: boolean;
    hygroundDefinedLegendsSelected: boolean;
    showPrintFarmTotalsOption: boolean;
    showUseProductRecs: boolean;
    showPLSSInfoCheckbox: boolean;
    disableBasemapOptionsWhenNoMapInReport: boolean;
    hasWeatherReport: boolean;
    showAgvanceColorOption: boolean;
    showCropPlanningSummaryCheckbox: boolean;
    showCropPlanningCropZonesCheckbox: boolean;
    showPrintFieldTotalsOption: boolean;
    cropOptionsNA: boolean;
    showYieldMapYieldOptions: boolean;
    truYieldImpact: boolean;
    showFieldLabelOptionsLink: boolean;
    showPrintGrowerTotalsOption: boolean;
    showGuaranteedAnalysisOption: boolean;
    showPlantingOptions: boolean;
    showPageSelectionBeforeRecSummary: boolean;
    hygroundOrAskSelected: boolean;
    isNotFieldsPerPage: boolean;
    hygroundMultiFieldSelected: boolean;
    useImportDate: boolean;
    showFieldsPerPageOption: boolean;
    showYieldOptions: boolean;
    showProductRecapOption: boolean;
    showDisplayTypeOptions: boolean;
    showPageSelectionOption: boolean;
    showGeneralNotes: boolean;
    showLabelSizeOption: boolean;
    showLatLongQrOption: boolean;
    showLowRateLegend: boolean;
    dateOptionsNA: boolean;
    showCropMapOrientationOptions: boolean;
    hideOverviewForPortrait: boolean;
    showUseOverviewMap: boolean;
    seasonDisabled: boolean;
    showMultipleMapsPerPage: boolean;
    showUseCostDetails: boolean;
    showSelectBasemap: boolean;
    showCustomImagery: boolean;
    optionsDisabled: boolean;
    showGrowerSummaryPerPageOption: boolean;
    showGrowerSummaryCostDetail: boolean;
    showDisplaySampleSitesPosition2: boolean;
    farmMap: boolean;
    showDisplaySampleSitesPosition1: boolean;
    showApplicationAttribute: boolean;
    showApplicationMapOptions: boolean;
    applicationAttributeDisabled: boolean;
    showUsePolygonStatsOption: boolean;
}

type FormatMessage = (message: string, messageOptions?: IMessageOptions) => string;

type OnInputChange = (key: string | string[], value: any) => void;

export interface IReportOptionsProps {
    agEventSummaryMap?: Map<string, AgEventSummary>;
    alwaysShowDefaultOptions?: boolean;
    createReport: () => void;
    crops?: ISelectorCropDataReturn[];
    cropPurpose?: { label: string; value: string }[];
    defaultFileNameFormat?: string;
    defaultMultiFieldRec?: boolean;
    formatMessage: FormatMessage;
    fileNameFormats?: IFileNameFormat[];
    getTestPackageListData?: (guid: string) => void;
    hideCreateButton?: boolean;
    hideDefaultOptions?: boolean;
    hideOptionsHeader?: boolean;
    hideReportOptions?: boolean;
    mergeReportData: (reportData: Record<string, any>) => void;
    legendType?: string;
    numberOfSeasons?: string;
    onClickCategories?: (type: number) => void;
    onInputChange: OnInputChange;
    onReportOptionsConfigChange?: (config: Record<string, any>) => void;
    onVisibleOptionsChange?: (visibleOptions) => void;
    recGeneralGuidToRecMap?: Map<string, RecSummary>;
    reportData: any;
    reportPreferences: any;
    reportTypePages: IReportTypePage[];
    seasons?: FieldAPI.ISeason[];
    selectedEventGuidSet?: Set<string>;
    selectedFieldCount?: number;
    selectedFields?: ReportAPI.IField[];
    selectedFileNameFormat?: string;
    selectedRecGuidSet?: Set<string>;
    selectedReportTypes: string[];
    testingLabVendors?: ITestingLabVendor[];
    testPackages?: {
        testPackageGuid: string;
        testPackageId: string;
        vendorGuid: string;
        sampleTypeGuid: string;
        sampleTypeName: string;
        label: string;
        value: string;
    }[];
    userInfo?: UserAPI.IUser;
}

interface IReportOptionsState {
    cropData: ICropYieldData[];
    cropLabel: boolean;
    showFieldLabelOptions: boolean;
    showFieldSymbologyOptions: boolean;
    showPageSelection: boolean;
    soilTypeAttributeOptions: ISoilTypeAttribute[];
    varietyLabel: boolean;
}

class ReportOptions_ extends Component<IReportOptionsProps, IReportOptionsState> {
    private readonly ageOfSampleOptions: IOption[];
    private readonly CropMapOrientationOptions: IOption[];
    private readonly cropMapLegendTypeOptions: IOption[];
    private readonly cropMapLabelTypeOptions: IOption[];
    private readonly displayTypeOptions: IOption[];
    private readonly orientationOptions: IOption[];
    private readonly plantingAttributeOptions: IOption[];
    private readonly sampleBagLabelOptions: IOption[];
    private readonly yieldAttributeOptions: IOption[];
    private readonly yieldMapYieldAttributeOptions: IOption[];
    private readonly applicationMapAttributeOptions: IOption[];
    private readonly applicationAttributeOptions: IOption[];
    private readonly numberOfSeasonsOptions: IOption[];
    private loadingSoilTypeAttributes = false;

    constructor(props: IReportOptionsProps) {
        super(props);
        this.state = {
            cropData: [],
            cropLabel: false,
            showFieldLabelOptions: false,
            showFieldSymbologyOptions: false,
            showPageSelection: false,
            soilTypeAttributeOptions: props.reportPreferences.soilTypeAttributeOptions || [],
            varietyLabel: false,
        };

        this.ageOfSampleOptions = this._buildAgeOfSampleOptions();
        this.CropMapOrientationOptions = this._buildCropMapOrientationOptions();
        this.cropMapLegendTypeOptions = this._buildCropMapLegendTypeOptions();
        this.cropMapLabelTypeOptions = this._buildCropMapLabelTypeOptions();
        this.displayTypeOptions = this._buildDisplayTypeOptions();
        this.orientationOptions = this._buildOrientationOptions();
        this.plantingAttributeOptions = this._buildPlantingAttributeOptions();
        this.sampleBagLabelOptions = this._buildSampleBagLabelOptions();
        this.yieldAttributeOptions = this._buildYieldAttributeOptions();
        this.numberOfSeasonsOptions = this._buildNumberOfSeasonsOptions();

        this.yieldMapYieldAttributeOptions = this._buildYieldMapYieldAttributeOptions();
        this.applicationMapAttributeOptions = this._buildApplicationMapAttributeOptions();
        this.applicationAttributeOptions = this._buildSkyApplicationAttributeOptions();
    }

    componentDidMount() {
        if (
            this.props.reportData ||
            !this.props.reportPreferences ||
            this.props.seasons.length === 0
        ) {
            return;
        }
        this.setInitialReportData(this.props);
    }

    setInitialReportData(props: IReportOptionsProps) {
        const reportData = {
            cropLabel: false,
            showFieldLabelOptions: false,
            showFieldSymbologyOptions: false,
            showPageSelection: false,
            soilTypeAttributeOptions: props.reportPreferences.soilTypeAttributeOptions || [],
            varietyLabel: false,
            [model.PROPS_START_DATE]: null,
            [model.PROPS_END_DATE]: null,
            [model.PROPS_IS_HOURLY]: false,
            [model.PROPS_MULTI_FIELD_REC]: props.defaultMultiFieldRec,
            [model.PROPS_SAMPLE_SITES]: true,
            [model.PROPS_SELECTED_TESTING_LAB]: null,
            [model.PROPS_SELECTED_TEST_PACKAGES]: null,
            [model.PROPS_SELECTED_FILENAME_FORMAT]: props.defaultFileNameFormat,
            [model.PROPS_START_POSITION]: null,
            [model.PROPS_NUMBER_OF_SEASONS]: null,
            [model.PROPS_CROPPING_SEASON_GUID_LIST]: [this.getCurrentYearAsValue(props.seasons)],
            [model.PROPS_ALL_CUSTOMERS_FIELDS_YN]: false,
            [model.PROPS_BATCH_GUID]: null,
            [model.PROPS_CROP_DATA]: [],
            [model.PROPS_CROP_GUID]: "",
            [model.PROPS_FIELD_ITEM_LIST]: [],
            [model.PROPS_REPORT_TYPE_GUID_LIST]: [],
            [model.PROPS_SELECTED_EVENT_GUID_LIST]: [],
            [model.PROPS_SELECTED_REC_GUID_LIST]: [],
            [model.PROPS_SELECTED_REC_GUIDS]: [],
            [model.PROPS_CUSTOMER_GUIDS]: [],
            [model.PROPS_NON_FIELD_FEATURE_GUIDS]: [],
            ...getReportDataFromPreferences(props.reportPreferences),
        };
        props.mergeReportData(reportData);
    }

    _retrieveOrEmptyObject = <TKey, TValue>(map: Map<TKey, TValue>, key: TKey): any => {
        return map.get(key) || {};
    };

    _buildAgeOfSampleOptions = (): IOption[] => {
        const options: IOption[] = [];
        for (let i = 0; i < 16; i++) {
            options.push({ label: i.toString(), value: i.toString() });
        }
        return options;
    };

    _buildCropMapOrientationOptions = (): IOption[] => {
        const options: IOption[] = [];
        options.push(
            {
                label: this.props.formatMessage(messages.dynamic),
                value: ReportsModels.CropMapOrientation.DYNAMIC,
            },
            {
                label: this.props.formatMessage(messages.landscape),
                value: ReportsModels.PageOrientation.LANDSCAPE,
            },
            {
                label: this.props.formatMessage(messages.portrait),
                value: ReportsModels.PageOrientation.PORTRAIT,
            }
        );
        return options;
    };

    _buildDisplayTypeOptions = (): IOption[] => {
        const options: IOption[] = [];
        options.push(
            {
                label: this.props.formatMessage(messages.coverage),
                value: "coverage",
            },
            {
                label: this.props.formatMessage(messages.point),
                value: "point",
            },
            {
                label: this.props.formatMessage(messages.polygon),
                value: "polygon",
            }
        );
        return options;
    };

    /* Almost identical to sky's Display Types. Eventually they may get folded into the same preference & dropdown,
       but for now we don't need points & the existing preference is a bit field*/
    _buildYieldByDisplayTypeOptions = (): IOption[] => [
        {
            label: this.props.formatMessage(messages.coverage),
            value: "coverage",
        },
        {
            label: this.props.formatMessage(messages.polygon),
            value: "polygon",
        },
    ];

    _buildOrientationOptions = (): IOption[] => {
        const options: IOption[] = [];
        options.push(
            {
                label: this.props.formatMessage(messages.portrait),
                value: ReportsModels.PageOrientation.PORTRAIT,
            },
            {
                label: this.props.formatMessage(messages.landscape),
                value: ReportsModels.PageOrientation.LANDSCAPE,
            }
        );
        return options;
    };

    _buildSampleBagLabelOptions = (): IOption[] => {
        const options: IOption[] = [];
        options.push(
            {
                label: this.props.formatMessage(messages.avery6572),
                value: constants.SAMPLE_BAG_LABEL_TYPES.Avery6572,
            },
            {
                label: this.props.formatMessage(messages.avery8160),
                value: constants.SAMPLE_BAG_LABEL_TYPES.Avery8160,
            },
            {
                label: this.props.formatMessage(messages.thermal),
                value: constants.SAMPLE_BAG_LABEL_TYPES.Thermal,
            }
        );
        return options;
    };

    _buildPlantingAttributeOptions = (): IOption[] => {
        const options: IOption[] = [];
        options.push(
            {
                label: this.props.formatMessage(messages.seedingRate),
                value: "seeding",
            },
            {
                label: this.props.formatMessage(messages.targetRate),
                value: "target",
            },
            {
                label: this.props.formatMessage(messages.varietyHybrid),
                value: "variety",
            },
            {
                label: this.props.formatMessage(messages.monitorVariety),
                value: "monitor",
            },
            {
                label: this.props.formatMessage(messages.aliasVarietyHybrid),
                value: "alias",
            },
            {
                label: this.props.formatMessage(messages.equipment),
                value: "equipment",
            },
            {
                label: this.props.formatMessage(messages.speed),
                value: "speed",
            },
            {
                label: this.props.formatMessage(messages.elevation),
                value: "elevation",
            }
        );
        return options;
    };

    _buildSkyApplicationAttributeOptions = (): IOption[] => {
        const options: IOption[] = [];
        options.push(
            {
                label: this.props.formatMessage(messages.productMix),
                value: "productMix",
            },
            {
                label: this.props.formatMessage(messages.monitorProduct),
                value: "monitor",
            },
            {
                label: this.props.formatMessage(messages.targetRate),
                value: "target",
            },
            {
                label: this.props.formatMessage(messages.equipment),
                value: "equipment",
            },
            {
                label: this.props.formatMessage(messages.speed),
                value: "speed",
            }
        );
        return options;
    };

    _buildApplicationMapAttributeOptions = (): IOption[] => {
        const options: IOption[] = [];
        options.push(
            {
                label: this.props.formatMessage(messages.elevation),
                value: "elevation",
            },
            {
                label: this.props.formatMessage(messages.equipment),
                value: "equipment",
            },
            {
                label: this.props.formatMessage(messages.applicationRate),
                value: "rate",
            },
            {
                label: this.props.formatMessage(messages.speed),
                value: "speed",
            },
            {
                label: this.props.formatMessage(messages.targetRate),
                value: "target",
            }
        );
        return options;
    };

    _buildCropMapLegendTypeOptions = (): IOption[] => {
        const options: IOption[] = [];
        options.push(
            {
                label: this.props.formatMessage(messages.ageOfCrop),
                value: "ageOfCrop",
            },
            {
                label: this.props.formatMessage(messages.crop),
                value: "crop",
            },
            {
                label: this.props.formatMessage(messages.herbicideTolerance),
                value: "herbicideTolerance",
            },
            {
                label: this.props.formatMessage(messages.none),
                value: "none",
            },
            {
                label: this.props.formatMessage(messages.relativeMaturity),
                value: "relativeMaturity",
            },
            {
                label: this.props.formatMessage(messages.variety),
                value: "variety",
            }
        );
        return options;
    };

    _buildCropMapLabelTypeOptions = (): IOption[] => {
        const options: IOption[] = [];
        options.push(
            {
                label: this.props.formatMessage(messages.ageOfCrop),
                value: "ageOfCrop",
            },
            {
                label: this.props.formatMessage(messages.crop),
                value: "crop",
            },
            {
                label: this.props.formatMessage(messages.fieldLabelFieldsFieldNameArea),
                value: "fieldNameArea",
            },
            {
                label: this.props.formatMessage(messages.herbicideTolerance),
                value: "herbicideTolerance",
            },
            {
                label: this.props.formatMessage(messages.none),
                value: "none",
            },
            {
                label: this.props.formatMessage(messages.plantingDate),
                value: "plantingDate",
            },
            {
                label: this.props.formatMessage(messages.relativeMaturity),
                value: "relativeMaturity",
            },
            {
                label: this.props.formatMessage(messages.variety),
                value: "variety",
            }
        );
        return options;
    };

    _buildYieldMapYieldAttributeOptions = (): IOption[] => {
        const options: IOption[] = [];
        options.push(
            {
                label: this.props.formatMessage(messages.dryYield),
                value: "dry",
            },
            {
                label: this.props.formatMessage(messages.elevation),
                value: "elevation",
            },
            {
                label: this.props.formatMessage(messages.lint),
                value: "lint",
            },
            {
                label: this.props.formatMessage(messages.moisture),
                value: "moisture",
            },
            {
                label: this.props.formatMessage(messages.speed),
                value: "speed",
            }
        );
        return options;
    };

    _buildYieldAttributeOptions = (): IOption[] => {
        const options: IOption[] = [];
        options.push(
            {
                label: this.props.formatMessage(messages.dryYield),
                value: "dry",
            },
            {
                label: this.props.formatMessage(messages.elevation),
                value: "elevation",
            },
            {
                label: this.props.formatMessage(messages.moisture),
                value: "moisture",
            },
            {
                label: this.props.formatMessage(messages.speed),
                value: "speed",
            }
        );
        return options;
    };

    _buildNumberOfSeasonsOptions = (): IOption[] => {
        const options: IOption[] = [];
        for (let i = 2; i <= 20; i++) {
            options.push({ label: i.toString(), value: i.toString() });
        }
        return options;
    };

    _createReportOptionsCheckbox = (
        prop: string,
        labelId: string,
        className = "",
        msgOpts: IMessageOptions | Record<string, any> = {},
        disabled = false
    ): React.ReactElement => {
        return (
            <Checkbox
                className={className}
                disabled={disabled}
                label={this.props.formatMessage(labelId, msgOpts)}
                onChange={(e, value) => this._updateHyGroundSummaryOptions(prop, value)}
                value={this.props.reportData[prop]}
            />
        );
    };

    _createOptionsCheckbox = (
        prop: string,
        labelId: string,
        className = "",
        msgOpts: IMessageOptions | Record<string, any> = {},
        disabled = false
    ): React.ReactElement => {
        return (
            <Checkbox
                className={className}
                disabled={disabled}
                label={this.props.formatMessage(labelId, msgOpts)}
                onChange={(e, value) => this._updateReportOption(prop, value)}
                value={this.props.reportData[prop]}
            />
        );
    };

    _updateHyGroundSummaryOptions(prop: string, value: boolean) {
        if (value) {
            this._updateReportOption(model.PROPS_HGSUMMARY_DISPLAY_OPTION, "grower");
        }
        this._updateReportOption(prop, value);
    }

    _allImportedApplicationEvent = (): boolean => {
        const isAllImport: boolean[] = [];
        const { agEventSummaryMap, selectedEventGuidSet } = this.props;

        for (const eventGeneralGuid of selectedEventGuidSet) {
            const event = this._retrieveOrEmptyObject(agEventSummaryMap, eventGeneralGuid);
            if (this._checkEventProps(event)) {
                isAllImport.push(event.isImportedYn);
            }
        }
        return isAllImport.every((x) => x === true);
    };

    _getOptionSettings = (props: IReportOptionsProps = this.props): IGetOptionSettings => {
        const { selectedEventGuidSet, selectedRecGuidSet, selectedReportTypes } = props;
        const hygroundOrAskSelected = this._someReportSelected(
            keywords.hyGroundFsr,
            keywords.hyGround,
            keywords.trueRec,
            keywords.hyGroundDefinedLegends,
            keywords.hyGroundDefinedLegendsEBU,
            keywords.ask,
            keywords.coreMax,
            keywords.accupoint,
            keywords.accupointDefinedLegendsEBU
        );
        const hygroundDefinedLegendsSelected = this._someReportSelected(
            keywords.hyGroundDefinedLegends,
            keywords.hyGroundDefinedLegendsEBU,
            keywords.hyGroundFsr,
            keywords.accupointDefinedLegendsEBU
        );
        const hygroundMultiFieldSelected = this._someReportSelected(
            keywords.hyGroundDefinedLegendsMultiField,
            keywords.coreMaxDefinedLegendsMultiField,
            keywords.accupointDefinedLegendsMultiField,
            keywords.hyGroundDefinedLegendsMultiFieldProductRec,
            keywords.coreMaxDefinedLegendsMultiFieldProductRec,
            keywords.accupointDefinedLegendsMultiFieldProductRec
        );
        const hasWeatherReport = this._someReportSelected(keywords.weatherHistory);
        const recSelectionReportType = this._allReportsSelected(
            ReportAPI.ReportNames.PRODUCT_REC_SUMMARY_REPORT,
            keywords.yieldBy,
            keywords.loadsheet,
            ReportAPI.ReportNames.AGVANCE_SKY_CROP_PLANNING_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_GROWER_SUMMARY_APPLICATION_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_APPLICATION,
            ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_PLANTING,
            ReportAPI.ReportNames.CROP_MAP_REPORT,
            ReportAPI.ReportNames.FOUR_MATION_RX_REPORT,
            ReportAPI.ReportNames.RECS_REPORT_APPLICATION,
            ReportAPI.ReportNames.RECS_REPORT_PLANTING,
            ReportAPI.ReportNames.SEED_STRONG_RX_REPORT
        );
        const eventSelectionReportType = this._allReportsSelected(
            ReportAPI.ReportNames.YIELD_BY_ANALYSIS_REPORT,
            keywords.yieldBy,
            keywords.yieldMap,
            keywords.inspex,
            keywords.labSubmissionForm,
            keywords.hyGroundSummary,
            keywords.sampleBagLabels,
            keywords.harvestSummary,
            keywords.harvestSummaryMultiSeason,
            keywords.agvanceSkyScouting,
            keywords.coreMaxSummary,
            keywords.accupointSummary,
            keywords.agOptic,
            ReportAPI.ReportNames.AGVANCE_SKY_APPLICATION_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_NUTRIENT_ATLAS,
            ReportAPI.ReportNames.AGVANCE_SKY_PLANTING_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_SOIL_TEST_RESULTS_REPORT,
            ReportAPI.ReportNames.CROP_MAP_REPORT,
            ReportAPI.ReportNames.EXTRACTOR_SUMMARY_REPORT,
            ReportAPI.ReportNames.NUTRIENT_MAP,
            ReportAPI.ReportNames.TRU_YIELD_IMPACT_REPORT,
            ReportAPI.ReportNames.TRU_YIELD_SUMMARY_REPORT,
            ReportAPI.ReportNames.YIELD_ONE_IMPACT_REPORT,
            ReportAPI.ReportNames.YIELD_ONE_SUMMARY_REPORT,
            ReportAPI.ReportNames.ASK_REPORT,
            ReportAPI.ReportNames.COREMAX,
            ReportAPI.ReportNames.COREMAX_DEFINED_LEGEND_MULTIFIELD,
            ReportAPI.ReportNames.COREMAX_DEFINED_LEGEND_MULTIFIELD_PRODUCT_REC,
            ReportAPI.ReportNames.COREMAX_RESULTS_ONLY,
            ReportAPI.ReportNames.HYGROUND,
            ReportAPI.ReportNames.HYGROUND_DEFINED_LEGEND,
            ReportAPI.ReportNames.HYGROUND_DEFINED_LEGEND_EBU,
            ReportAPI.ReportNames.HYGROUND_DEFINED_LEGEND_MULTIFIELD,
            ReportAPI.ReportNames.HYGROUND_DEFINED_LEGEND_MULTIFIELD_PRODUCT_REC,
            ReportAPI.ReportNames.HYGROUND_FSR,
            ReportAPI.ReportNames.HYGROUND_DESERT,
            ReportAPI.ReportNames.HYGROUND_NORTHWEST,
            ReportAPI.ReportNames.HYGROUND_RESULTS_ONLY,
            ReportAPI.ReportNames.HYGROUND_WESTERN,
            ReportAPI.ReportNames.ACCUPOINT,
            ReportAPI.ReportNames.ACCUPOINT_DEFINED_LEGEND_EBU,
            ReportAPI.ReportNames.ACCUPOINT_DEFINED_LEGEND_MULTIFIELD,
            ReportAPI.ReportNames.ACCUPOINT_DEFINED_LEGEND_MULTIFIELD_PRODUCT_REC,
            ReportAPI.ReportNames.PLANTING_SUMMARY,
            ReportAPI.ReportNames.EXTRACTOR_REPORT,
            ReportAPI.ReportNames.SLICE_REPORT,
            ReportAPI.ReportNames.SLICE_SUMMARY_REPORT,
            ReportAPI.ReportNames.PREVEAL_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_YIELD_REPORT,
            ReportAPI.ReportNames.APPLICATION_MAP
        );
        const allOptionsNA =
            props.hideDefaultOptions ||
            this._allReportsSelected(
                ReportAPI.ReportNames.AGVANCE_SKY_BOUNDARY_MAP_REPORT,
                ReportAPI.ReportNames.AGVANCE_SKY_SOILS_REPORT,
                ReportAPI.ReportNames.BOUNDARY_MAP_REPORT,
                ReportAPI.ReportNames.SOIL_TYPE_REPORT
            );
        const cropOptionsNA = this._allReportsSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_APPLICATION_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_SOILS_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_SOIL_TEST_RESULTS_REPORT,
            ReportAPI.ReportNames.HYGROUND_SUMMARY,
            ReportAPI.ReportNames.SOIL_SAMPLE_RESULTS_REPORT,
            ReportAPI.ReportNames.SOIL_TESTING_REQUIRED_REPORT,
            ReportAPI.ReportNames.WEATHER_REPORT,
            ReportAPI.ReportNames.COREMAX_SUMMARY,
            ReportAPI.ReportNames.ACCUPOINT_SUMMARY,
            ReportAPI.ReportNames.APPLICATION_MAP
        );
        const seasonOptionsNA = this._allReportsSelected(ReportAPI.ReportNames.WEATHER_REPORT);
        const seasonDisabled = this._checkSeasonDisabled(props);
        const dateOptionsNA = this._someReportSelected(
            keywords.soilTestingRequired,
            ReportAPI.ReportNames.AGVANCE_SKY_FIELD_RECORD_NOTEBOOK
        );
        const optionsDisabled =
            ((recSelectionReportType && selectedRecGuidSet.size > 0) ||
                (eventSelectionReportType && selectedEventGuidSet.size > 0)) &&
            this._eventsRecsMatchReportsSelected();
        const scoutingOptions = this._someReportSelected(
            keywords.inspex,
            keywords.agOptic,
            ReportAPI.ReportNames.PREVEAL_REPORT
        );

        const showApplicationAttribute = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_APPLICATION_REPORT
        );
        const showLowRateLegend = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_APPLICATION,
            ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_PLANTING
        );
        const showDisplayParameters = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_APPLICATION,
            ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_PLANTING
        );
        const showPlantingRecCostDetail = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_PLANTING
        );
        const showOriginalFormat = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_APPLICATION_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_BOUNDARY_MAP_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_CROP_PLANNING_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_GROWER_SUMMARY_APPLICATION_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_NUTRIENT_ATLAS,
            ReportAPI.ReportNames.AGVANCE_SKY_PLANTING_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_REC_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_APPLICATION,
            ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_PLANTING,
            ReportAPI.ReportNames.AGVANCE_SKY_SCOUTING_DETAIL_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_SCOUTING_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_SOIL_TEST_RESULTS_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_SOILS_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_YIELD_REPORT
        );
        const applicationAttributeDisabled = this._allImportedApplicationEvent();
        const showCropMapOrientationOptions = this._someReportSelected(
            ReportAPI.ReportNames.CROP_MAP_REPORT
        );
        const showDisplaySampleSitesPosition1 = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_APPLICATION,
            ReportAPI.ReportNames.RECS_REPORT_APPLICATION
        );
        const showDisplaySampleSitesPosition2 =
            hygroundMultiFieldSelected ||
            this._someReportSelected(
                ReportAPI.ReportNames.AGVANCE_SKY_NUTRIENT_ATLAS,
                ReportAPI.ReportNames.AGVANCE_SKY_SOIL_TEST_RESULTS_REPORT,
                ReportAPI.ReportNames.NUTRIENT_MAP
            );
        const showDisplayTypeOptions = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_APPLICATION_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_PLANTING_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_YIELD_REPORT,
            ReportAPI.ReportNames.YIELD_MAP_REPORT
        );
        const showGeneralNotes = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_APPLICATION,
            ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_PLANTING,
            ReportAPI.ReportNames.RECS_REPORT_APPLICATION,
            ReportAPI.ReportNames.RECS_REPORT_PLANTING
        );
        const showOrientationOptions = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_APPLICATION_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_BOUNDARY_MAP_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_CROP_PLANNING_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_FIELD_RECORD_NOTEBOOK,
            ReportAPI.ReportNames.AGVANCE_SKY_GROWER_SUMMARY_APPLICATION_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_NUTRIENT_ATLAS,
            ReportAPI.ReportNames.AGVANCE_SKY_PLANTING_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_APPLICATION,
            ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_PLANTING,
            ReportAPI.ReportNames.AGVANCE_SKY_SCOUTING_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_SOIL_TEST_RESULTS_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_SOILS_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_YIELD_REPORT,
            ReportAPI.ReportNames.NUTRIENT_MAP,
            ReportAPI.ReportNames.RECS_REPORT_APPLICATION,
            ReportAPI.ReportNames.RECS_REPORT_PLANTING
        );
        const showPlantingOptions = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_PLANTING_REPORT
        );
        const showSoilTypeAttributeSelection = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_SOILS_REPORT,
            ReportAPI.ReportNames.SOIL_TYPE_REPORT
        );
        const showYieldOptions = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_YIELD_REPORT
        );
        const showYieldMapYieldOptions = this._someReportSelected(
            ReportAPI.ReportNames.YIELD_MAP_REPORT
        );
        const showProductRecapOption = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_GROWER_SUMMARY_APPLICATION_REPORT
        );
        const showPrintFarmTotalsOption = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_GROWER_SUMMARY_APPLICATION_REPORT
        );
        const showPrintFieldTotalsOption = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_GROWER_SUMMARY_APPLICATION_REPORT
        );
        const showPrintGrowerTotalsOption = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_GROWER_SUMMARY_APPLICATION_REPORT
        );
        const showGrowerSummaryCostDetail = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_GROWER_SUMMARY_APPLICATION_REPORT
        );
        const showGrowerSummaryPerPageOption = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_GROWER_SUMMARY_APPLICATION_REPORT
        );
        const showApplicationMapOptions = this._someReportSelected(
            ReportAPI.ReportNames.APPLICATION_MAP
        );
        const showUsePolygonStatsOption =
            this._someReportSelected(ReportAPI.ReportNames.YIELD_MAP_REPORT) &&
            this.props.reportData[model.PROPS_DISPLAY_TYPE] !== "polygon";

        const hideOverviewForPortrait = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_APPLICATION_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_NUTRIENT_ATLAS,
            ReportAPI.ReportNames.AGVANCE_SKY_PLANTING_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_APPLICATION,
            ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_PLANTING,
            ReportAPI.ReportNames.AGVANCE_SKY_SOIL_TEST_RESULTS_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_SOILS_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_YIELD_REPORT,
            ReportAPI.ReportNames.NUTRIENT_MAP,
            ReportAPI.ReportNames.RECS_REPORT_APPLICATION,
            ReportAPI.ReportNames.RECS_REPORT_PLANTING
        );
        const disableBasemapOptionsWhenNoMapInReport =
            selectedReportTypes.length === 1 &&
            selectedReportTypes[0] === ReportAPI.ReportNames.AGVANCE_SKY_SOIL_TEST_RESULTS_REPORT;

        const showNumberOfSeasons = this._someReportSelected(
            ReportAPI.ReportNames.HARVEST_SUMMARY_MULTI_SEASON
        );

        const showNutrientTargetColors = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_SOIL_TEST_RESULTS_REPORT
        );
        const scoutingSummaryOptions = this._someReportSelected(keywords.agvanceSkyScouting);
        const farmMap = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_BOUNDARY_MAP_REPORT,
            ReportAPI.ReportNames.BOUNDARY_MAP_REPORT
        );
        const isNotFieldsPerPage =
            selectedReportTypes.length === 1 &&
            selectedReportTypes[0] === ReportAPI.ReportNames.AGVANCE_SKY_BOUNDARY_MAP_REPORT &&
            props.reportData[model.PROPS_FIELDS_PER_PAGE] !== getFieldsPerPageOptions()[2].value;
        const showPLSSInfoCheckbox = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_BOUNDARY_MAP_REPORT
        );
        const showAgvanceColorOption = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_CROP_PLANNING_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_FIELD_RECORD_NOTEBOOK
        );
        const showCropPlanningSummaryCheckbox = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_CROP_PLANNING_REPORT
        );
        const showCropPlanningCropZonesCheckbox = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_CROP_PLANNING_REPORT
        );
        const showLabelSizeOption = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_NUTRIENT_ATLAS,
            ReportAPI.ReportNames.NUTRIENT_MAP
        );
        const showLatLongQrOption = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_BOUNDARY_MAP_REPORT,
            ReportAPI.ReportNames.BOUNDARY_MAP_REPORT
        );
        const showFieldLabelOptionsLink = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_CROP_PLANNING_REPORT
        );
        const showFieldsPerPageOption = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_BOUNDARY_MAP_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_CROP_PLANNING_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_SOILS_REPORT,
            ReportAPI.ReportNames.BOUNDARY_MAP_REPORT,
            ReportAPI.ReportNames.CROP_MAP_REPORT,
            ReportAPI.ReportNames.SOIL_TYPE_REPORT
        );
        const showMultipleMapsPerPage = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_NUTRIENT_ATLAS,
            ReportAPI.ReportNames.NUTRIENT_MAP
        );

        const showUseProductRecs = this._someReportSelected(
            keywords.hyGroundFsr,
            keywords.hyGroundDefinedLegendsEBU,
            keywords.accupointDefinedLegendsEBU,
            keywords.hyGround,
            keywords.accupoint,
            keywords.coreMax,
            ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_APPLICATION,
            ReportAPI.ReportNames.RECS_REPORT_APPLICATION
        );
        const showUseCostDetails = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_APPLICATION,
            ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_PLANTING,
            ReportAPI.ReportNames.RECS_REPORT_APPLICATION,
            ReportAPI.ReportNames.RECS_REPORT_PLANTING
        );
        const showUseOverviewMap = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_APPLICATION,
            ReportAPI.ReportNames.AGVANCE_SKY_NUTRIENT_ATLAS,
            ReportAPI.ReportNames.AGVANCE_SKY_SOILS_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_YIELD_REPORT,
            ReportAPI.ReportNames.NUTRIENT_MAP,
            ReportAPI.ReportNames.RECS_REPORT_APPLICATION
        );
        const showUseOverviewMapAfterPageSelection = false;
        const useImportDate = this._someReportSelected(
            ReportAPI.ReportNames.EXTRACTOR_REPORT,
            ReportAPI.ReportNames.SLICE_REPORT
        );
        const showSelectBasemap = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_APPLICATION,
            ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_PLANTING,
            keywords.agvanceSkyScouting,
            keywords.agvanceSkyScoutingDetail,
            ReportAPI.ReportNames.AGVANCE_SKY_APPLICATION_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_CROP_PLANNING_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_FIELD_RECORD_NOTEBOOK,
            ReportAPI.ReportNames.AGVANCE_SKY_NUTRIENT_ATLAS,
            ReportAPI.ReportNames.AGVANCE_SKY_PLANTING_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_SOIL_TEST_RESULTS_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_SOILS_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_YIELD_REPORT,
            ReportAPI.ReportNames.AGOPTICS_REPORT,
            ReportAPI.ReportNames.ASK_REPORT,
            ReportAPI.ReportNames.COREMAX,
            ReportAPI.ReportNames.COREMAX_DEFINED_LEGEND_MULTIFIELD,
            ReportAPI.ReportNames.COREMAX_DEFINED_LEGEND_MULTIFIELD_PRODUCT_REC,
            ReportAPI.ReportNames.COREMAX_RESULTS_ONLY,
            ReportAPI.ReportNames.CROP_MAP_REPORT,
            ReportAPI.ReportNames.FOUR_MATION_RX_REPORT,
            ReportAPI.ReportNames.FOUR_MATION_ZONES_REPORT,
            ReportAPI.ReportNames.HYGROUND,
            ReportAPI.ReportNames.HYGROUND_DEFINED_LEGEND,
            ReportAPI.ReportNames.HYGROUND_DEFINED_LEGEND_EBU,
            ReportAPI.ReportNames.HYGROUND_DEFINED_LEGEND_MULTIFIELD,
            ReportAPI.ReportNames.HYGROUND_DEFINED_LEGEND_MULTIFIELD_PRODUCT_REC,
            ReportAPI.ReportNames.HYGROUND_DESERT,
            ReportAPI.ReportNames.HYGROUND_FSR,
            ReportAPI.ReportNames.HYGROUND_NORTHWEST,
            ReportAPI.ReportNames.HYGROUND_RESULTS_ONLY,
            ReportAPI.ReportNames.HYGROUND_WESTERN,
            ReportAPI.ReportNames.INSPEX_REPORT,
            ReportAPI.ReportNames.LOADSHEET,
            ReportAPI.ReportNames.NUTRIENT_MAP,
            ReportAPI.ReportNames.RECS_REPORT_APPLICATION,
            ReportAPI.ReportNames.RECS_REPORT_PLANTING,
            ReportAPI.ReportNames.SEED_STRONG_REPORT,
            ReportAPI.ReportNames.SEED_STRONG_DH_REPORT,
            ReportAPI.ReportNames.SEED_STRONG_RX_REPORT,
            ReportAPI.ReportNames.SOIL_TYPE_REPORT,
            ReportAPI.ReportNames.TRUE_REC_REPORT,
            ReportAPI.ReportNames.TRU_YIELD_IMPACT_REPORT,
            ReportAPI.ReportNames.YIELD_MAP_REPORT,
            ReportAPI.ReportNames.YIELD_ONE_IMPACT_REPORT,
            ReportAPI.ReportNames.EXTRACTOR_REPORT,
            ReportAPI.ReportNames.SLICE_REPORT,
            ReportAPI.ReportNames.PROFIT_OR_LOSS_MAP_REPORT,
            ReportAPI.ReportNames.ACCUPOINT,
            ReportAPI.ReportNames.ACCUPOINT_DEFINED_LEGEND_EBU,
            ReportAPI.ReportNames.ACCUPOINT_DEFINED_LEGEND_MULTIFIELD,
            ReportAPI.ReportNames.ACCUPOINT_DEFINED_LEGEND_MULTIFIELD_PRODUCT_REC,
            ReportAPI.ReportNames.PREVEAL_REPORT,
            ReportAPI.ReportNames.AGR_INTELLIGENCE_PROFIT_LOSS,
            ReportAPI.ReportNames.GROLINK_PROFIT_LOSS
        );
        const showCustomImagery = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_APPLICATION,
            ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_PLANTING,
            keywords.agvanceSkyScouting,
            keywords.agvanceSkyScoutingDetail,
            ReportAPI.ReportNames.AGVANCE_SKY_APPLICATION_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_CROP_PLANNING_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_NUTRIENT_ATLAS,
            ReportAPI.ReportNames.AGVANCE_SKY_PLANTING_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_SOIL_TEST_RESULTS_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_SOILS_REPORT,
            ReportAPI.ReportNames.AGVANCE_SKY_YIELD_REPORT,
            ReportAPI.ReportNames.AGOPTICS_REPORT,
            ReportAPI.ReportNames.ASK_REPORT,
            ReportAPI.ReportNames.COREMAX,
            ReportAPI.ReportNames.COREMAX_DEFINED_LEGEND_MULTIFIELD,
            ReportAPI.ReportNames.COREMAX_DEFINED_LEGEND_MULTIFIELD_PRODUCT_REC,
            ReportAPI.ReportNames.COREMAX_RESULTS_ONLY,
            ReportAPI.ReportNames.CROP_MAP_REPORT,
            ReportAPI.ReportNames.FOUR_MATION_RX_REPORT,
            ReportAPI.ReportNames.FOUR_MATION_ZONES_REPORT,
            ReportAPI.ReportNames.HYGROUND,
            ReportAPI.ReportNames.HYGROUND_DEFINED_LEGEND,
            ReportAPI.ReportNames.HYGROUND_DEFINED_LEGEND_EBU,
            ReportAPI.ReportNames.HYGROUND_DEFINED_LEGEND_MULTIFIELD,
            ReportAPI.ReportNames.HYGROUND_DEFINED_LEGEND_MULTIFIELD_PRODUCT_REC,
            ReportAPI.ReportNames.HYGROUND_DESERT,
            ReportAPI.ReportNames.HYGROUND_FSR,
            ReportAPI.ReportNames.HYGROUND_NORTHWEST,
            ReportAPI.ReportNames.HYGROUND_RESULTS_ONLY,
            ReportAPI.ReportNames.HYGROUND_WESTERN,
            ReportAPI.ReportNames.INSPEX_REPORT,
            ReportAPI.ReportNames.LOADSHEET,
            ReportAPI.ReportNames.NUTRIENT_MAP,
            ReportAPI.ReportNames.RECS_REPORT_APPLICATION,
            ReportAPI.ReportNames.RECS_REPORT_PLANTING,
            ReportAPI.ReportNames.SEED_STRONG_REPORT,
            ReportAPI.ReportNames.SEED_STRONG_DH_REPORT,
            ReportAPI.ReportNames.SEED_STRONG_RX_REPORT,
            ReportAPI.ReportNames.SOIL_TYPE_REPORT,
            ReportAPI.ReportNames.TRUE_REC_REPORT,
            ReportAPI.ReportNames.TRU_YIELD_IMPACT_REPORT,
            ReportAPI.ReportNames.YIELD_MAP_REPORT,
            ReportAPI.ReportNames.YIELD_ONE_IMPACT_REPORT,
            ReportAPI.ReportNames.EXTRACTOR_REPORT,
            ReportAPI.ReportNames.SLICE_REPORT,
            ReportAPI.ReportNames.PROFIT_OR_LOSS_MAP_REPORT,
            ReportAPI.ReportNames.ACCUPOINT,
            ReportAPI.ReportNames.ACCUPOINT_DEFINED_LEGEND_EBU,
            ReportAPI.ReportNames.ACCUPOINT_DEFINED_LEGEND_MULTIFIELD,
            ReportAPI.ReportNames.ACCUPOINT_DEFINED_LEGEND_MULTIFIELD_PRODUCT_REC,
            ReportAPI.ReportNames.PREVEAL_REPORT,
            ReportAPI.ReportNames.AGR_INTELLIGENCE_PROFIT_LOSS,
            ReportAPI.ReportNames.GROLINK_PROFIT_LOSS
        );
        const showGuaranteedAnalysisOption = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_APPLICATION,
            ReportAPI.ReportNames.RECS_REPORT_APPLICATION
        );
        const showLoadsheetOptions = this._someReportSelected(keywords.loadsheet);
        const showPageSelectionOption = this._someReportSelected(
            ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_APPLICATION,
            ReportAPI.ReportNames.RECS_REPORT_APPLICATION
        );
        const showPageSelectionBeforeRecSummary = this._someReportSelected(
            ReportAPI.ReportNames.ASK_REPORT,
            ReportAPI.ReportNames.COREMAX,
            ReportAPI.ReportNames.HYGROUND,
            ReportAPI.ReportNames.HYGROUND_DEFINED_LEGEND,
            ReportAPI.ReportNames.HYGROUND_DEFINED_LEGEND_EBU,
            ReportAPI.ReportNames.HYGROUND_FSR,
            ReportAPI.ReportNames.TRUE_REC_REPORT,
            ReportAPI.ReportNames.ACCUPOINT,
            ReportAPI.ReportNames.ACCUPOINT_DEFINED_LEGEND_EBU
        );

        const truYieldImpact = this._someReportSelected(
            ReportAPI.ReportNames.TRU_YIELD_IMPACT_REPORT,
            ReportAPI.ReportNames.YIELD_ONE_IMPACT_REPORT
        );

        return {
            allOptionsNA,
            seasonOptionsNA,
            seasonDisabled,
            cropOptionsNA,
            dateOptionsNA,
            disableBasemapOptionsWhenNoMapInReport,
            isNotFieldsPerPage,
            farmMap,
            hasWeatherReport,
            hideOverviewForPortrait,
            hygroundDefinedLegendsSelected,
            hygroundMultiFieldSelected,
            hygroundOrAskSelected,
            optionsDisabled,
            scoutingOptions,
            scoutingSummaryOptions,
            showApplicationAttribute,
            showApplicationMapOptions,
            applicationAttributeDisabled,
            showAgvanceColorOption,
            showCropMapOrientationOptions,
            showCropPlanningSummaryCheckbox,
            showCropPlanningCropZonesCheckbox,
            showDisplayParameters,
            showDisplaySampleSitesPosition1,
            showDisplaySampleSitesPosition2,
            showDisplayTypeOptions,
            showFieldLabelOptionsLink,
            showFieldsPerPageOption,
            showGeneralNotes,
            showGrowerSummaryCostDetail,
            showGrowerSummaryPerPageOption,
            showGuaranteedAnalysisOption,
            showLabelSizeOption,
            showLatLongQrOption,
            showLoadsheetOptions,
            showLowRateLegend,
            showMultipleMapsPerPage,
            showNumberOfSeasons,
            showNutrientTargetColors,
            showOriginalFormat,
            showPlantingRecCostDetail,
            showPrintFarmTotalsOption,
            showPrintFieldTotalsOption,
            showPrintGrowerTotalsOption,
            useImportDate,
            showOrientationOptions,
            showPageSelectionOption,
            showPageSelectionBeforeRecSummary,
            showPlantingOptions,
            showPLSSInfoCheckbox,
            showProductRecapOption,
            showSelectBasemap,
            showCustomImagery,
            showSoilTypeAttributeSelection,
            showUseCostDetails,
            showUseOverviewMap,
            showUseOverviewMapAfterPageSelection,
            showUseProductRecs,
            showYieldOptions,
            showYieldMapYieldOptions,
            truYieldImpact,
            showUsePolygonStatsOption,
        };
    };
    _getSeasonDisplayString = (): string => {
        const {
            seasons,
            selectedEventGuidSet,
            selectedRecGuidSet,
            agEventSummaryMap,
            recGeneralGuidToRecMap,
        } = this.props;
        const seasonGuids = [];
        const validEvents = this._getValidEvents();
        const validRecs = this._getValidRecs();

        for (const recGeneralGuid of selectedRecGuidSet) {
            const rec = this._retrieveOrEmptyObject(recGeneralGuidToRecMap, recGeneralGuid);
            if (validRecs.indexOf(rec.recType) > -1) {
                seasonGuids.push(rec.croppingSeasonGuid);
            }
        }
        for (const eventGeneralGuid of selectedEventGuidSet) {
            const event = this._retrieveOrEmptyObject(agEventSummaryMap, eventGeneralGuid);
            if (
                validEvents.some((ve) => event.agEventTypeName.indexOf(ve) > -1) &&
                this._checkEventProps(event)
            ) {
                seasonGuids.push(event.croppingSeasonGuid);
            }
        }
        const seasonsToDisplay = [];
        for (const seasonGuid of seasonGuids) {
            const selectedSeason = seasons.find((season) => season.value === seasonGuid);
            if (seasonsToDisplay.indexOf(selectedSeason.label) === -1) {
                seasonsToDisplay.push(selectedSeason.label);
            }
        }
        seasonsToDisplay.sort();
        return seasonsToDisplay.join(", ");
    };

    _updatePageSelection = (selectedReportTypePages: string): void => {
        this.props.onInputChange(
            model.PROPS_REPORT_TYPE_PAGE_SELECTION,
            selectedReportTypePages || {}
        );
    };

    _updateReportImageConfig = (newReportImageConfig: any): void => {
        const reportImageConfig = {
            ...(this.props.reportData[model.PROPS_REPORT_IMAGE_CONFIG] || {}),
            ...newReportImageConfig,
        };
        this.props.onInputChange(model.PROPS_REPORT_IMAGE_CONFIG, reportImageConfig);
    };

    _updateVisibleOptions = (props: IReportOptionsProps): void => {
        if (props.onVisibleOptionsChange != null) {
            const { selectedFieldCount, selectedRecGuidSet, selectedReportTypes } = props;
            const optionSettings: IGetOptionSettings = this._getOptionSettings(props);
            const primaryOptionsSection =
                optionSettings.hygroundOrAskSelected ||
                optionSettings.farmMap ||
                this._someReportSelected(ReportAPI.ReportNames.YIELD_BY_ANALYSIS_REPORT) ||
                optionSettings.showDisplaySampleSitesPosition1 ||
                optionSettings.showDisplaySampleSitesPosition2 ||
                optionSettings.showUseProductRecs ||
                optionSettings.showUseOverviewMap ||
                optionSettings.showUseOverviewMapAfterPageSelection ||
                optionSettings.showSelectBasemap ||
                optionSettings.showGuaranteedAnalysisOption ||
                optionSettings.showLoadsheetOptions ||
                optionSettings.scoutingOptions ||
                optionSettings.scoutingSummaryOptions ||
                optionSettings.showApplicationAttribute ||
                optionSettings.showPlantingOptions ||
                optionSettings.showOrientationOptions ||
                optionSettings.showCropMapOrientationOptions ||
                optionSettings.showProductRecapOption ||
                this._getShouldShowPageSelection();
            const showLabSubmission = this._someReportSelected(keywords.labSubmissionForm);
            const showSampleBags = this._someReportSelected(keywords.sampleBagLabels);

            const validator = (booleans) => {
                return booleans.every((boolean) => boolean === true);
            };

            props.onVisibleOptionsChange({
                [model.PROPS_AGE_OF_SAMPLE]:
                    selectedReportTypes.indexOf(keywords.soilTestingRequired) > -1,
                [model.PROPS_ALL_CUSTOMERS_FIELDS_YN]: this._someReportSelected(
                    keywords.soilTestingRequired
                ),
                [model.PROPS_BASEMAP]: true,
                [model.PROPS_BATCH_GUID]: true,
                [model.PROPS_BOUNDARY_MAP_QR]:
                    primaryOptionsSection &&
                    (optionSettings.farmMap || optionSettings.showLatLongQrOption),
                [model.PROPS_BREAK_EVEN_CROP_DATA]: optionSettings.truYieldImpact,
                [model.PROPS_CROPPING_SEASON_GUID_LIST]: validator([
                    !optionSettings.allOptionsNA,
                    !(optionSettings.seasonOptionsNA && optionSettings.cropOptionsNA),
                    !optionSettings.optionsDisabled,
                    !optionSettings.seasonDisabled,
                ]),
                [model.PROPS_CROP_DATA]:
                    (showLabSubmission && !showSampleBags) || (showLabSubmission && showSampleBags),
                [model.PROPS_CROP_GUID]: validator([
                    !optionSettings.allOptionsNA,
                    !(optionSettings.seasonOptionsNA && optionSettings.cropOptionsNA),
                    !optionSettings.cropOptionsNA,
                ]),
                [model.PROPS_CROP_MAP_ORIENTATION]:
                    primaryOptionsSection && optionSettings.showCropMapOrientationOptions,
                [model.PROPS_CROP_ZONE]:
                    primaryOptionsSection && optionSettings.showCropPlanningCropZonesCheckbox,
                [model.PROPS_DISPLAY_PARAMETERS]: optionSettings.showDisplayParameters,
                [model.PROPS_DISPLAY_SAMPLE_SITES]:
                    primaryOptionsSection &&
                    (optionSettings.showDisplaySampleSitesPosition1 ||
                        optionSettings.showDisplaySampleSitesPosition2),
                [model.PROPS_DISPLAY_TYPE]:
                    primaryOptionsSection && optionSettings.showDisplayTypeOptions,
                [model.PROPS_END_DATE]: validator([
                    !optionSettings.allOptionsNA,
                    !optionSettings.dateOptionsNA,
                ]),
                [model.PROPS_FIELD_LABEL_CONFIG]:
                    primaryOptionsSection && optionSettings.showFieldLabelOptionsLink,
                [model.PROPS_FIELD_GUID_LIST]: true,
                [model.PROPS_FIELD_ITEM_LIST]: true,
                [model.PROPS_FIELDS_PER_PAGE]:
                    primaryOptionsSection && optionSettings.showFieldsPerPageOption,
                [model.PROPS_GENERAL_NOTES]:
                    primaryOptionsSection && optionSettings.showGeneralNotes,
                [model.PROPS_GROWER_SUMMARY_DISPLAY_OPTION]:
                    primaryOptionsSection && optionSettings.showGrowerSummaryPerPageOption,
                [model.PROPS_HGSUMMARY_DISPLAY_OPTION]: this._someReportSelected(
                    ReportAPI.ReportNames.HYGROUND_SUMMARY,
                    ReportAPI.ReportNames.COREMAX_SUMMARY,
                    ReportAPI.ReportNames.ACCUPOINT_SUMMARY
                ),
                [model.PROPS_INCLUDE_PRODUCT_RECAP]:
                    primaryOptionsSection && optionSettings.showProductRecapOption,
                [model.PROPS_IS_HOURLY]: optionSettings.hasWeatherReport,
                [model.PROPS_LABEL_SIZE]:
                    primaryOptionsSection && optionSettings.showLabelSizeOption,
                [model.PROPS_LAB_ACCOUNT]:
                    (showLabSubmission && !showSampleBags) ||
                    (showLabSubmission && showSampleBags) ||
                    (!showLabSubmission && showSampleBags),
                [model.PROPS_LABEL_TYPE]: this._someReportSelected(
                    ReportAPI.ReportNames.CROP_MAP_REPORT
                ),
                [model.PROPS_LEGEND_TYPE]: this._someReportSelected(
                    ReportAPI.ReportNames.CROP_MAP_REPORT
                ),
                [model.PROPS_LOW_RATE_LEGEND]: optionSettings.showLowRateLegend,
                [model.PROPS_MULTI_FIELD_REC]:
                    primaryOptionsSection &&
                    selectedReportTypes.indexOf(keywords.loadsheet) > -1 &&
                    (selectedRecGuidSet.size > 1 || selectedFieldCount > 1),
                [model.PROPS_MULTIPLE_MAPS_PER_PAGE]:
                    primaryOptionsSection && optionSettings.showMultipleMapsPerPage,
                [model.PROPS_NUMBER_OF_SEASONS]:
                    primaryOptionsSection && optionSettings.showNumberOfSeasons,
                [model.PROPS_NUTRIENT_TARGET_COLORS]:
                    primaryOptionsSection && optionSettings.showNutrientTargetColors,
                [model.PROPS_ORIENTATION]:
                    primaryOptionsSection &&
                    ((optionSettings.farmMap && optionSettings.showOrientationOptions) ||
                        (optionSettings.showOrientationOptions && !optionSettings.farmMap)),
                [model.PROPS_ORIGINAL_FORMAT]: optionSettings.showOriginalFormat,
                [model.PROPS_PAGE_SIZE]: primaryOptionsSection && optionSettings.farmMap,
                [model.PROPS_PLANTING_ATTRIBUTE]:
                    primaryOptionsSection && optionSettings.showPlantingOptions,
                [model.PROPS_PLANTING_REC_COST_DETAIL]: optionSettings.showPlantingRecCostDetail,
                [model.PROPS_PLSS_INFO]:
                    primaryOptionsSection && optionSettings.showPLSSInfoCheckbox,
                [model.PROPS_PRINT_FARM_TOTALS]:
                    primaryOptionsSection && optionSettings.showPrintFarmTotalsOption,
                [model.PROPS_PRINT_FIELD_TOTALS]:
                    primaryOptionsSection && optionSettings.showPrintFieldTotalsOption,
                [model.PROPS_PRINT_GROWER_TOTALS]:
                    primaryOptionsSection && optionSettings.showPrintGrowerTotalsOption,
                [model.PROPS_REPORT_IMAGE_CONFIG]: primaryOptionsSection && optionSettings.farmMap,
                [model.PROPS_REPORT_STATUS]: true,
                [model.PROPS_REPORT_TYPE_GUID_LIST]: true,
                [model.PROPS_REPORT_TYPE_PAGE_SELECTION]:
                    primaryOptionsSection &&
                    (optionSettings.showPageSelectionBeforeRecSummary ||
                        (!optionSettings.showPageSelectionBeforeRecSummary &&
                            !optionSettings.showPageSelectionOption) ||
                        optionSettings.showPageSelectionOption) &&
                    this._getShouldShowPageSelection(),
                [model.PROPS_SAMPLE_BAG_LABEL_TYPE]:
                    (showLabSubmission && showSampleBags) || (!showLabSubmission && showSampleBags),
                [model.PROPS_SAMPLE_SITES]: true,
                [model.PROPS_SELECTED_BASEMAP]:
                    (primaryOptionsSection && optionSettings.showSelectBasemap) ||
                    optionSettings.farmMap ||
                    this._someReportSelected(
                        ReportAPI.ReportNames.APPLICATION_MAP,
                        ReportAPI.ReportNames.YIELD_BY_REPORT
                    ),
                [model.PROPS_SELECTED_EVENT_GUID_LIST]: true,
                [model.PROPS_SELECTED_REC_GUID_LIST]: true,
                [model.PROPS_SELECTED_FILENAME_FORMAT]:
                    primaryOptionsSection && optionSettings.showLoadsheetOptions,
                [model.PROPS_SHOW_GUARANTEED_ANALYSIS]:
                    primaryOptionsSection && optionSettings.showGuaranteedAnalysisOption,
                [model.PROPS_SHOW_LOADSHEET_PRODUCT_DETAIL]:
                    primaryOptionsSection && optionSettings.showLoadsheetOptions,
                [model.PROPS_SHOW_LOADSHEET_MULTI_FIELD_REC]:
                    primaryOptionsSection && optionSettings.showLoadsheetOptions,
                [model.PROPS_SHOW_NON_FIELD_FEATURES]:
                    primaryOptionsSection && optionSettings.farmMap,
                [model.PROPS_SHOW_GROWER_SUMMARY_COST_DETAIL]:
                    primaryOptionsSection && optionSettings.showGrowerSummaryCostDetail,
                [model.PROPS_SHOW_PHOTOS]:
                    primaryOptionsSection && optionSettings.scoutingSummaryOptions,
                [model.PROPS_SHOW_PRODUCT_REC_COST_DETAIL]:
                    primaryOptionsSection &&
                    optionSettings.showUseProductRecs &&
                    optionSettings.showUseCostDetails,
                [model.PROPS_SHOW_PRODUCT_REC_SUMMARY_COST_DETAIL]: this._someReportSelected(
                    ReportAPI.ReportNames.PRODUCT_REC_SUMMARY_REPORT
                ),
                [model.PROPS_SHOW_REC_SUMMARY]:
                    primaryOptionsSection &&
                    (optionSettings.hygroundOrAskSelected ||
                        !optionSettings.hygroundMultiFieldSelected),
                [model.PROPS_SHOW_SCOUTING_DETAILS]:
                    primaryOptionsSection &&
                    (optionSettings.scoutingOptions || optionSettings.scoutingSummaryOptions),
                [model.PROPS_SHOW_YIELD_POLYGON]: this._someReportSelected(
                    ReportAPI.ReportNames.YIELD_BY_REPORT,
                    ReportAPI.ReportNames.APPLICATION_MAP
                ),
                [model.PROPS_USE_IMPORT_DATE]: optionSettings.useImportDate,
                [model.PROPS_SOIL_TYPE_ATTRIBUTE]:
                    primaryOptionsSection && optionSettings.showSoilTypeAttributeSelection,
                [model.PROPS_START_DATE]: validator([
                    !optionSettings.allOptionsNA,
                    !optionSettings.dateOptionsNA,
                ]),
                [model.PROPS_START_POSITION]:
                    (showLabSubmission && showSampleBags) || (!showLabSubmission && showSampleBags),
                [model.PROPS_TESTING_LAB_SAMPLE_TYPE_GUID]:
                    (showLabSubmission && !showSampleBags) || (showLabSubmission && showSampleBags),
                [model.PROPS_TESTING_LAB_VENDOR_GUID]:
                    (showLabSubmission && !showSampleBags) || (showLabSubmission && showSampleBags),
                [model.PROPS_TEST_PACKAGE_GUID]:
                    (showLabSubmission && !showSampleBags) || (showLabSubmission && showSampleBags),
                [model.PROPS_MULTI_GROWER]: false,
                [model.PROPS_USE_CROP_PLANNING_SUMMARY]:
                    primaryOptionsSection && optionSettings.showCropPlanningSummaryCheckbox,
                [model.PROPS_USE_CUSTOM_IMAGERY]:
                    (primaryOptionsSection && optionSettings.showCustomImagery) ||
                    optionSettings.farmMap ||
                    this._someReportSelected(
                        ReportAPI.ReportNames.APPLICATION_MAP,
                        ReportAPI.ReportNames.YIELD_BY_REPORT
                    ),
                [model.PROPS_USE_OVERVIEW_MAP]:
                    primaryOptionsSection &&
                    (optionSettings.showUseOverviewMap ||
                        optionSettings.showUseOverviewMapAfterPageSelection),
                [model.PROPS_USE_PRODUCT_RECS]:
                    primaryOptionsSection && optionSettings.showUseProductRecs,
                [model.PROPS_YIELD_ATTRIBUTE]:
                    primaryOptionsSection && optionSettings.showYieldOptions,
                [model.PROPS_ZOOM_LEVEL]: primaryOptionsSection && optionSettings.farmMap,
                [model.PROPS_SKY_APPLICATION_ATTRIBUTE]:
                    primaryOptionsSection && optionSettings.showApplicationAttribute,
                [model.PROPS_APPLICATION_ATTRIBUTE]:
                    primaryOptionsSection && optionSettings.showApplicationMapOptions,
            });
        }
    };

    _updateFieldLabelConfig = (newFieldLabelConfig: any): void => {
        const { fieldLabelFields, fieldLabels } = newFieldLabelConfig;
        const fieldLabelConfig = {
            ...(this.props.reportData[model.PROPS_FIELD_LABEL_CONFIG] || {}),
            fieldLabelFields,
            fieldLabels,
        };
        this.props.onInputChange(model.PROPS_FIELD_LABEL_CONFIG, JSON.stringify(fieldLabelConfig));
    };

    _updateNonFieldFeatures = (value: boolean): void => {
        const { onInputChange } = this.props;
        onInputChange(model.PROPS_SHOW_NON_FIELD_FEATURES, value);

        // This is the result of this straddling the boundary between normal report option
        //   and something that really belongs in the report image config.
        const reportImageConfig = this.props.reportData[model.PROPS_REPORT_IMAGE_CONFIG];
        reportImageConfig.layers = reportImageConfig.layers.filter(
            (val) => val !== ReportsModels.layerType.nonFieldFeatures
        );
        if (value) {
            reportImageConfig.layers.push(ReportsModels.layerType.nonFieldFeatures);
        }
        this._updateReportImageConfig(reportImageConfig);
    };

    _updateReportOption = (modelVariable: string, value: any): void => {
        this.props.onInputChange(modelVariable, value);
    };

    getAgvanceColorOptions = (): IOption[] => {
        const options: IOption[] = [];
        options.push(
            {
                label: this.props.formatMessage(messages.crop),
                value: "crop",
            },
            {
                label: this.props.formatMessage(messages.cropChemistry),
                value: "cropChemistry",
            },
            {
                label: this.props.formatMessage(messages.cropPurposePlaceholderText),
                value: "cropPurpose",
            }
        );
        return options;
    };
    fetchSoilTypeAttributeOptions = (
        fieldGuid: string,
        selectedSoilTypeAttribute: string = null
    ): void => {
        if (this.state.soilTypeAttributeOptions.length > 0 || this.loadingSoilTypeAttributes) {
            return;
        }
        this.loadingSoilTypeAttributes = true;
        LayerAPI.getAvailableLayersByFieldGuid(this.props.userInfo.userGuid, fieldGuid).then(
            (layerInfoList) => {
                const soilLayers = [];
                const soilTypeAttributeOptions = [];
                for (const layerInfo of layerInfoList) {
                    const layerType = LayerUtilsAPI.getLayerType(layerInfo);
                    if (
                        layerType === LayerUtilsAPI.LayerType.SOIL &&
                        layerInfo.subLayers?.length > 0
                    ) {
                        soilLayers.push(...layerInfo.subLayers);
                    }
                }
                for (const layer of soilLayers) {
                    soilTypeAttributeOptions.push({
                        label: layer.displayName,
                        value: layer.displayName,
                    });
                }
                soilTypeAttributeOptions.sort((a, b) => a.label.localeCompare(b.label));
                const soilTypeName = soilTypeAttributeOptions.find(
                    (option) => option.value === constants.DEFAULT_SOIL_TYPE_ATTRIBUTE
                )?.value;
                this.setState({
                    soilTypeAttributeOptions,
                });
                this._updateReportOption(
                    model.PROPS_SOIL_TYPE_ATTRIBUTE,
                    selectedSoilTypeAttribute?.trim().length === 0 ||
                        soilTypeAttributeOptions.find(
                            (option) => option.value === selectedSoilTypeAttribute
                        ) == null
                        ? soilTypeName
                        : selectedSoilTypeAttribute
                );
            }
        );
    };

    setCurrentYearAsValue = (props: IReportOptionsProps): string => {
        if (
            props.reportData[model.PROPS_CROPPING_SEASON_GUID_LIST] &&
            props.reportData[model.PROPS_CROPPING_SEASON_GUID_LIST].length === 1
        ) {
            return props.reportData[model.PROPS_CROPPING_SEASON_GUID_LIST][0];
        }
        const selectedSeasonValue = this.getCurrentYearAsValue(props.seasons);
        if (selectedSeasonValue != null) {
            props.onInputChange(model.PROPS_CROPPING_SEASON_GUID_LIST, [selectedSeasonValue]);
        }
        return selectedSeasonValue;
    };

    getCurrentYearAsValue = (seasons: FieldAPI.ISeason[]): string => {
        const currentYear = moment(new Date(), "DD/MM/YYYY").year();
        const selectedSeason =
            seasons && seasons.filter((season) => season.label === `${currentYear}`);
        return selectedSeason.length > 0 ? selectedSeason[0].value : null;
    };

    onClickFieldLabelOptions = (): void => {
        this.setState({ showFieldLabelOptions: true });
    };

    onClickFieldSymbologyOptions = (): void => {
        this.setState({ showFieldSymbologyOptions: true });
    };

    onClickPageSelection = (): void => {
        this.setState({ showPageSelection: true });
    };

    onListDataChange = (key: string, value: any): void => {
        this.props.onInputChange(model.PROPS_CROP_DATA, value);
    };

    onBreakEvenDataDataChange = (key: string, value: any): void => {
        this.props.onInputChange(model.PROPS_BREAK_EVEN_CROP_DATA, value);
    };

    UNSAFE_componentWillReceiveProps(nextProps: IReportOptionsProps): void {
        if (!nextProps.reportData && nextProps.reportPreferences && nextProps.seasons.length > 0) {
            this.setInitialReportData(nextProps);
        }
        if (nextProps.defaultFileNameFormat !== this.props.defaultFileNameFormat) {
            nextProps.onInputChange(
                model.PROPS_SELECTED_FILENAME_FORMAT,
                nextProps.defaultFileNameFormat
            );
        }
        if (nextProps.defaultMultiFieldRec) {
            nextProps.onInputChange(
                model.PROPS_MULTI_FIELD_REC,
                (nextProps.selectedRecGuidSet.size > 1 || nextProps.selectedFieldCount > 1) &&
                    nextProps.defaultMultiFieldRec
            );
        }
        if (
            nextProps.reportData &&
            this.state.soilTypeAttributeOptions?.length === 0 &&
            nextProps.selectedFields?.length > 0
        ) {
            this.fetchSoilTypeAttributeOptions(
                nextProps.selectedFields[0].fieldGuid,
                nextProps.reportPreferences.soilTypeAttributeGuid
            );
        }
        if (
            nextProps.reportData &&
            !this.props.reportData &&
            nextProps.selectedReportTypes.length === 1
        ) {
            this._updateVisibleOptions(nextProps);
        }
        if (
            nextProps.selectedReportTypes.indexOf(keywords.weatherHistory) > -1 &&
            !this._someReportSelected(keywords.weatherHistory)
        ) {
            if (this.props.reportData[model.PROPS_IS_HOURLY]) {
                this.applyHourlyDefaults(nextProps.onInputChange);
            } else {
                this.applyDailyDefaults(nextProps.onInputChange);
            }
        }
    }

    vendorSampleTypeChanged = (value: string): void => {
        const leavePackageSelection =
            !value || value === this.props.reportData[model.PROPS_TESTING_LAB_SAMPLE_TYPE_GUID];
        this.props.onInputChange(model.PROPS_TESTING_LAB_SAMPLE_TYPE_GUID, value);
        if (!leavePackageSelection) {
            this.props.onInputChange(model.PROPS_TEST_PACKAGE_GUID, null);
            this.props.onInputChange(model.PROPS_SELECTED_TEST_PACKAGES, null);
        }
    };

    renderFieldSymbologyOptions = (isNotFieldsPerPage = false): ReactElement => {
        const { formatMessage } = this.props;
        const reportFieldSymbologyOptionsProps = {
            dialogOptions: {
                labelOptionSectionHeader: formatMessage(messages.fieldSymbologyBoundaryLabels),
            },
            dialogTitle: formatMessage(messages.fieldSymbologyOptions),
            isOpen: this.state.showFieldSymbologyOptions,
            onChange: (value) => {
                this._updateReportImageConfig(value);
                this.setState({
                    showFieldSymbologyOptions: false,
                });
            },
            onClose: () => {
                this.setState({
                    showFieldSymbologyOptions: false,
                });
            },
            reportImageConfig: this.props.reportData[model.PROPS_REPORT_IMAGE_CONFIG],
        };
        return (
            <React.Fragment>
                <SubSection className="two-wide field-symbology-options">
                    <NoLink
                        label={formatMessage(messages.fieldSymbologyOptions)}
                        onClick={() => {
                            this.onClickFieldSymbologyOptions();
                        }}
                    />
                    {this._createOptionsCheckbox(
                        model.PROPS_BOUNDARY_MAP_QR,
                        messages.showQrCode,
                        "",
                        {},
                        isNotFieldsPerPage
                    )}
                </SubSection>
                <ReportFieldSymbologyOptions {...reportFieldSymbologyOptionsProps} />
            </React.Fragment>
        );
    };

    renderFieldLabelOptions = (): ReactElement => {
        const { formatMessage } = this.props;
        const reportFieldSymbologyOptionsProps = {
            dialogOptions: {
                labelOptionSectionHeader: "",
                showBoundaryOptions: false,
            },
            dialogTitle: formatMessage(messages.fieldLabelOptions),
            isOpen: this.state.showFieldLabelOptions,
            onChange: (value) => {
                this._updateFieldLabelConfig(value);
                this.setState({
                    showFieldLabelOptions: false,
                });
            },
            onClose: () => {
                this.setState({
                    showFieldLabelOptions: false,
                });
            },
            reportId: ReportAPI.ReportNames.AGVANCE_SKY_CROP_PLANNING_REPORT,
            reportImageConfig: this.props.reportData[model.PROPS_FIELD_LABEL_CONFIG],
        };
        return (
            <React.Fragment>
                <NoLink
                    className="categories-link report-options-modal-link farm-map-label-color-options"
                    label={formatMessage(messages.fieldLabelOptions)}
                    onClick={() => {
                        this.onClickFieldLabelOptions();
                    }}
                />
                <ReportFieldSymbologyOptions {...reportFieldSymbologyOptionsProps} />
            </React.Fragment>
        );
    };

    renderPageSelectionOption = (): ReactElement => {
        const { formatMessage, selectedReportTypes } = this.props;
        const title = selectedReportTypes.includes(
            ReportAPI.ReportNames.AGVANCE_SKY_SOIL_TEST_RESULTS_REPORT
        )
            ? formatMessage(messages.attributeSelection)
            : formatMessage(messages.pageSelection);

        const reportPageSelectionProps = {
            isOpen: this.state.showPageSelection,
            onChange: (value) => {
                this._updatePageSelection(value);
                this.setState({
                    showPageSelection: false,
                });
            },
            onClose: () => {
                this.setState({
                    showPageSelection: false,
                });
            },
            reportTypePages: this.props.reportTypePages,
            reportTypePagesPrefs: this.props.reportPreferences.reportTypePageSelection,
            selectedReportTypes: this.props.selectedReportTypes,
            selectedReportTypePages: this.props.reportData[model.PROPS_REPORT_TYPE_PAGE_SELECTION],
            title: title,
        };

        return (
            <React.Fragment>
                <NoLink
                    className="categories-link report-options-modal-link page-selection"
                    label={title}
                    onClick={() => {
                        this.onClickPageSelection();
                    }}
                />
                <ReportPageSelection {...reportPageSelectionProps} />
            </React.Fragment>
        );
    };

    renderSampleSitesOption = (): ReactElement => {
        const { formatMessage } = this.props;
        return (
            <Checkbox
                label={formatMessage(messages.displaySampleSites)}
                onChange={(e, value) =>
                    this._updateReportOption(model.PROPS_DISPLAY_SAMPLE_SITES, value)
                }
                value={this.props.reportData[model.PROPS_DISPLAY_SAMPLE_SITES]}
            />
        );
    };
    renderSampleTypeTestingLab = (
        formatMessage: FormatMessage,
        onInputChange: OnInputChange
    ): ReactElement[] => [
        <PicklistSelectInput
            key="sample-type"
            picklistName={picklistNames.PICKLIST_SAMPLE_TYPE}
            required
            placeholderText={formatMessage(messages.sampleType)}
            value={this.props.reportData[model.PROPS_TESTING_LAB_SAMPLE_TYPE_GUID]}
            onChange={(value: string) => this.vendorSampleTypeChanged(value)}
            optionListFilter={(item) =>
                item.label === "Tissue" || item.label === "Soil" || item.label === "Water"
            }
        />,
        <SelectInput
            key="testing-lab"
            disabled={!this.props.reportData[model.PROPS_TESTING_LAB_SAMPLE_TYPE_GUID]}
            optionIsHiddenKey={ACTIVE_YN}
            value={this.props.reportData[model.PROPS_SELECTED_TESTING_LAB]}
            placeholderText={formatMessage(messages.testingLab)}
            options={this.props.testingLabVendors
                .filter((v) => v.activeYn)
                .map((v) => ({
                    label: v.vendorName,
                    value: v.vendorGuid,
                }))}
            onChange={(selectedTestingLab = "") => {
                if (selectedTestingLab) {
                    this.props.getTestPackageListData(selectedTestingLab);
                }
                onInputChange(model.PROPS_SELECTED_TEST_PACKAGES, null);
                onInputChange(model.PROPS_SELECTED_TESTING_LAB, selectedTestingLab);
                onInputChange(model.PROPS_TEST_PACKAGE_GUID, "");
                onInputChange(model.PROPS_TESTING_LAB_VENDOR_GUID, selectedTestingLab);
            }}
        />,
    ];

    renderTestPackage = (
        formatMessage: FormatMessage,
        onInputChange: OnInputChange
    ): ReactElement => {
        const sampleTypeGuid = this.props.reportData[model.PROPS_TESTING_LAB_SAMPLE_TYPE_GUID];
        const packageList =
            sampleTypeGuid && this.props.testPackages
                ? this.props.testPackages.filter((p) => p.sampleTypeGuid === sampleTypeGuid)
                : this.props.testPackages;

        const testingLabNotSelected = !this.props.reportData[model.PROPS_SELECTED_TESTING_LAB];

        return (
            <SelectInput
                disabled={testingLabNotSelected}
                required={!testingLabNotSelected}
                value={this.props.reportData[model.PROPS_SELECTED_TEST_PACKAGES]}
                optionIsHiddenKey={ACTIVE_YN}
                placeholderText={formatMessage(messages.testPackages)}
                options={packageList.map((p) => ({
                    label: p.testPackageId,
                    value: p.testPackageGuid,
                }))}
                onChange={(selectedTestPackages = "") => {
                    onInputChange(model.PROPS_SELECTED_TEST_PACKAGES, selectedTestPackages);
                    onInputChange(model.PROPS_TEST_PACKAGE_GUID, selectedTestPackages);
                }}
            />
        );
    };

    renderLabAccount = (
        formatMessage: FormatMessage,
        onInputChange: OnInputChange
    ): ReactElement => (
        <TextInput
            containerClassNames={["lab-account"]}
            value={this.props.reportData[model.PROPS_LAB_ACCOUNT]}
            maxLength={20}
            placeholderText={formatMessage(messages.labAccount)}
            onChange={(selectedLabAccount = "") => {
                onInputChange(model.PROPS_LAB_ACCOUNT, selectedLabAccount);
            }}
        />
    );

    renderSampleBagLabelOptions = (formatMessage: FormatMessage): ReactElement => (
        <SelectInput
            required
            containerClassNames={["select-form-input-container"]}
            clearable={false}
            placeholderText={formatMessage(messages.labelType)}
            options={this.sampleBagLabelOptions}
            onChange={(value) => this._updateReportOption(model.PROPS_SAMPLE_BAG_LABEL_TYPE, value)}
            value={this.props.reportData[model.PROPS_SAMPLE_BAG_LABEL_TYPE]}
        />
    );

    renderStartPosition = (
        formatMessage: FormatMessage,
        onInputChange: OnInputChange
    ): ReactElement => (
        <NumericInput
            disabled={
                this.props.reportData[model.PROPS_SAMPLE_BAG_LABEL_TYPE] ===
                constants.SAMPLE_BAG_LABEL_TYPES.Thermal
            }
            precision={2}
            scale={0}
            value={this.props.reportData[model.PROPS_START_POSITION]}
            onChange={(value: string, formattedValue: string, numericValue: number) => {
                onInputChange(model.PROPS_START_POSITION, numericValue);
            }}
            placeholderText={formatMessage(messages.startPosition)}
            containerClassNames={["start-position"]}
        />
    );

    renderCropYieldGoals = (formatMessage: FormatMessage, isFullWidth: boolean): ReactElement => (
        <CropYieldList
            className={isFullWidth ? "full-width" : ""}
            formatMessage={formatMessage}
            record={this.state.cropData}
            onTextChange={this.onListDataChange}
            crops={this.props.crops}
            cropPurpose={this.props.cropPurpose}
            itemListAlias={model.PROPS_CROP_DATA}
        />
    );

    renderLabSubmissionSampleBagOptions = (): ReactElement => {
        const { formatMessage, onInputChange } = this.props;
        const showLabSubmission = this._someReportSelected(keywords.labSubmissionForm);
        const showSampleBags = this._someReportSelected(keywords.sampleBagLabels);

        if (!showLabSubmission && !showSampleBags) {
            return null;
        } else if (showLabSubmission && !showSampleBags) {
            return (
                <SubSection key="labsubmission-samplingbag" className="two-wide">
                    {this.renderSampleTypeTestingLab(formatMessage, onInputChange)}
                    {this.renderLabAccount(formatMessage, onInputChange)}
                    {this.renderTestPackage(formatMessage, onInputChange)}
                    {this.renderCropYieldGoals(formatMessage, true)}
                </SubSection>
            );
        } else if (showLabSubmission && showSampleBags) {
            return (
                <SubSection key="labsubmission-samplingbag" className="two-wide">
                    {this.renderSampleBagLabelOptions(formatMessage)}
                    {this.renderSampleTypeTestingLab(formatMessage, onInputChange)}
                    {this.renderLabAccount(formatMessage, onInputChange)}
                    {this.renderTestPackage(formatMessage, onInputChange)}
                    {this.renderStartPosition(formatMessage, onInputChange)}
                    {this.renderCropYieldGoals(formatMessage, true)}
                </SubSection>
            );
        } else {
            return (
                <SubSection key="labsubmission-samplingbag" className="two-wide force-wrap">
                    {this.renderSampleBagLabelOptions(formatMessage)}
                    {this.renderLabAccount(formatMessage, onInputChange)}
                    {this.renderStartPosition(formatMessage, onInputChange)}
                </SubSection>
            );
        }
    };

    renderHygroundSummaryDisplayOption = (formatMessage: FormatMessage): ReactElement => (
        <SubSection className="two-wide">
            <SelectInput
                required
                clearable={false}
                optionIsHiddenKey={ACTIVE_YN}
                containerClassNames={["hg-display-options-list"]}
                placeholderText={formatMessage(messages.displayOptions)}
                options={getFieldsPerPageOptions()}
                onChange={(value) =>
                    this._updateReportOption(model.PROPS_HGSUMMARY_DISPLAY_OPTION, value)
                }
                value={this.props.reportData[model.PROPS_HGSUMMARY_DISPLAY_OPTION]}
                disabled={this.props.reportData[model.PROPS_MULTI_GROWER]}
            />
            {this._isMultiFieldAcrossCustomerSelected()
                ? this._createReportOptionsCheckbox(model.PROPS_MULTI_GROWER, messages.multiGrower)
                : null}
        </SubSection>
    );

    _isMultiFieldAcrossCustomerSelected = (): boolean => {
        const { selectedFields } = this.props;
        return new Set(selectedFields.map((x) => x.customerGuid)).size > 1;
    };

    renderAGRProfitLossDisplayOption = (formatMessage: FormatMessage) => (
        <SubSection className="two-wide">
            <SelectInput
                required
                clearable={false}
                optionIsHiddenKey={ACTIVE_YN}
                containerClassNames={["hg-display-options-list"]}
                placeholderText={formatMessage(messages.displayOptions)}
                options={getFieldsPerPageOptions().filter(
                    (x) => x.value.localeCompare("field") !== 0
                )}
                onChange={(value) =>
                    this._updateReportOption(model.PROPS_AGRPL_DISPLAY_OPTION, value)
                }
                value={this.props.reportData[model.PROPS_AGRPL_DISPLAY_OPTION]}
            />
        </SubSection>
    );

    renderYieldByDisplayType = (
        formatMessage: FormatMessage,
        onInputChange: OnInputChange
    ): ReactElement => (
        <SelectInput
            required
            containerClassNames={["yield-by-display-type"]}
            options={this._buildYieldByDisplayTypeOptions()}
            placeholderText={formatMessage(messages.displayType)}
            onChange={(stringValue) => {
                const value = stringValue !== constants.DEFAULT_DISPLAY_TYPE;
                onInputChange(model.PROPS_SHOW_YIELD_POLYGON, value);
            }}
            value={
                this.props.reportData[model.PROPS_SHOW_YIELD_POLYGON] === true
                    ? "polygon"
                    : this.props.reportData[model.PROPS_SHOW_YIELD_POLYGON] === false
                    ? constants.DEFAULT_DISPLAY_TYPE
                    : null
            }
        />
    );

    renderYieldMapYieldDisplayOptions = (formatMessage: FormatMessage): ReactElement => (
        <SelectInput
            required
            containerClassNames={["display-options-list"]}
            clearable={false}
            placeholderText={formatMessage(messages.yieldAttribute)}
            options={this.yieldMapYieldAttributeOptions}
            onChange={(value) => this._updateReportOption(model.PROPS_YIELD_ATTRIBUTE, value)}
            value={this.props.reportData[model.PROPS_YIELD_ATTRIBUTE]}
        />
    );

    renderApplicationMapApplicationAttributeOptions = (
        formatMessage: FormatMessage,
        applicationAttributeDisabled: boolean
    ): ReactElement =>
        !applicationAttributeDisabled ? (
            <SelectInput
                disabled={true}
                required
                containerClassNames={["display-options-list"]}
                clearable={false}
                placeholderText={formatMessage(messages.applicationAttribute)}
                options={this.applicationMapAttributeOptions}
                onChange={(value) =>
                    this._updateReportOption(model.PROPS_APPLICATION_ATTRIBUTE, value)
                }
                value={this.props.reportData[model.PROPS_APPLICATION_ATTRIBUTE]}
            />
        ) : (
            <SelectInput
                required
                containerClassNames={["display-options-list"]}
                clearable={false}
                placeholderText={formatMessage(messages.applicationAttribute)}
                options={this.applicationMapAttributeOptions}
                onChange={(value) =>
                    this._updateReportOption(model.PROPS_APPLICATION_ATTRIBUTE, value)
                }
                value={this.props.reportData[model.PROPS_APPLICATION_ATTRIBUTE]}
            />
        );

    updateValue = (key: string, newValue: boolean): void => {
        if (this.props.reportData[key] !== newValue) {
            this.props.onInputChange(key, newValue);
        }
    };

    render(): ReactElement {
        const {
            formatMessage,
            onInputChange,
            selectedReportTypes,
            fileNameFormats = [],
            reportData,
        } = this.props;

        if (!reportData) {
            return null;
        }

        const {
            ageOfSample,
            isHourly,
            multiFieldRec,
            selectedFileNameFormat,
            showNonFieldFeatures,
            useProductRecs,
        } = reportData;
        const agvanceColorOptions = this.getAgvanceColorOptions();
        const fileNameFormatOptions: IOption[] = fileNameFormats
            .filter((o) => o.isMergeController === multiFieldRec)
            .map((o) => {
                return { label: o.name, value: o.guid };
            });
        const exportFormat = fileNameFormatOptions.find((o) => o.value === selectedFileNameFormat)
            ? selectedFileNameFormat
            : null;
        const basemapOptions = getBasemapOptions();
        const pageSizeOptions = getPageSizeOptions();
        const fieldsPerPageOptions = getFieldsPerPageOptions();
        const labelSizeOptions = getLabelSizeOptions();
        const zoomLevelOptions = getZoomLevelOptions();

        const {
            allOptionsNA,
            seasonOptionsNA,
            seasonDisabled,
            cropOptionsNA,
            dateOptionsNA,
            disableBasemapOptionsWhenNoMapInReport,
            isNotFieldsPerPage,
            farmMap,
            hasWeatherReport,
            hideOverviewForPortrait,
            hygroundMultiFieldSelected,
            hygroundOrAskSelected,
            optionsDisabled,
            scoutingOptions,
            scoutingSummaryOptions,
            showAgvanceColorOption,
            showCropMapOrientationOptions,
            showCropPlanningSummaryCheckbox,
            showCropPlanningCropZonesCheckbox,
            showDisplayParameters,
            showDisplaySampleSitesPosition1,
            showDisplaySampleSitesPosition2,
            showDisplayTypeOptions,
            showFieldLabelOptionsLink,
            showFieldsPerPageOption,
            showGeneralNotes,
            showGrowerSummaryCostDetail,
            showGrowerSummaryPerPageOption,
            showGuaranteedAnalysisOption,
            showLabelSizeOption,
            showLatLongQrOption,
            showLoadsheetOptions,
            showLowRateLegend,
            showMultipleMapsPerPage,
            showNumberOfSeasons,
            showNutrientTargetColors,
            showOriginalFormat,
            showPlantingRecCostDetail,
            useImportDate,
            showOrientationOptions,
            showPageSelectionOption,
            showPageSelectionBeforeRecSummary,
            showPlantingOptions,
            showPLSSInfoCheckbox,
            showPrintFarmTotalsOption,
            showPrintFieldTotalsOption,
            showPrintGrowerTotalsOption,
            showProductRecapOption,
            showSelectBasemap,
            showCustomImagery,
            showSoilTypeAttributeSelection,
            showUseCostDetails,
            showUseOverviewMap,
            showUseOverviewMapAfterPageSelection,
            showUseProductRecs,
            showYieldOptions,
            showYieldMapYieldOptions,
            truYieldImpact,
            showApplicationAttribute,
            showApplicationMapOptions,
            applicationAttributeDisabled,
            showUsePolygonStatsOption,
        } = this._getOptionSettings();

        const orientationOptions = (
            <SelectInput
                required
                containerClassNames={["display-options-list"]}
                clearable={false}
                placeholderText={formatMessage(messages.orientation)}
                options={this.orientationOptions}
                onChange={(value) => this._updateReportOption(model.PROPS_ORIENTATION, value)}
                value={this.props.reportData[model.PROPS_ORIENTATION]}
            />
        );

        const fieldsPerPageSelectOptions = (
            <SelectInput
                required
                clearable={false}
                optionIsHiddenKey={ACTIVE_YN}
                containerClassNames={["display-options-list"]}
                placeholderText={formatMessage(messages.fieldsPerPageOptions)}
                options={fieldsPerPageOptions}
                onChange={(value) => this._updateReportOption(model.PROPS_FIELDS_PER_PAGE, value)}
                value={this.props.reportData[model.PROPS_FIELDS_PER_PAGE]}
            />
        );

        const labelSizeSelectOptions = (
            <SelectInput
                required
                clearable={false}
                optionIsHiddenKey={ACTIVE_YN}
                containerClassNames={["display-options-list"]}
                placeholderText={formatMessage(messages.labelSize)}
                options={labelSizeOptions}
                onChange={(value) => this._updateReportOption(model.PROPS_LABEL_SIZE, value)}
                value={this.props.reportData[model.PROPS_LABEL_SIZE]}
            />
        );

        const soilTypeAttributeSelectOptions = (
            <SelectInput
                required
                clearable={false}
                optionIsHiddenKey={ACTIVE_YN}
                containerClassNames={["display-options-list"]}
                placeholderText={formatMessage(messages.soilTypeAttribute)}
                options={this.state.soilTypeAttributeOptions}
                onChange={(value) =>
                    this._updateReportOption(model.PROPS_SOIL_TYPE_ATTRIBUTE, value)
                }
                value={this.props.reportData[model.PROPS_SOIL_TYPE_ATTRIBUTE]}
            />
        );

        const pageSelectionOption = this._getShouldShowPageSelection()
            ? this.renderPageSelectionOption()
            : null;

        const { showScoutingDetails } = this.props.reportData;

        const disableBasemapOptions =
            disableBasemapOptionsWhenNoMapInReport &&
            !this.props.reportData[model.PROPS_DISPLAY_SAMPLE_SITES];
        const disableUseOverviewMap =
            disableBasemapOptions ||
            (hideOverviewForPortrait &&
                this.props.reportData[model.PROPS_ORIENTATION] ===
                    ReportsModels.PageOrientation.PORTRAIT) ||
            (this._someReportSelected(
                ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_APPLICATION,
                ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_PLANTING
            ) &&
                this.props.reportData[model.PROPS_SHOW_PRODUCT_REC_COST_DETAIL]);

        const useOverviewMap = (
            <Checkbox
                label={formatMessage(messages.showOverviewMap)}
                disabled={disableUseOverviewMap}
                onChange={(e, value) =>
                    this._updateReportOption(model.PROPS_USE_OVERVIEW_MAP, value)
                }
                value={this.props.reportData[model.PROPS_USE_OVERVIEW_MAP]}
            />
        );

        const displaySampleSitesPosition1 = showDisplaySampleSitesPosition1
            ? this.renderSampleSitesOption()
            : null;
        const displaySampleSitesPosition2 = showDisplaySampleSitesPosition2
            ? this.renderSampleSitesOption()
            : null;

        const dateOptionsFragment = (
            <React.Fragment>
                <SubSection>
                    <DateInput
                        disabled={optionsDisabled}
                        required={hasWeatherReport}
                        placeholderText={formatMessage(messages.startDate)}
                        onChange={(value) => {
                            this.props.onInputChange(model.PROPS_START_DATE, value);
                        }}
                        clearDisabled={hasWeatherReport}
                        timeFormat={hasWeatherReport && isHourly ? "h a" : false}
                        value={
                            optionsDisabled ? null : this.props.reportData[model.PROPS_START_DATE]
                        }
                    />
                    <DateInput
                        disabled={optionsDisabled}
                        required={hasWeatherReport}
                        placeholderText={formatMessage(messages.endDate)}
                        onChange={(value) => {
                            this.props.onInputChange(model.PROPS_END_DATE, value);
                        }}
                        clearDisabled={hasWeatherReport}
                        timeFormat={hasWeatherReport && isHourly ? "h a" : false}
                        value={optionsDisabled ? null : this.props.reportData[model.PROPS_END_DATE]}
                    />
                </SubSection>
            </React.Fragment>
        );

        const cropOptions =
            this.props.crops
                ?.filter((c) => c.activeYn)
                .map((c) => ({ label: c.label, value: c.value.cropGuid })) || [];
        const cropOptionsFragment = (
            <React.Fragment>
                <SelectInput
                    disabled={optionsDisabled}
                    optionIsHiddenKey={ACTIVE_YN}
                    placeholderText={formatMessage(messages.crop)}
                    options={optionsDisabled ? [] : cropOptions}
                    onChange={(value) => {
                        onInputChange(model.PROPS_CROP_GUID, value ? value : "");
                    }}
                    value={
                        optionsDisabled
                            ? null
                            : cropOptions.find(
                                  (c) => c.value === this.props.reportData[model.PROPS_CROP_GUID]
                              )?.value
                    }
                />
            </React.Fragment>
        );

        const numberOfSeasonsFragment = (
            <React.Fragment>
                {optionsDisabled ? (
                    <TextInput
                        disabled={true}
                        placeholderText={formatMessage(messages.numberOfSeasons)}
                        labelText={formatMessage(messages.numberOfSeasons)}
                        value={this.props.reportData[model.PROPS_NUMBER_OF_SEASONS]}
                    />
                ) : seasonDisabled ? (
                    <SelectInput
                        clearable={false}
                        disabled={true}
                        optionIsHiddenKey={ACTIVE_YN}
                        placeholderText={formatMessage(messages.numberOfSeasons)}
                    />
                ) : (
                    <SelectInput
                        clearable={false}
                        disabled={optionsDisabled}
                        optionIsHiddenKey={ACTIVE_YN}
                        containerClassNames={["display-options-list"]}
                        placeholderText={formatMessage(messages.numberOfSeasons)}
                        options={this.numberOfSeasonsOptions}
                        onChange={(value) => {
                            this._updateReportOption(model.PROPS_NUMBER_OF_SEASONS, value);
                        }}
                        value={this.props.reportData[model.PROPS_NUMBER_OF_SEASONS]}
                    />
                )}
            </React.Fragment>
        );

        const renderBreakEvenYieldList = (): ReactElement => {
            const breakEvenYieldListProps = {
                formatMessage: formatMessage,
                record: this.props.reportData[model.PROPS_BREAK_EVEN_CROP_DATA],
                onTextChange: this.onBreakEvenDataDataChange,
                crops: this.props.crops,
                cropPurpose: this.props.cropPurpose,
            };
            return (
                <React.Fragment>
                    <BreakEvenYieldList {...breakEvenYieldListProps} />
                </React.Fragment>
            );
        };

        const renderLoadSheetMultiFieldRec = (): ReactElement => {
            const { selectedRecGuidSet, selectedFieldCount = 0 } = this.props;
            if (selectedRecGuidSet.size > 1 || selectedFieldCount > 1) {
                return (
                    <Checkbox
                        containerClassNames={["event-id-checkbox"]}
                        onChange={(e, value) =>
                            this.updateValue(model.PROPS_SHOW_LOADSHEET_MULTI_FIELD_REC, value)
                        }
                        value={this.props.reportData[model.PROPS_SHOW_LOADSHEET_MULTI_FIELD_REC]}
                        label={formatMessage(messages.multiFieldRec)}
                    />
                );
            }
            return null;
        };

        const seasonGuid =
            this.props.reportData[model.PROPS_CROPPING_SEASON_GUID_LIST] != null
                ? this.props.reportData[model.PROPS_CROPPING_SEASON_GUID_LIST][0]
                : null;

        const seasonFragment = (
            <React.Fragment>
                {optionsDisabled ? (
                    <TextInput
                        disabled={true}
                        placeholderText={formatMessage(messages.season)}
                        labelText={formatMessage(messages.season)}
                        value={this._getSeasonDisplayString()}
                    />
                ) : seasonDisabled ? (
                    <SelectInput
                        clearable={false}
                        disabled={true}
                        optionIsHiddenKey={ACTIVE_YN}
                        placeholderText={formatMessage(messages.season)}
                    />
                ) : (
                    <SelectInput
                        required
                        clearable={false}
                        disabled={optionsDisabled}
                        optionIsHiddenKey={ACTIVE_YN}
                        placeholderText={formatMessage(messages.season)}
                        options={this.props.seasons}
                        onChange={(value) => {
                            onInputChange(model.PROPS_CROPPING_SEASON_GUID_LIST, [value]);
                        }}
                        value={seasonGuid}
                    />
                )}
            </React.Fragment>
        );

        const seasonCropDateOptions = (
            <React.Fragment>
                {!this.props.alwaysShowDefaultOptions ? null : (
                    <SubSection>
                        {seasonFragment}
                        {!showNumberOfSeasons ? cropOptionsFragment : numberOfSeasonsFragment}
                    </SubSection>
                )}
                {this.props.alwaysShowDefaultOptions ||
                allOptionsNA ||
                (seasonOptionsNA && cropOptionsNA) ? null : (
                    <SubSection>
                        {seasonOptionsNA ? null : seasonFragment}
                        {!showNumberOfSeasons
                            ? cropOptionsNA
                                ? null
                                : cropOptionsFragment
                            : numberOfSeasonsFragment}
                    </SubSection>
                )}
                {!this.props.alwaysShowDefaultOptions ? null : dateOptionsFragment}
                {this.props.alwaysShowDefaultOptions || allOptionsNA || dateOptionsNA
                    ? null
                    : dateOptionsFragment}

                {!this.props.alwaysShowDefaultOptions ? null : !showNumberOfSeasons ? null : (
                    <SubSection>{cropOptionsFragment}</SubSection>
                )}
                {this.props.alwaysShowDefaultOptions || !showNumberOfSeasons ? null : (
                    <SubSection>{cropOptionsFragment}</SubSection>
                )}
            </React.Fragment>
        );

        const sectionReportOptionsContentProps = {
            classNames: "report-options-content",
        };

        if (this.props.hideReportOptions) {
            return (
                <div className="report-options">
                    <Section {...sectionReportOptionsContentProps}>{seasonCropDateOptions}</Section>
                </div>
            );
        }

        return (
            <div className="report-options">
                <Section {...sectionReportOptionsContentProps}>
                    {seasonCropDateOptions}
                    {this.renderLabSubmissionSampleBagOptions()}
                    {!(selectedReportTypes.indexOf(keywords.soilTestingRequired) > -1) ? null : (
                        <SubSection>
                            <SelectInput
                                required
                                clearable={false}
                                containerClassNames={["display-options-list"]}
                                placeholderText={formatMessage(messages.ageOfSample)}
                                options={this.ageOfSampleOptions}
                                value={ageOfSample}
                                onChange={(value) => {
                                    onInputChange(model.PROPS_AGE_OF_SAMPLE, value);
                                }}
                            />
                        </SubSection>
                    )}
                    {!(
                        hygroundOrAskSelected ||
                        farmMap ||
                        this._someReportSelected(ReportAPI.ReportNames.YIELD_BY_ANALYSIS_REPORT) ||
                        showDisplaySampleSitesPosition1 ||
                        showDisplaySampleSitesPosition2 ||
                        showUseProductRecs ||
                        showUseOverviewMap ||
                        showUseOverviewMapAfterPageSelection ||
                        showSelectBasemap ||
                        showGuaranteedAnalysisOption ||
                        showLoadsheetOptions ||
                        scoutingOptions ||
                        scoutingSummaryOptions ||
                        showApplicationAttribute ||
                        showPlantingOptions ||
                        showOrientationOptions ||
                        showCropMapOrientationOptions ||
                        showProductRecapOption ||
                        showLatLongQrOption ||
                        this._getShouldShowPageSelection()
                    ) ? null : (
                        <SubSection className="two-report-options">
                            {!farmMap ? null : (
                                <React.Fragment>
                                    <SelectInput
                                        required
                                        clearable={false}
                                        optionIsHiddenKey={ACTIVE_YN}
                                        containerClassNames={["display-options-list"]}
                                        placeholderText={formatMessage(messages.pageSizeOptions)}
                                        options={pageSizeOptions}
                                        onChange={(value) =>
                                            this._updateReportOption(model.PROPS_PAGE_SIZE, value)
                                        }
                                        value={this.props.reportData[model.PROPS_PAGE_SIZE]}
                                    />
                                    <SelectInput
                                        required
                                        optionIsHiddenKey={ACTIVE_YN}
                                        containerClassNames={["display-options-list"]}
                                        clearable={false}
                                        placeholderText={formatMessage(messages.selectedBasemap)}
                                        options={basemapOptions}
                                        onChange={(value) => {
                                            this._updateReportImageConfig(
                                                Object.assign(
                                                    this.props.reportData[
                                                        model.PROPS_REPORT_IMAGE_CONFIG
                                                    ],
                                                    { basemap: value }
                                                )
                                            );
                                        }}
                                        value={
                                            this.props.reportData[model.PROPS_REPORT_IMAGE_CONFIG]
                                                .basemap
                                        }
                                    />
                                    <SelectInput
                                        required
                                        optionIsHiddenKey={ACTIVE_YN}
                                        containerClassNames={["display-options-list"]}
                                        clearable={false}
                                        placeholderText={formatMessage(messages.zoomLevelOptions)}
                                        options={zoomLevelOptions}
                                        onChange={(value) =>
                                            this._updateReportOption(model.PROPS_ZOOM_LEVEL, value)
                                        }
                                        value={this.props.reportData[model.PROPS_ZOOM_LEVEL]}
                                    />
                                    <Checkbox
                                        label={formatMessage(messages.useCustomImagery)}
                                        onChange={(e, useCustomImagery) => {
                                            this._updateReportImageConfig(
                                                Object.assign(
                                                    this.props.reportData[
                                                        model.PROPS_REPORT_IMAGE_CONFIG
                                                    ],
                                                    {
                                                        useCustomImagery,
                                                    }
                                                )
                                            );
                                        }}
                                        value={
                                            this.props.reportData[model.PROPS_REPORT_IMAGE_CONFIG]
                                                .useCustomImagery
                                        }
                                    />
                                </React.Fragment>
                            )}
                            {!showSoilTypeAttributeSelection
                                ? null
                                : soilTypeAttributeSelectOptions}
                            {!showFieldsPerPageOption ? null : fieldsPerPageSelectOptions}
                            {!showGrowerSummaryPerPageOption ? null : (
                                <SelectInput
                                    required
                                    clearable={false}
                                    optionIsHiddenKey={ACTIVE_YN}
                                    containerClassNames={["display-options-list"]}
                                    placeholderText={formatMessage(messages.fieldsPerPageOptions)}
                                    options={fieldsPerPageOptions}
                                    onChange={(value) =>
                                        this._updateReportOption(
                                            model.PROPS_GROWER_SUMMARY_DISPLAY_OPTION,
                                            value
                                        )
                                    }
                                    value={
                                        this.props.reportData[
                                            model.PROPS_GROWER_SUMMARY_DISPLAY_OPTION
                                        ]
                                    }
                                />
                            )}
                            {!farmMap ? null : (
                                <React.Fragment>
                                    <Checkbox
                                        onChange={(e, value) => this._updateNonFieldFeatures(value)}
                                        value={showNonFieldFeatures}
                                        label={formatMessage(messages.showNonFieldFeatures)}
                                    />
                                    {!showOrientationOptions ? null : orientationOptions}
                                    {!showPLSSInfoCheckbox
                                        ? null
                                        : this._createReportOptionsCheckbox(
                                              model.PROPS_PLSS_INFO,
                                              messages.plssInfo,
                                              null,
                                              {},
                                              isNotFieldsPerPage
                                          )}
                                    {this.renderFieldSymbologyOptions(isNotFieldsPerPage)}
                                </React.Fragment>
                            )}
                            {!showDisplayTypeOptions ? null : (
                                <SelectInput
                                    required
                                    containerClassNames={["display-options-list"]}
                                    clearable={false}
                                    placeholderText={formatMessage(messages.displayType)}
                                    options={this.displayTypeOptions}
                                    onChange={(value) =>
                                        this._updateReportOption(model.PROPS_DISPLAY_TYPE, value)
                                    }
                                    value={this.props.reportData[model.PROPS_DISPLAY_TYPE]}
                                />
                            )}
                            {!showPlantingOptions ? null : (
                                <SelectInput
                                    required
                                    containerClassNames={["display-options-list"]}
                                    clearable={false}
                                    placeholderText={formatMessage(messages.plantingAttribute)}
                                    options={this.plantingAttributeOptions}
                                    onChange={(value) =>
                                        this._updateReportOption(
                                            model.PROPS_PLANTING_ATTRIBUTE,
                                            value
                                        )
                                    }
                                    value={this.props.reportData[model.PROPS_PLANTING_ATTRIBUTE]}
                                />
                            )}
                            {!showYieldOptions ? null : (
                                <SelectInput
                                    required
                                    containerClassNames={["display-options-list"]}
                                    clearable={false}
                                    placeholderText={formatMessage(messages.yieldAttribute)}
                                    options={this.yieldAttributeOptions}
                                    onChange={(value) =>
                                        this._updateReportOption(model.PROPS_YIELD_ATTRIBUTE, value)
                                    }
                                    value={this.props.reportData[model.PROPS_YIELD_ATTRIBUTE]}
                                />
                            )}
                            {!showYieldMapYieldOptions
                                ? null
                                : this.renderYieldMapYieldDisplayOptions(formatMessage)}
                            {!showOrientationOptions ? null : farmMap ? null : orientationOptions}
                            {!showCropMapOrientationOptions ? null : (
                                <SelectInput
                                    required
                                    containerClassNames={["display-options-list"]}
                                    clearable={false}
                                    placeholderText={formatMessage(messages.orientation)}
                                    options={this.CropMapOrientationOptions}
                                    onChange={(value) =>
                                        this._updateReportOption(
                                            model.PROPS_CROP_MAP_ORIENTATION,
                                            value
                                        )
                                    }
                                    value={this.props.reportData[model.PROPS_CROP_MAP_ORIENTATION]}
                                />
                            )}
                            {!showLabelSizeOption ? null : labelSizeSelectOptions}
                            {!showSelectBasemap ? null : (
                                <React.Fragment>
                                    <SelectInput
                                        required
                                        clearable={false}
                                        containerClassNames={["display-options-list"]}
                                        disabled={disableBasemapOptions}
                                        optionIsHiddenKey={ACTIVE_YN}
                                        options={basemapOptions}
                                        onChange={(value) =>
                                            this._updateReportOption(
                                                model.PROPS_SELECTED_BASEMAP,
                                                value
                                            )
                                        }
                                        placeholderText={formatMessage(messages.selectedBasemap)}
                                        value={this.props.reportData[model.PROPS_SELECTED_BASEMAP]}
                                    />
                                    {!showCustomImagery
                                        ? null
                                        : this._createReportOptionsCheckbox(
                                              model.PROPS_USE_CUSTOM_IMAGERY,
                                              messages.useCustomImagery,
                                              "",
                                              {},
                                              disableBasemapOptions
                                          )}

                                    {!showUsePolygonStatsOption
                                        ? null
                                        : this._createReportOptionsCheckbox(
                                              model.PROPS_USE_POLYGON_STATS,
                                              messages.usePolygonStats
                                          )}
                                </React.Fragment>
                            )}
                            {!showUseOverviewMap ? null : useOverviewMap}
                            {displaySampleSitesPosition1}
                            {!showFieldLabelOptionsLink ? null : this.renderFieldLabelOptions()}
                            {!showCropPlanningSummaryCheckbox
                                ? null
                                : this._createReportOptionsCheckbox(
                                      model.PROPS_USE_CROP_PLANNING_SUMMARY,
                                      messages.summary
                                  )}
                            {!showAgvanceColorOption ? null : (
                                <SelectInput
                                    required
                                    clearable={false}
                                    optionIsHiddenKey={ACTIVE_YN}
                                    containerClassNames={["display-options-list"]}
                                    placeholderText={formatMessage(messages.agvanceCropColor)}
                                    options={agvanceColorOptions}
                                    onChange={(value) =>
                                        this._updateReportOption(model.PROPS_AGVANCE_COLOR, value)
                                    }
                                    value={this.props.reportData[model.PROPS_AGVANCE_COLOR]}
                                />
                            )}
                            {!showCropPlanningCropZonesCheckbox
                                ? null
                                : this._createReportOptionsCheckbox(
                                      model.PROPS_CROP_ZONE,
                                      messages.cropZone
                                  )}
                            {displaySampleSitesPosition2}
                            {!showMultipleMapsPerPage
                                ? null
                                : this._createReportOptionsCheckbox(
                                      model.PROPS_MULTIPLE_MAPS_PER_PAGE,
                                      messages.multipleMapsPerPage
                                  )}
                            {showPageSelectionBeforeRecSummary ? pageSelectionOption : null}
                            {!hygroundOrAskSelected || hygroundMultiFieldSelected
                                ? null
                                : this._createReportOptionsCheckbox(
                                      model.PROPS_SHOW_REC_SUMMARY,
                                      messages.showRecSummary
                                  )}
                            {!showPageSelectionBeforeRecSummary && !showPageSelectionOption
                                ? pageSelectionOption
                                : null}
                            {!showUseProductRecs
                                ? null
                                : this._createReportOptionsCheckbox(
                                      model.PROPS_USE_PRODUCT_RECS,
                                      messages.useProductRecs
                                  )}
                            {!showUseOverviewMapAfterPageSelection ? null : useOverviewMap}
                            {!showGuaranteedAnalysisOption
                                ? null
                                : this._createReportOptionsCheckbox(
                                      model.PROPS_SHOW_GUARANTEED_ANALYSIS,
                                      messages.guaranteedAnalysis
                                  )}
                            {!(showUseProductRecs && useProductRecs && showUseCostDetails)
                                ? null
                                : this._createReportOptionsCheckbox(
                                      model.PROPS_SHOW_PRODUCT_REC_COST_DETAIL,
                                      messages.costDetails,
                                      "",
                                      { count: 1 }
                                  )}
                            {showPageSelectionOption ? pageSelectionOption : null}
                            {!showNutrientTargetColors
                                ? null
                                : this._createReportOptionsCheckbox(
                                      model.PROPS_NUTRIENT_TARGET_COLORS,
                                      messages.nutrientTargetColors
                                  )}
                            {!showGrowerSummaryCostDetail
                                ? null
                                : this._createReportOptionsCheckbox(
                                      model.PROPS_SHOW_GROWER_SUMMARY_COST_DETAIL,
                                      messages.costDetails,
                                      "",
                                      { count: 1 }
                                  )}
                            {!showPrintFarmTotalsOption
                                ? null
                                : this._createReportOptionsCheckbox(
                                      model.PROPS_PRINT_FARM_TOTALS,
                                      messages.farmTotals
                                  )}
                            {!showPrintFieldTotalsOption
                                ? null
                                : this._createReportOptionsCheckbox(
                                      model.PROPS_PRINT_FIELD_TOTALS,
                                      messages.fieldTotals
                                  )}
                            {!showPrintGrowerTotalsOption
                                ? null
                                : this._createReportOptionsCheckbox(
                                      model.PROPS_PRINT_GROWER_TOTALS,
                                      messages.growerTotals
                                  )}
                            {!showLoadsheetOptions ? null : (
                                <React.Fragment>
                                    <SelectInput
                                        clearable={true}
                                        containerClassNames={["display-options-list"]}
                                        placeholderText={formatMessage(messages.fileNameFormat)}
                                        options={fileNameFormatOptions}
                                        value={exportFormat}
                                        onChange={(value) =>
                                            this._updateReportOption(
                                                model.PROPS_SELECTED_FILENAME_FORMAT,
                                                value
                                            )
                                        }
                                    />
                                    {this._createReportOptionsCheckbox(
                                        model.PROPS_SHOW_NON_FIELD_FEATURES,
                                        messages.showNonFieldFeatures,
                                        "nonField-features-checkbox"
                                    )}
                                    {this._createReportOptionsCheckbox(
                                        model.PROPS_SHOW_LOADSHEET_PRODUCT_DETAIL,
                                        messages.productDetail,
                                        "event-id-checkbox"
                                    )}
                                    {renderLoadSheetMultiFieldRec()}
                                </React.Fragment>
                            )}
                            {!scoutingOptions
                                ? null
                                : this._createReportOptionsCheckbox(
                                      model.PROPS_SHOW_SCOUTING_DETAILS,
                                      messages.detail
                                  )}
                            {!scoutingSummaryOptions ? null : (
                                <React.Fragment>
                                    {this._createReportOptionsCheckbox(
                                        model.PROPS_SHOW_SCOUTING_DETAILS,
                                        messages.detail
                                    )}
                                    {!showScoutingDetails
                                        ? null
                                        : this._createReportOptionsCheckbox(
                                              model.PROPS_SHOW_PHOTOS,
                                              messages.displayPhotos
                                          )}
                                </React.Fragment>
                            )}
                            {!showApplicationAttribute ? null : (
                                <SelectInput
                                    required
                                    clearable={false}
                                    optionIsHiddenKey={ACTIVE_YN}
                                    containerClassNames={["display-options-list"]}
                                    placeholderText={formatMessage(messages.applicationAttribute)}
                                    options={this.applicationAttributeOptions}
                                    onChange={(value) =>
                                        this._updateReportOption(
                                            model.PROPS_SKY_APPLICATION_ATTRIBUTE,
                                            value
                                        )
                                    }
                                    value={
                                        this.props.reportData[model.PROPS_SKY_APPLICATION_ATTRIBUTE]
                                    }
                                />
                            )}
                            {!showLowRateLegend
                                ? null
                                : this._createReportOptionsCheckbox(
                                      model.PROPS_LOW_RATE_LEGEND,
                                      messages.lowRateLegend
                                  )}
                            {!showDisplayParameters
                                ? null
                                : this._createReportOptionsCheckbox(
                                      model.PROPS_DISPLAY_PARAMETERS,
                                      messages.displayParameters
                                  )}
                            {!useImportDate
                                ? null
                                : this._createReportOptionsCheckbox(
                                      model.PROPS_USE_IMPORT_DATE,
                                      messages.useImportDate
                                  )}
                            {!showProductRecapOption
                                ? null
                                : this._createReportOptionsCheckbox(
                                      model.PROPS_INCLUDE_PRODUCT_RECAP,
                                      messages.productRecap
                                  )}
                            {!showGeneralNotes
                                ? null
                                : this._createReportOptionsCheckbox(
                                      model.PROPS_GENERAL_NOTES,
                                      messages.generalNotes
                                  )}
                            {!showPlantingRecCostDetail
                                ? null
                                : this._createReportOptionsCheckbox(
                                      model.PROPS_PLANTING_REC_COST_DETAIL,
                                      messages.costDetails,
                                      "",
                                      { count: 0 }
                                  )}
                            {!showOriginalFormat
                                ? null
                                : this._createReportOptionsCheckbox(
                                      model.PROPS_ORIGINAL_FORMAT,
                                      messages.originalFormat
                                  )}
                            {!showLatLongQrOption
                                ? null
                                : farmMap
                                ? null
                                : this._createOptionsCheckbox(
                                      model.PROPS_BOUNDARY_MAP_QR,
                                      messages.showQrCode,
                                      "",
                                      {},
                                      isNotFieldsPerPage
                                  )}
                        </SubSection>
                    )}
                    {!this._someReportSelected(
                        ReportAPI.ReportNames.HYGROUND_SUMMARY,
                        ReportAPI.ReportNames.COREMAX_SUMMARY,
                        ReportAPI.ReportNames.ACCUPOINT_SUMMARY,
                        ReportAPI.ReportNames.EXTRACTOR_SUMMARY_REPORT,
                        ReportAPI.ReportNames.SLICE_SUMMARY_REPORT
                    ) ? null : !this._someReportSelected(
                          ReportAPI.ReportNames.HYGROUND_SUMMARY,
                          ReportAPI.ReportNames.COREMAX_SUMMARY,
                          ReportAPI.ReportNames.ACCUPOINT_SUMMARY
                      ) ? (
                        this._isMultiFieldAcrossCustomerSelected() ? (
                            <SubSection>
                                {this._createReportOptionsCheckbox(
                                    model.PROPS_MULTI_GROWER,
                                    messages.multiGrower
                                )}
                            </SubSection>
                        ) : null
                    ) : (
                        this.renderHygroundSummaryDisplayOption(formatMessage)
                    )}

                    {!this._someReportSelected(
                        ReportAPI.ReportNames.AGR_INTELLIGENCE_PROFIT_LOSS,
                        ReportAPI.ReportNames.GROLINK_PROFIT_LOSS
                    )
                        ? null
                        : this.renderAGRProfitLossDisplayOption(formatMessage)}
                    {!this._someReportSelected(ReportAPI.ReportNames.YIELD_BY_REPORT) ? null : (
                        <SubSection className="two-wide">
                            {this.renderYieldByDisplayType(formatMessage, onInputChange)}
                            <SelectInput
                                required
                                optionIsHiddenKey={ACTIVE_YN}
                                containerClassNames={["yield-basemap-select"]}
                                clearable={false}
                                placeholderText={formatMessage(messages.selectedBasemap)}
                                options={basemapOptions}
                                onChange={(value) =>
                                    this._updateReportOption(model.PROPS_SELECTED_BASEMAP, value)
                                }
                                value={this.props.reportData[model.PROPS_SELECTED_BASEMAP]}
                            />
                            <NoLink
                                className="categories-link"
                                label={formatMessage(messages.yieldByCompareByOptions)}
                                onClick={() => {
                                    this.props.onClickCategories(categoryTypes.yieldBy);
                                }}
                            />
                            <Checkbox
                                containerClassNames={["yield-custom-imagery"]}
                                onChange={(e, value) =>
                                    this.updateValue(model.PROPS_USE_CUSTOM_IMAGERY, value)
                                }
                                value={this.props.reportData[model.PROPS_USE_CUSTOM_IMAGERY]}
                                label={formatMessage(messages.useCustomImagery)}
                            />
                        </SubSection>
                    )}
                    {!this._someReportSelected(ReportAPI.ReportNames.APPLICATION_MAP) ? null : (
                        <SubSection className="two-wide">
                            <SelectInput
                                disabled={!applicationAttributeDisabled}
                                required
                                containerClassNames={["display-options-list"]}
                                clearable={false}
                                placeholderText={formatMessage(messages.displayType)}
                                options={this.displayTypeOptions}
                                onChange={(value) =>
                                    this._updateReportOption(model.PROPS_DISPLAY_TYPE, value)
                                }
                                value={this.props.reportData[model.PROPS_DISPLAY_TYPE]}
                            />
                            {!showApplicationMapOptions
                                ? null
                                : this.renderApplicationMapApplicationAttributeOptions(
                                      formatMessage,
                                      applicationAttributeDisabled
                                  )}
                            <SelectInput
                                required
                                optionIsHiddenKey={ACTIVE_YN}
                                containerClassNames={["yield-basemap-select"]}
                                clearable={false}
                                placeholderText={formatMessage(messages.selectedBasemap)}
                                options={basemapOptions}
                                onChange={(value) =>
                                    this._updateReportOption(model.PROPS_SELECTED_BASEMAP, value)
                                }
                                value={this.props.reportData[model.PROPS_SELECTED_BASEMAP]}
                            />
                            <Checkbox
                                containerClassNames={["yield-custom-imagery"]}
                                onChange={(e, value) =>
                                    this.updateValue(model.PROPS_USE_CUSTOM_IMAGERY, value)
                                }
                                value={this.props.reportData[model.PROPS_USE_CUSTOM_IMAGERY]}
                                label={formatMessage(messages.useCustomImagery)}
                            />
                        </SubSection>
                    )}
                    {!truYieldImpact ? null : <SubSection>{renderBreakEvenYieldList()}</SubSection>}
                    {!hasWeatherReport ? null : (
                        <SubSection>
                            <Checkbox
                                onChange={(e, isHourly) => {
                                    this._updateReportOption(model.PROPS_IS_HOURLY, isHourly);
                                    if (isHourly) {
                                        this.applyHourlyDefaults(this.props.onInputChange);
                                    }
                                }}
                                value={this.props.reportData[model.PROPS_IS_HOURLY]}
                                label={formatMessage(messages.isHourly)}
                            />
                        </SubSection>
                    )}
                    {!this._someReportSelected(
                        ReportAPI.ReportNames.PRODUCT_REC_SUMMARY_REPORT
                    ) ? null : (
                        <SubSection>
                            {this._createReportOptionsCheckbox(
                                model.PROPS_SHOW_PRODUCT_REC_SUMMARY_COST_DETAIL,
                                messages.costDetails,
                                "",
                                { count: 0 }
                            )}
                        </SubSection>
                    )}
                    {!this._someReportSelected(ReportAPI.ReportNames.CROP_MAP_REPORT) ? null : (
                        <SubSection>
                            <SelectInput
                                required
                                containerClassNames={["display-options-list"]}
                                clearable={false}
                                placeholderText={formatMessage(messages.legendType)}
                                options={this.cropMapLegendTypeOptions}
                                onChange={(value) =>
                                    this._updateReportOption(model.PROPS_LEGEND_TYPE, value)
                                }
                                value={this.props.reportData[model.PROPS_LEGEND_TYPE]}
                            />
                            <SelectInput
                                required
                                containerClassNames={["display-options-list"]}
                                clearable={false}
                                placeholderText={formatMessage(messages.labelType)}
                                options={this.cropMapLabelTypeOptions}
                                onChange={(value) =>
                                    this._updateReportOption(model.PROPS_LABEL_TYPE, value)
                                }
                                value={this.props.reportData[model.PROPS_LABEL_TYPE]}
                            />
                        </SubSection>
                    )}
                </Section>
            </div>
        );
    }

    _allReportsSelected = (...reportTypes: string[]): boolean => {
        const { selectedReportTypes } = this.props;
        const matchesReportTypes = (report) => {
            return reportTypes.some((type) => report.indexOf(type) > -1);
        };
        return selectedReportTypes.length > 0 && selectedReportTypes.every(matchesReportTypes);
    };

    _checkEventProps = (event: FieldAPI.IAgEventSummary): boolean => {
        const { selectedReportTypes } = this.props;
        for (const reportType of selectedReportTypes) {
            switch (reportType) {
                case keywords.harvestSummary:
                case keywords.harvestSummaryMultiSeason:
                case keywords.yieldBy:
                case keywords.yieldMap:
                case ReportAPI.ReportNames.YIELD_BY_ANALYSIS_REPORT: {
                    if (event.agEventTypeName === keywords.harvestEvent) {
                        const valid = event.isImportedYn ? true : false;
                        return valid;
                    }
                    return true;
                }
                case keywords.labSubmissionForm: {
                    if (event.agEventTypeName === keywords.samplingSoil) {
                        const valid = event.hasSoilSampleResults ? false : true;
                        return valid;
                    }
                    return true;
                }
                case ReportAPI.ReportNames.ASK_REPORT:
                case ReportAPI.ReportNames.COREMAX:
                case ReportAPI.ReportNames.COREMAX_DEFINED_LEGEND_MULTIFIELD:
                case ReportAPI.ReportNames.COREMAX_DEFINED_LEGEND_MULTIFIELD_PRODUCT_REC:
                case ReportAPI.ReportNames.COREMAX_RESULTS_ONLY:
                case ReportAPI.ReportNames.HYGROUND:
                case ReportAPI.ReportNames.HYGROUND_DEFINED_LEGEND:
                case ReportAPI.ReportNames.HYGROUND_DEFINED_LEGEND_EBU:
                case ReportAPI.ReportNames.HYGROUND_DEFINED_LEGEND_MULTIFIELD:
                case ReportAPI.ReportNames.HYGROUND_DEFINED_LEGEND_MULTIFIELD_PRODUCT_REC:
                case ReportAPI.ReportNames.HYGROUND_FSR:
                case ReportAPI.ReportNames.HYGROUND_DESERT:
                case ReportAPI.ReportNames.HYGROUND_RESULTS_ONLY:
                case ReportAPI.ReportNames.HYGROUND_NORTHWEST:
                case ReportAPI.ReportNames.HYGROUND_WESTERN:
                case ReportAPI.ReportNames.ACCUPOINT:
                case ReportAPI.ReportNames.ACCUPOINT_DEFINED_LEGEND_EBU:
                case ReportAPI.ReportNames.ACCUPOINT_DEFINED_LEGEND_MULTIFIELD:
                case ReportAPI.ReportNames.ACCUPOINT_DEFINED_LEGEND_MULTIFIELD_PRODUCT_REC: {
                    return event.agEventTypeName === keywords.samplingSoil;
                }
                case ReportAPI.ReportNames.AGVANCE_SKY_SOILS_REPORT:
                case keywords.hyGroundSummary:
                case keywords.coreMaxSummary:
                case keywords.accupointSummary: {
                    if (event.agEventTypeName === keywords.samplingSoil) {
                        return event.hasSoilSampleResults ? true : false;
                    }
                    return true;
                }
                case ReportAPI.ReportNames.TRU_YIELD_SUMMARY_REPORT:
                case ReportAPI.ReportNames.YIELD_ONE_SUMMARY_REPORT: {
                    if (
                        event.agEventTypeName === keywords.harvestEvent ||
                        event.agEventTypeName === keywords.plantingEvent
                    ) {
                        const valid = event.isImportedYn ? true : false;
                        return valid;
                    }
                    return true;
                }
                case ReportAPI.ReportNames.TRU_YIELD_IMPACT_REPORT:
                case ReportAPI.ReportNames.YIELD_ONE_IMPACT_REPORT: {
                    let valid = true;
                    if (event.agEventTypeName === keywords.harvestEvent) {
                        valid = event.isImportedYn ? true : false;
                    } else if (event.agEventTypeName === keywords.samplingSoil) {
                        valid = event.isImportedYn && event.hasSoilSampleResults ? true : false;
                    }
                    return valid;
                }
                case ReportAPI.ReportNames.PLANTING_SUMMARY: {
                    return event.agEventTypeName === keywords.plantingEvent;
                }
                case ReportAPI.ReportNames.AGVANCE_SKY_YIELD_REPORT: {
                    return event.agEventTypeName === keywords.harvestEvent;
                }
                case ReportAPI.ReportNames.APPLICATION_MAP: {
                    return event.agEventTypeName.indexOf(keywords.applicationEvent) !== -1;
                }
                default:
                    return true;
            }
        }
    };

    _eventsRecsMatchReportsSelected = (): boolean => {
        const {
            agEventSummaryMap,
            recGeneralGuidToRecMap,
            selectedEventGuidSet,
            selectedRecGuidSet,
        } = this.props;
        const validEvents = this._getValidEvents();
        const validRecs = this._getValidRecs();
        const eventTypes = [];
        const recTypes = [];
        for (const eventGeneralGuid of selectedEventGuidSet) {
            const event = this._retrieveOrEmptyObject(agEventSummaryMap, eventGeneralGuid);
            if (this._checkEventProps(event)) {
                eventTypes.push(event.agEventTypeName);
            }
        }
        for (const recGeneralGuid of selectedRecGuidSet) {
            const rec = this._retrieveOrEmptyObject(recGeneralGuidToRecMap, recGeneralGuid);
            recTypes.push(rec.recType);
        }
        const eventsAreValid =
            eventTypes.length > 0 &&
            eventTypes.some((type) => validEvents.some((ve) => type.indexOf(ve) > -1));
        const recsAreValid =
            recTypes.length > 0 && recTypes.some((type) => validRecs.indexOf(type) > -1);
        return Boolean(eventsAreValid || recsAreValid);
    };

    _getSelectedPagedReportTypes = () => {
        const { reportTypePages } = this.props;
        return reportTypePages.reduce((a, c) => {
            if (a.indexOf(c.reportTypeName) === -1) {
                a.push(c.reportTypeName);
            }
            return a;
        }, [] as string[]);
    };

    _getShouldShowPageSelection = (): boolean => {
        const { selectedReportTypes } = this.props;
        const pagedReportTypes = this._getSelectedPagedReportTypes();

        return selectedReportTypes.filter((s) => pagedReportTypes.indexOf(s) > -1).length > 0;
    };

    _getValidEvents = (): string[] => {
        const { selectedReportTypes } = this.props;
        for (const reportType of selectedReportTypes) {
            switch (reportType) {
                case keywords.labSubmissionForm:
                case keywords.sampleBagLabels:
                    return [keywords.samplingSoil, keywords.samplingTissue];
                case keywords.hyGroundSummary:
                case keywords.coreMaxSummary:
                case keywords.accupointSummary:
                case ReportAPI.ReportNames.AGVANCE_SKY_SOIL_TEST_RESULTS_REPORT:
                    return [keywords.samplingSoil];
                case ReportAPI.ReportNames.HARVEST_SUMMARY:
                case ReportAPI.ReportNames.HARVEST_SUMMARY_MULTI_SEASON:
                case keywords.yieldMap:
                    return [keywords.harvestEvent];
                case ReportAPI.ReportNames.YIELD_BY_ANALYSIS_REPORT:
                    return [
                        keywords.harvestEvent,
                        keywords.plantingEvent,
                        keywords.samplingSoil,
                        keywords.applicationEvent,
                    ];
                case ReportAPI.ReportNames.YIELD_BY_REPORT:
                    return [
                        keywords.applicationEvent,
                        keywords.plantingEvent,
                        keywords.harvestEvent,
                        keywords.tillageEvent,
                    ];
                case ReportAPI.ReportNames.AGVANCE_SKY_SCOUTING_REPORT:
                case ReportAPI.ReportNames.INSPEX_REPORT:
                case ReportAPI.ReportNames.PREVEAL_REPORT:
                case ReportAPI.ReportNames.AGOPTICS_REPORT:
                    return [keywords.scoutingEvent];
                case ReportAPI.ReportNames.AGVANCE_SKY_APPLICATION_REPORT:
                    return [keywords.applicationEvent, keywords.applicationManual];
                case ReportAPI.ReportNames.AGVANCE_SKY_NUTRIENT_ATLAS:
                case ReportAPI.ReportNames.NUTRIENT_MAP:
                    return [keywords.samplingSoil];
                case ReportAPI.ReportNames.TRU_YIELD_SUMMARY_REPORT:
                case ReportAPI.ReportNames.YIELD_ONE_SUMMARY_REPORT:
                    return [keywords.harvestEvent, keywords.plantingEvent];
                case ReportAPI.ReportNames.AGVANCE_SKY_PLANTING_REPORT:
                case ReportAPI.ReportNames.CROP_MAP_REPORT:
                case ReportAPI.ReportNames.PLANTING_SUMMARY:
                case ReportAPI.ReportNames.MYAGDATA_ACREAGE:
                    return [keywords.plantingEvent];
                case ReportAPI.ReportNames.TRU_YIELD_IMPACT_REPORT:
                case ReportAPI.ReportNames.YIELD_ONE_IMPACT_REPORT:
                    return [keywords.harvestEvent, keywords.samplingSoil];
                case ReportAPI.ReportNames.ASK_REPORT:
                case ReportAPI.ReportNames.COREMAX:
                case ReportAPI.ReportNames.COREMAX_DEFINED_LEGEND_MULTIFIELD:
                case ReportAPI.ReportNames.COREMAX_DEFINED_LEGEND_MULTIFIELD_PRODUCT_REC:
                case ReportAPI.ReportNames.COREMAX_RESULTS_ONLY:
                case ReportAPI.ReportNames.COREMAX_SUMMARY:
                case ReportAPI.ReportNames.HYGROUND:
                case ReportAPI.ReportNames.HYGROUND_DEFINED_LEGEND:
                case ReportAPI.ReportNames.HYGROUND_DEFINED_LEGEND_EBU:
                case ReportAPI.ReportNames.HYGROUND_DEFINED_LEGEND_MULTIFIELD:
                case ReportAPI.ReportNames.HYGROUND_DEFINED_LEGEND_MULTIFIELD_PRODUCT_REC:
                case ReportAPI.ReportNames.HYGROUND_FSR:
                case ReportAPI.ReportNames.HYGROUND_SUMMARY:
                case ReportAPI.ReportNames.HYGROUND_DESERT:
                case ReportAPI.ReportNames.HYGROUND_NORTHWEST:
                case ReportAPI.ReportNames.HYGROUND_RESULTS_ONLY:
                case ReportAPI.ReportNames.HYGROUND_WESTERN:
                case ReportAPI.ReportNames.ACCUPOINT:
                case ReportAPI.ReportNames.ACCUPOINT_DEFINED_LEGEND_EBU:
                case ReportAPI.ReportNames.ACCUPOINT_DEFINED_LEGEND_MULTIFIELD:
                case ReportAPI.ReportNames.ACCUPOINT_DEFINED_LEGEND_MULTIFIELD_PRODUCT_REC:
                case ReportAPI.ReportNames.ACCUPOINT_SUMMARY:
                    return [keywords.samplingSoil];
                case ReportAPI.ReportNames.EXTRACTOR_REPORT:
                case ReportAPI.ReportNames.SLICE_REPORT:
                case ReportAPI.ReportNames.EXTRACTOR_SUMMARY_REPORT:
                case ReportAPI.ReportNames.SLICE_SUMMARY_REPORT:
                    return [keywords.samplingTissue];
                case ReportAPI.ReportNames.AGVANCE_SKY_YIELD_REPORT:
                    return [keywords.harvestEvent];
                case ReportAPI.ReportNames.APPLICATION_MAP:
                    return [keywords.applicationEvent];
                default:
                    return [];
            }
        }
        return [];
    };

    _getValidRecs = (): string[] => {
        const { selectedReportTypes } = this.props;
        for (const reportType of selectedReportTypes) {
            switch (reportType) {
                case keywords.loadsheet:
                case ReportAPI.ReportNames.PRODUCT_REC_SUMMARY_REPORT:
                    return [
                        keywords.applicationEquation,
                        keywords.applicationManual,
                        keywords.plantingManual,
                        keywords.plantingEquation,
                    ];
                case ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_APPLICATION:
                case ReportAPI.ReportNames.RECS_REPORT_APPLICATION:
                    return [keywords.applicationEquation, keywords.applicationManual];
                case ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_PLANTING:
                case ReportAPI.ReportNames.RECS_REPORT_PLANTING:
                    return [keywords.plantingManual, keywords.plantingEquation];
                case ReportAPI.ReportNames.AGVANCE_SKY_GROWER_SUMMARY_APPLICATION_REPORT:
                    return [keywords.applicationEquation, keywords.applicationManual];
                case ReportAPI.ReportNames.AGVANCE_SKY_CROP_PLANNING_REPORT:
                case ReportAPI.ReportNames.CROP_MAP_REPORT:
                    return [keywords.plantingManual, keywords.plantingEquation];
                case keywords.yieldBy:
                    return [keywords.applicationManual, keywords.plantingManual];
                case ReportAPI.ReportNames.FOUR_MATION_RX_REPORT:
                case ReportAPI.ReportNames.SEED_STRONG_RX_REPORT:
                    return [keywords.plantingManual];
                case ReportAPI.ReportNames.ASK_REPORT:
                case ReportAPI.ReportNames.COREMAX:
                case ReportAPI.ReportNames.COREMAX_DEFINED_LEGEND_MULTIFIELD:
                case ReportAPI.ReportNames.COREMAX_DEFINED_LEGEND_MULTIFIELD_PRODUCT_REC:
                case ReportAPI.ReportNames.HYGROUND:
                case ReportAPI.ReportNames.HYGROUND_DEFINED_LEGEND:
                case ReportAPI.ReportNames.HYGROUND_DEFINED_LEGEND_EBU:
                case ReportAPI.ReportNames.HYGROUND_DEFINED_LEGEND_MULTIFIELD:
                case ReportAPI.ReportNames.HYGROUND_DEFINED_LEGEND_MULTIFIELD_PRODUCT_REC:
                case ReportAPI.ReportNames.HYGROUND_FSR:
                case ReportAPI.ReportNames.HYGROUND_SUMMARY:
                case ReportAPI.ReportNames.COREMAX_SUMMARY:
                case ReportAPI.ReportNames.ACCUPOINT:
                case ReportAPI.ReportNames.ACCUPOINT_DEFINED_LEGEND_EBU:
                case ReportAPI.ReportNames.ACCUPOINT_DEFINED_LEGEND_MULTIFIELD:
                case ReportAPI.ReportNames.ACCUPOINT_DEFINED_LEGEND_MULTIFIELD_PRODUCT_REC:
                case ReportAPI.ReportNames.ACCUPOINT_SUMMARY:
                    return [keywords.applicationEquation];
                default:
                    return [];
            }
        }
        return [];
    };

    _checkSeasonDisabled = (props: IReportOptionsProps = this.props): boolean => {
        const reportSelected =
            this._someReportSelected(
                ReportAPI.ReportNames.AGVANCE_SKY_RECS_REPORT_APPLICATION,
                ReportAPI.ReportNames.RECS_REPORT_APPLICATION,
                ReportAPI.ReportNames.EXTRACTOR_REPORT,
                ReportAPI.ReportNames.SLICE_REPORT,
                ReportAPI.ReportNames.EXTRACTOR_SUMMARY_REPORT,
                ReportAPI.ReportNames.SLICE_SUMMARY_REPORT,
                ReportAPI.ReportNames.YIELD_MAP_REPORT,
                ReportAPI.ReportNames.APPLICATION_MAP,
                ReportAPI.ReportNames.AGOPTICS_REPORT,
                ReportAPI.ReportNames.INSPEX_REPORT,
                ReportAPI.ReportNames.PREVEAL_REPORT,
                ReportAPI.ReportNames.HARVEST_SUMMARY_MULTI_SEASON,
                ReportAPI.ReportNames.LAB_SUBMISSION_FORM_REPORT,
                ReportAPI.ReportNames.SAMPLE_BAG_LABELS_REPORT
            ) && props.selectedReportTypes.length === 1;
        const hasDate =
            props.reportData[model.PROPS_START_DATE] || props.reportData[model.PROPS_END_DATE];
        return !!reportSelected && !!hasDate;
    };

    _someReportSelected = (...reportTypes: string[]): boolean => {
        const { selectedReportTypes } = this.props;
        return (
            selectedReportTypes.length > 0 &&
            reportTypes.some((type) => selectedReportTypes.indexOf(type) > -1)
        );
    };

    applyDailyDefaults = (onInputChange: (key: string, value: any) => void): void => {
        onInputChange(model.PROPS_START_DATE, moment().subtract(1, "days"));
        onInputChange(model.PROPS_END_DATE, moment().subtract(1, "days"));
    };

    applyHourlyDefaults = (onInputChange: (key: string, value: any) => void): void => {
        onInputChange(
            model.PROPS_START_DATE,
            moment().subtract(1, "days").hour(6).minute(0).second(0)
        );

        onInputChange(
            model.PROPS_END_DATE,
            moment().subtract(1, "days").hour(18).minute(0).second(0)
        );
    };
}

export const ReportOptions = withUser(injectIntl(ReportOptions_));
