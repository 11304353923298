import React from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { Checkbox } from "~/core";
import { messages } from "../i18n-messages";
import { IReportType, ISelectReportType } from "../data/interfaces";

export interface IReportTypesProps {
    intl: injectIntl;
    reportTypes?: IReportType[];
    selectReportType?: (report: ISelectReportType) => void;
    selectedReportTypes?: string[];
}

const ReportTypes = ({
    intl,
    reportTypes,
    selectReportType,
    selectedReportTypes,
}: IReportTypesProps): JSX.Element => {
    const isReportTypeSelected = ({ reportTypeGuid }) => {
        return selectedReportTypes.indexOf(reportTypeGuid) > -1;
    };

    const getLabelString = () => {
        return `1. ${intl.formatMessage(messages.selectReports)}:`;
    };

    return (
        <div className="report-types">
            <div className="options-header">{getLabelString()}</div>
            {reportTypes?.map((reportType, index) => (
                <Checkbox
                    key={index}
                    value={isReportTypeSelected(reportType)}
                    label={reportType.reportTypeDisplayName}
                    onChange={() =>
                        selectReportType({
                            name: reportType.reportTypeName,
                            guid: reportType.reportTypeGuid,
                        })
                    }
                />
            ))}
        </div>
    );
};

ReportTypes.propTypes = {
    intl: intlShape,
    reportTypes: PropTypes.array,
    selectReportType: PropTypes.func,
    selectedReportTypes: PropTypes.array,
};

export default injectIntl(ReportTypes);
