import { createSelector } from "reselect";

import { getUser } from "~/login";
import { AgEventAPI } from "@ai360/core";

import { BATCH_TEMPLATE_FIELD_GUID } from "../model";
import * as models from "./model";
import { IEventsState } from "./reducer";

import { getInFlightCollapsingEvents } from "~/action-panel/components/event-module/components/event-info/selectors";

import { filterOutEvents } from "./data";
import { getZonesState, getMapToolsState, RECS_EVENTS_DATA_KEY } from "../selectors";
import { ICalibrateEquipmentLoads } from "./models/yield-calibration";
import Graphic from "@arcgis/core/Graphic";

export const EVENTS_STATE_KEY = "EVENTS_STATE";

export const getModuleState = (state: unknown): IEventsState => {
    return state[RECS_EVENTS_DATA_KEY][EVENTS_STATE_KEY];
};

export const getIgnorableEventGeneralGuids = createSelector(getInFlightCollapsingEvents, (events) =>
    events.map((event) => event.agEventGeneralGuid)
);

export const getCurrentAgEventArea: (state, fieldGuid) => AgEventAPI.IAgEventArea | null =
    createSelector(
        (state, fieldGuid) => getZonesState(state).fieldGuidToCurrentAreaId.get(fieldGuid),
        (state, fieldGuid) => getModuleState(state).fieldGuidToEventDetails.get(fieldGuid),
        (agEventAreaId, eventDetails: models.EventDetails) => {
            if (!eventDetails || !agEventAreaId) {
                return null;
            }
            return eventDetails.eventAreaList.find(
                (agEventArea) => agEventArea.eventAreaId === agEventAreaId
            );
        }
    );

export const getEventTypeInfoList = (state: unknown): models.AgEventTypeInfo[] =>
    getModuleState(state).agEventTypeInfoList;

export const getEventTypeTransactionGuidToTypeNameMap = createSelector(
    getEventTypeInfoList,
    (eventTypeInfoList) => {
        const guidNamePairs = eventTypeInfoList.map((eventType) => {
            return [eventType.agEventTransactionTypeGuid, eventType.agEventTransactionTypeName];
        }) as Array<[string, string]>;
        return new Map<string, string>(guidNamePairs);
    }
);

export const getEventTypeNameToTransactionGuidMap = createSelector(
    getEventTypeInfoList,
    (eventTypeInfoList) => {
        const nameGuidPairs = eventTypeInfoList.map((eventType) => {
            return [eventType.agEventTransactionTypeName, eventType.agEventTransactionTypeGuid];
        }) as Array<[string, string]>;
        return new Map<string, string>(nameGuidPairs);
    }
);

export const getEventTypeOptions = createSelector(getEventTypeInfoList, (eventTypeInfoList) =>
    eventTypeInfoList.map((eventType) => ({
        label: eventType.agEventTransactionTypeName,
        value: eventType,
    }))
);

export const getFieldGuidHasDataSet: (state) => Set<string> = createSelector(
    (state) => getModuleState(state).fieldGuidToEventDetails,
    (state) => (fieldGuid) => getEnableSave(state, fieldGuid),
    (fieldGuidToEventDetails, getEnableSaveForFieldGuid) => {
        const fieldGuidList = [...fieldGuidToEventDetails.keys()].filter(
            (fieldGuid) => fieldGuid !== BATCH_TEMPLATE_FIELD_GUID
        );
        if (
            fieldGuidToEventDetails.has(BATCH_TEMPLATE_FIELD_GUID) &&
            getEnableSaveForFieldGuid(BATCH_TEMPLATE_FIELD_GUID)
        ) {
            return new Set(fieldGuidList);
        }
        return new Set(fieldGuidList.filter((fieldGuid) => getEnableSaveForFieldGuid(fieldGuid)));
    }
);

export const getNewableEventTypeInfoList = createSelector(
    getUser,
    getEventTypeInfoList,
    (user, eventTypeInfoList) => {
        const { role } = user;
        return eventTypeInfoList.filter((eventType) => {
            return (
                models.NEWABLE_EVENT_TYPES.has(eventType.agEventTransactionTypeName) &&
                models.NEWABLE_EVENT_TYPES.get(eventType.agEventTransactionTypeName)(role)
            );
        });
    }
);

export const getNewableEventTypeOptions = createSelector(
    getNewableEventTypeInfoList,
    (eventTypeInfoList) => {
        return eventTypeInfoList.map((eventType) => ({
            label: eventType.agEventTransactionTypeName,
            value: eventType,
        }));
    }
);

export const getAgEventTypeInfoList = createSelector(getEventTypeInfoList, (eventTypeInfoList) => {
    return eventTypeInfoList.filter((eventType) => {
        return models.NEWABLE_EVENT_TYPES.has(eventType.agEventTransactionTypeName);
    });
});

export const getAgEventTypeOptions = createSelector(getAgEventTypeInfoList, (eventTypeInfoList) => {
    return eventTypeInfoList.map((eventType) => ({
        label: eventType.agEventTransactionTypeName,
        value: eventType,
    }));
});

const getAgEventFromEventDetails = (eventDetails) => {
    console.assert(eventDetails.eventAreaList.length > 0);
    const area = eventDetails.eventAreaList[0];
    console.assert(area.agEventList.length > 0);
    const { agEventModel } = area.agEventList[0];
    return agEventModel;
};

const batchSamplingPointsReady = (
    fieldGuidToEventDetails: Immutable.OrderedMap<string, models.EventDetails>,
    fieldGuidList: string[],
    isTissue: boolean
): boolean => {
    return fieldGuidList.every((fieldGuid) => {
        const eventDetails = fieldGuidToEventDetails.get(fieldGuid);
        const agEventModel = getAgEventFromEventDetails(eventDetails);
        return Boolean(
            agEventModel.samplePoints.length > 0 &&
                (!isTissue || agEventModel.samplePoints.every((p) => p.cropGrowthStageGuid))
        );
    });
};

const batchTissueSamplingReady = (
    fieldGuidToEventDetails: Immutable.OrderedMap<string, models.EventDetails>,
    fieldGuidList: string[]
): boolean => {
    const eventDetails = fieldGuidToEventDetails.get(BATCH_TEMPLATE_FIELD_GUID);
    const agEventModel = getAgEventFromEventDetails(eventDetails);
    return Boolean(
        agEventModel.cropGuid &&
            batchSamplingPointsReady(fieldGuidToEventDetails, fieldGuidList, true)
    );
};

export const getBatchSamplingEnableSave: (state) => boolean = createSelector(
    (state) => getModuleState(state).fieldGuidToEventDetails,
    (fieldGuidToEventDetails) => {
        const batchEventDetails = fieldGuidToEventDetails.get(BATCH_TEMPLATE_FIELD_GUID);
        const isTissue = batchEventDetails.agEventTypeList.some(
            (agEventType) =>
                agEventType.agEventTransactionTypeName === models.EVENT_TYPE_NAME_SAMPLING_TISSUE
        );
        const fieldGuidList = [...fieldGuidToEventDetails.keys()].filter(
            (fieldGuid) => fieldGuid !== BATCH_TEMPLATE_FIELD_GUID
        );

        return isTissue
            ? batchTissueSamplingReady(fieldGuidToEventDetails, fieldGuidList)
            : batchSamplingPointsReady(fieldGuidToEventDetails, fieldGuidList, false);
    }
);

export const getBatchSamplingFieldPointsPlaced = (state: unknown): boolean => {
    return getModuleState(state).samplingFieldPointsPlaced;
};

export const getFieldGuidToEventListMap = createSelector(
    (state) => getModuleState(state).fieldGuidToEventListMap,
    getIgnorableEventGeneralGuids,
    filterOutEvents
);

export const getFieldGuidToInactiveEventListMap = createSelector(
    (state) => getModuleState(state).fieldGuidToInactiveEventListMap,
    getIgnorableEventGeneralGuids,
    filterOutEvents
);

export const getFieldGuidToFieldMap = (state: unknown): any =>
    getModuleState(state).fieldGuidToFieldMap;

export const getFieldGuidToInactiveFieldMap = (state: unknown): any =>
    getModuleState(state).fieldGuidToInactiveFieldMap;

export const getLastEventBatchFieldGuid = (state: unknown): any => {
    return getModuleState(state).batchFieldGuid;
};

export const getYieldCalibrationProgressState = (state: unknown): Map<string, number> => {
    return getModuleState(state).yieldCalibrationProgress;
};

export const getEventGeneralGuidToEventSummaryMap: (state) => Map<string, models.AgEventSummary> =
    createSelector(getFieldGuidToEventListMap, (eventListMap) => {
        const eventGuidToEventMap = new Map<string, models.AgEventSummary>();
        for (const eventList of eventListMap.values()) {
            for (const event of eventList) {
                eventGuidToEventMap.set(event.agEventGeneralGuid, event);
            }
        }
        return eventGuidToEventMap;
    });

export const getEventGeneralGuidToInactiveEventSummaryMap: (
    state
) => Map<string, models.AgEventSummary> = createSelector(
    getFieldGuidToInactiveEventListMap,
    (eventListMap) => {
        const eventGuidToEventMap = new Map();
        for (const eventList of eventListMap.values()) {
            for (const event of eventList) {
                eventGuidToEventMap.set(event.agEventGeneralGuid, event);
            }
        }
        return eventGuidToEventMap;
    }
);

export const getEnableSave: (state, fieldGuid) => boolean = createSelector(
    (state, fieldGuid) => getModuleState(state).fieldGuidToEventDetails.get(fieldGuid),
    (eventDetails): boolean => {
        /**
         * have at least 1 area where applyEventToArea is true
         * AND for all such areas:
         *   1. the polygons have not been updated or their geometry is defined
         *   2. every agEvent in the area has the required fields set
         */
        if (!eventDetails) {
            return false;
        }
        const zoneGeometryDefined = (zone) => {
            return zone.zonePolygons != null && zone.zonePolygons.every((p) => p.shape != null);
        };
        const zonesWithData = eventDetails.isSamplingEvent
            ? [eventDetails.eventAreaList[0]]
            : eventDetails.eventAreaList.filter((zone) => zone.applyEventToArea);

        return (
            zonesWithData.length > 0 &&
            zonesWithData.every((zone) => {
                let samplingFlag = true;
                const agEventModel: any =
                    zone.agEventList.length > 0 ? zone.agEventList[0].agEventModel : null;
                if (agEventModel instanceof models.SampleSetup) {
                    console.assert(eventDetails.isSamplingEvent);
                    samplingFlag =
                        agEventModel.samplePoints.length > 0 &&
                        agEventModel.samplePoints.every((pt) => pt.sequenceId != null) &&
                        ((agEventModel.samplePoints[0] as AgEventAPI.ISoilSamplePoint)
                            .soilSamplePointGuid !== undefined ||
                            Boolean(
                                agEventModel.cropGuid &&
                                    agEventModel.samplePoints.every(
                                        (p) =>
                                            (p as AgEventAPI.ITissueSamplePoint).cropGrowthStageGuid
                                    )
                            ));
                }
                return (
                    samplingFlag &&
                    (eventDetails.fieldGuid === BATCH_TEMPLATE_FIELD_GUID ||
                        zoneGeometryDefined(zone) ||
                        eventDetails.isECDataEvent) &&
                    (zonesWithData.length >= 2 || samplingFlag
                        ? true
                        : zone.agEventList.every(
                              (agEvent) => agEvent.agEventModel.isAllRequiredFieldsSet
                          ) || eventDetails.isECDataEvent)
                );
            })
        );
    }
);

export const getEventAreaIds: (state, fieldGuid) => Array<number> | null = createSelector(
    (state, fieldGuid) => getModuleState(state).fieldGuidToEventDetails.get(fieldGuid),
    (eventDetails: models.EventDetails) => {
        const eventAreaIds = eventDetails.eventAreaList.map((eventArea) => eventArea.eventAreaId);
        return eventAreaIds;
    }
);

export const getEventAreaIdToHasDataMap: (state, fieldGuid) => Map<number, boolean> =
    createSelector(
        (state, fieldGuid) => getModuleState(state).fieldGuidToEventDetails.get(fieldGuid),
        (eventDetails): Map<number, boolean> => {
            return (
                eventDetails &&
                new Map(
                    eventDetails.eventAreaList.map((agEventArea): [number, boolean] => [
                        agEventArea.eventAreaId,
                        agEventArea.applyEventToArea,
                    ])
                )
            );
        }
    );

export const getIsCopyEventAction = (state: unknown): boolean => getModuleState(state).isCopyAction;
export const getEventFieldBoundaryGraphics = (state: unknown): Graphic[] =>
    getModuleState(state).boundaryGraphics;

/** Determines if the current map tools are targeting an *imported* event.
 * @param {object} state - The current application state.
 * @returns {boolean}
 */
export const getIsImported = createSelector(
    (state) => getMapToolsState(state).toolsetPayload.recEventDetails,
    getEventGeneralGuidToEventSummaryMap,
    (recEventDetails, eventGuidToEventSummaryMap) => {
        const eventSummary = eventGuidToEventSummaryMap.get(
            recEventDetails && recEventDetails.agEventGeneralGuid
        );
        return eventSummary && eventSummary.isImportedYn != null
            ? eventSummary.isImportedYn
            : false;
    }
);

export const getIsFromEquationRec = createSelector(
    (state) => getMapToolsState(state).toolsetPayload.recEventDetails,
    getEventGeneralGuidToEventSummaryMap,
    (recEventDetails, eventGuidToEventSummaryMap) => {
        const eventSummary = eventGuidToEventSummaryMap.get(
            recEventDetails && recEventDetails.agEventGeneralGuid
        );
        return eventSummary && eventSummary.isFromEquationRec != null
            ? eventSummary.isFromEquationRec
            : false;
    }
);

export const getSampleAgEvent: (state, fieldGuid) => models.SampleSetup | null = createSelector(
    (state, fieldGuid) => getModuleState(state).fieldGuidToEventDetails.get(fieldGuid),
    (eventDetails) => {
        if (
            !eventDetails ||
            !eventDetails.isSamplingEvent ||
            eventDetails.eventAreaList.length === 0 ||
            eventDetails.eventAreaList[0].agEventList.length === 0
        ) {
            return null;
        }

        return eventDetails.eventAreaList[0].agEventList[0].agEventModel as models.SampleSetup;
    }
);

export const getScoutingPhotoPresignedUrlMap = (state: unknown): Map<string, string> => {
    return getModuleState(state).scoutingPhotoPresignedUrlMap;
};

export const getEquipmentGuidToHarvestLoadListMap = (
    state: unknown
): Map<string, ICalibrateEquipmentLoads> => {
    return getModuleState(state).equipmentGuidToHarvestLoadListMap;
};

export const getYieldCalibrationOptions = (state: unknown): any[] => {
    return getModuleState(state).yieldCalibrationOptions;
};

export const getYieldCalibrationPassedValidation = (state: unknown): boolean => {
    return getModuleState(state).yieldCalibrationPassedValidation;
};

export const getYieldCalibrationErrorState = (state: unknown): boolean =>
    getModuleState(state).yieldCalibrationHasError;
