import { defineMessages } from "react-intl";

export const messages = defineMessages({
    title: {
        id: "addBoundaryToPortal.title",
        defaultMessage: "Add field to JD Ops Center",
    },
    addFailed: {
        id: "addBoundaryToPortal.addFailed",
        defaultMessage: "Could not add field to the JD Ops Center",
    },
    addSucceeded: {
        id: "addBoundaryToPortal.addSucceeded",
        defaultMessage: "Added field to the JD Ops Center",
    },
    fieldHasNoConflictsOrMatches: {
        id: "addBoundaryToPortal.fieldHasNoConflicts",
        defaultMessage:
            "No overlapping Fields detected for same Customer in the JD Ops Center. Would you like to add this Field to JD Ops Center?",
    },
});
