import { AgvanceAPI, EventAPI, FieldAPI } from "@ai360/core";
import { IFieldsToMove, IActionData, IPayloadData, FieldBoundaryOverlapAction } from "./interfaces";
import * as models from "./models";
import { Polygon } from "@arcgis/core/geometry";

export enum FieldModulePages {
    FIELD_LIST = "FIELD_LIST",
    FIELD_INFORMATION = "FIELD_INFORMATION",
}

export enum FieldListTabs {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    SELECTED = "SELECTED",
}

export interface IMoveFieldsPayload {
    userGuid: string;
    customerGuid: string;
    farmName: string;
    fieldGuidList: string[];
    agvanceCustomerGuid?: string;
    agvanceFieldOrgLevelGuid?: string;
}

export const ACTIVATE_CUSTOMER = "fields/ACTIVATE_CUSTOMER";
export const ACTIVATE_FIELD = "fields/ACTIVATE_FIELD";
export const ADD_ANALYSIS_LAYER = "fields/ADD_ANALYSIS_LAYER";
export const CHANGE_ACTIVE_TAB = "fields/CHANGE_ACTIVE_TAB";
export const CLEAR_AGVANCE_FIELD_LIST = "fields/CLEAR_AGVANCE_FIELD_LIST";
export const CLEAR_AGVANCE_FIELD_CLASSIFICATION_LIST =
    "fields/CLEAR_AGVANCE_FIELD_CLASSIFICATION_LIST";
export const CLEAR_CUSTOMER_FIELD_LIST_WITH_ORG_LEVEL =
    "fields/CLEAR_CUSTOMER_FIELD_LIST_WITH_ORG_LEVEL ";
export const CLEAR_ERROR_CODES = "fields/CLEAR_ERROR_CODES";
export const CLEAR_FIELD_INFO = "fields/CLEAR_FIELD_INFO";
export const CLEAR_FIELDS_TO_MOVE = "fields/CLEAR_FIELDS_TO_MOVE";
export const CLEAR_FULL_FIELD_RECORDS = "fields/CLEAR_FULL_FIELD_RECORDS";
export const CLEAR_RESURFACE_EVENTS = "fields/CLEAR_RESURFACE_EVENTS";
export const COMBINE_FIELDS = "fields/COMBINE_FIELDS";
export const SET_COMBINE_FIELDS_ERROR = "fields/SET_COMBINE_FIELDS_ERROR";
export const DELETE_CUSTOMER = "fields/DELETE_CUSTOMER";
export const DELETE_FIELD = "fields/DELETE_FIELD";
export const DELETE_SELECTED_FIELDS = "fields/DELETE_SELECTED_FIELDS";
export const EXPORT_BOUNDARY = "fields/EXPORT_BOUNDARY";
export const EXPORT_DETAILS = "fields/EXPORT_DETAILS";
export const FETCHED_DROPDOWN_DATA = "fields/FETCHED_DROPDOWN_DATA";
export const FETCH_AGVANCE_FIELD_LIST = "fields/FETCH_AGVANCE_FIELD_LIST";
export const FETCH_AGVANCE_FIELD_LIST_SUCCEEDED = "fields/FETCH_AGVANCE_FIELD_LIST_SUCCEEDED";
export const FETCH_AGVANCE_FIELD_CLASSIFICATION_LIST =
    "fields/FETCH_AGVANCE_FIELD_CLASSIFICATION_LIST";
export const FETCH_AGVANCE_FIELD_CLASSIFICATION_LIST_SUCCEEDED =
    "fields/FETCH_AGVANCE_FIELD_CLASSIFICATION_LIST_SUCCEEDED";
export const FETCH_AGVANCE_ORG_LEVEL_LIST = "fields/FETCH_AGVANCE_ORG_LEVEL_LIST";
export const FETCH_AGVANCE_ORG_LEVEL_LIST_SUCCEEDED =
    "fields/FETCH_AGVANCE_ORG_LEVEL_LIST_SUCCEEDED";
export const FETCH_FIELD_INFO_INIT = "fields/FETCH_FIELD_INFO_INIT";
export const FETCH_FIELD_INFO_SUCCEEDED = "fields/FETCH_FIELD_INFO_SUCCEEDED";
export const FETCH_FIELD_INFO_ERROR = "fields/FETCH_FIELD_INFO_ERROR";
export const SET_FIELD_INITIALIZING = "fields/SET_FIELD_INITIALIZING";
export const FETCH_FULL_FIELD_RECORDS = "fields/FETCH_FULL_FIELD_RECORDS";
export const FETCH_FULL_FIELD_RECORDS_SUCCESS = "fields/FETCH_FULL_FIELD_RECORDS_SUCCESS";
export const FETCH_ORG_LEVEL_LIST = "fields/FETCH_ORG_LEVEL_LIST";
export const FETCH_ORG_LEVEL_LIST_SUCCEEDED = "fields/FETCH_ORG_LEVEL_LIST_SUCCEEDED";
export const FETCH_RESURFACE_EVENTS = "fields/FETCH_RESURFACE_EVENTS";
export const FETCH_RESURFACE_EVENTS_SUCCEEDED = "fields/FETCH_RESURFACE_EVENTS_SUCCEEDED";
export const FIELD_BOUNDARY_OVERLAP_DETECTED = "fields/FIELD_BOUNDARY_OVERLAP_DETECTED";
export const IMAGERY_SETUP = "fields/IMAGERY_SETUP";
export const LOCKED_BOUNDARY_OVERLAP_DETECTED = "fields/LOCKED_BOUNDARY_OVERLAP_DETECTED";
export const MOVE_CUSTOMER_FIELDS = "fields/MOVE_CUSTOMER_FIELDS";
export const REMOVE_RESURFACE_EVENTS = "fields/REMOVE_RESURFACE_EVENTS";
export const SAVE_FIELD_INIT = "fields/SAVE_FIELD_INIT";
export const SAVE_FIELD_SUCCEEDED = "fields/SAVE_FIELD_SUCCEEDED";
export const SET_AGVANCE_FIELD_DATA = "fields/SET_AGVANCE_FIELD_DATA";
export const SET_COMBINE_FIELDS_MODAL_OPEN = "fields/SET_COMBINE_FIELDS_MODAL_OPEN";
export const SET_FARM_NAMES = "fields/SET_FARM_NAMES";
export const SET_FIELD_BOUNDARY_OVERLAP_ACTION = "fields/SET_FIELD_BOUNDARY_OVERLAP_ACTION";
export const SET_FIELD_BOUNDARY_TRANSFER_GEOMETRIES =
    "fields/SET_FIELD_BOUNDARY_TRANSFER_GEOMETRIES";
export const SET_FIELD_BOUNDARY_TRANSFER_FIELD_MODEL =
    "fields/SET_FIELD_BOUNDARY_TRANSFER_FIELD_MODEL";
export const SET_FIELD_BOUNDARY_IMPORT_FILE_GUID = "fields/SET_FIELD_BOUNDARY_IMPORT_FILE_GUID";
export const SET_FIELD_BOUNDARY_TRANSFER_MODAL_OPEN =
    "fields/SET_FIELD_BOUNDARY_TRANSFER_MODAL_OPEN";
export const SET_PORTAL_BOUNDARY_SYNC_MODAL_OPEN = "fields/SET_PORTAL_BOUNDARY_SYNC_MODAL_OPEN";
export const SET_ADD_BOUNDARY_TO_PORTAL_MODAL_OPEN = "fields/SET_ADD_BOUNDARY_TO_PORTAL_MODAL_OPEN";
export const SET_CENTER_AND_ZOOM_FOR_BOUNDARY_SYNC_MODAL =
    "fields/SET_CENTER_AND_ZOOM_FOR_BOUNDARY_SYNC_MODAL";
export const SET_JD_BOUNDARIES_FOR_BOUNDARY_SYNC_MODAL =
    "fields/SET_JD_BOUNDARIES_FOR_BOUNDARY_SYNC_MODAL";
export const SET_FIELD_DELETE_AGVANCE_OVERRIDE_MODAL_OPEN =
    "fields/SET_FIELD_DELETE_AGVANCE_OVERRIDE_MODAL_OPEN";
export const SET_FIELD_BOUNDARIES_TO_CLIP = "fields/SET_FIELD_BOUNDARIES_TO_CLIP";
export const SET_IS_LOADING = "fields/SET_IS_LOADING";
export const SET_IS_NEW_FIELD = "fields/SET_IS_NEW_FIELD";
export const SET_ORG_LEVEL_GUID = "field/SET_ORG_LEVEL_GUID";
export const SET_FIELDS_TO_MOVE = "fields/SET_FIELDS_TO_MOVE";
export const SET_MOVE_FIELD_LOADING = "fields/SET_MOVE_FIELD_LOADING";
export const UPDATE_FILTERED_FIELD_GUID_SET = "fields/UPDATE_FILTERED_FIELD_GUID_SET";
export const UPDATE_FIELD_INFO = "fields/UPDATE_FIELD_INFO";

export const activateCustomer = (customerGuid: string): IActionData => ({
    type: ACTIVATE_CUSTOMER,
    payload: { customerGuid },
});

export const activateField = (fieldGuid: string): IActionData => ({
    type: ACTIVATE_FIELD,
    payload: { fieldGuid },
});

export const addAnalysisLayer = (
    fieldGuids: string[],
    analysisLayerType: string,
    cropGuid?: string,
    croppingSeasonGuid?: string
): IActionData => ({
    type: ADD_ANALYSIS_LAYER,
    payload: { fieldGuids, analysisLayerType, cropGuid, croppingSeasonGuid },
});

export const changeActiveTab = (activeTab: string): IActionData => ({
    type: CHANGE_ACTIVE_TAB,
    payload: { activeTab },
});

export const clearCustomerFieldListWithOrgLevel = (): IActionData => ({
    type: CLEAR_CUSTOMER_FIELD_LIST_WITH_ORG_LEVEL,
});

export const clearFieldInfo = (): IActionData => ({
    type: CLEAR_FIELD_INFO,
});

export const clearFieldsToMove = (): IActionData => ({
    type: CLEAR_FIELDS_TO_MOVE,
});

export const deleteCustomer = (customerGuid: string): IActionData => ({
    type: DELETE_CUSTOMER,
    payload: { customerGuid },
});

export const deleteField = (fieldGuid: string, ignoreAgvance?: boolean): IActionData => ({
    type: DELETE_FIELD,
    payload: { fieldGuid, ignoreAgvance },
});

export const deleteSelectedFields = (ignoreAgvance?: boolean): IActionData => ({
    type: DELETE_SELECTED_FIELDS,
    payload: { ignoreAgvance },
});

export const exportBoundary = (fieldGuid?: string): IActionData => ({
    type: EXPORT_BOUNDARY,
    payload: { fieldGuid },
});

export const exportDetails = (fieldGuid?: string): IActionData => ({
    type: EXPORT_DETAILS,
    payload: { fieldGuid },
});

export const fetchAgvanceFieldList = (customerGuid: IPayloadData): IActionData => ({
    type: FETCH_AGVANCE_FIELD_LIST,
    payload: customerGuid,
});

export const fetchAgvanceFieldListSucceeded = (
    agvanceFieldList: AgvanceAPI.IFieldResult[]
): IActionData => ({
    type: FETCH_AGVANCE_FIELD_LIST_SUCCEEDED,
    payload: { agvanceFieldList },
});

export const clearAgvanceFieldList = (): IActionData => ({
    type: CLEAR_AGVANCE_FIELD_LIST,
});

export const fetchAgvanceFieldClassificationList = (orgLevelGuid: IPayloadData): IActionData => ({
    type: FETCH_AGVANCE_FIELD_CLASSIFICATION_LIST,
    payload: orgLevelGuid,
});

export const fetchAgvanceFieldClassificationListSucceeded = (
    agvanceFieldClassificationList: string[]
): IActionData => ({
    type: FETCH_AGVANCE_FIELD_CLASSIFICATION_LIST_SUCCEEDED,
    payload: { agvanceFieldClassificationList },
});

export const clearAgvanceFieldClassificationList = (): IActionData => ({
    type: CLEAR_AGVANCE_FIELD_CLASSIFICATION_LIST,
});

export const fetchAgvanceOrgLevelList = (customerGuid: IPayloadData): IActionData => ({
    type: FETCH_AGVANCE_ORG_LEVEL_LIST,
    payload: customerGuid,
});

export const fetchAgvanceOrgLevelListSucceeded = (agvanceOrgLevelList: string[]): IActionData => ({
    type: FETCH_AGVANCE_ORG_LEVEL_LIST_SUCCEEDED,
    payload: { agvanceOrgLevelList },
});

export const setFieldInitializing = (fieldInit: boolean): IActionData => ({
    type: SET_FIELD_INITIALIZING,
    payload: { fieldInit },
});

export const fetchFieldInformation = (
    activeCustomerGuid: string,
    activeFieldGuid: string
): IActionData => ({
    type: FETCH_FIELD_INFO_INIT,
    payload: { activeCustomerGuid, activeFieldGuid },
});

export const fetchFieldInformationSucceeded = (
    fieldModel: unknown,
    customerGuid: string
): IActionData => ({
    type: FETCH_FIELD_INFO_SUCCEEDED,
    payload: { fieldModel, customerGuid },
});

export const fetchFieldInformationError = (): IActionData => ({
    type: FETCH_FIELD_INFO_ERROR,
});

export const fetchOrgLevelList = (): IActionData => ({
    type: FETCH_ORG_LEVEL_LIST,
});

export const fetchOrgLevelListSucceeded = (orgLevelList: string[]): IActionData => ({
    type: FETCH_ORG_LEVEL_LIST_SUCCEEDED,
    payload: { orgLevelList },
});

export const fetchResurfaceEvents = (
    fieldGuid: string,
    keepExistingEvents = false
): IActionData => ({
    type: FETCH_RESURFACE_EVENTS,
    payload: { fieldGuid, keepExistingEvents },
});

export const fieldBoundaryOverlapDetected = (): IActionData => ({
    type: FIELD_BOUNDARY_OVERLAP_DETECTED,
});

export const clearResurfaceEvents = (): IActionData => ({
    type: CLEAR_RESURFACE_EVENTS,
});

export const lockedBoundaryOverlapDetected = (): IActionData => ({
    type: LOCKED_BOUNDARY_OVERLAP_DETECTED,
});

export const fetchResurfaceEventsSuccess = (
    resurfaceEvents: EventAPI.IResurfaceEvent,
    keepExistingEvents = false
): IActionData => ({
    type: FETCH_RESURFACE_EVENTS_SUCCEEDED,
    payload: {
        resurfaceEvents,
        keepExistingEvents,
    },
});

export const fetchedDropdownData = (payload: IPayloadData): IActionData => ({
    type: FETCHED_DROPDOWN_DATA,
    payload,
});

export const imagerySetup = (fieldGuid: string): IActionData => ({
    type: IMAGERY_SETUP,
    payload: { fieldGuid },
});

export const moveCustomerFields = ({
    userGuid,
    customerGuid,
    farmName,
    fieldGuidList,
    agvanceCustomerGuid,
    agvanceFieldOrgLevelGuid,
}: IMoveFieldsPayload): IActionData => ({
    type: MOVE_CUSTOMER_FIELDS,
    payload: {
        userGuid,
        customerGuid,
        farmName,
        fieldGuidList,
        agvanceCustomerGuid,
        agvanceFieldOrgLevelGuid,
    },
});

export const removeResurfaceEvents = (fieldGuid: string): IActionData => ({
    type: REMOVE_RESURFACE_EVENTS,
    payload: { fieldGuid },
});

export const setIsMoveDialogLoading = (isLoading: boolean): IActionData => ({
    type: SET_MOVE_FIELD_LOADING,
    payload: { isLoading },
});

export const saveFieldInformation = (fieldModel: unknown, callback: () => any): IActionData => ({
    type: SAVE_FIELD_INIT,
    payload: { fieldModel, callback },
});

export const saveFieldInformationSucceeded = (response: unknown): IActionData => ({
    type: SAVE_FIELD_SUCCEEDED,
    payload: { response },
});

export const clearErrorCodes = (): IActionData => ({
    type: CLEAR_ERROR_CODES,
});

export const setAgvanceFieldData = (agvanceFieldData: IFieldsToMove): IActionData => ({
    type: SET_AGVANCE_FIELD_DATA,
    payload: { agvanceFieldData },
});

export const setFieldBoundariesToClip = (fieldBoundaries: {
    [fieldGuid: string]: FieldAPI.IFieldBoundary[];
}): IActionData => ({
    type: SET_FIELD_BOUNDARIES_TO_CLIP,
    payload: { fieldBoundaries },
});

export const setFieldBoundaryOverlapAction = (action: FieldBoundaryOverlapAction): IActionData => ({
    type: SET_FIELD_BOUNDARY_OVERLAP_ACTION,
    payload: { action },
});

export const setFieldBoundaryImportFileGuid = (importFileGuid: string): IActionData => ({
    type: SET_FIELD_BOUNDARY_IMPORT_FILE_GUID,
    payload: { importFileGuid },
});

export const setIsFieldLoading = (isLoading: boolean): IActionData => ({
    type: SET_IS_LOADING,
    payload: { isLoading },
});

export const setIsNewField = (isNewField: boolean): IActionData => ({
    type: SET_IS_NEW_FIELD,
    payload: { isNewField },
});

export const setFieldsToMove = (fieldsToMove: IFieldsToMove): IActionData => ({
    type: SET_FIELDS_TO_MOVE,
    payload: { fieldsToMove },
});

export const setOrgLevelGuid = (orgLevelGuid: string): IActionData => ({
    type: SET_ORG_LEVEL_GUID,
    payload: { orgLevelGuid },
});

export const setCombineFieldsModalOpen = (isCombineFieldsModalOpen: boolean): IActionData => ({
    type: SET_COMBINE_FIELDS_MODAL_OPEN,
    payload: { isCombineFieldsModalOpen },
});

export const setFieldBoundaryTransferGeometries = (geometries: __esri.Geometry[]): IActionData => ({
    type: SET_FIELD_BOUNDARY_TRANSFER_GEOMETRIES,
    payload: { geometries },
});

export const setFieldBoundaryTransferModalOpen = (
    isFieldBoundaryTransferModalOpen: boolean
): IActionData => ({
    type: SET_FIELD_BOUNDARY_TRANSFER_MODAL_OPEN,
    payload: { isFieldBoundaryTransferModalOpen },
});

export const setPortalBoundarySyncModalOpen = (
    isPortalBoundarySyncModalOpen: boolean
): IActionData => ({
    type: SET_PORTAL_BOUNDARY_SYNC_MODAL_OPEN,
    payload: { isPortalBoundarySyncModalOpen },
});

export const setAddBoundaryToPortalModalOpen = (
    isAddBoundaryToPortalModalOpen: boolean
): IActionData => ({
    type: SET_ADD_BOUNDARY_TO_PORTAL_MODAL_OPEN,
    payload: { isAddBoundaryToPortalModalOpen },
});

export const setCenterAndZoomForBoundarySyncModal = (
    latitude: number,
    longitude: number,
    zoomLevel: number,
    fieldPolygons: Polygon[]
): IActionData => ({
    type: SET_CENTER_AND_ZOOM_FOR_BOUNDARY_SYNC_MODAL,
    payload: { latitude, longitude, zoomLevel, fieldPolygons },
});

export const setJdBoundariesForBoundarySyncModal = (overlaps: any): IActionData => ({
    type: SET_JD_BOUNDARIES_FOR_BOUNDARY_SYNC_MODAL,
    payload: { overlaps },
});

export const setFieldBoundaryTransferFieldModel = (
    fieldModel: models.IFieldModel
): IActionData => ({
    type: SET_FIELD_BOUNDARY_TRANSFER_FIELD_MODEL,
    payload: fieldModel,
});

export const fetchFullFieldRecords = (fieldGuidList: IPayloadData): IActionData => ({
    type: FETCH_FULL_FIELD_RECORDS,
    payload: fieldGuidList,
});

export const fetchFullFieldRecordSuccess = (fieldList: IPayloadData): IActionData => ({
    type: FETCH_FULL_FIELD_RECORDS_SUCCESS,
    payload: fieldList,
});

export const clearFullFieldRecords = (): IActionData => ({
    type: CLEAR_FULL_FIELD_RECORDS,
});

export const combineFields = (combineFieldRequest: IPayloadData): IActionData => ({
    type: COMBINE_FIELDS,
    payload: combineFieldRequest,
});

export const setCombineFieldsError = (error: Error): IActionData => ({
    type: SET_COMBINE_FIELDS_ERROR,
    payload: { error },
});

export const setFieldDeleteAgvanceOverrideModalOpen = (fieldGuids: string[]): IActionData => ({
    type: SET_FIELD_DELETE_AGVANCE_OVERRIDE_MODAL_OPEN,
    payload: { fieldGuids },
});

export const updateFieldInfo = (fieldModel: unknown): IActionData => ({
    type: UPDATE_FIELD_INFO,
    payload: { fieldModel },
});
