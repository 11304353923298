import { configureStore } from "@reduxjs/toolkit";
import { batchDispatchMiddleware } from "redux-batched-actions";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";

import { RequireRefreshError } from "~/notifications";
import { Ai360API } from "@ai360/core";
import { rootReducer } from "./root-reducer";
import { rootSaga } from "./root-saga";

const middlewares = [];
const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);
middlewares.push(batchDispatchMiddleware);
middlewares.push(Ai360API.middleware);

declare global {
    interface Window {
        process_env: any;
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    }
}

if (!window.process_env.NO_LOGGER && window.process_env.NODE_ENV === "development") {
    const logger = createLogger({
        duration: true,
        timestamp: true,
    });
    middlewares.push(logger);
}

export default configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false, // Disable immutable checks
            serializableCheck: false, // Optionally disable serialization checks
        }).concat(middlewares),
    devTools:
        process.env.NODE_ENV !== "production"
            ? {
                  maxAge: 100,
                  trace: true,
                  traceLimit: 50,
                  autoPause: true,
                  shouldRecordChanges: false,
              }
            : false, // Automatically use Redux DevTools if not in production
});

const rootTask = sagaMiddleware.run(rootSaga);
rootTask.done.catch((err) => {
    // one of the sagas has an uncaught error, so we should probably reload the app
    throw new RequireRefreshError(err.toString());
});
