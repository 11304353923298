import { defineMessages } from "react-intl";
import { messages } from "./i18n-messages";

export const errorCodeMessages = defineMessages({
    1: {
        id: "errorCode.1",
        defaultMessage: "The Username or Password provided is incorrect.",
    },
    2: {
        id: "errorCode.2",
        defaultMessage: "Crop(s)/Crop Purpose(s) list contains duplicates.",
    },
    3: {
        id: "errorCode.3",
        defaultMessage: "Product list contains duplicates.",
    },
    4: {
        id: "errorCode.4",
        defaultMessage: "City is required.",
    },
    5: {
        id: "errorCode.5",
        defaultMessage: "State is required.",
    },
    6: {
        id: "errorCode.6",
        defaultMessage: "Country is required.",
    },
    7: {
        id: "errorCode.7",
        defaultMessage: "Address Type is required.",
    },
    8: {
        id: "errorCode.8",
        defaultMessage: "Street Address is required.",
    },
    9: {
        id: "errorCode.9",
        defaultMessage: "Category is required.",
    },
    11: {
        id: "errorCode.11",
        defaultMessage: "Long Description is required.",
    },
    15: {
        id: "errorCode.15",
        defaultMessage: "Product ID is required",
    },
    17: {
        id: "errorCode.17",
        defaultMessage: "The e-mail address is required.",
    },
    20: {
        id: "errorCode.20",
        defaultMessage: "User role is required.",
    },
    21: {
        id: "errorCode.21",
        defaultMessage: "Login email is required.",
    },
    22: {
        id: "errorCode.22",
        defaultMessage: "Product ID already exists.",
    },
    23: {
        id: "errorCode.23",
        defaultMessage: "The Product Package ID is required.",
    },
    24: {
        id: "errorCode.24",
        defaultMessage: "Owner Name is required.",
    },
    25: {
        id: "errorCode.25",
        defaultMessage: "Document List contains duplicates.",
    },
    26: {
        id: "errorCode.26",
        defaultMessage: "First Name is required.",
    },
    27: {
        id: "errorCode.27",
        defaultMessage: "Last Name is required.",
    },
    28: {
        id: "errorCode.28",
        defaultMessage: "A Job Title must be selected.",
    },
    29: {
        id: "errorCode.29",
        defaultMessage: "You cannot delete yourself.",
    },
    30: {
        id: "errorCode.30",
        defaultMessage: "The name of the column being searched is invalid.",
    },
    31: {
        id: "errorCode.31",
        defaultMessage: "The name of the column being sorted is invalid.",
    },
    33: {
        id: "errorCode.33",
        defaultMessage: "Application Standard Deviation is required.",
    },
    40: {
        id: "errorCode.40",
        defaultMessage: "User Role Name is required.",
    },
    41: {
        id: "errorCode.41",
        defaultMessage: "User Role Description is required.",
    },
    42: {
        id: "errorCode.42",
        defaultMessage: "Telematics Setup list contains duplicates.",
    },
    43: {
        id: "errorCode.43",
        defaultMessage: "Location name is required.",
    },
    44: {
        id: "errorCode.44",
        defaultMessage: "Planting Standard Deviation is required.",
    },
    45: {
        id: "errorCode.45",
        defaultMessage: "Layers cannot be the only item selected.",
    },
    46: {
        id: "errorCode.46",
        defaultMessage: "Exporting the file was unsuccessful. Please try again.",
    },
    47: {
        id: "errorCode.47",
        defaultMessage:
            "The User Role Name you entered is not allowed. Please enter a different name.",
    },
    48: {
        id: "errorCode.48",
        defaultMessage: "User Role is assigned",
    },
    49: {
        id: "errorCode.49",
        defaultMessage: "Editing this User Role is not allowed.",
    },
    50: {
        id: "errorCode.50",
        defaultMessage: "Editing this Owner Name is not allowed.",
    },
    51: {
        id: "errorCode.51",
        defaultMessage:
            "The Location Name you entered is not allowed. Please enter a different name.",
    },
    52: {
        id: "errorCode.52",
        defaultMessage: "The Owner Name you entered is not allowed. Please enter a different name.",
    },
    53: {
        id: "errorCode.53",
        defaultMessage: "Login email is invalid.",
    },
    54: {
        id: "errorCode.54",
        defaultMessage: "Email already exists.",
    },
    55: {
        id: "errorCode.55",
        defaultMessage: "Customer Name is required.",
    },
    56: {
        id: "errorCode.56",
        defaultMessage: "Account cannot be the only item selected for the User Role.",
    },
    61: {
        id: "errorCode.61",
        defaultMessage: "A field name is required.",
    },
    "Field.PolygonsOverlappingExistingField": {
        id: "errorCode.Field.PolygonsOverlappingExistingField",
        defaultMessage: "Field boundary overlaps an existing field.",
    },
    "Field.FieldNameNotProvided": {
        id: "errorCode.Field.FieldNameNotProvided",
        defaultMessage: "A field name is required.",
    },
    62: {
        id: "errorCode.62",
        defaultMessage: "There is already a field by this name.",
    },
    "Field.WillCreateDuplicateName": {
        id: "errorCode.Field.WillCreateDuplicateName",
        defaultMessage: "There is already a field by this name.",
    },
    63: {
        id: "errorCode.63",
        defaultMessage: "Customer is required.",
    },
    68: {
        id: "errorCode.68",
        defaultMessage: "Picklist Name is required.",
    },
    70: {
        id: "errorCode.70",
        defaultMessage:
            "A connection with this Name or Parameters already exists for this Connection Type.",
    },
    72: {
        id: "errorCode.72",
        defaultMessage: "Branding Name is required.",
    },
    81: {
        id: "errorCode.81",
        defaultMessage: "Ag Event start date is required.",
    },
    83: {
        id: "errorCode.83",
        defaultMessage: "Browser Tab Icon is required.",
    },
    84: {
        id: "errorCode.84",
        defaultMessage: "Sample Type Name is required.",
    },
    85: {
        id: "errorCode.85",
        defaultMessage: "Nutrient Name is required.",
    },
    "AgBytes.Nutrient.NameNotProvided": {
        id: "errorCode.AgBytes.Nutrient.NameNotProvided",
        defaultMessage: "Nutrient Name is required.",
    },
    86: {
        id: "errorCode.86",
        defaultMessage: "Product Assignment is not unique.",
    },
    88: {
        id: "errorCode.88",
        defaultMessage: "Location Level Name is required.",
    },
    89: {
        id: "errorCode.89",
        defaultMessage:
            "The following selected Fields are missing the required Planting Event or Rec needed for this report: {codeValue}",
    },
    90: {
        id: "errorCode.90",
        defaultMessage:
            "The following selected Fields are missing the required Planting Rec needed for this report: {codeValue}",
    },
    91: {
        id: "errorCode.91",
        defaultMessage: "There must be an Admin Login Email entered.",
    },
    92: {
        id: "errorCode.92",
        defaultMessage: "This Admin Login already exists.",
    },
    93: {
        id: "errorCode.93",
        defaultMessage: "The Browser Tab Icon is required.",
    },
    95: {
        id: "errorCode.95",
        defaultMessage: "Depth Config Depth ID is required.",
    },
    96: {
        id: "errorCode.96",
        defaultMessage: "Depth Config start depth is required.",
    },
    97: {
        id: "errorCode.97",
        defaultMessage: "Depth Config end depth is required.",
    },
    98: {
        id: "errorCode.98",
        defaultMessage: "Sample Method is required.",
    },
    99: {
        id: "errorCode.99",
        defaultMessage: "Grid Size is required.",
    },
    101: {
        id: "errorCode.101",
        defaultMessage: "Multi or Single depth is required.",
    },
    102: {
        id: "errorCode.102",
        defaultMessage: "Height is required.",
    },
    103: {
        id: "errorCode.103",
        defaultMessage: "Width is required.",
    },
    104: {
        id: "errorCode.104",
        defaultMessage:
            "The Vendor is currently affiliated with a Template. Please remove the affiliation before deleting.",
    },
    105: {
        id: "errorCode.105",
        defaultMessage: "Sample ID is required.",
    },
    106: {
        id: "errorCode.106",
        defaultMessage: "Field Name already exists and is Inactive. Please re-activate the field.",
    },
    "AgBytes.CountryState.NameNotProvided": {
        id: "errorCode.AgBytes.CountryState.NameNotProvided",
        defaultMessage: "State Name is required.",
    },
    "AgBytes.CountryState.CountryNameNotProvided": {
        id: "errorCode.AgBytes.CountryState.CountryNameNotProvided",
        defaultMessage: "Country Name is required.",
    },
    "AgBytes.CountryState.StateAbbreviationNotProvided": {
        id: "errorCode.AgBytes.CountryState.StateAbbreviationNotProvided",
        defaultMessage: "State Abbreviation is required.",
    },
    111: {
        id: "errorCode.111",
        defaultMessage: "Records to update were not found.",
    },
    "AgBytes.CountryState.WillCreateDuplicateName": {
        id: "errorCode.AgBytes.CountryState.WillCreateDuplicateName",
        defaultMessage: "State Name already exists.",
    },
    "AgBytes.CountryState.StateAbbreviationIsNotTwoLetters": {
        id: "errorCode.AgBytes.CountryState.StateAbbreviationIsNotTwoLetters",
        defaultMessage: "State Abbreviation should be two letters.",
    },
    123: {
        id: "errorCode.123",
        defaultMessage: "This record is being used and can't be deleted.",
    },
    "AgBytes.RecordInUse": {
        id: "errorCode.AgBytes.RecordInUse",
        defaultMessage: "This record is being used and can't be deleted.",
    },
    124: {
        id: "errorCode.124",
        defaultMessage:
            "Import failed.  Please review and correct the import file for the following issues:",
    },
    "AgBytes.ImportFailed": {
        id: "errorCode.AgBytes.ImportFailed",
        defaultMessage:
            "Import failed.  Please review and correct the import file for the following issues:",
    },
    125: {
        id: "errorCode.125",
        defaultMessage: "State ID is required.</br/>",
    },
    126: {
        id: "errorCode.126",
        defaultMessage: "Total number of issues = {codeValue}.",
    },
    128: {
        id: "errorCode.128",
        defaultMessage: "Import Attribute Name is required.",
    },
    "AgBytes.ImportAttribute.NameNotProvided": {
        id: "errorCode.AgBytes.ImportAttribute.NameNotProvided",
        defaultMessage: "Import Attribute Name is required.",
    },
    "AgBytes.ImportAttribute.WillCreateDuplicateName": {
        id: "errorCode.AgBytes.ImportAttribute.WillCreateDuplicateName",
        defaultMessage: "Sample Attribute Name already exists.",
    },
    "AgBytes.ImportAttribute.CannotBeEventAndSample": {
        id: "errorCode.AgBytes.ImportAttribute.CannotBeEventAndSample",
        defaultMessage: "Cannot be both an event and a sample.",
    },
    "AgBytes.ImportAttribute.SampleTypeRequiredIfSamplingEvent": {
        id: "errorCode.AgBytes.ImportAttribute.SampleTypeRequiredIfSamplingEvent",
        defaultMessage: "SampleType must be provided if EventType is Sampling.",
    },
    "AgBytes.Unit.NameNotProvided": {
        id: "errorCode.AgBytes.Unit.NameNotProvided",
        defaultMessage: "Unit Name is required.",
    },
    "AgBytes.Unit.SystemNotProvided": {
        id: "errorCode.AgBytes.Unit.SystemNotProvided",
        defaultMessage: "Unit System is required.",
    },
    "AgBytes.Unit.TypeNotProvided": {
        id: "errorCode.AgBytes.Unit.TypeNotProvided",
        defaultMessage: "Unit Type is required.",
    },
    "AgBytes.Unit.AbbreviationNotProvided": {
        id: "errorCode.AgBytes.Unit.AbbreviationNotProvided",
        defaultMessage: "Unit Abbreviation is required.",
    },
    136: {
        id: "errorCode.136",
        defaultMessage: "Unit Name already exists for this Unit Type.",
    },
    140: {
        id: "errorCode.140",
        defaultMessage: "Crop Name is required.",
    },
    "AgBytes.Crop.NameNotProvided": {
        id: "errorCode.AgBytes.Crop.NameNotProvided",
        defaultMessage: "Crop Name is required.",
    },
    141: {
        id: "errorCode.141",
        defaultMessage: "Morphology Type is invalid.",
    },
    "AgBytes.Trait.CropNotExistsInRepository": {
        id: "errorCode.AgBytes.Trait.CropNotExistsInRepository",
        defaultMessage: "Crop must exist in the repository.",
    },
    142: {
        id: "errorCode.142",
        defaultMessage: "Lifespan is invalid.",
    },
    "AgBytes.Crop.MorphologyTypeNotNullAndNotExistsInRepository": {
        id: "errorCode.AgBytes.Crop.MorphologyTypeNotNullAndNotExistsInRepository",
        defaultMessage: "Morphology Type is invalid.",
    },
    "AgBytes.Crop.LifespanNotNullAndNotExistsInRepository": {
        id: "errorCode.AgBytes.Crop.LifespanNotNullAndNotExistsInRepository",
        defaultMessage: "Lifespan is invalid.",
    },
    144: {
        id: "errorCode.144",
        defaultMessage: "Sample Type is required.",
    },
    145: {
        id: "errorCode.145",
        defaultMessage: "Sampling Event ID already exists.",
    },
    146: {
        id: "errorCode.146",
        defaultMessage: "Sampling Event ID is required.",
    },
    147: {
        id: "errorCode.147",
        defaultMessage: "Nutrient Name is required.",
    },
    "AgBytes.Nutrient.WillCreateDuplicateName": {
        id: "errorCode.AgBytes.Nutrient.WillCreateDuplicateName",
        defaultMessage: "Nutrient Name already exists.",
    },
    149: {
        id: "errorCode.149",
        defaultMessage: "Nutrient ID is required.",
    },
    "AgBytes.Nutrient.WillCreateDuplicateId": {
        id: "errorCode.AgBytes.Nutrient.WillCreateDuplicateId",
        defaultMessage: "Nutrient ID already exists.",
    },
    "AgBytes.Nutrient.SortOrderNotProvided": {
        id: "errorCode.AgBytes.Nutrient.SortOrderNotProvided",
        defaultMessage: "SortOrder must be provided.",
    },
    "AgBytes.CountryState.WillCreateDuplicateId": {
        id: "errorCode.AgBytes.CountryState.WillCreateDuplicateId",
        defaultMessage: "State ID already exists.",
    },
    "AgBytes.Unit.WillCreateDuplicateId": {
        id: "errorCode.AgBytes.Unit.WillCreateDuplicateId",
        defaultMessage: "Unit ID already exists.",
    },
    "AgBytes.Unit.IntegerIdNotGreaterThanZero": {
        id: "errorCode.AgBytes.Unit.IntegerIdNotGreaterThanZero",
        defaultMessage: "Unit ID must be greater than zero.",
    },
    154: {
        id: "errorCode.154",
        defaultMessage: "Crop ID already exists.",
    },
    155: {
        id: "errorCode.155",
        defaultMessage: "Crop ID is required.",
    },
    156: {
        id: "errorCode.156",
        defaultMessage: "Event ID not found.",
    },
    157: {
        id: "errorCode.157",
        defaultMessage: "Surface Defaults are not unique for this Owner.",
    },
    158: {
        id: "errorCode.158",
        defaultMessage: "Color Ramp is required.",
    },
    "AgBytes.CountryState.WillCreateDuplicateStateAbbreviation": {
        id: "errorCode.AgBytes.CountryState.WillCreateDuplicateStateAbbreviation",
        defaultMessage: "State Abbreviation already exists.",
    },
    161: {
        id: "errorCode.161",
        defaultMessage: "Growth Stage Group Name is required.",
    },
    162: {
        id: "errorCode.162",
        defaultMessage: "Growth Stage ID already exists.",
    },
    163: {
        id: "errorCode.163",
        defaultMessage: "Growth Stage ID is required.",
    },
    164: {
        id: "errorCode.164",
        defaultMessage: "Growth Stage Order Name already exists.",
    },
    165: {
        id: "errorCode.165",
        defaultMessage: "Growth Stage Order Name is required.",
    },
    166: {
        id: "errorCode.166",
        defaultMessage: "Growth Stage Order ID already exists.",
    },
    167: {
        id: "errorCode.167",
        defaultMessage: "Growth Stage Order ID is required.",
    },
    168: {
        id: "errorCode.168",
        defaultMessage: "Observation ID is required.",
    },
    169: {
        id: "errorCode.169",
        defaultMessage: "Observation ID is required.",
    },
    170: {
        id: "errorCode.170",
        defaultMessage: "Organism Type is invalid.",
    },
    171: {
        id: "errorCode.171",
        defaultMessage: "Growth Stage ID is invalid.",
    },
    "AgBytes.Crop.GrowthStageGroupNotNullAndNotExistsInRepository": {
        id: "errorCode.AgBytes.Crop.GrowthStageGroupNotNullAndNotExistsInRepository",
        defaultMessage: "Growth Stage ID is invalid.",
    },
    172: {
        id: "errorCode.172",
        defaultMessage: "Observation Parent Type is required.",
    },
    173: {
        id: "errorCode.173",
        defaultMessage: "Observation Parent Type is invalid.",
    },
    174: {
        id: "errorCode.174",
        defaultMessage: "Observation Type is required.",
    },
    175: {
        id: "errorCode.175",
        defaultMessage: "Observation Type is invalid.",
    },
    176: {
        id: "errorCode.176",
        defaultMessage: "Observation Alias list contains duplicates.",
    },
    177: {
        id: "errorCode.177",
        defaultMessage: "Observation Crop ID list contains duplicates.",
    },
    "AgBytes.Gene.NameNotProvided": {
        id: "errorCode.AgBytes.Gene.NameNotProvided",
        defaultMessage: "Gene Name is required.",
    },
    "AgBytes.Gene.WillCreateDuplicateName": {
        id: "errorCode.AgBytes.Gene.WillCreateDuplicateName",
        defaultMessage: "Gene Name already exists.",
    },
    180: {
        id: "errorCode.180",
        defaultMessage: "Gene ID is required.",
    },
    "AgBytes.Gene.WillCreateDuplicateId": {
        id: "errorCode.AgBytes.Gene.WillCreateDuplicateId",
        defaultMessage: "Gene ID already exists.",
    },
    "AgBytes.Gene.WillCreateDuplicateTraitCharacteristics": {
        id: "errorCode.AgBytes.Gene.WillCreateDuplicateTraitCharacteristics",
        defaultMessage: "Gene Trait Characteristic list contains duplicates.",
    },
    "AgBytes.Gene.WillCreateDuplicateTraitSpecifics": {
        id: "errorCode.AgBytes.Gene.WillCreateDuplicateTraitSpecifics",
        defaultMessage: "Gene Trait Specific list contains duplicates.",
    },
    "AgBytes.Gene.WillCreateDuplicateDevelopers": {
        id: "errorCode.AgBytes.Gene.WillCreateDuplicateDevelopers",
        defaultMessage: "Gene Developer list contains duplicates.",
    },
    "AgBytes.Trait.NameNotProvided": {
        id: "errorCode.AgBytes.Trait.NameNotProvided",
        defaultMessage: "Trait Name is required.",
    },
    "AgBytes.Trait.WillCreateDuplicateName": {
        id: "errorCode.AgBytes.Trait.WillCreateDuplicateName",
        defaultMessage: "Trait Name already exists.",
    },
    187: {
        id: "errorCode.187",
        defaultMessage: "Trait ID is required.",
    },
    "AgBytes.Trait.WillCreateDuplicateId": {
        id: "errorCode.AgBytes.Trait.WillCreateDuplicateId",
        defaultMessage: "Trait ID already exists.",
    },
    "AgBytes.Trait.GeneIdsContainDuplicates": {
        id: "errorCode.AgBytes.Trait.GeneIdsContainDuplicates",
        defaultMessage: "Gene ID list contains duplicates.",
    },
    "AgBytes.Trait.GeneNamesContainDuplicates": {
        id: "errorCode.AgBytes.Trait.GeneNamesContainDuplicates",
        defaultMessage: "Gene Name list contains duplicates.",
    },
    "AgBytes.Trait.EventsContainDuplicates": {
        id: "errorCode.AgBytes.Trait.EventsContainDuplicates",
        defaultMessage: "Event list contains duplicates.",
    },
    193: {
        id: "errorCode.193",
        defaultMessage: "Brand/Organization is required.",
    },
    194: {
        id: "errorCode.194",
        defaultMessage: "Brand/Organization is invalid.",
    },
    "AgBytes.Trait.BrandOrganizationNotNullAndNotExistsInRepository": {
        id: "errorCode.AgBytes.Trait.BrandOrganizationNotNullAndNotExistsInRepository",
        defaultMessage: "Brand/Organization is invalid.",
    },
    195: {
        id: "errorCode.195",
        defaultMessage: "Crop Name is invalid.",
    },
    "AgBytes.Gene.IntegerIdNotGreaterThanZero": {
        id: "errorCode.AgBytes.Gene.IntegerIdNotGreaterThanZero",
        defaultMessage: "Gene ID must be greater than zero.",
    },
    "AgBytes.Trait.GeneIdNotNullAndNotExistsInRepository": {
        id: "errorCode.AgBytes.Trait.GeneIdNotNullAndNotExistsInRepository",
        defaultMessage: "Gene ID must be null or exist in the repository.",
    },
    "AgBytes.Trait.GeneNameNotNullAndNotExistsInRepository": {
        id: "errorCode.AgBytes.Trait.GeneNameNotNullAndNotExistsInRepository",
        defaultMessage: "Gene Name is invalid.",
    },
    199: {
        id: "errorCode.199",
        defaultMessage: "Variety/Hybrid ID is required.",
    },
    200: {
        id: "errorCode.200",
        defaultMessage: "Variety/Hybrid ID already exists.",
    },
    201: {
        id: "errorCode.201",
        defaultMessage: "Variety/Hybrid Name is required.",
    },
    202: {
        id: "errorCode.202",
        defaultMessage: "Crop ID is invalid.",
    },
    "AgBytes.Crop.IntegerIdNotGreaterThanZero": {
        id: "errorCode.AgBytes.Crop.IntegerIdNotGreaterThanZero",
        defaultMessage: "Crop ID must be greater than zero.",
    },
    205: {
        id: "errorCode.205",
        defaultMessage: "Attribute Value is required.",
    },
    206: {
        id: "errorCode.206",
        defaultMessage: "Variety/Hybrid ID is invalid.",
    },
    208: {
        id: "errorCode.208",
        defaultMessage: "Equipment ID is required.",
    },
    209: {
        id: "errorCode.209",
        defaultMessage: "Equipment ID already exists.",
    },
    210: {
        id: "errorCode.210",
        defaultMessage: "Weight Unit Abbreviation is invalid.",
    },
    211: {
        id: "errorCode.211",
        defaultMessage: "Equipment Parent Type is required.",
    },
    212: {
        id: "errorCode.212",
        defaultMessage: "Equipment Parent Type is invalid.",
    },
    213: {
        id: "errorCode.213",
        defaultMessage: "Equipment Type is required.",
    },
    214: {
        id: "errorCode.214",
        defaultMessage: "Equipment Type is invalid.",
    },
    215: {
        id: "errorCode.215",
        defaultMessage: "Manufacturer is invalid.",
    },
    216: {
        id: "errorCode.216",
        defaultMessage: "Dimension Unit Abbreviation is invalid.",
    },
    217: {
        id: "errorCode.217",
        defaultMessage: "Towing Capacity Unit Abbreviation is invalid.",
    },
    218: {
        id: "errorCode.218",
        defaultMessage: "Max Speed Unit Abbreviation is invalid.",
    },
    219: {
        id: "errorCode.219",
        defaultMessage: "Fuel Capacity Unit Abbreviation is invalid.",
    },
    220: {
        id: "errorCode.220",
        defaultMessage: "Drivetrain is invalid.",
    },
    221: {
        id: "errorCode.221",
        defaultMessage: "Transmission Type is invalid.",
    },
    222: {
        id: "errorCode.222",
        defaultMessage: "Fuel Type is invalid.",
    },
    223: {
        id: "errorCode.223",
        defaultMessage: "Fuel Efficiency Unit Abbreviation is invalid.",
    },
    224: {
        id: "errorCode.224",
        defaultMessage: "Horsepower Unit Abbreviation is invalid.",
    },
    225: {
        id: "errorCode.225",
        defaultMessage: "System Attribute is required.",
    },
    226: {
        id: "errorCode.226",
        defaultMessage: "Container Size Unit Abbreviation is invalid.",
    },
    227: {
        id: "errorCode.227",
        defaultMessage: "Removal Rate ID is required.",
    },
    228: {
        id: "errorCode.228",
        defaultMessage: "Removal Rate ID is invalid.",
    },
    229: {
        id: "errorCode.229",
        defaultMessage: "Nutrient ID is invalid.",
    },
    "AgBytes.Nutrient.IntegerIdNotGreaterThanZero": {
        id: "errorCode.AgBytes.Nutrient.IntegerIdNotGreaterThanZero",
        defaultMessage: "Nutrient ID must be greater than zero.",
    },
    230: {
        id: "errorCode.230",
        defaultMessage: "Rate Unit Abbreviation is required.",
    },
    231: {
        id: "errorCode.231",
        defaultMessage: "Rate Unit Abbreviation is invalid.",
    },
    232: {
        id: "errorCode.232",
        defaultMessage: "Nutrient Removal Rate is required.",
    },
    233: {
        id: "errorCode.233",
        defaultMessage: "Nutrient Removal Rate already exists.",
    },
    234: {
        id: "errorCode.234",
        defaultMessage: "Observation Name is required.",
    },
    235: {
        id: "errorCode.235",
        defaultMessage: "Observation Name already exists.",
    },
    236: {
        id: "errorCode.236",
        defaultMessage: "Guid is invalid.",
    },
    237: {
        id: "errorCode.237",
        defaultMessage: "File format is invalid.",
    },
    238: {
        id: "errorCode.238",
        defaultMessage: "File column count is invalid.",
    },
    "AgBytes.InvalidFileColumnCount": {
        id: "errorCode.AgBytes.InvalidFileColumnCount",
        defaultMessage: "File column count is invalid.",
    },
    "AgBytes.InputStringFormatIncorrect": {
        id: "errorCode.AgBytes.InputStringFormatIncorrect",
        defaultMessage: "Input string format incorrect.",
    },
    239: {
        id: "errorCode.239",
        defaultMessage: "Crop Map Color is invalid.",
    },
    "AgBytes.Crop.GroupNotProvided": {
        id: "errorCode.AgBytes.Crop.GroupNotProvided",
        defaultMessage: "Crop Group is required.",
    },
    241: {
        id: "errorCode.241",
        defaultMessage: "System Attribute is required.",
    },
    "AgBytes.ImportAttribute.SystemAttributeNameNotProvided": {
        id: "errorCode.AgBytes.ImportAttribute.SystemAttributeNameNotProvided",
        defaultMessage: "System Attribute is required.",
    },
    "AgBytes.CountryState.CountryNameDoesNotExist": {
        id: "errorCode.AgBytes.CountryState.CountryNameDoesNotExist",
        defaultMessage: "Country is invalid.",
    },
    "AgBytes.Unit.SystemNotInRepository": {
        id: "errorCode.AgBytes.Unit.SystemNotInRepository",
        defaultMessage: "Unit System is invalid.",
    },
    "AgBytes.Unit.TypeNotInRepository": {
        id: "errorCode.AgBytes.Unit.TypeNotInRepository",
        defaultMessage: "Unit Type is invalid.",
    },
    "AgBytes.Gene.TraitSpecificDoesNotExist": {
        id: "errorCode.AgBytes.Gene.TraitSpecificDoesNotExist",
        defaultMessage: "Trait Specific is invalid.",
    },
    "AgBytes.Gene.TraitCharacteristicDoesNotExist": {
        id: "errorCode.AgBytes.Gene.TraitCharacteristicDoesNotExist",
        defaultMessage: "Trait Characteristic is invalid.",
    },
    247: {
        id: "errorCode.247",
        defaultMessage: "Sample ID and Event ID are required.",
    },
    248: {
        id: "errorCode.248",
        defaultMessage: "Template name already exists.",
    },
    249: {
        id: "errorCode.249",
        defaultMessage: "Template Name is required.",
    },
    250: {
        id: "errorCode.250",
        defaultMessage: "Duplicate Sample ID's exist for the same event.",
    },
    253: {
        id: "errorCode.253",
        defaultMessage: "Growth Stage Order Description is required.",
    },
    254: {
        id: "errorCode.254",
        defaultMessage: "Attribute Category is required.",
    },
    255: {
        id: "errorCode.255",
        defaultMessage: "Variety Attribute is required.",
    },
    256: {
        id: "errorCode.256",
        defaultMessage: "Observation Organism Type list contains duplicates.",
    },
    "AgBytes.Gene.TraitCharacteristicNotProvided": {
        id: "errorCode.AgBytes.Gene.TraitCharacteristicNotProvided",
        defaultMessage: "Gene Trait Characteristic is required.",
    },
    "AgBytes.Gene.DeveloperNotProvided": {
        id: "errorCode.AgBytes.Gene.DeveloperNotProvided",
        defaultMessage: "Gene Developer is required.",
    },
    260: {
        id: "errorCode.260",
        defaultMessage: "Crop ID must be numeric.",
    },
    "AgBytes.Nutrient.EquivalentWeightNotGreaterThanOrEqualToZero": {
        id: "errorCode.AgBytes.Nutrient.EquivalentWeightNotGreaterThanOrEqualToZero",
        defaultMessage: "EquivalentWeight must be greater than or equal to zero.",
    },
    "AgBytes.Crop.TestWeightNotNullZeroOrPositive": {
        id: "errorCode.AgBytes.Crop.TestWeightNotNullZeroOrPositive",
        defaultMessage: "TestWeight must be null, zero, or positive.",
    },
    "AgBytes.Crop.OptimalMoistureAtHarvestNotNullZeroOrPositive": {
        id: "errorCode.AgBytes.Crop.OptimalMoistureAtHarvestNotNullZeroOrPositive",
        defaultMessage: "OptimalMoistureAtHarvest must be null, zero, or positive.",
    },
    285: {
        id: "errorCode.285",
        defaultMessage: "Variety/Hybrid ID must be numeric.",
    },
    "AgBytes.CountryState.IntegerIdNotGreaterThanZero": {
        id: "errorCode.AgBytes.CountryState.IntegerIdNotGreaterThanZero",
        defaultMessage: "State ID must be greater than zero.",
    },
    "AgBytes.Unit.ConversionFactorIsNotNumeric": {
        id: "errorCode.AgBytes.Unit.ConversionFactorIsNotNumeric",
        defaultMessage: "ConversionFactor must be numeric when not using formulas.",
    },
    298: {
        id: "errorCode.298",
        defaultMessage: "File size is too large.",
    },
    299: {
        id: "errorCode.299",
        defaultMessage: "File extension is not valid.",
    },
    300: {
        id: "errorCode.300",
        defaultMessage: "Import file contains duplicate Crop IDs.",
    },
    301: {
        id: "errorCode.301",
        defaultMessage: "Import file contains duplicate Crop Names.",
    },
    302: {
        id: "errorCode.302",
        defaultMessage: "Import file contains duplicate Equipment IDs.",
    },
    303: {
        id: "errorCode.303",
        defaultMessage:
            "Import file contains duplicate Equipment Type + Manufacturer + Model Name.",
    },
    304: {
        id: "errorCode.304",
        defaultMessage: "Import file contains duplicate Variety/Hybrid IDs.",
    },
    305: {
        id: "errorCode.305",
        defaultMessage: "Import file contains duplicate Gene IDs.",
    },
    306: {
        id: "errorCode.306",
        defaultMessage: "Import file contains duplicate Gene Names.",
    },
    307: {
        id: "errorCode.307",
        defaultMessage: "Import file contains duplicate Trait IDs.",
    },
    308: {
        id: "errorCode.308",
        defaultMessage: "Import file contains duplicate Trait Names.",
    },
    309: {
        id: "errorCode.309",
        defaultMessage: "Import file contains duplicate Observation IDs.",
    },
    310: {
        id: "errorCode.310",
        defaultMessage: "Import file contains duplicate Observation Names.",
    },
    311: {
        id: "errorCode.311",
        defaultMessage: "Import file contains duplicate Growth Stage IDs.",
    },
    312: {
        id: "errorCode.312",
        defaultMessage: "Import file contains duplicate Growth Stage Group Name + Group Type.",
    },
    313: {
        id: "errorCode.313",
        defaultMessage: "Import file contains duplicate Nutrient Removal Rate IDs.",
    },
    314: {
        id: "errorCode.314",
        defaultMessage:
            "Import file contains duplicate Nutrient ID + Crop ID + Crop Purpose + Crop Class ID.",
    },
    315: {
        id: "errorCode.315",
        defaultMessage: "Import file contains duplicate Nutrient ID.",
    },
    316: {
        id: "errorCode.316",
        defaultMessage: "Import file contains duplicate Nutrient Names.",
    },
    317: {
        id: "errorCode.317",
        defaultMessage: "Import file contains duplicate Elements.",
    },
    319: {
        id: "errorCode.319",
        defaultMessage: "File not found for Import Template.",
    },
    321: {
        id: "errorCode.321",
        defaultMessage: "Planting event Crop is required.",
    },
    323: {
        id: "errorCode.323",
        defaultMessage: "Import file not found.",
    },
    324: {
        id: "errorCode.324",
        defaultMessage: "Harvest event Crop is required.",
    },
    326: {
        id: "errorCode.326",
        defaultMessage: "Irrigation event Irrigation Type required.",
    },
    "AgBytes.ImportAttribute.DataTypeNotProvided": {
        id: "errorCode.AgBytes.ImportAttribute.DataTypeNotProvided",
        defaultMessage: "Sample Attribute Data Type is required.",
    },
    332: {
        id: "errorCode.332",
        defaultMessage: "Sample Type is invalid.",
    },
    333: {
        id: "errorCode.333",
        defaultMessage: "System Attribute is invalid.",
    },
    "AgBytes.ImportAttribute.SystemAttributeNameDoesNotExist": {
        id: "errorCode.AgBytes.ImportAttribute.SystemAttributeNameDoesNotExist",
        defaultMessage: "System Attribute is invalid.",
    },
    334: {
        id: "errorCode.334",
        defaultMessage: "Sample Attribute Test Type is invalid.",
    },
    "AgBytes.ImportAttribute.ReportingUnitDoesNotExist": {
        id: "errorCode.AgBytes.ImportAttribute.ReportingUnitDoesNotExist",
        defaultMessage: "Reporting Unit Abbreviation is invalid.",
    },
    337: {
        id: "errorCode.337",
        defaultMessage: "Field not found.",
    },
    338: {
        id: "errorCode.338",
        defaultMessage: "Event summary records are incomplete.",
    },
    341: {
        id: "errorCode.341",
        defaultMessage: "Crop Purpose is invalid.",
    },
    342: {
        id: "errorCode.342",
        defaultMessage:
            "Import file contains duplicate Crop + Brand + Variety/Hybrid Name + Crop Class Name.",
    },
    343: {
        id: "errorCode.343",
        defaultMessage: "Manufacturer is required.",
    },
    345: {
        id: "errorCode.345",
        defaultMessage: "Nutrient Target Value ID is required.",
    },
    346: {
        id: "errorCode.346",
        defaultMessage: "Import file contains duplicate Nutrient Target Value IDs.",
    },
    "AgBytes.Gene.DeveloperDoesNotExist": {
        id: "errorCode.AgBytes.Gene.DeveloperDoesNotExist",
        defaultMessage: "Developer is invalid.",
    },
    "AgBytes.Nutrient.NcdaFactorNotGreaterThanOrEqualToZero": {
        id: "errorCode.AgBytes.Nutrient.NcdaFactorNotGreaterThanOrEqualToZero",
        defaultMessage: "NcdaFactor must be greater than or equal to zero.",
    },
    359: {
        id: "errorCode.359",
        defaultMessage: "Product Name is required.",
    },
    360: {
        id: "errorCode.360",
        defaultMessage: "The import file Product ID is required.",
    },
    361: {
        id: "errorCode.361",
        defaultMessage: "The import file contains duplicate Product IDs.",
    },
    362: {
        id: "errorCode.362",
        defaultMessage: "Product Type list contains duplicates.",
    },
    363: {
        id: "errorCode.363",
        defaultMessage: "Ingredient list contains duplicates.",
    },
    364: {
        id: "errorCode.364",
        defaultMessage: "Product Type is required.",
    },
    365: {
        id: "errorCode.365",
        defaultMessage: "The import file Product Type is invalid.",
    },
    368: {
        id: "errorCode.368",
        defaultMessage: "The import file Product ID must be numeric.",
    },
    382: {
        id: "errorCode.382",
        defaultMessage: "The Model Name is required.",
    },
    383: {
        id: "errorCode.383",
        defaultMessage: "Number of Classes is required.",
    },
    385: {
        id: "errorCode.385",
        defaultMessage: "The import file contains duplicate Picklist Name + Value ID.",
    },
    386: {
        id: "errorCode.386",
        defaultMessage: "The import file contains duplicate Picklist Name + Value.",
    },
    387: {
        id: "errorCode.387",
        defaultMessage: "Picklist Name is invalid.",
    },
    388: {
        id: "errorCode.388",
        defaultMessage: "Picklist Value is required.",
    },
    389: {
        id: "errorCode.389",
        defaultMessage: "Picklist Value already exists.",
    },
    390: {
        id: "errorCode.390",
        defaultMessage: "Picklist Value ID is required.",
    },
    391: {
        id: "errorCode.391",
        defaultMessage: "Picklist Parent Value is required.",
    },
    394: {
        id: "errorCode.394",
        defaultMessage: "Vendor Name is required.",
    },
    395: {
        id: "errorCode.395",
        defaultMessage: "Vendor Type is required.",
    },
    396: {
        id: "errorCode.396",
        defaultMessage: "Ag Event guid is required.",
    },
    398: {
        id: "errorCode.398",
        defaultMessage: "Crop not found.",
    },
    "AgBytes.Crop.AliasesContainDuplicates": {
        id: "errorCode.AgBytes.Crop.AliasesContainDuplicates",
        defaultMessage: "Crop Alias list contains duplicates.",
    },
    401: {
        id: "errorCode.401",
        defaultMessage: "Max Speed Unit is required.",
    },
    402: {
        id: "errorCode.402",
        defaultMessage: "Fuel Capacity Unit is required.",
    },
    403: {
        id: "errorCode.403",
        defaultMessage: "Fuel Efficiency Unit is required.",
    },
    404: {
        id: "errorCode.404",
        defaultMessage: "Horsepower Unit is required.",
    },
    406: {
        id: "errorCode.406",
        defaultMessage: "Container Size Unit is required.",
    },
    407: {
        id: "errorCode.407",
        defaultMessage: "Max Gross and Tare Weight Unit is required.",
    },
    408: {
        id: "errorCode.408",
        defaultMessage: "Width, Length and Height Dimension Unit is required.",
    },
    409: {
        id: "errorCode.409",
        defaultMessage: "Towing Capacity Unit is required.",
    },
    410: {
        id: "errorCode.410",
        defaultMessage: "Attribute Name is required.",
    },
    414: {
        id: "errorCode.414",
        defaultMessage: "Product Parent Type is required.",
    },
    415: {
        id: "errorCode.415",
        defaultMessage: "The import file Product Parent Type is invalid.",
    },
    416: {
        id: "errorCode.416",
        defaultMessage: "The import file Physical State is invalid.",
    },
    417: {
        id: "errorCode.417",
        defaultMessage: "The import file Formulation Type is invalid.",
    },
    418: {
        id: "errorCode.418",
        defaultMessage: "The import file Density Unit is invalid.",
    },
    419: {
        id: "errorCode.419",
        defaultMessage:
            "The Physical State and Density Unit are required when Density is populated.",
    },
    421: {
        id: "errorCode.421",
        defaultMessage: "The import file Chemical Family is invalid.",
    },
    422: {
        id: "errorCode.422",
        defaultMessage: "The import file Product Action Method is invalid.",
    },
    423: {
        id: "errorCode.423",
        defaultMessage: "The import file Density must be numeric.",
    },
    424: {
        id: "errorCode.424",
        defaultMessage: "The import file Freezing Temperature must be numeric.",
    },
    425: {
        id: "errorCode.425",
        defaultMessage: "The import file Salting Out Temperature must be numeric.",
    },
    426: {
        id: "errorCode.426",
        defaultMessage: "The import file pH must be numeric.",
    },
    427: {
        id: "errorCode.427",
        defaultMessage: "The import file Solubility In Water must be numeric.",
    },
    428: {
        id: "errorCode.428",
        defaultMessage: "The import file Angle of Repose must be numeric.",
    },
    429: {
        id: "errorCode.429",
        defaultMessage: "The import file Shelf Life must be numeric.",
    },
    430: {
        id: "errorCode.430",
        defaultMessage: "The import file Federally Restricted must be boolean.",
    },
    431: {
        id: "errorCode.431",
        defaultMessage: "The import file Grazing Restriction must be boolean.",
    },
    432: {
        id: "errorCode.432",
        defaultMessage: "The import file REI Hours Low must be numeric.",
    },
    433: {
        id: "errorCode.433",
        defaultMessage: "The import file REI Hours High must be numeric.",
    },
    434: {
        id: "errorCode.434",
        defaultMessage: "Crop ID list contains duplicates.",
    },
    435: {
        id: "errorCode.435",
        defaultMessage: "Attribute list contains duplicates.",
    },
    437: {
        id: "errorCode.437",
        defaultMessage: "Phone number type is required.",
    },
    441: {
        id: "errorCode.441",
        defaultMessage: "Plant part name list contains duplicates.",
    },
    442: {
        id: "errorCode.442",
        defaultMessage: "Location ID is invalid.",
    },
    443: {
        id: "errorCode.443",
        defaultMessage: "Location ID is required.",
    },
    444: {
        id: "errorCode.444",
        defaultMessage: "Location ID list contains duplicates.",
    },
    445: {
        id: "errorCode.445",
        defaultMessage: "Nutrient Alias already exists.",
    },
    "AgBytes.Nutrient.AliasesContainDuplicates": {
        id: "errorCode.AgBytes.Nutrient.AliasesContainDuplicates",
        defaultMessage: "Nutrient Alias list contains duplicates.",
    },
    447: {
        id: "errorCode.447",
        defaultMessage: "Import file contains duplicate Aliases.< br />",
    },
    448: {
        id: "errorCode.448",
        defaultMessage: "Attribute name list contains duplicates.",
    },
    449: {
        id: "errorCode.449",
        defaultMessage: "Agrian ID list contains duplicates.",
    },
    450: {
        id: "errorCode.450",
        defaultMessage: "Product Name has been duplicated.",
    },
    451: {
        id: "errorCode.451",
        defaultMessage: "The cropping season was not found.",
    },
    452: {
        id: "errorCode.452",
        defaultMessage: "Cropping Season must be a valid year.",
    },
    454: {
        id: "errorCode.454",
        defaultMessage: "The Nutrient list contains duplicates.",
    },
    455: {
        id: "errorCode.455",
        defaultMessage: "The Nutrient Name does not exist.",
    },
    456: {
        id: "errorCode.456",
        defaultMessage: "Product Package ID already exists.",
    },
    457: {
        id: "errorCode.457",
        defaultMessage: "The import file Product Package ID is required.",
    },
    458: {
        id: "errorCode.458",
        defaultMessage: "The import file Product ID is invalid.",
    },
    459: {
        id: "errorCode.459",
        defaultMessage: "The import file Package Size Unit is invalid.",
    },
    460: {
        id: "errorCode.460",
        defaultMessage: "The import file Package Size Unit is required.",
    },
    461: {
        id: "errorCode.461",
        defaultMessage: "The import file Seed Count Unit is invalid.",
    },
    462: {
        id: "errorCode.462",
        defaultMessage: "The import file Seed Count Unit is required.",
    },
    463: {
        id: "errorCode.463",
        defaultMessage: "The GTINs list contains duplicates.",
    },
    464: {
        id: "errorCode.464",
        defaultMessage: "Back Office Product Code list contains duplicates.",
    },
    465: {
        id: "errorCode.465",
        defaultMessage: "The import file Product Package ID must be numeric.",
    },
    466: {
        id: "errorCode.466",
        defaultMessage: "The import file Package Size must be numeric.",
    },
    467: {
        id: "errorCode.467",
        defaultMessage: "The import file Seed Count must be numeric.",
    },
    469: {
        id: "errorCode.469",
        defaultMessage: "Plant Back Restriction list contains duplicates.",
    },
    470: {
        id: "errorCode.470",
        defaultMessage: "The import file Plant Back Restriction State is invalid.",
    },
    471: {
        id: "errorCode.471",
        defaultMessage: "The import file Plant Back Restriction Rate is required.",
    },
    472: {
        id: "errorCode.472",
        defaultMessage: "The import file Plant Back Restriction Rate Unit is invalid.",
    },
    473: {
        id: "errorCode.473",
        defaultMessage: "The import file Plant Back Restriction Rate Unit is required.",
    },
    474: {
        id: "errorCode.474",
        defaultMessage: "The import file Plant Back Restriction Rate must be numeric.",
    },
    "AgBytes.ImportAttribute.DisplayNameNotProvided": {
        id: "errorCode.AgBytes.ImportAttribute.DisplayNameNotProvided",
        defaultMessage: "Attribute Display Name is required.",
    },
    476: {
        id: "errorCode.476",
        defaultMessage: "Crop + Brand + Variety/Hybrid already exists.",
    },
    477: {
        id: "errorCode.477",
        defaultMessage: "Unit Abbreviation already exists for this Unit Type.",
    },
    "AgBytes.Unit.WillCreateTypeAndNameDuplicate": {
        id: "errorCode.AgBytes.Unit.WillCreateTypeAndNameDuplicate",
        defaultMessage: "The import file contains duplicate Unit Type + Unit Name.",
    },
    "AgBytes.Unit.WillCreateTypeAndAbbreviationDuplicate": {
        id: "errorCode.AgBytes.Unit.WillCreateTypeAndAbbreviationDuplicate",
        defaultMessage: "The import file contains duplicate Unit Type + Abbreviation.",
    },
    480: {
        id: "errorCode.480",
        defaultMessage: "The Observation Agrian ID list contains duplicates.",
    },
    "AgBytes.Crop.AgrianIdsContainDuplicates": {
        id: "errorCode.AgBytes.Crop.AgrianIdsContainDuplicates",
        defaultMessage: "The Observation Agrian ID list contains duplicates.",
    },
    487: {
        id: "errorCode.487",
        defaultMessage: "Very Low must be zero or more.",
    },
    489: {
        id: "errorCode.489",
        defaultMessage: "Low must be zero or more.",
    },
    491: {
        id: "errorCode.491",
        defaultMessage: "Medium must be zero or more.",
    },
    493: {
        id: "errorCode.493",
        defaultMessage: "Optimal must be zero or more.",
    },
    495: {
        id: "errorCode.495",
        defaultMessage: "Very High must be zero or more.",
    },
    "AgBytes.ImportAttribute.TestTypeNameDoesNotExist": {
        id: "errorCode.AgBytes.ImportAttribute.TestTypeNameDoesNotExist",
        defaultMessage: "TestTypeName must exist in the repository.",
    },
    507: {
        id: "errorCode.507",
        defaultMessage: "The Document list contains duplicates.",
    },
    512: {
        id: "errorCode.512",
        defaultMessage: "Equipment Name is required.",
    },
    508: {
        id: "errorCode.508",
        defaultMessage: "The Active Ingredient Percent is invalid.",
    },
    509: {
        id: "errorCode.509",
        defaultMessage: "The Nutrient Percent is invalid.",
    },
    514: {
        id: "errorCode.514",
        defaultMessage: "Product Mix is required.",
    },
    515: {
        id: "errorCode.515",
        defaultMessage: "Product unit is required.",
    },
    522: {
        id: "errorCode.522",
        defaultMessage: "Growth Stage Group Type is required.",
    },
    523: {
        id: "errorCode.523",
        defaultMessage: "Growth Stage Group Type is invalid.",
    },
    "AgBytes.Crop.TissueGrowthStageGroupNotNullAndNotExistsInRepository": {
        id: "errorCode.AgBytes.Crop.TissueGrowthStageGroupNotNullAndNotExistsInRepository",
        defaultMessage: "Tissue Growth Stage ID is invalid.",
    },
    527: {
        id: "errorCode.527",
        defaultMessage: "Phone number is required.",
    },
    528: {
        id: "errorCode.528",
        defaultMessage: "The matching event date exceeds the import grace period.",
    },
    529: {
        id: "errorCode.529",
        defaultMessage: "The event date is invalid.",
    },
    530: {
        id: "errorCode.530",
        defaultMessage: "The import file contains duplicate Equation Parameter Names.",
    },
    531: {
        id: "errorCode.531",
        defaultMessage: "Equation Parameter Name is required.",
    },
    532: {
        id: "errorCode.532",
        defaultMessage: "Equation Parameter Name already exists.",
    },
    533: {
        id: "errorCode.533",
        defaultMessage: "Data Type is required.",
    },
    534: {
        id: "errorCode.534",
        defaultMessage: "Data Type is invalid.",
    },
    535: {
        id: "errorCode.535",
        defaultMessage: "Target Value must be greater than zero.",
    },
    539: {
        id: "errorCode.539",
        defaultMessage: "Sample Template Type is required.",
    },
    540: {
        id: "errorCode.540",
        defaultMessage: "Equation Set Name is required.",
    },
    542: {
        id: "errorCode.542",
        defaultMessage: "Equation Set Name already exists.",
    },
    543: {
        id: "errorCode.543",
        defaultMessage: "Boom Section Length Unit is required.",
    },
    544: {
        id: "errorCode.544",
        defaultMessage: "Nozzle Spacing Unit is required.",
    },
    545: {
        id: "errorCode.545",
        defaultMessage: "Spray Width Unit is required.",
    },
    546: {
        id: "errorCode.546",
        defaultMessage: "Orifice Diameter Unit is required.",
    },
    547: {
        id: "errorCode.547",
        defaultMessage: "Tires Load Rating Unit is required.",
    },
    548: {
        id: "errorCode.548",
        defaultMessage: "Tires Recommended Pressure Unit is required.",
    },
    549: {
        id: "errorCode.549",
        defaultMessage: "Tires Rim Diameter Unit is required.",
    },
    551: {
        id: "errorCode.551",
        defaultMessage: "Pivot Size Unit is required.",
    },
    552: {
        id: "errorCode.552",
        defaultMessage: "Tubing Size Unit is required.",
    },
    553: {
        id: "errorCode.553",
        defaultMessage: "Well Depth Unit is required.",
    },
    554: {
        id: "errorCode.554",
        defaultMessage: "Well Output Unit is required.",
    },
    555: {
        id: "errorCode.555",
        defaultMessage: "Planter Row Spacing Unit is required.",
    },
    556: {
        id: "errorCode.556",
        defaultMessage: "Harvester Velocity Unit is required.",
    },
    557: {
        id: "errorCode.557",
        defaultMessage: "Harvester Minimum Swath Width Unit is required.",
    },
    558: {
        id: "errorCode.558",
        defaultMessage: "Purchase Date Usage Total Area Unit is required.",
    },
    559: {
        id: "errorCode.559",
        defaultMessage: "Purchase Date Usage Total Hours Unit is required.",
    },
    560: {
        id: "errorCode.560",
        defaultMessage: "Purchase Date Usage Total Volume Unit is required.",
    },
    561: {
        id: "errorCode.561",
        defaultMessage: "Depreciation Cost Unit is required.",
    },
    562: {
        id: "errorCode.562",
        defaultMessage: "Weight Unit is required.",
    },
    563: {
        id: "errorCode.563",
        defaultMessage: "Dimension Unit is required.",
    },
    564: {
        id: "errorCode.564",
        defaultMessage: "Towing Capacity Unit is required.",
    },
    565: {
        id: "errorCode.565",
        defaultMessage: "Max Speed Unit is required.",
    },
    566: {
        id: "errorCode.566",
        defaultMessage: "Fuel Capacity Unit is required.",
    },
    567: {
        id: "errorCode.567",
        defaultMessage: "Fuel Efficiency Unit is required.",
    },
    568: {
        id: "errorCode.568",
        defaultMessage: "Horsepower Unit is required.",
    },
    570: {
        id: "errorCode.570",
        defaultMessage: "Total Application Width Unit is required.",
    },
    571: {
        id: "errorCode.571",
        defaultMessage: "Purchase Price Unit is required.",
    },
    573: {
        id: "errorCode.573",
        defaultMessage: "Invalid data type.",
    },
    574: {
        id: "errorCode.574",
        defaultMessage: "All Photos must have a Title.",
    },
    575: {
        id: "errorCode.575",
        defaultMessage: "Owner/Operator is required.",
    },
    "AgBytes.Crop.CropPhotoURLNotValid": {
        id: "errorCode.AgBytes.Crop.CropPhotoURLNotValid",
        defaultMessage: "Crop photo url is not valid in one or more places.",
    },
    577: {
        id: "errorCode.577",
        defaultMessage:
            "Data required by equation does not exist over the entire recommendation area.",
    },
    581: {
        id: "errorCode.581",
        defaultMessage: "Equation contains invalid User Parameter.",
    },
    582: {
        id: "errorCode.582",
        defaultMessage: "Equation contains invalid Import Attribute.",
    },
    583: {
        id: "errorCode.583",
        defaultMessage: "Equation opening delimiter must have closing delimiter.",
    },
    584: {
        id: "errorCode.584",
        defaultMessage: "Equation is invalid.",
    },
    587: {
        id: "errorCode.587",
        defaultMessage:
            "The following selected fields are missing the required Sampling Event: {codeValue}",
    },
    588: {
        id: "errorCode.588",
        defaultMessage:
            "Nutrient does not exist for the Soil Sampling Event, report generation failed  for the selected Customer / Field - {codeValue}",
    },
    589: {
        id: "errorCode.589",
        defaultMessage:
            "Event Surface does not exist, report generation failed for the selected Customer / Field - {codeValue}",
    },
    591: {
        id: "errorCode.591",
        defaultMessage: "Trait ID must be greater than zero.",
    },
    "AgBytes.Trait.IntegerIdNotGreaterThanZero": {
        id: "errorCode.AgBytes.Trait.IntegerIdNotGreaterThanZero",
        defaultMessage: "Trait ID must be greater than zero.",
    },
    592: {
        id: "errorCode.592",
        defaultMessage: "Trait Name is invalid.",
    },
    593: {
        id: "errorCode.593",
        defaultMessage: "Physical State is required.",
    },
    597: {
        id: "errorCode.597",
        defaultMessage: "The symbology for the specified layer was not found.",
    },
    598: {
        id: "errorCode.598",
        defaultMessage: "Import File Type is Required",
    },
    599: {
        id: "errorCode.599",
        defaultMessage: "Equation Name is required.",
    },
    600: {
        id: "errorCode.600",
        defaultMessage: "Equation Formula is required.",
    },
    602: {
        id: "errorCode.602",
        defaultMessage: "Location is required.",
    },
    603: {
        id: "errorCode.603",
        defaultMessage:
            "Phone Number format is invalid. Value must contain 10 numbers, and letters are not allowed.",
    },
    606: {
        id: "errorCode.606",
        defaultMessage: "Product mix type not found.",
    },
    607: {
        id: "errorCode.607",
        defaultMessage: "First and Last Name Required",
    },
    609: {
        id: "errorCode.609",
        defaultMessage:
            "The folders in the zip file are not in the required structure.  These should be structured and named as follows: GS3_2630/{SomeProfileName}/RCD/ for GS3 controllers; RCD/ for GS2 controllers.",
    },
    610: {
        id: "errorCode.610",
        defaultMessage:
            "Server found spatial conflicts with the following existing fields: {codeValue}",
    },
    611: {
        id: "errorCode.611",
        defaultMessage: "One or more fields overlap existing boundaries under the new Customer.",
    },
    618: {
        id: "errorCode.618",
        defaultMessage: "Invalid Location Move.",
    },
    619: {
        id: "errorCode.619",
        defaultMessage: "Invalid Location Move - Destination Inactive.",
    },
    620: {
        id: "errorCode.620",
        defaultMessage: "Invalid Location Move - Destination Null.",
    },
    621: {
        id: "errorCode.621",
        defaultMessage: "Invalid Location Move - Structure Mismatch.",
    },
    622: {
        id: "errorCode.622",
        defaultMessage: "Invalid Location Move - Destination is own descendant.",
    },
    623: {
        id: "errorCode.623",
        defaultMessage: "Equation Nutrient is required.",
    },
    624: {
        id: "errorCode.624",
        defaultMessage: "Nutrient Layer File was not found.",
    },
    625: {
        id: "errorCode.625",
        defaultMessage: "File Not Found/ Please check whether the file is in open mode",
    },
    626: {
        id: "errorCode.626",
        defaultMessage: "User type is required.",
    },
    638: {
        id: "errorCode.638",
        defaultMessage: "Custom product not found.",
    },
    639: {
        id: "errorCode.639",
        defaultMessage: "Product not found.",
    },
    640: {
        id: "errorCode.640",
        defaultMessage: "Unit System is required.",
    },
    641: {
        id: "errorCode.641",
        defaultMessage: "Import File Error Parsing File Row.",
    },
    642: {
        id: "errorCode.642",
        defaultMessage:
            "The Import File contains duplicate Documents States for a single document file.",
    },
    643: {
        id: "errorCode.643",
        defaultMessage: "The Import File has Document States is invalid.",
    },
    644: {
        id: "errorCode.644",
        defaultMessage: "Response Email is required.",
    },
    645: {
        id: "errorCode.645",
        defaultMessage: "Percent of mix is required.",
    },
    647: {
        id: "errorCode.647",
        defaultMessage: "Importance must be less than 2000 characters.",
    },
    651: {
        id: "errorCode.651",
        defaultMessage: "The import file Specific Gravity must be numeric.",
    },
    653: {
        id: "errorCode.653",
        defaultMessage: "The import file Oxide must be boolean.",
    },
    657: {
        id: "errorCode.657",
        defaultMessage: "Irrigation Amount Unit Required",
    },
    658: {
        id: "errorCode.658",
        defaultMessage: "Irrigation Duration Unit Required",
    },
    659: {
        id: "errorCode.659",
        defaultMessage: "Irrigation Frequency Unit Required",
    },
    660: {
        id: "errorCode.660",
        defaultMessage: "Time Since Last Irrigation Unit Required",
    },
    661: {
        id: "errorCode.661",
        defaultMessage: "Rainfall Since Last Irrigation Unit Required",
    },
    663: {
        id: "errorCode.663",
        defaultMessage: "Installation Depth Unit Required",
    },
    664: {
        id: "errorCode.664",
        defaultMessage: "Estimated Water Need Unit Required",
    },
    665: {
        id: "errorCode.665",
        defaultMessage: "Pay Rate is required.",
    },
    666: {
        id: "errorCode.666",
        defaultMessage: "The import file contains duplicate Equation Set ID + Equation ID.",
    },
    667: {
        id: "errorCode.667",
        defaultMessage: "Equation ID is required.",
    },
    668: {
        id: "errorCode.668",
        defaultMessage: "Equation ID already exists.",
    },
    669: {
        id: "errorCode.669",
        defaultMessage: "Product Name is invalid.",
    },
    670: {
        id: "errorCode.670",
        defaultMessage: "Product list contains duplicates.",
    },
    671: {
        id: "errorCode.671",
        defaultMessage: "Previous Crop is invalid.",
    },
    672: {
        id: "errorCode.672",
        defaultMessage: "Next Crop is invalid.",
    },
    673: {
        id: "errorCode.673",
        defaultMessage: "Tillage Method is invalid.",
    },
    674: {
        id: "errorCode.674",
        defaultMessage: "Application Timing is invalid.",
    },
    675: {
        id: "errorCode.675",
        defaultMessage: "Application Method is invalid.",
    },
    676: {
        id: "errorCode.676",
        defaultMessage: "Soil Texture is invalid.",
    },
    677: {
        id: "errorCode.677",
        defaultMessage: "Incorporation Depth is invalid.",
    },
    678: {
        id: "errorCode.678",
        defaultMessage: "Build Factor is invalid.",
    },
    679: {
        id: "errorCode.679",
        defaultMessage: "Target pH is invalid.",
    },
    680: {
        id: "errorCode.680",
        defaultMessage: "Rec Option is invalid.",
    },
    681: {
        id: "errorCode.681",
        defaultMessage: "Crop + Crop Purpose must be unique.",
    },
    682: {
        id: "errorCode.682",
        defaultMessage: "Nutrient Oxide Basis is invalid.",
    },
    683: {
        id: "errorCode.683",
        defaultMessage: "Nutrient Element is invalid.",
    },
    "AgBytes.Nutrient.WillCreateDuplicateElement": {
        id: "errorCode.AgBytes.Nutrient.WillCreateDuplicateElement",
        defaultMessage: "Nutrient Element is invalid.",
    },
    685: {
        id: "errorCode.685",
        defaultMessage: "There are no matching Sample IDs.",
    },
    686: {
        id: "errorCode.686",
        defaultMessage: "Time Zone is required.",
    },
    687: {
        id: "errorCode.687",
        defaultMessage: "An Active Ingredient Percent is required for each Active Ingredient Name.",
    },
    688: {
        id: "errorCode.688",
        defaultMessage: "Equipment Parent Type is required.",
    },
    689: {
        id: "errorCode.689",
        defaultMessage: "Equipment Type is required.",
    },
    694: {
        id: "errorCode.694",
        defaultMessage: "Fuel Added Unit is required.",
    },
    695: {
        id: "errorCode.695",
        defaultMessage: "Maintenance Event Meter Reading Unit is required.",
    },
    696: {
        id: "errorCode.696",
        defaultMessage: "Tank/Bin Container Size Unit is required.",
    },
    697: {
        id: "errorCode.697",
        defaultMessage: "Yield Unit is required.",
    },
    "Field.FieldYieldUnitRequired": {
        id: "errorCode.Field.FieldYieldUnitRequired",
        defaultMessage: "Yield Unit is required.",
    },
    704: {
        id: "errorCode.704",
        defaultMessage: "AgBytes Admin Email Address Required.",
    },
    706: {
        id: "errorCode.706",
        defaultMessage: "Nozzle Type is required.",
    },
    707: {
        id: "errorCode.707",
        defaultMessage: "Invalid Sample ID: {errorContext}.",
    },
    708: {
        id: "errorCode.708",
        defaultMessage: "Duplicate Sample ID: {errorContext}.",
    },
    709: {
        id: "errorCode.709",
        defaultMessage: "No sampling results were found.",
    },
    711: {
        id: "errorCode.711",
        defaultMessage: "Package Size is required when a Unit is selected.",
    },
    712: {
        id: "errorCode.712",
        defaultMessage: "Seed Count is required when a Unit is selected.",
    },
    716: {
        id: "errorCode.716",
        defaultMessage: "Towing Capacity is required when a Unit is selected.",
    },
    717: {
        id: "errorCode.717",
        defaultMessage: "Fuel Capacity is required when a Unit is selected.",
    },
    718: {
        id: "errorCode.718",
        defaultMessage: "Fuel Efficiency is required when a Unit is selected.",
    },
    719: {
        id: "errorCode.719",
        defaultMessage: "Horsepower is required when a Unit is selected.",
    },
    720: {
        id: "errorCode.720",
        defaultMessage: "Container Size is required when a Unit is selected.",
    },
    721: {
        id: "errorCode.721",
        defaultMessage: "Max Speed is required when a Unit is selected.",
    },
    722: {
        id: "errorCode.722",
        defaultMessage: "Width, Length or Height is required when a Unit is selected.",
    },
    723: {
        id: "errorCode.723",
        defaultMessage: "Tare or Max Weight is required when a Unit is selected.",
    },
    724: {
        id: "errorCode.724",
        defaultMessage: "Density is required when a Unit is selected.",
    },
    735: {
        id: "errorCode.735",
        defaultMessage: "Equation Set ID already exists.",
    },
    738: {
        id: "errorCode.738",
        defaultMessage: "Package Size Unit is required.",
    },
    739: {
        id: "errorCode.739",
        defaultMessage: "Package Size is required when a Unit is selected.",
    },
    740: {
        id: "errorCode.740",
        defaultMessage: "Seed Count Unit is required.",
    },
    741: {
        id: "errorCode.741",
        defaultMessage: "Seed Count is required when a Unit is selected.",
    },
    742: {
        id: "errorCode.742",
        defaultMessage: "Average or CountyYT Yield is required when a Unit is selected.",
    },
    "Field.FieldYieldUnitDependentRequired": {
        id: "errorCode.Field.FieldYieldUnitDependentRequired",
        defaultMessage: "Average or CountyYT Yield is required when a Unit is selected.",
    },
    744: {
        id: "errorCode.744",
        defaultMessage: "Total Application Width is required when a Unit is selected.",
    },
    745: {
        id: "errorCode.745",
        defaultMessage: "Boom Section Width is required when a Unit is selected.",
    },
    746: {
        id: "errorCode.746",
        defaultMessage: "Nozzle Spacing is required when a Unit is selected.",
    },
    747: {
        id: "errorCode.747",
        defaultMessage: "Tires Load Rating is required when a Unit is selected.",
    },
    748: {
        id: "errorCode.748",
        defaultMessage: "Tires Recommended Pressure is required when a Unit is selected.",
    },
    749: {
        id: "errorCode.749",
        defaultMessage: "Tires Rim Diameter is required when a Unit is selected.",
    },
    750: {
        id: "errorCode.750",
        defaultMessage: "Pivot Size is required when a Unit is selected.",
    },
    751: {
        id: "errorCode.751",
        defaultMessage: "Tubing Size is required when a Unit is selected.",
    },
    752: {
        id: "errorCode.752",
        defaultMessage: "Well Depth is required when a Unit is selected.",
    },
    753: {
        id: "errorCode.753",
        defaultMessage: "Well Output is required when a Unit is selected.",
    },
    754: {
        id: "errorCode.754",
        defaultMessage: "Planter Row Spacing is required when a Unit is selected.",
    },
    755: {
        id: "errorCode.755",
        defaultMessage: "Harvester Velocity Unit is required when a Unit is selected.",
    },
    756: {
        id: "errorCode.756",
        defaultMessage: "Harvester Minimum Swath Width is required when a Unit is selected.",
    },
    757: {
        id: "errorCode.757",
        defaultMessage: "Purchase Date Usage Total Area is required when a Unit is selected.",
    },
    758: {
        id: "errorCode.758",
        defaultMessage: "Purchase Date Usage Total Usage is required when a Unit is selected.",
    },
    759: {
        id: "errorCode.759",
        defaultMessage: "Purchase Date Usage Total Volume is required when a Unit is selected.",
    },
    760: {
        id: "errorCode.760",
        defaultMessage: "Tare or Max Weight is required when a Unit is selected.",
    },
    761: {
        id: "errorCode.761",
        defaultMessage: "Swath Width, Width, Length or Height is required when a Unit is selected.",
    },
    762: {
        id: "errorCode.762",
        defaultMessage: "Towing Capacity is required when a Unit is selected.",
    },
    763: {
        id: "errorCode.763",
        defaultMessage: "Max Speed is required when a Unit is selected.",
    },
    764: {
        id: "errorCode.764",
        defaultMessage: "Fuel Capacity is required when a Unit is selected.",
    },
    765: {
        id: "errorCode.765",
        defaultMessage: "Fuel Efficiency is required when a Unit is selected.",
    },
    766: {
        id: "errorCode.766",
        defaultMessage: "Horsepower is required when a Unit is selected.",
    },
    767: {
        id: "errorCode.767",
        defaultMessage: "Spray Width is required when a Unit is selected.",
    },
    768: {
        id: "errorCode.768",
        defaultMessage: "Orifice Diameter is required when a Unit is selected.",
    },
    769: {
        id: "errorCode.769",
        defaultMessage: "Fuel Added is required when a Unit is selected.",
    },
    770: {
        id: "errorCode.770",
        defaultMessage: "Meter Reading is required when a Unit is selected.",
    },
    771: {
        id: "errorCode.771",
        defaultMessage: "Bin/Tank Container Size is required when a Unit is selected.",
    },
    772: {
        id: "errorCode.772",
        defaultMessage: "Usage Reading Usage Unit is required.",
    },
    773: {
        id: "errorCode.773",
        defaultMessage: "Usage Reading Usage is required when a Unit is selected.",
    },
    774: {
        id: "errorCode.774",
        defaultMessage: "Usage Reading Total Area Unit is required.",
    },
    775: {
        id: "errorCode.775",
        defaultMessage: "Usage Reading Total Area is required when a Unit is selected.",
    },
    "AgBytes.Crop.WillCreateDuplicateId": {
        id: "errorCode.AgBytes.Crop.WillCreateDuplicateId",
        defaultMessage: "Crop Class ID already exists.",
    },
    "AgBytes.Crop.ClassIdNotGreaterThanZero": {
        id: "errorCode.AgBytes.Crop.ClassIdNotGreaterThanZero",
        defaultMessage: "Crop Class ID is required.",
    },
    "AgBytes.Crop.ClassIdNotProvided": {
        id: "errorCode.AgBytes.Crop.ClassIdNotProvided",
        defaultMessage: "ClassId must be provided.",
    },
    "AgBytes.Crop.WillCreateDuplicateName": {
        id: "errorCode.AgBytes.Crop.WillCreateDuplicateName",
        defaultMessage: "Crop Class Name already exists.",
    },
    794: {
        id: "errorCode.794",
        defaultMessage: "Crop ID + Crop Class ID is invalid.",
    },
    795: {
        id: "errorCode.795",
        defaultMessage:
            "Negative numbers are not allowed for certain attributes. See text file for details.",
    },
    "AgBytes.Unit.ConversionFactorIsNegative": {
        id: "errorCode.AgBytes.Unit.ConversionFactorIsNegative",
        defaultMessage:
            "Negative numbers are not allowed for certain attributes. See text file for details.",
    },
    796: {
        id: "errorCode.796",
        defaultMessage: "Negative numbers are not allowed for IDs.",
    },
    797: {
        id: "errorCode.797",
        defaultMessage: "Crop Name + Crop Class Name is invalid.",
    },
    798: {
        id: "errorCode.798",
        defaultMessage: "Picklist Value must be a number with this format: ####.##",
    },
    799: {
        id: "errorCode.799",
        defaultMessage: "Picklist Value must be an integer number.",
    },
    803: {
        id: "errorCode.803",
        defaultMessage:
            "You have exceeded the maximum number of attempts.  Please click the “Resend Code” button to try a new Code.",
    },
    804: {
        id: "errorCode.804",
        defaultMessage:
            "Your code has expired.  Please click the “Resend Code” button to try a new Code.",
    },
    805: {
        id: "errorCode.805",
        defaultMessage: "Your code is incorrect.  Please try again.",
    },
    806: {
        id: "errorCode.806",
        defaultMessage:
            "An error has occured.  Please click the “Resend Code” button to try a new Code.",
    },
    807: {
        id: "errorCode.807",
        defaultMessage: "Product mix is required.",
    },
    808: {
        id: "errorCode.808",
        defaultMessage: "The import file Density Unit is not valid for the Physical State.",
    },
    809: {
        id: "errorCode.809",
        defaultMessage: "An error has occured.  Verification code was not sent.",
    },
    810: {
        id: "errorCode.810",
        defaultMessage: "An error has occured.  Customer is not ready for enrollment.",
    },
    811: {
        id: "errorCode.811",
        defaultMessage: "Cannot deactivate the last active item.",
    },
    812: {
        id: "errorCode.812",
        defaultMessage: "Cannot reactivate an item with an inactive parent.",
    },
    813: {
        id: "errorCode.813",
        defaultMessage: "Average Weight Unit is required.",
    },
    814: {
        id: "errorCode.814",
        defaultMessage: "Average Weight is required when that Unit is selected.",
    },
    815: {
        id: "errorCode.815",
        defaultMessage: "Price Unit is required.",
    },
    816: {
        id: "errorCode.816",
        defaultMessage: "Price is required when a Unit is selected.",
    },
    817: {
        id: "errorCode.817",
        defaultMessage: "LDP Payment Unit is required.",
    },
    819: {
        id: "errorCode.819",
        defaultMessage: "Average Yield Unit is required.",
    },
    821: {
        id: "errorCode.821",
        defaultMessage: "Import file contains duplicate Product ID + Package ID.",
    },
    833: {
        id: "errorCode.833",
        defaultMessage: "Growth Stage Order Name is invalid.",
    },
    838: {
        id: "errorCode.838",
        defaultMessage: "Automated Test Result Issues Email Address is required.",
    },
    841: {
        id: "errorCode.841",
        defaultMessage: "Planting Depth Unit is required.",
    },
    842: {
        id: "errorCode.842",
        defaultMessage: "Planting Depth Unit is required.",
    },
    844: {
        id: "errorCode.844",
        defaultMessage: "Row Spacing Unit is required.",
    },
    846: {
        id: "errorCode.846",
        defaultMessage: "Plant Spacing Unit is required.",
    },
    848: {
        id: "errorCode.848",
        defaultMessage: "Crop Age When Planted Unit is required.",
    },
    850: {
        id: "errorCode.850",
        defaultMessage: "Seeding Rate Unit is required.",
    },
    852: {
        id: "errorCode.852",
        defaultMessage: "Plants Per Unit is required.",
    },
    854: {
        id: "errorCode.854",
        defaultMessage: "Price Unit is required.",
    },
    858: {
        id: "errorCode.858",
        defaultMessage:
            "The following selected Field is missing the required Profit/Loss layer needed to generate this report: {codeValue}",
    },
    859: {
        id: "errorCode.859",
        defaultMessage:
            "The following selected Field is missing the required 4Mation layer needed to generate this report: {codeValue}",
    },
    860: {
        id: "errorCode.860",
        defaultMessage:
            "The following selected Field is missing the required Scouting Event needed to generate this report: {codeValue}",
    },
    861: {
        id: "errorCode.861",
        defaultMessage: "Not a number. See text file for details.",
    },
    863: {
        id: "errorCode.863",
        defaultMessage:
            "The following selected Field is missing the required SeedStrong layer needed to generate this report: {codeValue}",
    },
    865: {
        id: "errorCode.865",
        defaultMessage:
            "The following selected Field is missing the required Planting Rec needed to generate this report: {codeValue}",
    },
    866: {
        id: "errorCode.866",
        defaultMessage: "Commodity ID is required.",
    },
    867: {
        id: "errorCode.867",
        defaultMessage: "Commodity Name is required.",
    },
    868: {
        id: "errorCode.868",
        defaultMessage: "Commodity ID must be numeric.",
    },
    869: {
        id: "errorCode.869",
        defaultMessage: "Agvance Location is required.",
    },
    "Agvance.LocationIsRequired": {
        id: "errorCode.Agvance.LocationIsRequired",
        defaultMessage: "Agvance Location is required.",
    },
    870: {
        id: "errorCode.870",
        defaultMessage: "Agvance Customer is required.",
    },
    "Agvance.CustomerIsRequired": {
        id: "errorCode.Agvance.CustomerIsRequired",
        defaultMessage: "Agvance Customer is required.",
    },
    871: {
        id: "errorCode.871",
        defaultMessage: "Agvance Field is required.",
    },
    "Agvance.FieldIsRequired": {
        id: "errorCode.Agvance.FieldIsRequired",
        defaultMessage: "Agvance Field is required.",
    },
    872: {
        id: "errorCode.872",
        defaultMessage: "Agvance Primary Customer is required.",
    },
    873: {
        id: "errorCode.873",
        defaultMessage: "Agvance Primary Customer is duplicated.",
    },
    874: {
        id: "errorCode.874",
        defaultMessage: "Agvance Customer Location is invalid.",
    },
    875: {
        id: "errorCode.875",
        defaultMessage: "Agvance Customer Location has been deleted.",
    },
    876: {
        id: "errorCode.876",
        defaultMessage:
            "The Additional Agvance Customer removed has Field(s) associated to it. These Field(s) need to be changed to a different Customer in order to remove this one.",
    },
    877: {
        id: "errorCode.877",
        defaultMessage: "Start Pass Delay (Seconds) is required.",
    },
    878: {
        id: "errorCode.878",
        defaultMessage: "End Pass Delay (Seconds) is required.",
    },
    879: {
        id: "errorCode.879",
        defaultMessage:
            "The following selected Field(s) are missing the required Application Event needed for this report: {codeValue}",
    },
    880: {
        id: "errorCode.880",
        defaultMessage:
            "The following selected Field(s) are missing the required Planting Event needed for this report: {codeValue}",
    },
    881: {
        id: "errorCode.881",
        defaultMessage: "Yield Smooth Velocity is required.",
    },
    883: {
        id: "errorCode.883",
        defaultMessage: "Yield Standard Deviation is required.",
    },
    884: {
        id: "errorCode.884",
        defaultMessage: "EC Data Standard Deviation is required.",
    },
    885: {
        id: "errorCode.885",
        defaultMessage: "Owner Personality is required.",
    },
    886: {
        id: "errorCode.886",
        defaultMessage: "Agvance Field cannot be made active.",
    },
    887: {
        id: "errorCode.887",
        defaultMessage: "Owner Admin User is required.",
    },
    888: {
        id: "errorCode.888",
        defaultMessage: "A valid Customer Name must be selected.",
    },
    889: {
        id: "errorCode.889",
        defaultMessage:
            "Duplicate Customer Names with the same Location Affiliation is not allowed.",
    },
    "AgBytes.Unit.ModusUnitsContainDuplicates": {
        id: "errorCode.AgBytes.Unit.ModusUnitsContainDuplicates",
        defaultMessage: "Modus Unit list contains duplicates.",
    },
    891: {
        id: "errorCode.891",
        defaultMessage: "Surface not found.",
    },
    892: {
        id: "errorCode.892",
        defaultMessage: "Test Package is required for this Testing Lab.",
    },
    893: {
        id: "errorCode.893",
        defaultMessage:
            "Unable to get Lab Submission Order ID. Please verify that the Test Packages are valid for this Vendor.",
    },
    894: {
        id: "errorCode.894",
        defaultMessage:
            "This Location has at least one field associated with it. All fields need to be changed to another location in order to remove this location.",
    },
    895: {
        id: "errorCode.895",
        defaultMessage:
            "The Primary Customer has been made inactive in Agvance. In order to Save the Primary flag needs to be changed to an active Customer so this inactive Customer can be removed from this profile. If this Customer is affiliated to any Fields those will need to be moved to an active Customer.",
    },
    898: {
        id: "errorCode.898",
        defaultMessage:
            "The Database ID is invalid. Please verify the correct Database ID and try again.",
    },
    899: {
        id: "errorCode.899",
        defaultMessage:
            "This Customer has either active or inactive Fields currently associated with it. These Fields need to be moved to another Customer in order to permanently delete this Customer.",
    },
    900: {
        id: "errorCode.900",
        defaultMessage:
            "This Customer has either active or inactive Equipment Profiles currently associated with it. These Equipment Profiles need to be moved to another Owner/Operator in order to permanently delete this Customer.",
    },
    901: {
        id: "errorCode.901",
        defaultMessage: "Field is required for Resurfacing.",
    },
    902: {
        id: "errorCode.902",
        defaultMessage: "Updating people from another owner is not allowed.",
    },
    903: {
        id: "errorCode.903",
        defaultMessage: "Sampling Event does not exist, report generation failed",
    },
    904: {
        id: "errorCode.904",
        defaultMessage: "Adding a Database ID is not allowed on Parent Locations.",
    },
    905: {
        id: "errorCode.905",
        defaultMessage: "Migrate Customers To Location Required.",
    },
    906: {
        id: "errorCode.906",
        defaultMessage: "Classification Method is required.",
    },
    907: {
        id: "errorCode.907",
        defaultMessage: "Color Ramp is required.",
    },
    908: {
        id: "errorCode.908",
        defaultMessage: "Color Ramp is invalid.",
    },
    909: {
        id: "errorCode.909",
        defaultMessage: "Company is required.",
    },
    910: {
        id: "errorCode.910",
        defaultMessage: "Company Name is invalid.",
    },
    "AgBytes.Crop.WillCreateDuplicateNameAndClassId": {
        id: "errorCode.AgBytes.Crop.WillCreateDuplicateNameAndClassId",
        defaultMessage: "Crop Name + Class ID already exists.",
    },
    912: {
        id: "errorCode.912",
        defaultMessage: "Surface Defaults are not unique for this Location + System Attribute.",
    },
    913: {
        id: "errorCode.913",
        defaultMessage: "Yield Upper Percentile is required.",
    },
    914: {
        id: "errorCode.914",
        defaultMessage: "Yield Lower Percentile is required.",
    },
    915: {
        id: "errorCode.915",
        defaultMessage: "Yield Scale is required.",
    },
    916: {
        id: "errorCode.916",
        defaultMessage: "Yield Minimum Absolute is required.",
    },
    917: {
        id: "errorCode.917",
        defaultMessage: "Minimum Swath Width is required.",
    },
    918: {
        id: "errorCode.918",
        defaultMessage: "Speed Upper Percentile is required.",
    },
    919: {
        id: "errorCode.919",
        defaultMessage: "Speed Lower Percentile is required.",
    },
    920: {
        id: "errorCode.920",
        defaultMessage: "Speed Scale is required.",
    },
    921: {
        id: "errorCode.921",
        defaultMessage: "Speed Minimum Absolute is required.",
    },
    923: {
        id: "errorCode.923",
        defaultMessage:
            "A value between 0 and 1 must be entered for the following settings: {codeValue}",
    },
    924: {
        id: "errorCode.924",
        defaultMessage:
            "A value greater than 0 must be entered for the following settings: {codeValue}",
    },
    925: {
        id: "errorCode.925",
        defaultMessage:
            "You must have the same Product or saved Product Mix within these Events in order to Merge this data.",
    },
    931: {
        id: "errorCode.931",
        defaultMessage:
            "The Lab Account provided is invalid.  Please verify you have the correct Testing Lab selected and the Lab Account entered is valid.",
    },
    932: {
        id: "errorCode.932",
        defaultMessage:
            "Unable to complete submission to the Lab. Please verify you have entered a valid account number.",
    },
    933: {
        id: "errorCode.933",
        defaultMessage: "The Waypoint web service is non-responsive. Please try again.",
    },
    934: {
        id: "errorCode.934",
        defaultMessage:
            "This Customer is currently not properly linked to Agvance.  That issue needs to be resolved before you can add a new Field.",
    },
    935: {
        id: "errorCode.935",
        defaultMessage:
            "The field you are attempting to Save is not connected to Agvance. You must move this field to a valid connected Customer.",
    },
    950: {
        id: "errorCode.950",
        defaultMessage: "Merging requires two or more events.",
    },
    951: {
        id: "errorCode.951",
        defaultMessage: "Merging requires all events to be from the same field.",
    },
    952: {
        id: "errorCode.952",
        defaultMessage: "Merging requires all events to be from the same cropping season.",
    },
    953: {
        id: "errorCode.953",
        defaultMessage: "The file cannot have duplicate Rec ID's per Sample ID",
    },
    954: {
        id: "errorCode.954",
        defaultMessage: "The file cannot have duplicate Depth ID's per Sample ID",
    },
    955: {
        id: "errorCode.955",
        defaultMessage: "The file cannot have duplicate Rec ID's or Depth ID's per Sample ID",
    },
    957: {
        id: "errorCode.957",
        defaultMessage: "Invalid Agvance Field Add: {codeValue}",
    },
    958: {
        id: "errorCode.958",
        defaultMessage: "Invalid Agvance Field Update: {codeValue}",
    },
    959: {
        id: "errorCode.959",
        defaultMessage: "Agvance Database Locked: {codeValue}",
    },
    960: {
        id: "errorCode.960",
        defaultMessage: "Unexpected error communicating with Agvance: {codeValue}",
    },
    964: {
        id: "errorCode.964",
        defaultMessage: "This report type is no longer supported",
    },
    "NonFieldFeature.FeatureNameNotProvided": {
        id: "errorCode.NonFieldFeature.FeatureNameNotProvided",
        defaultMessage: "Name must be provided.",
    },
    "NonFieldFeature.FeatureTypeNotProvided": {
        id: "errorCode.NonFieldFeature.FeatureTypeNotProvided",
        defaultMessage: "Type must be provided.",
    },
    "NonFieldFeature.FeatureTypeNotInRepository": {
        id: "errorCode.NonFieldFeature.FeatureTypeNotInRepository",
        defaultMessage: "Type must be in the repository.",
    },
    "NonFieldFeature.NoGeometriesInFeature": {
        id: "errorCode.NonFieldFeature.NoGeometriesInFeature",
        defaultMessage: "Geometries must have at least one element.",
    },
    "Rec.JohnDeere.WorkPlan.MissingClient": {
        id: "errorCode.Rec.JohnDeere.WorkPlan.CouldNotCreateClient",
        defaultMessage: "Could not create client in the JD Operations Center",
    },
    "Rec.JohnDeere.WorkPlan.CouldNotCreateInput": {
        id: "errorCode.Rec.JohnDeere.WorkPlan.CouldNotCreateInput",
        defaultMessage: "Could not create input in the JD Operations Center",
    },
    "Rec.JohnDeere.WorkPlan.CouldNotCreateTankMix": {
        id: "errorCode.Rec.JohnDeere.WorkPlan.CouldNotCreateTankMix",
        defaultMessage: "Could not create tank mix in the JD Operations Center",
    },
    "Rec.JohnDeere.WorkPlan.CouldNotCreateDryBlend": {
        id: "errorCode.Rec.JohnDeere.WorkPlan.CouldNotCreateDryBlend",
        defaultMessage: "Could not create dry blend in the JD Operations Center",
    },
    "Rec.JohnDeere.WorkPlan.CouldNotCreateManure": {
        id: "errorCode.Rec.JohnDeere.WorkPlan.CouldNotCreateManure",
        defaultMessage: "Could not create manure in the JD Operations Center",
    },
    "Rec.JohnDeere.WorkPlan.CouldNotCreateField": {
        id: "errorCode.Rec.JohnDeere.WorkPlan.CouldNotCreateField",
        defaultMessage: "Could not create the field in the JD Operations Center",
    },
    "Rec.JohnDeere.WorkPlan.CouldNotCreateFieldBoundary": {
        id: "errorCode.Rec.JohnDeere.WorkPlan.CouldNotCreateFieldBoundary",
        defaultMessage: "Could not create the field boundary in the JD Operations Center",
    },
    "Rec.JohnDeere.WorkPlan.CouldNotCreateFileId": {
        id: "errorCode.Rec.JohnDeere.WorkPlan.CouldNotCreateFileId",
        defaultMessage: "Could not create a file ID in the JD Operations Center",
    },
    "Rec.JohnDeere.WorkPlan.CouldNotCreateWorkPlan": {
        id: "errorCode.Rec.JohnDeere.WorkPlan.CouldNotCreateWorkPLan",
        defaultMessage: "Could not create Work Plan in the JD Ops Center",
    },
    "Rec.JohnDeere.WorkPlan.CouldNotUploadFile": {
        id: "errorCode.Rec.JohnDeere.WorkPlan.CouldNotUploadFile",
        defaultMessage: "Could not upload file to the JD Operations Center",
    },
    "Rec.JohnDeere.WorkPlan.FieldNotAssociatedWithJdOrganization": {
        id: "errorCode.Rec.JohnDeere.WorkPlan.FieldNotAssociatedWithJdOrganization",
        defaultMessage:
            "This field is not associated with any JD Organization. Please check your Portal Integration settings.",
    },
    "Rec.JohnDeere.WorkPlan.NoMyJohnDeereTelematicsConnectionAtOwnerLevel": {
        id: "errorCode.Rec.JohnDeere.WorkPlan.NoMyJohnDeereTelematicsConnectionAtOwnerLevel",
        defaultMessage:
            "A MyJohnDeere Telematics connection has not been set up at the owner level. Sending Work Plans is not possible.",
    },
    "Report.CroppingSeasonNotSame": {
        id: "errorCode.Report.CroppingSeasonNotSame",
        defaultMessage: "All Events must have the same Season.",
    },
    "Report.CustomerRegistrationIncomplete": {
        id: "errorCode.Report.CustomerRegistrationIncomplete",
        defaultMessage: "MyAgData registration is incomplete for the following Grower(s): {Field}",
    },
    "Report.EventMyAgdataIncomplete": {
        id: "errorCode.Report.EventMyAgdataIncomplete",
        defaultMessage:
            "The following Planting Event(s) are missing MyAgData commodity data: {Event}",
    },
    "ReportBook.OrgLevelGuidNotInRepository": {
        id: "errorCode.ReportBook.OrgLevelGuidNotInRepository",
        defaultMessage: "Org Level Guid must be in the repository.",
    },
    "ReportBook.ReportBookTypeNameNotProvided": {
        id: "errorCode.ReportBook.ReportBookTypeNameNotProvided",
        defaultMessage: "Report Book Type name must be provided.",
    },
    "ReportBook.ReportBookOptionsGuidNotInRepository": {
        id: "errorCode.ReportBook.ReportBookOptionsGuidNotInRepository",
        defaultMessage: "Report Book Options must be in the repository.",
    },
    "ReportBook.ReportBookOrgLevelGuidsNotProvided": {
        id: "errorCode.ReportBook.ReportBookOrgLevelGuidsNotProvided",
        defaultMessage: "Report Book must provide Org Levels.",
    },
    "ReportBook.ReportBookReportsNotProvided": {
        id: "errorCode.ReportBook.ReportBookReportsNotProvided",
        defaultMessage: "Report Book must have reports.",
    },
    "ReportBook.ReportTypeNotProvided": {
        id: "errorCode.ReportBook.ReportTypeNotProvided",
        defaultMessage: "Report Type is invalid.",
    },
    "ReportBook.ReportTypeNotInRepository": {
        id: "errorCode.ReportBook.ReportTypeNotInRepository",
        defaultMessage: "Report type must be in the repository.",
    },
    "Event.RecNotFound": {
        id: "errorCode.Event.RecNotFound",
        defaultMessage: "Rec not found.",
    },
    "Event.AgEventCropSeasonRequired": {
        id: "errorCode.Event.AgEventCropSeasonRequired",
        defaultMessage: "Cropping Season is required.",
    },
    "Event.AgEventFieldRequired": {
        id: "errorCode.Event.AgEventFieldRequired",
        defaultMessage: "Field is required.",
    },
    "Event.AgEventGeneralGuidDuplicate": {
        id: "errorCode.Event.AgEventGeneralGuidDuplicate",
        defaultMessage: "An Event with this AgEventGeneralGuid already exists.",
    },
    "Event.AgEventStartDatetimeRequired": {
        id: "errorCode.Event.AgEventStartDatetimeRequired",
        defaultMessage: "Ag Event start date is required.",
    },
    "Event.AgEventTypeDateNameDuplicated": {
        id: "errorCode.Event.AgEventTypeDateNameDuplicated",
        defaultMessage: "An Event with this Type + Date + Name already exists.",
    },
    "Event.CustomProductNotFound": {
        id: "errorCode.Event.CustomProductNotFound",
        defaultMessage: "Custom product not found.",
    },
    "Event.EventAreaListEmpty": {
        id: "errorCode.Event.EventAreaListEmpty",
        defaultMessage: "A zone must be defined.",
    },
    "Event.EventProductMixProductsRequired": {
        id: "errorCode.Event.EventProductMixProductsRequired",
        defaultMessage: "Product mix is required.",
    },
    "Event.FieldBoundaryGuidRequired": {
        id: "errorCode.Event.FieldBoundaryGuidRequired",
        defaultMessage: "A field boundary must be defined.",
    },
    "Event.FieldIsInactive": {
        id: "errorCode.Event.FieldIsInactive",
        defaultMessage: "Field is inactive.",
    },
    "Event.GuaranteedAnalysisRequired": {
        id: "errorCode.Event.GuaranteedAnalysisRequired",
        defaultMessage: "Guaranteed Analysis is required.",
    },
    "Event.ProductMixTypeRequired": {
        id: "errorCode.Event.ProductMixTypeRequired",
        defaultMessage: "Product mix type is required.",
    },
    "Event.ProductNotFound": {
        id: "errorCode.Event.ProductNotFound",
        defaultMessage: "Product not found.",
    },
    "Event.ProductRequired": {
        id: "errorCode.Event.ProductRequired",
        defaultMessage: "Product is required.",
    },
    "Event.ProductUnitRequired": {
        id: "errorCode.Event.ProductUnitRequired",
        defaultMessage: "Product unit is required.",
    },
    2001: {
        id: "errorCode.2001",
        defaultMessage: "Address is required.",
    },
    2002: {
        id: "errorCode.2002",
        defaultMessage: "Primary Address is required.",
    },
    2003: {
        id: "errorCode.2003",
        defaultMessage: "There can only be one Primary Address.",
    },
    2009: {
        id: "errorCode.2009",
        defaultMessage: "Zip code length exceeded.",
    },
    2011: {
        id: "errorCode.2011",
        defaultMessage: "You do not have permissions to complete this operation.",
    },
    2016: {
        id: "errorCode.2016",
        defaultMessage: "Send From Email is already used on another vendor: {codeValue}",
    },
    2017: {
        id: "errorCode.2017",
        defaultMessage: "We cannot verify your entry.",
    },
    2018: {
        id: "errorCode.2018",
        defaultMessage: "Your answer provided is not correct.",
    },
    2022: {
        id: "errorCode.2022",
        defaultMessage: "The email already exists. Please enter another email.",
    },
    2023: {
        id: "errorCode.2023",
        defaultMessage: "The person must be Active to be assigned a Role.",
    },
    2025: {
        id: "errorCode.2025",
        defaultMessage:
            "There is already an Owner on file with the same name. Please enter a unique Owner Name.",
    },
    2033: {
        id: "errorCode.2033",
        defaultMessage: "A Location Affiliation is required.",
    },
    2042: {
        id: "errorCode.2042",
        defaultMessage: "User Role Name already exists.",
    },
    2044: {
        id: "errorCode.2044",
        defaultMessage: "Location name already exists.",
    },
    2045: {
        id: "errorCode.2045",
        defaultMessage: "Delete is not allowed if children exist.",
    },
    2046: {
        id: "errorCode.2046",
        defaultMessage: "Delete is not allowed if a person is affiliated with this entity.",
    },
    2049: {
        id: "errorCode.2049",
        defaultMessage: "You must select at least one item for the User Role.",
    },
    2050: {
        id: "errorCode.2050",
        defaultMessage: "Setup cannot be the only item selected for the User Role.",
    },
    2051: {
        id: "errorCode.2051",
        defaultMessage: "The following Role with these settings already exists: {codeValue}",
    },
    2052: {
        id: "errorCode.2052",
        defaultMessage: "Email is invalid.",
    },
    2056: {
        id: "errorCode.2056",
        defaultMessage: "Address Type and Address Name must be unique. Type: {codeValue}",
    },
    2072: {
        id: "errorCode.2072",
        defaultMessage: "AgBytes cannot be the only item selected.",
    },
    2076: {
        id: "errorCode.2076",
        defaultMessage:
            "Sampling Events must have a single Sample Id in each zone class when Zone Sampling is selected.",
    },
    2077: {
        id: "errorCode.2077",
        defaultMessage: "Sampling Options cannot be the only item selected.",
    },
    2078: {
        id: "errorCode.2078",
        defaultMessage: "Selecting both Zone Interpolation and Zone Sampling is not allowed.",
    },
    2079: {
        id: "errorCode.2079",
        defaultMessage: "Sampling cannot be the only item selected.",
    },
    2085: {
        id: "errorCode.2085",
        defaultMessage: "{codeValue} is not a valid Email.",
    },
    2086: {
        id: "errorCode.2086",
        defaultMessage: "Email {codeValue} is duplicated.",
    },
    2089: {
        id: "errorCode.2089",
        defaultMessage: "Company name must be different than the location level names.",
    },
    2090: {
        id: "errorCode.2090",
        defaultMessage: "Location level names must be unique.",
    },
    2093: {
        id: "errorCode.2093",
        defaultMessage: "The Admin Login Email is invalid.",
    },
    2104: {
        id: "errorCode.2104",
        defaultMessage: "Starting depth cannot be greater than ending depth.",
    },
    2115: {
        id: "errorCode.2115",
        defaultMessage: "Manage Fields cannot be the only item selected.",
    },
    2118: {
        id: "errorCode.2118",
        defaultMessage: "Work Orders cannot be the only item selected.",
    },
    2119: {
        id: "errorCode.2119",
        defaultMessage: "Financials cannot be the only item selected.",
    },
    2122: {
        id: "errorCode.2122",
        defaultMessage: "Import cannot be the only item selected.",
    },
    2156: {
        id: "errorCode.2156",
        defaultMessage: "At least one sample point is required.",
    },
    2120: {
        id: "errorCode.2120",
        defaultMessage: "Events cannot be the only item selected.",
    },
    2121: {
        id: "errorCode.2121",
        defaultMessage: "Reports cannot be the only item selected for the user role.",
    },
    2157: {
        id: "errorCode.2157",
        defaultMessage: "No Sampling Points found.",
    },
    2158: {
        id: "errorCode.2158",
        defaultMessage: "No Surfaces found to export.",
    },
    2159: {
        id: "errorCode.2159",
        defaultMessage:
            "Export batch limit exceeded. Please reduce the number of exports to {codeValue} or less.",
    },
    2207: {
        id: "errorCode.2207",
        defaultMessage: "Equipment Type Alias list contains duplicates.",
    },
    2247: {
        id: "errorCode.2247",
        defaultMessage: "Sample ID and Event ID are required.",
    },
    2248: {
        id: "errorCode.2248",
        defaultMessage: "Template Name already exists.",
    },
    2320: {
        id: "errorCode.2320",
        defaultMessage: "At least one Attribute is required.",
    },
    2329: {
        id: "errorCode.2329",
        defaultMessage: "X and Y Coordinates required.",
    },
    2338: {
        id: "errorCode.2338",
        defaultMessage: "Template Name is more than 50 characters.",
    },
    2344: {
        id: "errorCode.2344",
        defaultMessage: "Equipment Type + Manufacturer + Model Name already exists.",
    },
    2527: {
        id: "errorCode.2527",
        defaultMessage: "A required column in the Import File is missing or has no valid data.",
    },
    2528: {
        id: "errorCode.2528",
        defaultMessage: "The Import File Template is invalid.",
    },
    2536: {
        id: "errorCode.2536",
        defaultMessage: "URL list contains duplicates.",
    },
    2537: {
        id: "errorCode.2537",
        defaultMessage: "Phone Number list contains duplicates.",
    },
    2576: {
        id: "errorCode.2576",
        defaultMessage:
            "Owner/Operator can only be assigned to a single Customer, Vendor, or Location.",
    },
    2595: {
        id: "errorCode.2595",
        defaultMessage: "Selected Import Attribute, {codeValue}, is not set up correctly.",
    },
    2596: {
        id: "errorCode.2596",
        defaultMessage: "Import attribute, {codeValue}, is mapped more than once.",
    },
    2602: {
        id: "errorCode.2602",
        defaultMessage: "Active/Inactive cannot be the only item selected for the User Role.",
    },
    2604: {
        id: "errorCode.2604",
        defaultMessage:
            "The Salesperson ID is already being used by {codeValue} and must be unique.",
    },
    2611: {
        id: "errorCode.2611",
        defaultMessage: "GLN Identifier must be 13 digits.",
    },
    2627: {
        id: "errorCode.2627",
        defaultMessage: "User Customer Affiliation  is required.",
    },
    2628: {
        id: "errorCode.2628",
        defaultMessage: "Salesperson ID is required.",
    },
    2690: {
        id: "errorCode.2690",
        defaultMessage: "Customer cannot be the only item selected for the User Role.",
    },
    2692: {
        id: "errorCode.2692",
        defaultMessage: "Recommendations cannot be the only item selected for the User Role.",
    },
    2800: {
        id: "errorCode.2800",
        defaultMessage: "No analysis yield data exists.",
    },
    2801: {
        id: "errorCode.2801",
        defaultMessage: "User Role Access Level is required.",
    },
    2803: {
        id: "errorCode.2803",
        defaultMessage: "No analysis layer found.",
    },
    2804: {
        id: "errorCode.2804",
        defaultMessage: "Analysis type guid is missing.",
    },
    2805: {
        id: "errorCode.2805",
        defaultMessage: "Analysis layer name is required.",
    },
    2806: {
        id: "errorCode.2806",
        defaultMessage: "Duplicate Analysis Layer name.",
    },
    2807: {
        id: "errorCode.2807",
        defaultMessage: "Management Area type is missing.",
    },
    2808: {
        id: "errorCode.2808",
        defaultMessage: "Management Area must have at least one field filled out.",
    },
    2809: {
        id: "errorCode.2809",
        defaultMessage: "Analysis Layers cannot be the only item selected for the User Role.",
    },
    2810: {
        id: "errorCode.2810",
        defaultMessage: "Exports cannot be the only item selected.",
    },
    2811: {
        id: "errorCode.2811",
        defaultMessage: "This Vendor is already in use for another Template of this type.",
    },
    2812: {
        id: "errorCode.2812",
        defaultMessage: "Vendor list contains duplicates.",
    },
    2814: {
        id: "errorCode.2814",
        defaultMessage: "Field boundary could not be found for the whole field zone.",
    },
    2816: {
        id: "errorCode.2816",
        defaultMessage: "Please enter start position between 1 and 15.",
    },
    2817: {
        id: "errorCode.2817",
        defaultMessage: "Please enter start position between 1 and 30.",
    },
    2818: {
        id: "errorCode.2818",
        defaultMessage: "Only one Rec per Field is supported",
    },
    2819: {
        id: "errorCode.2819",
        defaultMessage: "Only one Rec Type per Report is supported",
    },
    2820: {
        id: "errorCode.2820",
        defaultMessage: "File Name Format is required.",
    },
    2824: {
        id: "errorCode.2824",
        defaultMessage: "An AgBytes Admin Primary Email is required.",
    },
    2825: {
        id: "errorCode.2825",
        defaultMessage: "There can only be one primary AgBytes Admin Email Address.",
    },
    2826: {
        id: "errorCode.2826",
        defaultMessage: "AgBytes Admin Email Addresses must be unique. Email Address: {codeValue}",
    },
    2827: {
        id: "errorCode.2827",
        defaultMessage: "AgBytes Admin Email is invalid. Email Address: {codeValue}",
    },
    2828: {
        id: "errorCode.2828",
        defaultMessage: "Automated Test Result Issues Primary Email is required.",
    },
    2829: {
        id: "errorCode.2829",
        defaultMessage: "There can only be one primary Automated Test Result Issues Email Address.",
    },
    2830: {
        id: "errorCode.2830",
        defaultMessage: "Automated Test Result Issues Email is invalid. Email Address: {codeValue}",
    },
    2836: {
        id: "errorCode.2836",
        defaultMessage: "No Soil Sampling data exists for the selected areas.",
    },
    2837: {
        id: "errorCode.2837",
        defaultMessage: "No normalized yield layer is available.",
    },
    2839: {
        id: "errorCode.2839",
        defaultMessage: "Response Email is invalid. Email Address: {codeValue}",
    },
    2840: {
        id: "errorCode.2840",
        defaultMessage: "Send Results to Email is invalid. Email Address: {codeValue}",
    },
    2841: {
        id: "errorCode.2841",
        defaultMessage: "AgBytes Admin Email Addresses must be unique. Email Address: {codeValue}",
    },
    2842: {
        id: "errorCode.2842",
        defaultMessage:
            "An active Field with this Farm and Field Name already exists for this Customer.",
    },
    2843: {
        id: "errorCode.2843",
        defaultMessage:
            "This Customer has an active Field Boundary which conflicts with the one you wish to activate. Please resolve this conflict before activating this Field. Conflicting Field(s): {codeValue}",
    },
    2848: {
        id: "errorCode.2848",
        defaultMessage:
            "You must enter a Break Even Yield for the following crop and yield unit(s): {codeValue}",
    },
    2849: {
        id: "errorCode.2849",
        defaultMessage:
            "The following selected Field is missing the required Sampling Event with results needed to generate this report: {codeValue}",
    },
    2850: {
        id: "errorCode.2850",
        defaultMessage:
            "The following selected Field is missing the required Imported Harvest Event needed to generate this report: {codeValue}",
    },
    2851: {
        id: "errorCode.2851",
        defaultMessage: "No Surfaces Exist for the Harvest Event: {codeValue}",
    },
    2852: {
        id: "errorCode.2852",
        defaultMessage:
            "% of Planter values need to collectively equal 100% across all Variety/Hybrids added.",
    },
    2855: {
        id: "errorCode.2855",
        defaultMessage: "Crop Name already exists with different Crop ID.",
    },
    "AgBytes.Crop.PrimaryClassIdRequired": {
        id: "errorCode.AgBytes.Crop.PrimaryClassIdRequired",
        defaultMessage: "There needs to be one Crop with Crop Class ID = 1.",
    },
    "AgBytes.Crop.PrimaryClassIdHasChildren": {
        id: "errorCode.AgBytes.Crop.PrimaryClassIdHasChildren",
        defaultMessage: "Cannot delete Crop.  There needs to be one Crop with Crop Class ID = 1.",
    },
    2859: {
        id: "errorCode.2859",
        defaultMessage: "At least one category must be selected.",
    },
    2860: {
        id: "errorCode.2860",
        defaultMessage: "Soil Type cannot be the only item selected.",
    },
    2861: {
        id: "errorCode.2861",
        defaultMessage: "Variety cannot be the only item selected.",
    },
    2862: {
        id: "errorCode.2862",
        defaultMessage: "Fertility/Topography cannot be the only item selected.",
    },
    2863: {
        id: "errorCode.2863",
        defaultMessage: "Batch cannot be the only item selected.",
    },
    2865: {
        id: "errorCode.2865",
        defaultMessage:
            "The following selected Field is missing the required Rec needed to generate this report: {codeValue}",
    },
    2866: {
        id: "errorCode.2866",
        defaultMessage: "System Name is required.",
    },
    2867: {
        id: "errorCode.2867",
        defaultMessage: "EULA Title is required.",
    },
    2868: {
        id: "errorCode.2868",
        defaultMessage: "Copyright Text is required.",
    },
    2869: {
        id: "errorCode.2869",
        defaultMessage: "System Logo Dark Theme is required.",
    },
    2870: {
        id: "errorCode.2870",
        defaultMessage: "System Logo Light Theme is required.",
    },
    2871: {
        id: "errorCode.2871",
        defaultMessage: "EULA Body Content required.",
    },
    2872: {
        id: "errorCode.2872",
        defaultMessage: "EULA Last Updated is required.",
    },
    2873: {
        id: "errorCode.2873",
        defaultMessage: "Owner Branding Report Logo is required.",
    },
    2874: {
        id: "errorCode.2874",
        defaultMessage: "Owner Branding System Name is required.",
    },
    2875: {
        id: "errorCode.2875",
        defaultMessage: "Owner Report Logo is required.",
    },
    2876: {
        id: "errorCode.2876",
        defaultMessage: "Target Value is required.",
    },
    2877: {
        id: "errorCode.2877",
        defaultMessage:
            "Report batch limit exceeded. Please reduce the number of reports to {codeValue} or less.",
    },
    2880: {
        id: "errorCode.2880",
        defaultMessage:
            "The following selected Field is missing the required Recommendation needed to generate this report: {codeValue}",
    },
    2882: {
        id: "errorCode.2882",
        defaultMessage: "At least one category must be selected.",
    },
    2883: {
        id: "errorCode.2883",
        defaultMessage: "Planting cannot be the only item selected.",
    },
    2884: {
        id: "errorCode.2884",
        defaultMessage: "Application cannot be the only item selected.",
    },
    2885: {
        id: "errorCode.2885",
        defaultMessage: "Tillage cannot be the only item selected.",
    },
    2886: {
        id: "errorCode.2886",
        defaultMessage: "Soil Type cannot be the only item selected.",
    },
    2887: {
        id: "errorCode.2887",
        defaultMessage:
            "The data required to generate this report is missing from your current selection.",
    },
    2888: {
        id: "errorCode.2888",
        defaultMessage:
            "You must select either fewer Fields and/or a shorter date range to create a Weather History report.",
    },
    2889: {
        id: "errorCode.2889",
        defaultMessage:
            "The following Field(s) are missing the required Management Area Analysis Layer needed for this report: {codeValue}",
    },
    2890: {
        id: "errorCode.2890",
        defaultMessage:
            "The start and end must be specified as dates in the past for this historical report.",
    },
    2891: {
        id: "errorCode.2891",
        defaultMessage:
            "This email address has already been setup under a different Owner and these need to be unique per Owner or left blank. Email Address: {codeValue}",
    },
    2892: {
        id: "errorCode.2892",
        defaultMessage: "Send Results To Email is required.",
    },
    2893: {
        id: "errorCode.2893",
        defaultMessage: "Page Size is required to generate this report.",
    },
    2894: {
        id: "errorCode.2894",
        defaultMessage: "Classification Method is invalid.",
    },
    2895: {
        id: "errorCode.2895",
        defaultMessage:
            "Number of Classes is invalid, the value must be a whole number between 2 and 10.",
    },
    2896: {
        id: "errorCode.2896",
        defaultMessage: "An active Event with this Type + Start Date + Name already exists.",
    },
    2897: {
        id: "errorCode.2897",
        defaultMessage: "Event Transaction Type Guid not found.",
    },
    2898: {
        id: "errorCode.2898",
        defaultMessage: "An active Rec with this Type + Start Date + Name already exists.",
    },
    2900: {
        id: "errorCode.2900",
        defaultMessage: "Events are missing required data for the selected pages.",
    },
    2903: {
        id: "errorCode.2903",
        defaultMessage: "No Yield data exists for the selected Crop and Season.",
    },
    2904: {
        id: "errorCode.2904",
        defaultMessage: "Fields are missing required data for the selected pages: {codeValue}",
    },
    2905: {
        id: "errorCode.2905",
        defaultMessage:
            "Harvest data below coverage area threshold. Exclude no harvest area using zone tools.",
    },
    2906: {
        id: "errorCode.2906",
        defaultMessage: "Unknown classification method.",
    },
    2907: {
        id: "errorCode.2907",
        defaultMessage: "Batch analysis layers require exactly one zone per field.",
    },
    2908: {
        id: "errorCode.2908",
        defaultMessage:
            "Unable to generate the requested number of class breaks with the selected classification method, which may occur for data with low variability.",
    },
    2909: {
        id: "errorCode.2909",
        defaultMessage:
            "This file exceeds the maximum number of points per area for sampling based data",
    },
    2911: {
        id: "errorCode.2911",
        defaultMessage: "Harvest Events across all Fields must be the same Crop and Season.",
    },
    2999: {
        id: "errorCode.2999",
        defaultMessage:
            "The following Field(s) are missing the required Variety/Hybrid or Seeding Rate information needed for this report: {codeValue}",
    },
    3000: {
        id: "errorCode.3000",
        defaultMessage:
            "An error occurred while downloading reports. Please try again. If this error persists, the report(s) will need to be created again.",
    },
    3001: {
        id: "errorCode.3001",
        defaultMessage:
            "The following Field(s) are missing the required Nutrient Rec information needed for this report: {codeValue}",
    },
    3002: {
        id: "errorCode.3002",
        defaultMessage: "Please enter a value less than 100.",
    },
    3003: {
        id: "errorCode.3003",
        defaultMessage: "The Event attempting to be created is missing a boundary.",
    },
    3004: {
        id: "errorCode.3004",
        defaultMessage: "System Report Logo is required.",
    },
    3005: {
        id: "errorCode.3005",
        defaultMessage: "Area Limit is required.",
    },
    3006: {
        id: "errorCode.3006",
        defaultMessage: "Vendor has duplicate Test Package IDs.",
    },
    3007: {
        id: "errorCode.3007",
        defaultMessage: "Test Package ID cannot be empty.",
    },
    3008: {
        id: "errorCode.3008",
        defaultMessage: "Missing Test Results For OM (%) or Humic Matter (%).",
    },
    3009: {
        id: "errorCode.3009",
        defaultMessage: "Missing Test Results For CEC.",
    },
    3010: {
        id: "errorCode.3010",
        defaultMessage: "This Testing Lab is no longer active",
    },
    3020: {
        id: "errorCode.3020",
        defaultMessage: "File is empty.",
    },
    3021: {
        id: "errorCode.3021",
        defaultMessage: "Non-Polygon based files are not supported.",
    },
    3022: {
        id: "errorCode.3022",
        defaultMessage: "Only Shape files are supported.",
    },
    3023: {
        id: "errorCode.3023",
        defaultMessage: "No numeric data columns found.",
    },
    3024: {
        id: "errorCode.3024",
        defaultMessage: "Event ID is required.",
    },
    3033: {
        id: "errorCode.3033",
        defaultMessage: "Irrigation Category is invalid.",
    },
    3034: {
        id: "errorCode.3034",
        defaultMessage: "Critical must be zero or more.",
    },
    3035: {
        id: "errorCode.3035",
        defaultMessage: "Severe must be zero or more.",
    },
    3036: {
        id: "errorCode.3036",
        defaultMessage: "Water Purpose is required.",
    },
    3037: {
        id: "errorCode.3037",
        defaultMessage: "Water Purpose is invalid.",
    },
    4003: {
        id: "errorCode.4003",
        defaultMessage: "Invalid Access Key.",
    },
    4004: {
        id: "errorCode.4004",
        defaultMessage: "John Deere Connection is not valid.",
    },
    4005: {
        id: "errorCode.4005",
        defaultMessage: "Raven Slingshot Connection is not valid.",
    },
    4006: {
        id: "errorCode.4006",
        defaultMessage: "Climate Connection is not valid.",
    },
    4007: {
        id: "errorCode.4007",
        defaultMessage: "John Deere Connection cannot be deleted.",
    },
    4008: {
        id: "errorCode.4008",
        defaultMessage: "Raven Connection cannot be deleted.",
    },
    4009: {
        id: "errorCode.4009",
        defaultMessage: "Climate Connection cannot be deleted.",
    },
    4010: {
        id: "errorCode.4010",
        defaultMessage: "Farmobile Connection cannot be deleted.",
    },
    4011: {
        id: "errorCode.4011",
        defaultMessage: "Farmobile Connection is not valid.",
    },
    4012: {
        id: "errorCode.4012",
        defaultMessage: "Invalid Climate Connection - Requires a delete and new add.",
    },
    6000: {
        id: "errorCode.6000",
        defaultMessage:
            "The following selected Customer has not Enrolled so it was not included within this report: {codeValue}",
    },
    6001: {
        id: "errorCode.6001",
        defaultMessage:
            "The following selected Field has data not set to Community Data so it was not included within this report: {codeValue}",
    },
    7000: {
        id: "errorCode.7000",
        defaultMessage:
            "The following selected Non-Field Feature is missing the required Water Sampling Event: {codeValue}.",
    },
    7001: {
        id: "errorCode.7001",
        defaultMessage:
            "The following Customer is missing the required Water Sampling Event: {codeValue}.",
    },
    8999: {
        id: "errorCode.8999",
        defaultMessage: "Undefined error.",
    },
    9000: {
        id: "errorCode.9000",
        defaultMessage: "Operation was successful",
    },
    9001: {
        id: "errorCode.9001",
        defaultMessage: "Field Boundary is invalid.",
    },
    9002: {
        id: "errorCode.9002",
        defaultMessage:
            "Field Boundary intersects an existing field boundary.  If you do not see the intersecting field boundary, it may currently be inactive.",
    },
    9003: {
        id: "errorCode.9003",
        defaultMessage: "Field Polygon Area is too small.",
    },
    9004: {
        id: "errorCode.9004",
        defaultMessage: "Nothing to Export. All Product Recs are 0.",
    },
    9005: {
        id: "errorCode.9005",
        defaultMessage: "This file is not a valid file type.",
    },
    9006: {
        id: "errorCode.9006",
        defaultMessage:
            "One or more of the Date or TimeStamp import attributes have an invalid format.",
    },
    9007: {
        id: "errorCode.9007",
        defaultMessage: "Loadsheet creation failed.",
    },
    9008: {
        id: "errorCode.9008",
        defaultMessage:
            "There are currently no Product Rec's selected for Export.  You need to select at least one Product Rec to proceed.",
    },
    9009: {
        id: "errorCode.9009",
        defaultMessage: "Text has been entered for a numeric field in one or more places.",
    },
    "AgBytes.MandatoryFieldNotProvided": {
        id: "errorCode.AgBytes.MandatoryFieldNotProvided",
        defaultMessage: "A required value is missing in one or more columns.",
    },
    9011: {
        id: "errorCode.9011",
        defaultMessage:
            "Adjacent Field Boundary polygons have small gaps between them. Deleting and redrawing adjacent polygons may resolve the issue.",
    },
    9012: {
        id: "errorCode.9012",
        defaultMessage:
            "This boundary has reached a maximum vertex density threshold. Excess vertices need to be removed.",
    },
    9013: {
        id: "errorCode.9013",
        defaultMessage: "Field contains polygons that overlap with each other.",
    },
    9014: {
        id: "errorCode.9014",
        defaultMessage: "Event areas contain polygons that overlap with each other.",
    },
    9996: {
        id: "errorCode.9996",
        defaultMessage: "Error decoding request.",
    },
    9997: {
        id: "errorCode.9997",
        defaultMessage: "Generic error.",
    },
    9998: {
        id: "errorCode.9998",
        defaultMessage: "Generic database error.",
    },
    9999: {
        id: "errorCode.9999",
        defaultMessage: "Generic spatial error.",
    },
    "AgBytes.NutrientRemoval.IntegerIdNotGreaterThanZero": {
        id: "errorCode.AgBytes.NutrientRemoval.IntegerIdNotGreaterThanZero",
        defaultMessage: "IntegerId must be greater than zero.",
    },
    "AgBytes.NutrientRemoval.WillCreateDuplicateIntegerId": {
        id: "errorCode.AgBytes.NutrientRemoval.WillCreateDuplicateIntegerId",
        defaultMessage: "NutrientRemovalRate ID already exists.",
    },
    "AgBytes.NutrientRemoval.WillCreateDuplicateNutrientRemoval": {
        id: "errorCode.AgBytes.NutrientRemoval.WillCreateDuplicateNutrientRemoval",
        defaultMessage: "NutrientRemovalRate already exists.",
    },
    "AgBytes.NutrientRemoval.NutrientNotProvided": {
        id: "errorCode.AgBytes.NutrientRemoval.NutrientNotProvided",
        defaultMessage: "Nutrient must be provided.",
    },
    "AgBytes.NutrientRemoval.NutrientMustExist": {
        id: "errorCode.AgBytes.NutrientRemoval.NutrientMustExist",
        defaultMessage: "Nutrient ID is invalid.",
    },
    "AgBytes.NutrientRemoval.CropNotProvided": {
        id: "errorCode.AgBytes.NutrientRemoval.CropNotProvided",
        defaultMessage: "Crop must be provided.",
    },
    "AgBytes.NutrientRemoval.CropMustExist": {
        id: "errorCode.AgBytes.NutrientRemoval.CropMustExist",
        defaultMessage: "Crop ID is invalid.",
    },
    "AgBytes.NutrientRemoval.RateNotProvided": {
        id: "errorCode.AgBytes.NutrientRemoval.RateNotProvided",
        defaultMessage: "Rate must be provided.",
    },
    "AgBytes.NutrientRemoval.RateNotGreaterThanZero": {
        id: "errorCode.AgBytes.NutrientRemoval.RateNotGreaterThanZero",
        defaultMessage: "Rate must be greater than zero.",
    },
    "AgBytes.NutrientRemoval.CropPurposeNotProvidedOrExists": {
        id: "errorCode.AgBytes.NutrientRemoval.CropPurposeNotProvidedOrExists",
        defaultMessage: "Crop Purpose is invalid.",
    },
    "AgBytes.NutrientRemoval.RateUnitNotProvided": {
        id: "errorCode.AgBytes.NutrientRemoval.RateUnitNotProvided",
        defaultMessage: "Rate Unit Abbreviation must be provided.",
    },
    "AgBytes.NutrientRemoval.RateUnitMustExist": {
        id: "errorCode.AgBytes.NutrientRemoval.RateUnitMustExist",
        defaultMessage: "Rate Unit Abbreviation is invalid.",
    },
    "Report.FieldsDoNotExist": {
        id: "errorCode.Report.FieldsDoNotExist",
        defaultMessage: "Fields do not exist.",
    },
    "Report.FieldsDoNotHaveHarvestEvents": {
        id: "errorCode.Report.FieldsDoNotHaveHarvestEvents",
        defaultMessage:
            "The following Field(s) are missing the required Harvest Event needed for this report: {Field}",
    },
    "Report.FieldsDoNotHavePlantingEvents": {
        id: "errorCode.Report.FieldsDoNotHavePlantingEvents",
        defaultMessage:
            "The following Field(s) are missing the required Planting Event needed for this report: {Field}",
    },
    "Report.FieldsDoNotHavePlantingRecs": {
        id: "errorCode.Report.FieldsDoNotHavePlantingRecs",
        defaultMessage:
            "The following Field(s) are missing the required Planting Rec needed for this report: {Field}",
    },
    "Report.GenericError": {
        id: "errorCode.Report.GenericError",
        defaultMessage: "GenericError",
    },
    "Report.IrrigationStatusRequired": {
        id: "errorCode.Report.IrrigationStatusRequired",
        defaultMessage: "The following Field(s) need an Irrigated status set: {Field}",
    },
    "Report.NoMatchingRecs": {
        id: "errorCode.Report.NoMatchingRecs",
        defaultMessage: "No recs matched.",
    },
    "Report.NoLinkedBatch": {
        id: "errorCode.Report.NoLinkedBatch",
        defaultMessage: "No batch is linked to this report.",
    },
    "Report.NoReportGuid": {
        id: "errorCode.Report.NoReportGuid",
        defaultMessage: "No report guid is assigned to this report.",
    },
    "Report.WaterSampleDoesNotExistForNonFieldFeature": {
        id: "errorCode.Report.WaterSampleDoesNotExistForNonFieldFeature",
        defaultMessage:
            "The following selected Non-Field Feature is missing the required Water Sampling Event and/or Results: {NonFieldFeatureDetail}.",
    },
    "Report.FieldsDoNotHaveSamplingEvents": {
        id: "errorCode.Report.FieldsDoNotHaveSamplingEvents",
        defaultMessage:
            "The following Field(s) are missing the required Sampling Event needed for this report: {Field}",
    },
    "Report.WaterSamplesDoNotExistForCustomer": {
        id: "errorCode.Report.WaterSamplesDoNotExistForCustomer",
        defaultMessage:
            "The following Customer is missing the required Water Sampling Event and/or Results: {CustomerName}.",
    },
    "Report.CroppingSeasonNotSupplied": {
        id: "errorCode.Report.CroppingSeasonNotSupplied",
        defaultMessage: "Cropping Season must be supplied.",
    },
    confirmDeleteMsg: {
        id: "agBytes.confirmDeleteMsg",
        defaultMessage: "Are you sure you want to delete?",
    },
    validImport: {
        id: "import.addUpdateMessage",
        defaultMessage:
            "You will be adding {addCount, number} {addCount, plural, one {record} other {records}} " +
            "and updating {updateCount, number} {updateCount, plural, one {record} other {records}}. Would you like to proceed?",
    },
    validDelete: {
        id: "import.validDelete",
        defaultMessage:
            "WARNING!!!!  Clicking the Delete button below will permanently delete the <span>{count, number}</span>" +
            " {count, plural, one {record} other {records}} that " +
            "{count, plural, one {is} other {are}} currently selected and are not in use.",
    },
    vendorDeleteMessage: {
        id: "setup.vendorDeleteMessage",
        defaultMessage:
            "Deactivating this Vendor will also deactivate this active Vendor profile. Do you want to proceed?",
    },
});

export const DetailedApiStatus = {
    Success: 0,
    UnknownError: 10,
    HttpError: 11,
    ValidationError: 20,
};

function preBreakLineExp(delimiter) {
    const breakLine = ["<br/>"];
    return delimiter.indexOf(breakLine) > -1 ? delimiter : "";
}

export const codeToErrorMessage = (formatMessage, code, values = {}): string => {
    const message = errorCodeMessages[code];
    return message !== undefined
        ? formatMessage(message, values)
        : formatMessage(errorCodeMessages[9997]);
};

export const getErrorMessages = (formatMessage, err, delimiter = "; "): string => {
    if (err.errorCodeList == null || err.errorCodeList.length < 1) {
        if (err.errors?.length > 0) {
            return err.errors[0].message;
        } else if (err.message) {
            return err.message;
        } else {
            return formatMessage(errorCodeMessages[9997]);
        }
    }

    const messages = mapCodesToMessages(formatMessage, err, delimiter, err.errorCodeList);

    return messages.join(`${preBreakLineExp(delimiter)}${delimiter}`);
};

export const getSplitErrorMessages = (formatMessage, err, delimiter = "; "): string[] => {
    if (err.errorCodeList == null || err.errorCodeList.length < 1) {
        if (err.message) {
            return err.message;
        } else {
            return formatMessage(errorCodeMessages[9997]);
        }
    }

    const messages = mapCodesToMessages(formatMessage, err, delimiter, err.errorCodeList);

    return messages;
};

function mapCodesToMessages(formatMessage, error, delimiter, codeList): string[] {
    return codeList
        .filter((c) => c !== 9995)
        .map((code) => {
            // Ignore reports partial success
            try {
                if (
                    error.apiResultObj &&
                    error.apiResultObj.model &&
                    error.apiResultObj.model[code]
                ) {
                    return formatMessage(errorCodeMessages[code], {
                        codeValue: Array.isArray(error.apiResultObj.model[code])
                            ? error.apiResultObj.model[code].length === 1
                                ? `${preBreakLineExp(delimiter)}${
                                      error.apiResultObj.model[code][0]
                                  }`
                                : `${delimiter}${error.apiResultObj.model[code].join(delimiter)}`
                            : error.apiResultObj.model[code],
                        errorContext: error.errorContext,
                    });
                }
                return formatMessage(errorCodeMessages[code], {
                    errorContext: error.errorContext,
                });
            } catch (e) {
                if (error.errorContext) {
                    return `${formatMessage(messages.unknownError)} ${error.errorContext}`;
                }
                return formatMessage(messages.unknownError);
            }
        });
}

export const getConfirmMessages = (formatMessage, confirmMessageKey, values): string => {
    try {
        return formatMessage(errorCodeMessages[confirmMessageKey], values);
    } catch (e) {
        return formatMessage(messages.unknownError);
    }
};
