import React, { useState } from "react";
import { messages } from "./i18n-messages";
import { DialogBox, DialogBoxFooterType, Loader } from "~/core";
import { useAppSelector } from "~/store/hooks";
import {
    getFieldInfoState,
    getIsAddBoundaryToPortalModalOpen,
} from "~/action-panel/components/field-module/selectors";
import { getFieldEditGeometries } from "~/map/components/map-tools/selectors";
import { injectIntl, intlShape } from "react-intl";
import { useDispatch } from "react-redux";
import { actions as fieldActions } from "~/action-panel/components/field-module";
import { RecAPI } from "@ai360/core";
import { getUser } from "~/login/selectors";
import { MSGTYPE, actions as notificationActions } from "~/notifications";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils";

function AddBoundaryToPortal({ intl: { formatMessage } }) {
    const isOpen = useAppSelector(getIsAddBoundaryToPortalModalOpen);
    const fieldInfo = useAppSelector(getFieldInfoState);
    const fieldName = fieldInfo.fieldModel.fieldName;
    const fieldGuid = fieldInfo.fieldModel.fieldGuid;

    const userGuid = useAppSelector(getUser).userGuid;
    const geometries = useAppSelector(getFieldEditGeometries);
    const [showLoader, setShowLoader] = useState<boolean>(false);

    const dispatch = useDispatch();

    const addBoundary = async () => {
        setShowLoader(true);
        const geometriesForAdd = geometries.map((x) => webMercatorUtils.webMercatorToGeographic(x));
        const request = {
            geometries: JSON.stringify(geometriesForAdd),
            fieldName: fieldName,
        };
        const response = await RecAPI.addBoundaryToJdOpsCenter(request, fieldGuid, userGuid);
        if (response.statusCode !== 200) {
            dispatch(
                notificationActions.pushToasterMessage(
                    formatMessage(messages.addFailed),
                    MSGTYPE.ERROR
                )
            );
        } else {
            dispatch(
                notificationActions.pushToasterMessage(
                    formatMessage(messages.addSucceeded),
                    MSGTYPE.SUCCESS
                )
            );
        }

        setShowLoader(false);
        dispatch(fieldActions.setAddBoundaryToPortalModalOpen(false));
    };

    return (
        <DialogBox
            draggable={true}
            isOpen={isOpen}
            unrestricted={true}
            footerType={DialogBoxFooterType.YES_NO}
            title={formatMessage(messages.title)}
            onAction={() => {
                addBoundary();
            }}
            onClose={() => {
                dispatch(fieldActions.setAddBoundaryToPortalModalOpen(false));
            }}
        >
            {showLoader && <Loader />}
            {formatMessage(messages.fieldHasNoConflictsOrMatches)}
        </DialogBox>
    );
}

AddBoundaryToPortal.propTypes = {
    intl: intlShape,
};

export default injectIntl(AddBoundaryToPortal);
