import React, { PureComponent } from "react";
import { defineMessages, InjectedIntl, injectIntl } from "react-intl";
import { Bucket, BucketHeader, Checkbox, NumericInput, SelectInput } from "~/core";
import { CropAPI, CropModels, ISelectOption } from "@ai360/core";
import { ACTIVE_YN, picklistNames } from "~/core/picklist";
import { unitNames } from "~/core/units";
import { messages } from "./i18n-messages";
import "./event-planting.css";
import { keywords } from "./keywords";

import { getSetValuesForErrorCodeList } from "~/action-panel/components/common/validation-utils";
import {
    IBrandOrgData,
    ICropData,
    IDropDownData,
    IRowSpacing,
    IUpdatePlantingModal,
} from "./interfaces";

// Constants
import * as constants from "./variety-hybrid/constants";
import VarietyHybridComponent, { VarietyHybrid } from "./variety-hybrid/variety-hybrid";
import {
    IAgEventPlanting,
    IEventPlantingVarietyHybrid,
} from "@ai360/core/dist/4x/es/api/ag-event/ag-event.4x";
import { IPicklistItem } from "@ai360/core/dist/4x/es/api/picklist";
import { isEqual, uniq } from "lodash";

export const errorMessages = defineMessages({
    cropAgeWhenPlantedUnitError: {
        id: "eventModule.eventInfo.cropAgeWhenPlantedUnitError",
        defaultMessage: "Crop Age Planted Unit",
    },
    cropError: {
        id: "eventModule.eventInfo.cropError",
        defaultMessage: "Crop Error",
    },
    percentOfPlanterTotalError: {
        id: "eventModule.eventInfo.percentOfPlanterTotalError",
        defaultMessage: "Percent Of Planter",
    },
    plantPerAreaUnitError: {
        id: "eventModule.eventInfo.plantPerAreaUnitError",
        defaultMessage: "Plant Per Area Dependent",
    },
    plantSpacingUnitError: {
        id: "eventModule.eventInfo.plantSpacingUnitError",
        defaultMessage: "Plant Spacing Unit",
    },
    plantingDepthUnitError: {
        id: "eventModule.eventInfo.plantingDepthUnitError",
        defaultMessage: "Planting Depth Unit",
    },
    pricePerUnitError: {
        id: "eventModule.eventInfo.pricePerUnitError",
        defaultMessage: "Price Per Unit",
    },
    rowSpacingUnitError: {
        id: "eventModule.eventInfo.rowSpacingUnitError",
        defaultMessage: "Row Spacing Unit",
    },
    seedingRateUnitError: {
        id: "eventModule.eventInfo.seedingRateUnitError",
        defaultMessage: "Sedding Rate Unit",
    },
});

export const errorCodeToMessageIdSetMap = new Map([
    [321, errorMessages.cropError], // ErrorCode.EventPlantingCropRequired
    [841, errorMessages.percentPlanterError], //PercentOfPlanterIsRequired
    [842, errorMessages.plantingDepthUnitError], // ErrorCode.PlantingDepthUnitRequired
    [844, errorMessages.rowSpacingUnitError], // ErrorCode.RowSpacingUnitRequired
    [846, errorMessages.plantSpacingUnitError], // ErrorCode.PlantSpacingUnitRequired
    [848, errorMessages.cropAgeWhenPlantedUnitError], //CropAgeWhenPlantedUnitRequired
    [850, errorMessages.seedingRateUnitError], //SeedingRateUnitRequired
    [852, errorMessages.plantPerAreaUnitError], //PlantsPerAreaUnitRequired
    [854, errorMessages.pricePerUnitError], //PricePerUnitUnitRequired
    [2852, errorMessages.percentOfPlanterTotalError], //PercentOfPlanterTotalIsInvalid]
]);

export const errorCodesApply = (errorCodeList: Record<string, any>): void => {
    return errorCodeList.some((errorCode) => errorCodeToMessageIdSetMap.has(errorCode));
};

export interface IPlantingEventFormProps {
    agEventModel?: Record<string, any>;
    brandOrgData?: IBrandOrgData[];
    cropData?: ICropData[];
    cropPurpose?: ISelectOption<any>[];
    dryPriceUnit?: number;
    dryProductUnit?: number;
    fetchDropdownData?: (data: IDropDownData) => void;
    fetchPicklistData?: (obj: Record<string, any>) => void;
    fetchSystemAttributePicklist?: (data: Record<string, any>) => void;
    fetchUnitData?: (data: Record<string, any>) => void;
    intl: InjectedIntl;
    isEventType?: boolean;
    liquidPriceUnit?: number;
    liquidProducteUnit?: number;
    model?: IAgEventPlanting;
    onUpdateModel?: (props: Partial<IAgEventPlanting>) => void;
    packageUnit?: number;
    plantSpacing?: Record<string, any>[];
    plantingCropAge?: Record<string, any>[];
    plantingDepth?: Record<string, any>[];
    plantingPattern?: Record<string, any>[];
    productData?: Record<string, any>[];
    rowOrientation?: Record<string, any>[];
    rowSpacing?: IRowSpacing[];
    saveEventDetailsErrorCodeList: number[];
    seedingRate?: Record<string, any>[];
    transplantRate?: number;
    transplantType?: IPicklistItem[];
    varietyHybridData?: Partial<VarietyHybrid>[];
    fieldGuid?: string;
    agEventTransactionTypeGuid?: string;
    updatePlantingModel?: (plantingModal: IUpdatePlantingModal) => void;
    varietyHybridKey?: string;
    [picklistNames.PICKLIST_COMMODITY]: IPicklistItem[];
    [picklistNames.PICKLIST_COMMODITY_TYPE]: IPicklistItem[];
    [picklistNames.PICKLIST_INTENDED_USE]: IPicklistItem[];
    [picklistNames.PICKLIST_CROPPING_PRACTICE]: IPicklistItem[];
    enableMyAgData: boolean;
    fetchMyAgDataCommodities: () => void;
    myAgDataCommodities: Map<string, CropModels.IMyAgDataCommodity[]>;
}

export interface IPlantingEventFormState {
    errorMessagePlaceholderSet: Set<any>;
    commodity: IPicklistItem[];
    commodityType: IPicklistItem[];
    intendedUse: IPicklistItem[];
}
class PlantingEventForm_ extends PureComponent<IPlantingEventFormProps, IPlantingEventFormState> {
    varietyHybridKey: string;

    static defaultProps = {
        rowSpacing: [],
        plantingDepth: [],
    };

    constructor(props) {
        super(props);
        this.varietyHybridKey = props.isEventType
            ? keywords.EVENT_PLANTING_VARIETY_HYBRID_LIST
            : keywords.REC_PLANTING_VARIETY_HYBRID_LIST;
        this.state = {
            errorMessagePlaceholderSet: this._getErrorMessagePlaceholderSet(props),
            commodity: [],
            commodityType: [],
            intendedUse: [],
        };
    }

    private _getErrorMessagePlaceholderSet(props) {
        const { saveEventDetailsErrorCodeList } = props;
        return getSetValuesForErrorCodeList(
            saveEventDetailsErrorCodeList,
            errorCodeToMessageIdSetMap
        );
    }

    private _updateAgEvent(newProps: Partial<IAgEventPlanting>) {
        this.props.onUpdateModel(newProps);
    }

    public getLabel = ({ options = [], guid = "" }, valueKey = "value", labelKey = "label") => {
        if (options.length) {
            const selectedOption = options.filter((option) => {
                return guid === option[valueKey];
            });
            return selectedOption.length && selectedOption[0][labelKey];
        }
        return null;
    };

    public getPhysicalState = (productGuid: string): string => {
        const selectedOption = this.props.productData.find(({ guid }) => {
            return productGuid === guid;
        });
        return selectedOption ? selectedOption.physicalStateId : "";
    };

    public onCropNameChange = (guid, options) => {
        const clearedBrandVarietyHybridList = this.props.model[this.varietyHybridKey].map((vh) => {
            return {
                ...vh,
                [constants.PROPS_BRAND_ORGANIZATION_GUID]: "",
                [constants.PROPS_BRAND_ORGANIZATION_NAME]: "",
                [constants.PROPS_VARIETY_HYBRID_GUID]: "",
                [constants.PROPS_VARIETY_HYBRID_NAME]: "",
            };
        });

        this._updateAgEvent({
            cropGuid: guid,
            cropName: this.getLabel({ options, guid }, "guid", "name"),
            [this.varietyHybridKey]: clearedBrandVarietyHybridList,
            commodityTypeGuid: null,
            commodityGuid: null,
            intendedUseGuid: null,
        });
    };

    public onVarietyHybridChange(value: IEventPlantingVarietyHybrid[] | null) {
        this._updateAgEvent({
            [this.varietyHybridKey]: value,
        });
    }

    private varietyHybridGuidChanged(oldList: IEventPlantingVarietyHybrid[] | null) {
        return !isEqual(
            oldList?.map((x) => x.varietyHybridGuid),
            this.props.model[this.varietyHybridKey]?.map((x) => x.varietyHybridGuid)
        );
    }

    componentDidUpdate(prevProps: IPlantingEventFormProps) {
        const { model } = this.props;
        const prevModel = prevProps.model;
        if (model.cropGuid && model.cropGuid !== prevModel.cropGuid) {
            this.props.fetchDropdownData({
                brandOrgData: {
                    url: CropAPI.REQUEST_BRAND_ORG,
                    model: model.cropGuid,
                },
            });

            this.filterMyAgDataDropdowns();
        } else if (
            this.varietyHybridGuidChanged(prevModel[this.varietyHybridKey]) ||
            model.commodityGuid !== prevModel.commodityGuid
        ) {
            this.filterMyAgDataDropdowns();
        } else if (model.commodityTypeGuid !== prevModel.commodityTypeGuid) {
            this.filterMyAgDataDropdowns(!model.commodityTypeGuid);
        }

        if (this.props.saveEventDetailsErrorCodeList !== prevProps.saveEventDetailsErrorCodeList) {
            const errorMessagePlaceholderSet = this._getErrorMessagePlaceholderSet(this.props);
            this.setState({ errorMessagePlaceholderSet });
        }

        if (this.props.myAgDataCommodities?.size > prevProps.myAgDataCommodities?.size) {
            this.filterMyAgDataDropdowns();
        }
    }

    componentDidMount() {
        this.props.fetchDropdownData({
            brandOrgData: {
                url: CropAPI.REQUEST_BRAND_ORG,
                model: this.props.model.cropGuid,
            },
            cropData: {
                url: CropAPI.REQUEST_CROP,
                model: keywords.name,
            },
        });
        this.props.fetchPicklistData({
            [picklistNames.PICKLIST_CROP_PURPOSE]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_CROP_PURPOSE
            ),
            [picklistNames.PICKLIST_TRANSPLANT_TYPE]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_TRANSPLANT_TYPE
            ),
            [picklistNames.PICKLIST_PLANTING_PATTERN]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_PLANTING_PATTERN
            ),
            [picklistNames.PICKLIST_ROW_ORIENTATION]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_ROW_ORIENTATION
            ),
            [picklistNames.PICKLIST_COMMODITY]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_COMMODITY
            ),
            [picklistNames.PICKLIST_COMMODITY_TYPE]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_COMMODITY_TYPE
            ),
            [picklistNames.PICKLIST_CROPPING_PRACTICE]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_CROPPING_PRACTICE
            ),
            [picklistNames.PICKLIST_INTENDED_USE]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_INTENDED_USE
            ),
        });
        this.props.fetchUnitData({
            [unitNames.UNIT_PACKAGE]: unitNames.getUnitCode(unitNames.UNIT_PACKAGE),
            [unitNames.UNIT_PRICE_UNIT_DRY]: unitNames.getUnitCode(unitNames.UNIT_PRICE_UNIT_DRY),
            [unitNames.UNIT_PRICE_UNIT_LIQUID]: unitNames.getUnitCode(
                unitNames.UNIT_PRICE_UNIT_LIQUID
            ),
            [unitNames.UNIT_PRODUCT_RATE_DRY]: unitNames.getUnitCode(
                unitNames.UNIT_PRODUCT_RATE_DRY
            ),
            [unitNames.UNIT_PRODUCT_RATE_LIQUID]: unitNames.getUnitCode(
                unitNames.UNIT_PRODUCT_RATE_LIQUID
            ),
        });

        this.props.fetchSystemAttributePicklist([
            {
                eventType: keywords.planting,
                systemAttribute: keywords.cropAge,
                model: "plantingCropAge",
                forceRaw: true,
            },
            {
                eventType: keywords.planting,
                systemAttribute: keywords.plantingDepth,
                model: "plantingDepth",
                forceRaw: true,
            },
            {
                eventType: keywords.planting,
                systemAttribute: keywords.rowSpacing,
                model: "rowSpacing",
                forceRaw: true,
            },
            {
                eventType: keywords.planting,
                systemAttribute: keywords.plantSpacing,
                model: "plantSpacing",
                forceRaw: true,
            },
            {
                eventType: keywords.planting,
                systemAttribute: keywords.seedingRate,
                model: "seedingRate",
                forceRaw: true,
            },
            {
                eventType: keywords.planting,
                systemAttribute: keywords.transplantRate,
                model: "transplantRate",
                forceRaw: true,
            },
        ]);
        if (this.props.enableMyAgData && this.props.isEventType) {
            if (this.props.myAgDataCommodities?.size === 0 ?? true) {
                this.props.fetchMyAgDataCommodities();
            } else {
                this.filterMyAgDataDropdowns();
            }
        }
    }

    private filterMyAgDataDropdowns(clearedType = false) {
        const {
            enableMyAgData,
            myAgDataCommodities,
            commodity,
            isEventType,
            model,
            commodityType,
            intendedUse,
        } = this.props;
        const { cropGuid } = model;

        if (
            !isEventType ||
            !enableMyAgData ||
            !myAgDataCommodities ||
            (commodity?.length === 0 ?? true)
        ) {
            return;
        }

        if (!myAgDataCommodities.has(cropGuid)) {
            this.setState({
                commodity: [],
                commodityType: [],
                intendedUse: [],
            });
            return;
        }

        const varietyHybridGuids = (model[this.varietyHybridKey] ?? [])
            .map((v: IEventPlantingVarietyHybrid) => v.varietyHybridGuid)
            .filter(Boolean);

        const filteredByCropVariety = myAgDataCommodities
            .get(cropGuid)
            .filter(
                (x) =>
                    !x.varietyHybridGuid ||
                    (varietyHybridGuids.length > 0 &&
                        varietyHybridGuids.includes(x.varietyHybridGuid))
            );

        if (filteredByCropVariety.length === 0) {
            this.setState({
                commodity: [],
                commodityType: [],
                intendedUse: [],
            });
            return;
        }
        const newValues: Partial<IAgEventPlanting> = {
            commodityGuid: model.commodityGuid,
            commodityTypeGuid: model.commodityTypeGuid,
            intendedUseGuid: model.intendedUseGuid,
            croppingPracticeGuid: model.croppingPracticeGuid,
        };
        const stateUpdate = {
            commodity: [],
            commodityType: [],
            intendedUse: [],
        };

        // now that we know there are some commodities for the selected crops & varieties, let's populate the commodity list & see if we can default it
        const commodityGuids = uniq(filteredByCropVariety.map((x) => x.commodityGuid));
        stateUpdate.commodity =
            commodityGuids.length > 0
                ? commodity.filter((x) => commodityGuids.includes(x.value as string))
                : [];
        if (!commodityGuids.includes(newValues.commodityGuid)) {
            newValues.commodityGuid = null;
        }

        // if we have a commodity, populate the type list & see if we can default it
        if (newValues.commodityGuid) {
            const typeGuids = uniq(
                filteredByCropVariety
                    .filter((x) => x.commodityGuid === newValues.commodityGuid)
                    .map((x) => x.commodityTypeGuid)
            );
            if (typeGuids.length > 0) {
                stateUpdate.commodityType = commodityType.filter((x) =>
                    typeGuids.includes(x.value as string)
                );

                if (typeGuids.length === 1 && !clearedType) {
                    newValues.commodityTypeGuid = typeGuids[0];
                } else if (!typeGuids.includes(newValues.commodityTypeGuid)) {
                    newValues.commodityTypeGuid = null;
                }
            } else {
                newValues.commodityTypeGuid = null;
            }
        } else {
            newValues.commodityTypeGuid = null;
        }

        // if we have a type, populate the intended uses list & see if we can default it
        if (newValues.commodityTypeGuid) {
            const useGuids = uniq(
                filteredByCropVariety
                    .filter(
                        (x) =>
                            x.commodityGuid === newValues.commodityGuid &&
                            x.commodityTypeGuid === newValues.commodityTypeGuid
                    )
                    .map((x) => x.intendedUseGuid)
            );
            if (useGuids.length > 0) {
                stateUpdate.intendedUse = intendedUse.filter((x) =>
                    useGuids.includes(x.value as string)
                );

                if (useGuids.length === 1) {
                    newValues.intendedUseGuid = useGuids[0];
                } else if (!useGuids.includes(newValues.intendedUseGuid)) {
                    newValues.intendedUseGuid = null;
                }
            } else {
                newValues.intendedUseGuid = null;
            }
        } else {
            newValues.intendedUseGuid = null;
        }

        this.setState(stateUpdate);
        this._updateAgEvent(newValues);
    }

    render() {
        const { formatMessage } = this.props.intl;
        const {
            brandOrgData,
            cropData,
            cropPurpose,
            dryPriceUnit,
            dryProductUnit,
            liquidPriceUnit,
            liquidProducteUnit,
            model,
            packageUnit,
            plantSpacing,
            plantingCropAge,
            plantingDepth,
            plantingPattern,
            productData,
            rowOrientation,
            rowSpacing,
            seedingRate,
            transplantRate,
            transplantType,
            fieldGuid,
            agEventTransactionTypeGuid,
            updatePlantingModel,
            croppingPractice,
            isEventType,
        } = this.props;
        const { errorMessagePlaceholderSet, commodityType, intendedUse, commodity } = this.state;

        return (
            <div className="planting-event-container">
                <div>
                    <Bucket className="crop-info-section-bucket" isExpanded>
                        <BucketHeader className="planting-event-section-header">
                            <div className="create-events-header">
                                {formatMessage(messages.cropInfo)}
                            </div>
                        </BucketHeader>
                        <div className="input-row">
                            <SelectInput
                                optionIsHiddenKey={ACTIVE_YN}
                                containerClassNames={[
                                    {
                                        "select-form-input-error": errorMessagePlaceholderSet.has(
                                            errorMessages.cropError
                                        ),
                                    },
                                ]}
                                clearable={false}
                                placeholderText={formatMessage(messages.crop)}
                                value={model.cropGuid}
                                options={cropData.map(({ guid, name, activeYn }) => ({
                                    value: guid,
                                    label: name,
                                    activeYn,
                                }))}
                                required
                                onChange={(value) => this.onCropNameChange(value, cropData)}
                            />
                            <SelectInput
                                placeholderText={formatMessage(messages.cropPurpose)}
                                value={model.cropPurposeGuid}
                                options={cropPurpose}
                                onChange={(value) =>
                                    this._updateAgEvent({
                                        cropPurposeGuid: value,
                                        cropPurposeName: this.getLabel({
                                            options: cropPurpose,
                                            guid: value,
                                        }),
                                    })
                                }
                            />
                        </div>
                        {!model.transplantYn ? null : (
                            <div className="transplant-container">
                                <div className="input-row">
                                    <SelectInput
                                        placeholderText={formatMessage(messages.transplantType)}
                                        value={model.transplantTypeGuid}
                                        options={transplantType}
                                        onChange={(value) =>
                                            this._updateAgEvent({
                                                transplantTypeGuid: value,
                                            })
                                        }
                                    />
                                    <SelectInput
                                        placeholderText={formatMessage(messages.plantingStyle)}
                                        value={model.plantingPatternGuid}
                                        options={plantingPattern}
                                        onChange={(value) =>
                                            this._updateAgEvent({
                                                plantingPatternGuid: value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="input-row">
                                    <NumericInput
                                        containerClassNames={[
                                            {
                                                "form-input-error": errorMessagePlaceholderSet.has(
                                                    errorMessages.cropAgeWhenPlantedUnitDependentError
                                                ),
                                            },
                                        ]}
                                        scale={0}
                                        precision={9}
                                        value={model.cropAgeWhenPlanted}
                                        placeholderText={formatMessage(messages.cropAgeWhenPlanted)}
                                        onChange={(value) =>
                                            this._updateAgEvent({
                                                cropAgeWhenPlanted: value,
                                            })
                                        }
                                    />
                                    <SelectInput
                                        containerClassNames={[
                                            {
                                                "select-form-input-error":
                                                    errorMessagePlaceholderSet.has(
                                                        errorMessages.cropAgeWhenPlantedUnitError
                                                    ),
                                            },
                                        ]}
                                        placeholderText={formatMessage(messages.unit)}
                                        value={model.cropAgeIaGuid}
                                        options={plantingCropAge?.map(
                                            ({ importAttributeGuid, abbreviation }) => ({
                                                value: importAttributeGuid,
                                                label: abbreviation,
                                            })
                                        )}
                                        onChange={(value) =>
                                            this._updateAgEvent({
                                                cropAgeIaGuid: value,
                                            })
                                        }
                                    />
                                    <NumericInput
                                        containerClassNames={[
                                            {
                                                "form-input-error": errorMessagePlaceholderSet.has(
                                                    errorMessages.plantSpacingUnitDependentError
                                                ),
                                            },
                                        ]}
                                        scale={2}
                                        precision={9}
                                        value={model.plantSpacing}
                                        placeholderText={formatMessage(messages.plantSpacing)}
                                        onChange={(value) =>
                                            this._updateAgEvent({
                                                plantSpacing: value,
                                            })
                                        }
                                    />
                                    <SelectInput
                                        containerClassNames={[
                                            {
                                                "select-form-input-error":
                                                    errorMessagePlaceholderSet.has(
                                                        errorMessages.plantingSpacingUnitError
                                                    ),
                                            },
                                        ]}
                                        placeholderText={formatMessage(messages.unit)}
                                        value={model.plantSpacingIaGuid}
                                        options={plantSpacing?.map(
                                            ({ importAttributeGuid, abbreviation }) => ({
                                                value: importAttributeGuid,
                                                label: abbreviation,
                                            })
                                        )}
                                        onChange={(value) =>
                                            this._updateAgEvent({
                                                plantSpacingIaGuid: value,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        <div className="input-row">
                            <NumericInput
                                containerClassNames={[
                                    {
                                        "form-input-error": errorMessagePlaceholderSet.has(
                                            errorMessages.plantingDepthUnitDependentError
                                        ),
                                    },
                                ]}
                                scale={2}
                                precision={9}
                                value={model.plantingDepth}
                                placeholderText={formatMessage(messages.plantingDepth)}
                                onChange={(value) =>
                                    this._updateAgEvent({
                                        plantingDepth: value,
                                    })
                                }
                            />
                            <SelectInput
                                containerClassNames={[
                                    {
                                        "select-form-input-error": errorMessagePlaceholderSet.has(
                                            errorMessages.plantingDepthUnitError
                                        ),
                                    },
                                ]}
                                placeholderText={formatMessage(messages.unit)}
                                value={model.plantingDepthIaGuid}
                                options={plantingDepth.map(
                                    ({ importAttributeGuid, abbreviation }) => ({
                                        value: importAttributeGuid,
                                        label: abbreviation,
                                    })
                                )}
                                onChange={(value) =>
                                    this._updateAgEvent({
                                        plantingDepthIaGuid: value,
                                    })
                                }
                            />
                            <NumericInput
                                containerClassNames={[
                                    {
                                        "form-input-error": errorMessagePlaceholderSet.has(
                                            errorMessages.rowSpacingUnitDependentError
                                        ),
                                    },
                                ]}
                                scale={2}
                                precision={9}
                                value={model.rowSpacing}
                                placeholderText={formatMessage(messages.rowSpacing)}
                                onChange={(value) =>
                                    this._updateAgEvent({
                                        rowSpacing: value,
                                    })
                                }
                            />
                            <SelectInput
                                containerClassNames={[
                                    {
                                        "select-form-input-error": errorMessagePlaceholderSet.has(
                                            errorMessages.rowSpacingUnitError
                                        ),
                                    },
                                ]}
                                placeholderText={formatMessage(messages.unit)}
                                value={model.rowSpacingIaGuid}
                                options={rowSpacing.map(
                                    ({ importAttributeGuid, abbreviation }) => ({
                                        value: importAttributeGuid,
                                        label: abbreviation,
                                    })
                                )}
                                onChange={(value) =>
                                    this._updateAgEvent({
                                        rowSpacingIaGuid: value,
                                    })
                                }
                            />
                        </div>
                        <div className="input-row">
                            <SelectInput
                                placeholderText={formatMessage(messages.rowOrientation)}
                                value={model.rowOrientationGuid}
                                options={rowOrientation}
                                onChange={(value) =>
                                    this._updateAgEvent({
                                        rowOrientationGuid: value,
                                    })
                                }
                            />
                            <Checkbox
                                className="transplant-checkbox"
                                label={formatMessage(messages.transplant)}
                                value={model.transplantYn}
                                onChange={(evt, value) =>
                                    this._updateAgEvent({
                                        transplantYn: value,
                                    })
                                }
                            />
                        </div>
                    </Bucket>
                    <VarietyHybridComponent
                        cropGuid={model.cropGuid}
                        brandOrgData={brandOrgData}
                        seedingRate={seedingRate}
                        productData={productData}
                        packageUnit={packageUnit}
                        dryPriceUnit={dryPriceUnit}
                        liquidPriceUnit={liquidPriceUnit}
                        dryProductUnit={dryProductUnit}
                        liquidProducteUnit={liquidProducteUnit}
                        transplant={model.transplantYn}
                        transplantRate={transplantRate}
                        onChildComponentChange={(value) => this.onVarietyHybridChange(value)}
                        varietyHybridList={model[this.varietyHybridKey]}
                        getPhysicalState={this.getPhysicalState}
                        saveEventDetailsErrorCodeList={this.props.saveEventDetailsErrorCodeList}
                        errorMessagePlaceholderSet={this.state.errorMessagePlaceholderSet}
                        errorMessages={errorMessages}
                        fieldGuid={fieldGuid}
                        agEventTransactionTypeGuid={agEventTransactionTypeGuid}
                        updatePlantingModel={updatePlantingModel}
                        isImportedYn={model.isImportedYn}
                    />
                    {isEventType && commodity.length > 0 && (
                        <Bucket className="myagdata-commodity-bucket" isExpanded>
                            <BucketHeader className="planting-event-section-header">
                                <div className="create-events-header">
                                    {formatMessage(messages.myAgData)}
                                </div>
                            </BucketHeader>
                            <div className="input-row">
                                <SelectInput
                                    clearable={true}
                                    placeholderText={formatMessage(messages.commodity)}
                                    value={model.commodityGuid}
                                    options={commodity}
                                    onChange={(value) =>
                                        this._updateAgEvent({
                                            commodityGuid: value,
                                        })
                                    }
                                />
                                <SelectInput
                                    clearable={true}
                                    disabled={!model.commodityGuid}
                                    required={Boolean(model.commodityGuid)}
                                    placeholderText={formatMessage(messages.commodityType)}
                                    value={model.commodityTypeGuid}
                                    options={commodityType}
                                    onChange={(value) =>
                                        this._updateAgEvent({
                                            commodityTypeGuid: value,
                                        })
                                    }
                                />
                            </div>
                            <div className="input-row">
                                <SelectInput
                                    clearable={true}
                                    disabled={!model.commodityTypeGuid}
                                    required={Boolean(model.commodityGuid)}
                                    placeholderText={formatMessage(messages.intendedUse)}
                                    value={model.intendedUseGuid}
                                    options={intendedUse}
                                    onChange={(value) =>
                                        this._updateAgEvent({
                                            intendedUseGuid: value,
                                        })
                                    }
                                />
                                <SelectInput
                                    clearable={true}
                                    disabled={!model.commodityGuid}
                                    required={Boolean(model.commodityGuid)}
                                    placeholderText={formatMessage(messages.croppingPractice)}
                                    value={model.croppingPracticeGuid}
                                    options={croppingPractice}
                                    onChange={(value) =>
                                        this._updateAgEvent({
                                            croppingPracticeGuid: value,
                                        })
                                    }
                                />
                            </div>
                        </Bucket>
                    )}
                </div>
            </div>
        );
    }
}

export const PlantingEventForm = injectIntl(PlantingEventForm_);
