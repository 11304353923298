export const defaultTreeObject = [
    {
        name: "account",
        nameCode: "245",
        labelKey: "accountText",
        value: false,
        isOpen: true,
        children: [
            {
                name: "telematics",
                nameCode: "244",
                labelKey: "telematicsText",
                value: false,
                isOpen: true,
                children: [
                    {
                        name: "autoExchange",
                        nameCode: "431",
                        labelKey: "autoExchangeText",
                        value: false,
                        isOpen: true,
                        children: null,
                    },
                ],
            },
        ],
    },
    {
        name: "activeInactive",
        nameCode: "185",
        labelKey: "activeInactiveText",
        value: false,
        isOpen: true,
        children: null,
    },
    {
        name: "manageFields",
        nameCode: "116",
        labelKey: "manageFieldsText",
        value: false,
        isOpen: true,
        children: [
            {
                name: "cluBoundaries",
                nameCode: "258",
                labelKey: "cluBoundariesText",
                value: false,
                children: null,
            },
            {
                name: "resurfacing",
                nameCode: "248",
                labelKey: "resurfacingText",
                value: false,
                children: null,
            },
            {
                name: "agvanceBillableArea",
                nameCode: "209",
                labelKey: "agvanceBillableAreaText",
                value: false,
                children: null,
            },
            {
                name: "imagerySetup",
                nameCode: "208",
                labelKey: "imagerySetupText",
                value: false,
                children: null,
            },
            {
                name: "fieldBoundaryTransfer",
                nameCode: "210",
                labelKey: "fieldBoundaryTransferText",
                value: false,
                children: null,
            },
            {
                name: "lockBoundary",
                nameCode: "206",
                labelKey: "lockBoundaryText",
                value: false,
                children: null,
            },
            {
                name: "portalBoundaryManualSync",
                nameCode: "272",
                labelKey: "portalBoundaryManualSyncText",
                value: false,
                children: null,
            },
        ],
    },
    {
        name: "agBytes",
        nameCode: "102",
        labelKey: "agBytesText",
        value: false,
        isOpen: true,
        children: [
            {
                name: "agBytesCountryStateSetup",
                nameCode: "134",
                labelKey: "agBytesCountryStateSetupText",
                value: false,
                children: null,
            },
            {
                name: "agBytesCropsSetup",
                nameCode: "126",
                labelKey: "agBytesCropsSetupText",
                value: false,
                children: null,
            },
            {
                name: "agBytesEquipment",
                nameCode: "128",
                labelKey: "agBytesEquipmentText",
                value: false,
                children: null,
            },
            {
                name: "agBytesGenes",
                nameCode: "178",
                labelKey: "agBytesGenesText",
                value: false,
                children: null,
            },
            {
                name: "agBytesGrowthStages",
                nameCode: "136",
                labelKey: "agBytesGrowthStagesText",
                value: false,
                children: null,
            },
            {
                name: "agBytesSampleAttributesSetup",
                nameCode: "176",
                labelKey: "agBytesSampleAttributesSetupText",
                value: false,
                children: null,
            },
            {
                name: "agBytesNutrients",
                nameCode: "133",
                labelKey: "agBytesNutrientsText",
                value: false,
                children: null,
            },
            {
                name: "agBytesObservations",
                nameCode: "127",
                labelKey: "agBytesObservationsText",
                value: false,
                children: null,
            },
            {
                name: "agBytesPicklistSetup",
                nameCode: "130",
                labelKey: "agBytesPicklistSetupText",
                value: false,
                children: null,
            },
            {
                name: "agBytesTraits",
                nameCode: "125",
                labelKey: "agBytesProductsSetupText",
                value: false,
                children: null,
            },
            {
                name: "agBytesProductsSetup",
                nameCode: "179",
                labelKey: "agBytesTraitsText",
                value: false,
                children: null,
            },
            {
                name: "agBytesUnitsSetup",
                nameCode: "129",
                labelKey: "agBytesUnitsSetupText",
                value: false,
                children: null,
            },
            {
                name: "agBytesVarietyHybrid",
                nameCode: "135",
                labelKey: "agBytesVarietyHybridText",
                value: false,
                children: null,
            },
            {
                name: "agBytesVarietyHybridAttributes",
                nameCode: "177",
                labelKey: "agBytesVarietyHybridAttributesText",
                value: false,
                children: null,
            },
        ],
    },
    {
        name: "analysisLayers",
        nameCode: "222",
        labelKey: "analysisLayersText",
        value: false,
        isOpen: true,
        children: null,
    },
    {
        name: "analytics",
        nameCode: "257",
        labelKey: "analyticsText",
        value: false,
        isOpen: true,
        children: null,
    },
    {
        name: "batch",
        nameCode: "230",
        labelKey: "batchText",
        value: false,
        isOpen: true,
        children: [
            {
                name: "batchEditRecs",
                nameCode: "252",
                labelKey: "batchEditRecsText",
                value: false,
                children: null,
            },
            {
                name: "batchEvents",
                nameCode: "231",
                labelKey: "batchEventsText",
                value: false,
                children: null,
            },
            {
                name: "batchRecs",
                nameCode: "232",
                labelKey: "batchRecsText",
                value: false,
                children: null,
            },
            {
                name: "batchAnalysisLayers",
                nameCode: "267",
                labelKey: "batchAnalysisLayersText",
                value: false,
                children: null,
            },
            {
                name: "batchImagerySetup",
                nameCode: "207",
                labelKey: "batchImagerySetupText",
                value: false,
                children: null,
            },
            // {
            //     name: "batchSendRecsTo",
            //     nameCode: "270",
            //     labelKey: "batchSendRecsToText",
            //     value: false,
            //     children: null,
            // },
        ],
    },
    {
        name: "agEvents",
        nameCode: "103",
        labelKey: "exportEventsText",
        value: false,
        isOpen: true,
        children: [
            {
                name: "agEventsApplication",
                nameCode: "147",
                labelKey: "agEventsApplicationText",
                value: false,
                children: null,
            },
            {
                name: "agEventsCustom",
                nameCode: "149",
                labelKey: "agEventsCustomText",
                value: false,
                children: null,
            },
            {
                name: "agEventsEcData",
                nameCode: "217",
                labelKey: "agEventsEcDataText",
                value: false,
                children: null,
            },
            {
                name: "agEventsHarvest",
                nameCode: "145",
                labelKey: "agEventsHarvestText",
                value: false,
                children: null,
            },
            {
                name: "agEventsIrrigation",
                nameCode: "148",
                labelKey: "agEventsIrrigationText",
                value: false,
                children: null,
            },
            {
                name: "agEventsPlanting",
                nameCode: "144",
                labelKey: "agEventsPlantingText",
                value: false,
                children: null,
            },
            {
                name: "sampling",
                nameCode: "110",
                labelKey: "samplingText",
                value: false,
                isOpen: true,
                children: [
                    {
                        name: "fertilizer",
                        nameCode: "139",
                        labelKey: "fertilizerText",
                        value: false,
                        children: null,
                    },
                    {
                        name: "manureCompost",
                        nameCode: "141",
                        labelKey: "manureCompostText",
                        value: false,
                        children: null,
                    },
                    {
                        name: "nematode",
                        nameCode: "124",
                        labelKey: "nematodeText",
                        value: false,
                        children: null,
                    },
                    {
                        name: "samplingOptions",
                        nameCode: "264",
                        labelKey: "samplingOptionsText",
                        value: false,
                        isOpen: true,
                        children: [
                            {
                                name: "mergeEvents",
                                nameCode: "137",
                                labelKey: "mergeEventsText",
                                value: false,
                                children: null,
                            },
                            {
                                name: "zoneInterpolation",
                                nameCode: "236",
                                labelKey: "zoneInterpolationText",
                                value: false,
                                children: null,
                            },
                            {
                                name: "zoneSampling",
                                nameCode: "265",
                                labelKey: "zoneSamplingText",
                                value: false,
                                children: null,
                            },
                        ],
                    },
                    {
                        name: "soil",
                        nameCode: "138",
                        labelKey: "soilText",
                        value: false,
                        children: null,
                    },
                    {
                        name: "tissue",
                        nameCode: "142",
                        labelKey: "tissueText",
                        value: false,
                        children: null,
                    },
                    {
                        name: "water",
                        nameCode: "143",
                        labelKey: "waterText",
                        value: false,
                        children: null,
                    },
                ],
            },
            {
                name: "scouting",
                nameCode: "111",
                labelKey: "scoutingText",
                value: false,
                children: null,
            },
            {
                name: "agEventsTillage",
                nameCode: "146",
                labelKey: "agEventsTillageText",
                value: false,
                children: null,
            },
        ],
    },
    {
        name: "exports",
        nameCode: "218",
        labelKey: "exportsText",
        value: false,
        isOpen: true,
        children: [
            {
                name: "exportEventData",
                nameCode: "263",
                labelKey: "exportEventDataText",
                value: false,
                children: null,
            },
            {
                name: "exportFieldBoundary",
                nameCode: "221",
                labelKey: "exportFieldBoundaryText",
                value: false,
                children: null,
            },
            {
                name: "exportFieldDetails",
                nameCode: "430",
                labelKey: "exportFieldDetailsText",
                value: false,
                children: null,
            },
            {
                name: "exportRecs",
                nameCode: "219",
                labelKey: "exportRecsText",
                value: false,
                children: null,
            },
            {
                name: "exportSamplePoints",
                nameCode: "220",
                labelKey: "exportSamplePointsText",
                value: false,
                children: null,
            },
            {
                name: "exportSurfaces",
                nameCode: "227",
                labelKey: "exportSurfacesText",
                value: false,
                children: null,
            },
        ],
    },
    {
        name: "financials",
        nameCode: "113",
        labelKey: "financialsText",
        value: false,
        children: null,
    },
    {
        name: "import",
        nameCode: "114",
        labelKey: "importText",
        value: false,
        isOpen: true,
        children: [
            {
                name: "assignToAll",
                nameCode: "150",
                labelKey: "assignToAllText",
                value: false,
                children: null,
            },
            {
                name: "importAliasName",
                nameCode: "153",
                labelKey: "importAliasNameText",
                value: false,
                children: null,
            },
        ],
    },
    {
        name: "layers",
        nameCode: "240",
        labelKey: "layersText",
        value: false,
        isOpen: true,
        children: [
            {
                name: "layerAnalysis",
                nameCode: "241",
                labelKey: "layerAnalysisText",
                value: false,
                children: null,
            },
            {
                name: "layerEvents",
                nameCode: "242",
                labelKey: "layerEventsText",
                value: false,
                children: null,
            },
            {
                name: "layerRecs",
                nameCode: "243",
                labelKey: "layerRecsText",
                value: false,
                children: null,
            },
        ],
    },
    {
        name: "notifications",
        nameCode: "429",
        labelKey: "notificationsText",
        value: false,
        children: null,
    },
    {
        name: "preferences",
        nameCode: "246",
        labelKey: "preferencesText",
        value: false,
        isOpen: true,
        children: [
            {
                name: "attributeColorOverride",
                nameCode: 268,
                labelKey: "attributeColorOverrideSingular",
                value: false,
                children: null,
            },
            {
                name: "autoImportData",
                nameCode: "151",
                labelKey: "autoImportDataText",
                value: false,
                isOpen: true,
                children: [
                    {
                        name: "autoImportCreateEquipmentProfile",
                        nameCode: "152",
                        labelKey: "autoImportCreateEquipmentProfileText",
                        value: false,
                        children: null,
                    },
                    {
                        name: "autoImportUnspecifiedProduct",
                        nameCode: "154",
                        labelKey: "autoImportUnspecifiedProductText",
                        value: false,
                        children: null,
                    },
                    {
                        name: "autoImportUnspecifiedVariety",
                        nameCode: "155",
                        labelKey: "autoImportUnspecifiedVarietyText",
                        value: false,
                        children: null,
                    },
                ],
            },
            {
                name: "enableMyAgData",
                nameCode: "432",
                labelKey: "enableMyAgDataText",
                value: false,
                children: null,
            },
            {
                name: "orgLevelQuickChanger",
                nameCode: "254",
                labelKey: "orgLevelQuickChangerText",
                value: false,
                children: null,
            },
            {
                name: "realTimeUpdates",
                nameCode: "247",
                labelKey: "realTimeUpdatesText",
                value: false,
                children: null,
            },
        ],
    },
    {
        name: "recommendations",
        nameCode: "104",
        labelKey: "recommendationsText",
        value: false,
        isOpen: true,
        children: [
            {
                name: "equationApplication",
                nameCode: "215",
                labelKey: "equationApplicationText",
                value: false,
                children: null,
            },
            {
                name: "manualApplication",
                nameCode: "214",
                labelKey: "manualApplicationText",
                value: false,
                children: null,
            },
            {
                name: "equationPlanting",
                nameCode: "262",
                labelKey: "equationPlantingText",
                value: false,
                children: null,
            },
            {
                name: "manualPlanting",
                nameCode: "216",
                labelKey: "manualPlantingText",
                value: false,
                children: null,
            },
            {
                name: "sendRecTo",
                nameCode: "269",
                labelKey: "sendRecToText",
                value: false,
                children: null,
            },
        ],
    },
    {
        name: "reportWizard",
        nameCode: "105",
        labelKey: "reportWizardText",
        value: false,
        isOpen: true,
        children: [
            {
                name: "agrIntelligenceReports",
                nameCode: "224",
                labelKey: "agrIntelligenceReportsText",
                value: false,
                children: null,
            },
            {
                name: "fieldReports",
                nameCode: "225",
                labelKey: "fieldReportsText",
                value: false,
                children: null,
            },
            {
                name: "reportReportBooks",
                nameCode: "427",
                labelKey: "reportReportBooksText",
                value: false,
                children: null,
            },
            {
                name: "summaryReports",
                nameCode: "226",
                labelKey: "summaryReportsText",
                value: false,
                children: null,
            },
        ],
    },
    {
        name: "setup",
        nameCode: "101",
        labelKey: "setupText",
        value: false,
        isOpen: true,
        children: [
            {
                name: "setupHierarchyZapper",
                nameCode: "180",
                labelKey: "hierarchyZapperText",
                value: false,
                children: null,
            },
            {
                name: "setupCustomerProfile",
                nameCode: "173",
                labelKey: "customersText",
                value: false,
                isOpen: true,
                children: [
                    {
                        name: "customerAliasName",
                        nameCode: "235",
                        labelKey: "customerAliasNameText",
                        value: false,
                        children: null,
                    },
                    {
                        name: "communityData",
                        nameCode: "211",
                        labelKey: "customerAddText",
                        value: false,
                        children: null,
                    },
                    {
                        name: "customerAdd",
                        nameCode: "212",
                        labelKey: "customerAddText",
                        value: false,
                        children: null,
                    },
                    {
                        name: "customerDelete",
                        nameCode: "229",
                        labelKey: "customerDeleteText",
                        value: false,
                        children: null,
                    },
                    {
                        name: "customerEdit",
                        nameCode: "213",
                        labelKey: "customerEditText",
                        value: false,
                        children: null,
                    },
                    {
                        name: "combineFields",
                        nameCode: "250",
                        labelKey: "combineFieldsText",
                        value: false,
                        children: null,
                    },
                    {
                        name: "moveField",
                        nameCode: "228",
                        labelKey: "moveFieldText",
                        value: false,
                        isOpen: true,
                        children: [
                            {
                                name: "acrossCustomers",
                                nameCode: "237",
                                labelKey: "acrossCustomersText",
                                value: false,
                                children: null,
                            },
                        ],
                    },
                    {
                        name: "nonFieldFeatures",
                        nameCode: "260",
                        labelKey: "nonFieldFeaturesText",
                        value: false,
                        children: null,
                    },
                    {
                        name: "portalIntegration",
                        nameCode: "271",
                        labelKey: "portalIntegration",
                        value: false,
                        children: null,
                    },
                    {
                        name: "customerSalesperson",
                        nameCode: "238",
                        labelKey: "customerSalespersonText",
                        value: false,
                        children: null,
                    },
                ],
            },
            {
                name: "agBytesEquationParameterSetup",
                nameCode: "183",
                labelKey: "agBytesEquationParameterSetupText",
                value: false,
                children: null,
            },
            {
                name: "agBytesEquationSetSetup",
                nameCode: "123",
                labelKey: "agBytesEquationSetSetupText",
                value: false,
                children: null,
            },
            {
                name: "agBytesEquationSetPlantingSetup",
                nameCode: "261",
                labelKey: "agBytesEquationSetPlantingSetupText",
                value: false,
                children: null,
            },
            {
                name: "setupEquipment",
                nameCode: "140",
                labelKey: "equipmentText",
                value: false,
                children: null,
            },
            {
                name: "setupHierarchy",
                nameCode: "117",
                labelKey: "hierarchyText",
                value: false,
                children: null,
            },
            {
                name: "locationMigrationTool",
                nameCode: "256",
                labelKey: "locationMigrationToolText",
                value: false,
                children: null,
            },
            {
                name: "agBytesNutrientRemove",
                nameCode: "131",
                labelKey: "agBytesNutrientRemoveText",
                value: false,
                children: null,
            },
            {
                name: "agBytesNutrientTargets",
                nameCode: "132",
                labelKey: "agBytesNutrientTargetsText",
                value: false,
                children: null,
            },
            {
                name: "setupPersonProfile",
                nameCode: "118",
                labelKey: "personProfileText",
                value: false,
                isOpen: true,
                children: [
                    {
                        name: "autoCreateReports",
                        nameCode: "251",
                        labelKey: "autoCreateReportsText",
                        value: false,
                        children: null,
                    },
                    {
                        name: "personImportExport",
                        nameCode: "223",
                        labelKey: "importExportTemplatesText",
                        value: false,
                        children: null,
                    },
                    {
                        name: "personSalesperson",
                        nameCode: "239",
                        labelKey: "personSalespersonText",
                        value: false,
                        children: null,
                    },
                ],
            },
            {
                name: "productAssignment",
                nameCode: "253",
                labelKey: "productAssignmentText",
                value: false,
                children: null,
            },
            {
                name: "setupReportBooks",
                nameCode: "266",
                labelKey: "setupReportBooksText",
                value: false,
                children: null,
            },
            {
                name: "setupReportBookOptions",
                nameCode: "259",
                labelKey: "setupReportBookOptionsText",
                value: false,
                children: null,
            },
            {
                name: "importExport",
                nameCode: "107",
                labelKey: "importExportText",
                value: false,
                children: null,
            },
            {
                name: "setupUILabels",
                nameCode: "184",
                labelKey: "uiLabelsText",
                value: false,
                children: null,
            },
            {
                name: "setupUserSetup",
                nameCode: "120",
                labelKey: "userSetupText",
                value: false,
                isOpen: true,
                children: [
                    {
                        name: "setupUserImportExport",
                        nameCode: "249",
                        labelKey: "setupUserImportExportText",
                        value: false,
                        children: null,
                    },
                ],
            },
            {
                name: "setupUserRole",
                nameCode: "121",
                labelKey: "userRoleText",
                value: false,
                children: null,
            },
            {
                name: "setupVendorSetup",
                nameCode: "122",
                labelKey: "vendorSetupText",
                value: false,
                isOpen: true,
                children: [
                    {
                        name: "setupVendorTestingLab",
                        nameCode: "188",
                        labelKey: "setupVendorTestingLabText",
                        value: false,
                        children: null,
                    },
                ],
            },
        ],
    },
];
