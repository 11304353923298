import React, { Component } from "react";
import PropTypes from "prop-types";
import { OrgLevelSearch } from "./org-level-search";
import { ZeroToInfiniteGrid } from "~/core";
import { injectIntl, intlShape } from "react-intl";
import { messages } from "./../i18n-messages";
import { AppHelpers } from "@ai360/core";
import { adminData } from "~/admin/data";
import { Checkbox, DialogBox, DialogBoxFooterType } from "~/core";

const PROPS_ORG_LEVEL_GUID = "orgLevelGuid";
const PROPS_ORG_LEVEL_NAME = "orgLevelName";
const PROPS_ORG_LEVEL_CITY = "city";
const PROPS_ORG_LEVEL_STATE = "state";
const PROPS_ORG_LEVEL_PARENT = "orgLevelParents";
const PROPS_ORG_LEVEL_PARENT_GUIDS = "orgLevelParentsGuids";
const PROPS_IS_LOCATION_PRIMARY = "isLocationPrimary";
const DELIMITER = " > ";
export const PROPS_STATE_ABBREVIATION = adminData.PROPS_STATE_ABBREVIATION;

class OrgLevelList_ extends Component {
    static propTypes = {
        apiErrors: PropTypes.array,
        classNames: PropTypes.array,
        deleteConfirmation: PropTypes.func,
        disabled: PropTypes.bool,
        intl: intlShape.isRequired,
        itemList: PropTypes.array,
        onSelectionChange: PropTypes.func.isRequired,
        onPrimaryLocationChange: PropTypes.func.isRequired,
        ownerLevel: PropTypes.object,
        record: PropTypes.array,
        required: PropTypes.bool,
        statePropName: PropTypes.string,
        isUser: PropTypes.bool,
        isPrimary: PropTypes.bool,
        isForCustomerScreen: PropTypes.bool,
    };
    static defaultProps = {
        disabled: false,
        itemList: [],
        record: [],
        required: true,
        statePropName: PROPS_ORG_LEVEL_STATE,
        isUser: false,
        isForCustomerScreen: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            currentOrgLevelList: props.itemList || [],
            showPrimaryChangeDialogBox: false,
            newPrimaryLocation: null,
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            JSON.stringify(nextProps.itemList) !== JSON.stringify(this.props.itemList) ||
            JSON.stringify(nextProps.record) !== JSON.stringify(this.props.record)
        ) {
            let convertedArray = [],
                hasOwnerlevel = false;
            if (nextProps.record.length > 0) {
                convertedArray = this.prepareRecords(nextProps.record);
                hasOwnerlevel = convertedArray.some(
                    ({ orgLevelParents }) => orgLevelParents && orgLevelParents.length === 0
                );
            }
            const modifiedItemList =
                JSON.stringify(nextProps.itemList) !== JSON.stringify(this.props.itemList)
                    ? this.prepareItemList(nextProps.itemList)
                    : nextProps.itemList;
            const itemListWithoutOwnerLevelAsParent = AppHelpers.parseOrgLevelList(
                modifiedItemList,
                PROPS_ORG_LEVEL_PARENT,
                DELIMITER,
                nextProps.ownerLevel
            );
            this.setState({
                currentOrgLevelList: this.removesOwnerLevel(
                    this.getSelectedOptionList(
                        itemListWithoutOwnerLevelAsParent,
                        convertedArray,
                        false
                    ),
                    hasOwnerlevel
                ),
            });
        }
        // If you add many locations, make 1 primary, then delete it, then throw
        // an onSave validation error (a super edge-case), you can actually
        // make more than 1 Primary checkbox checked. This just makes sure that only
        // 1 location is selected as primary whenever this component receives props.
        if (nextProps.record.length > 1) {
            if (nextProps.record.filter((x) => x[PROPS_IS_LOCATION_PRIMARY]).length > 1) {
                let primarySet = false;
                nextProps.record.forEach((rec) => {
                    if (rec[PROPS_IS_LOCATION_PRIMARY]) {
                        if (!primarySet) {
                            primarySet = true;
                        } else {
                            rec[PROPS_IS_LOCATION_PRIMARY] = false;
                        }
                    }
                });
            }
        }
        this.props = nextProps;
    }

    UNSAFE_componentWillMount() {
        if (this.props.itemList.length === 1) {
            this.setState({
                currentOrgLevelList: [],
            });
        }
    }

    removesOwnerLevel = (itemList, hasOwnerlevel) => {
        return hasOwnerlevel
            ? itemList.filter(({ orgLevelParentsGuids }) => orgLevelParentsGuids.length !== 0)
            : itemList;
    };

    prepareItemList = (itemList) => {
        return itemList.map((item) => {
            let parentList = item[PROPS_ORG_LEVEL_PARENT];
            if (typeof parentList === "string") {
                item[PROPS_ORG_LEVEL_PARENT] = parentList;
                parentList = item[PROPS_ORG_LEVEL_PARENT_GUIDS] || [];
            } else if (parentList.length) {
                item[PROPS_ORG_LEVEL_PARENT] = parentList.map(({ name }) => name);
            }
            item[PROPS_ORG_LEVEL_PARENT_GUIDS] = parentList.map(({ guid }) => guid);
            return item;
        });
    };

    // Restrict the delete for non-affliated locations for the current user
    prepareAffiliatedRecords(records) {
        return records.map((record) => {
            const hasAccess = this.props.itemList.some(
                (it) => it.orgLevelGuid === record[PROPS_ORG_LEVEL_GUID]
            );
            if (!hasAccess) {
                return { ...record, restrictEditDelete: true };
            }
            return record;
        });
    }

    // Convert state object in the record to state abbrevation string,
    // and also set the location to primary in case there's only one location
    prepareRecords(records) {
        if (records.length === 1) {
            records[0][PROPS_IS_LOCATION_PRIMARY] = true;
        }
        if (PROPS_ORG_LEVEL_STATE !== this.props.statePropName) {
            return records.map((record) => {
                let newRecord = { ...record };
                if (!record[this.props.statePropName]) {
                    newRecord[this.props.statePropName] = record[PROPS_ORG_LEVEL_STATE];
                }
                return newRecord;
            });
        }
        return records;
    }

    getSelectedOptionList = (itemList, selectedItems, selected = true) => {
        const optionList = itemList.filter((item) => {
            const match = selectedItems.some((selectedItem) => {
                return selectedItem[PROPS_ORG_LEVEL_GUID] === item[PROPS_ORG_LEVEL_GUID];
            });
            return (selected && match) || (!selected && !match);
        });
        return optionList || [];
    };

    onChangeIsPrimary = (item) => {
        let { record } = this.props;
        item[PROPS_IS_LOCATION_PRIMARY] = !item[PROPS_IS_LOCATION_PRIMARY] ? true : false;
        if (item[PROPS_IS_LOCATION_PRIMARY]) {
            record.forEach((x) => {
                if (x.orgLevelGuid !== item.orgLevelGuid) {
                    x[PROPS_IS_LOCATION_PRIMARY] = false;
                }
            });
        }
        this.props.onPrimaryLocationChange(record);
    };

    onChange = (item, isAdd) => {
        if (item == null) {
            return;
        }
        const { record, itemList } = this.props;
        const value = item[PROPS_ORG_LEVEL_GUID];
        let selectedValues = [...record];
        let selectedOrgLevelIndex = -1;
        let selectedOrgLevelIndexArr = [];
        const isAlreadyInList = selectedValues.some((orgLevel, index) => {
            if (orgLevel[PROPS_ORG_LEVEL_GUID] === value) {
                selectedOrgLevelIndex = index;
            }
            return orgLevel[PROPS_ORG_LEVEL_GUID] === value;
        });
        const removedOrgLevel = selectedValues[selectedOrgLevelIndex];
        // If a location was selected as primary, then deleted, just make the first location
        // in the list primary in it's place
        if (removedOrgLevel && removedOrgLevel[PROPS_IS_LOCATION_PRIMARY] && record.length > 1) {
            record.filter((x) => x.orgLevelGuid != removedOrgLevel.orgLevelGuid)[0][
                PROPS_IS_LOCATION_PRIMARY
            ] = true;
        }
        selectedValues.forEach((orgLevel, index) => {
            let modifiedOrgLevel = orgLevel;
            if (!orgLevel[PROPS_ORG_LEVEL_PARENT_GUIDS]) {
                modifiedOrgLevel = itemList.find(
                    ({ orgLevelGuid }) => orgLevelGuid === orgLevel[PROPS_ORG_LEVEL_GUID]
                );
            }
            // Remove the existing locations if - new location is child or parent of the existing location
            // Ignores the check, if the current user doesn't have access to the exisiting location
            if (
                isAdd &&
                modifiedOrgLevel &&
                (!modifiedOrgLevel[PROPS_ORG_LEVEL_PARENT_GUIDS].length || // Removes highest orgLevel
                    modifiedOrgLevel[PROPS_ORG_LEVEL_PARENT_GUIDS].some(
                        (guid) => guid === item[PROPS_ORG_LEVEL_GUID]
                    ))
            ) {
                // Removes child when parent is selected
                selectedOrgLevelIndexArr.push(index);
            }
        });
        const newOrgLevel = itemList.find((orgLevel) => {
            return orgLevel[PROPS_ORG_LEVEL_GUID] === value;
        });
        if (isAdd && newOrgLevel[PROPS_ORG_LEVEL_PARENT_GUIDS].length === 0) {
            //if the highest orglevel is selected, it should be the only value in the list
            selectedValues = [newOrgLevel];
        } else if (isAdd && selectedOrgLevelIndexArr.length) {
            selectedValues = selectedValues.filter(
                (items, index) => !selectedOrgLevelIndexArr.includes(index)
            );
            selectedValues.push(newOrgLevel);
        } else if (isAdd && !isAlreadyInList) {
            selectedValues.push(newOrgLevel);
        } else {
            selectedValues.splice(selectedOrgLevelIndex, 1);
        }
        selectedValues = this.prepareRecords(selectedValues);
        this.props.onSelectionChange(selectedValues, removedOrgLevel);
    };

    printIsPrimary = (value, record) => {
        return (
            <Checkbox
                key={record.orgLevelGuid}
                className="agvance-checkbox"
                value={record[PROPS_IS_LOCATION_PRIMARY]}
                onChange={() => {
                    if (!record[PROPS_IS_LOCATION_PRIMARY]) {
                        this.setState({ showPrimaryChangeDialogBox: true });
                        this.setState({ newPrimaryLocation: record });
                    }
                }}
            />
        );
    };

    render() {
        const {
            apiErrors,
            classNames,
            deleteConfirmation,
            disabled,
            itemList,
            record,
            required,
            isUser,
        } = this.props;
        const { formatMessage } = this.props.intl;
        const { currentOrgLevelList, selectedValue, showPrimaryChangeDialogBox } = this.state;
        const { onChange } = this;
        return (
            <div className={"form-section-child-stretch mini-grid auto-search-list-container"}>
                <DialogBox
                    footerType={DialogBoxFooterType.YES_NO}
                    isOpen={showPrimaryChangeDialogBox}
                    onAction={() => {
                        this.onChangeIsPrimary(this.state.newPrimaryLocation);
                        this.setState({
                            showPrimaryChangeDialogBox: false,
                            newPrimaryLocation: null,
                        });
                    }}
                    onClose={() => this.setState({ showPrimaryChangeDialogBox: false })}
                    title={formatMessage(messages.primaryLocationChangeTitle)}
                >
                    {formatMessage(messages.primaryLocationChangeText)}
                </DialogBox>
                {currentOrgLevelList.length === 0 || isUser ? null : (
                    <OrgLevelSearch
                        disabled={disabled}
                        classNames={classNames}
                        apiErrors={apiErrors}
                        clearOnSelection
                        containerClassName={"org-level-search-container"}
                        itemList={currentOrgLevelList}
                        onSelection={(value) => this.onChange(value, true)}
                        placeholderText={formatMessage(messages.addLocation)}
                        required={required}
                        selectedValue={selectedValue}
                    />
                )}
                {!(record && record.length > 0) ? null : (
                    <ZeroToInfiniteGrid
                        disabled={disabled}
                        records={this.prepareAffiliatedRecords(record)}
                        columns={{
                            [PROPS_ORG_LEVEL_NAME]: {
                                title: formatMessage(messages.name),
                                className: "org-level-name",
                            },
                            [PROPS_ORG_LEVEL_CITY]: {
                                title: formatMessage(messages.city),
                                className: "org-level-city",
                            },
                            [this.props.statePropName]: {
                                title: formatMessage(messages.state),
                                className: "org-level-state",
                            },
                            ...(record.length > 1 &&
                                this.props.isForCustomerScreen && {
                                    [this.props.isPrimary]: {
                                        title: formatMessage(messages.primary),
                                        className: "org-level-primary",
                                        printer: this.printIsPrimary,
                                    },
                                }),
                        }}
                        className="org-level-grid"
                        onDelete={
                            itemList.length === 1 || isUser
                                ? false
                                : deleteConfirmation
                                ? (option) => deleteConfirmation(option, onChange)
                                : (option) => onChange(option, false)
                        }
                    />
                )}
            </div>
        );
    }
}

export const OrgLevelList = injectIntl(OrgLevelList_);
