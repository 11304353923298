import { defineMessages } from "react-intl";

export const messages = defineMessages({
    abbreviation: {
        id: "agBytes.abbreviation",
        defaultMessage: "Abbreviation",
    },
    active: { id: "agBytes.active", defaultMessage: "Active" },
    activeIngredient: {
        id: "agBytes.activeIngredient",
        defaultMessage: "Active Ingredient",
    },
    activeIngredientName: {
        id: "agBytes.activeIngredientName",
        defaultMessage: "Active Ingredient Name",
    },
    activeIngredientPercentage: {
        id: "agBytes.activeIngredientPercentage",
        defaultMessage: "Active Ingredient Percentage",
    },
    activity: { id: "agBytes.activity", defaultMessage: "Activity" },
    addLocation: { id: "agBytes.addLocation", defaultMessage: "Add Location" },
    agBytesValue: {
        id: "agBytes.agBytesValue",
        defaultMessage: "AgBytes Value",
    },
    agGatewayCompanyID: {
        id: "agBytes.agGatewayCompanyID",
        defaultMessage: "AgGateway Company ID",
    },
    agGatewayID: { id: "agBytes.agGatewayID", defaultMessage: "AgGateway ID" },
    agGatewayPackageId: {
        id: "agBytes.agGatewayPackageId",
        defaultMessage: "AgGateway Package ID",
    },
    agXID: { id: "agBytes.agXID", defaultMessage: "AgX ID" },
    agiisConversion: {
        id: "importExportHeader.agiisConversion",
        defaultMessage: "Agiis Conversion",
    },
    agrianConversion: {
        id: "importExportHeader.agrianConversion",
        defaultMessage: "Agrian Conversion",
    },
    agrianID: { id: "agBytes.agrianID", defaultMessage: "Agrian ID" },
    agrianIdHeader: { id: "agBytes.agrianIdHeader", defaultMessage: "ID" },
    agrianPackageId: {
        id: "agBytes.agrianPackageId",
        defaultMessage: "Agrian Package ID",
    },
    alias: { id: "agBytes.alias", defaultMessage: "Alias" },
    aliasHeader: { id: "agBytes.aliasHeader", defaultMessage: "Alias" },
    aliasName: { id: "agBytes.aliasName", defaultMessage: "Alias Name" },
    allowNegatives: {
        id: "agBytes.allowNegatives",
        defaultMessage: "Allow Negatives",
    },
    angleOfRepose: {
        id: "agBytes.angleOfRepose",
        defaultMessage: "Angle Of Repose ({unit})",
    },
    applicationMethod: {
        id: "agBytes.applicationMethod",
        defaultMessage: "Application Method",
    },
    applicationTiming: {
        id: "agBytes.applicationTiming",
        defaultMessage: "Application Timing",
    },
    attributeCategory: {
        id: "agBytes.attributeCategory",
        defaultMessage: "Attribute Category",
    },
    attributeName: {
        id: "agBytes.attributeName",
        defaultMessage: "Attribute Name",
    },
    attributeValue: {
        id: "agBytes.attributeValue",
        defaultMessage: "Attribute Value",
    },
    backOfficeCode: {
        id: "agBytes.backOfficeCode",
        defaultMessage: "Back Office Code",
    },
    brand: { id: "agBytes.brand", defaultMessage: "Brand" },
    brandOrganization: {
        id: "agBytes.brandOrganization",
        defaultMessage: "Brand Organization",
    },
    bufferDistanceInformation: {
        id: "agBytes.bufferDistanceInformation",
        defaultMessage: "Buffer Distance Information",
    },
    buildFactor: { id: "agBytes.buildFactor", defaultMessage: "Build Factor" },
    canDelete: { id: "agBytes.canDelete", defaultMessage: "Can Delete" },
    category: { id: "agBytes.category", defaultMessage: "Category" },
    cecMax: { id: "agBytes.cecMax", defaultMessage: "CEC Max" },
    cecMin: { id: "agBytes.cecMin", defaultMessage: "CEC Min" },
    chemicalFamily: {
        id: "agBytes.chemicalFamily",
        defaultMessage: "Chemical Family",
    },
    chemicalFertilizerInfo: {
        id: "agBytes.chemicalFertilizerInfo",
        defaultMessage: "Chemical/Fertilizer Information",
    },
    chillHoursHigh: {
        id: "agBytes.chillHoursHigh",
        defaultMessage: "Chill Hours High",
    },
    chillHoursLow: {
        id: "agBytes.chillHoursLow",
        defaultMessage: "Chill Hours Low",
    },
    citation: { id: "agBytes.citation", defaultMessage: "Citation" },
    city: { id: "agBytes.city", defaultMessage: "City" },
    class: { id: "agBytes.class", defaultMessage: "Class" },
    classSize: { id: "agBytes.classSize", defaultMessage: "Class Size" },
    commodity: { id: "agBytes.commodity", defaultMessage: "Commodity" },
    commodityType: { id: "agBytes.commodityType", defaultMessage: "Commodity Type" },
    confirmTitle: { id: "agBytes.confirmTitle", defaultMessage: "Confirm" },
    containerInfo: {
        id: "agBytes.containerInfo",
        defaultMessage: "Container Info",
    },
    containerSize: {
        id: "agBytes.containerSize",
        defaultMessage: "Container Size",
    },
    containerSizeUnit: {
        id: "agBytes.containerSizeUnit",
        defaultMessage: "Container Size Unit",
    },
    conversionFactor: {
        id: "agBytes.conversionFactor",
        defaultMessage: "Conversion Factor",
    },
    countryName: { id: "agBytes.countryName", defaultMessage: "Country Name" },
    critical: { id: "agBytes.critical", defaultMessage: "Critical" },
    crop: { id: "agBytes.crop", defaultMessage: "Crop" },
    cropClassId: { id: "agBytes.cropClassId", defaultMessage: "Crop Class ID" },
    cropClassIdName: {
        id: "agBytes.cropClassIdName",
        defaultMessage: "Crop Class ID/Name",
    },
    cropClassName: {
        id: "agBytes.cropClassName",
        defaultMessage: "Crop Class Name",
    },
    cropGroup: { id: "agBytes.cropGroup", defaultMessage: "Crop Group" },
    cropId: { id: "agBytes.cropId", defaultMessage: "Crop ID" },
    cropIdName: { id: "agBytes.cropIdName", defaultMessage: "Crop ID/Name" },
    cropLogHistory: {
        id: "agBytes.cropLogHistory",
        defaultMessage: "Crop Log History",
    },
    cropName: { id: "agBytes.cropName", defaultMessage: "Crop Name" },
    cropNameAlias: {
        id: "agBytes.cropNameAlias",
        defaultMessage: "Crop Name Alias",
    },
    cropPhotos: { id: "agBytes.cropPhotos", defaultMessage: "Crop Photo(s)" },
    cropPurpose: { id: "agBytes.cropPurpose", defaultMessage: "Crop Purpose" },
    cropPurposeList: {
        id: "agBytes.cropPurposeList",
        defaultMessage: "Crop(s)/Crop Purpose(s)",
    },
    cropSubDescription: {
        id: "agBytes.cropSubDescription",
        defaultMessage: "If specific crops are not selected, all crops will be included.",
    },
    crossReference: {
        id: "agBytes.crossReference",
        defaultMessage: "Cross Reference",
    },
    cylinders: { id: "agBytes.cylinders", defaultMessage: "Cylinders" },
    date: { id: "agBytes.date", defaultMessage: "Date" },
    decimalPlaces: {
        id: "agBytes.decimalPlaces",
        defaultMessage: "Decimal Places",
    },
    defaultColor: {
        id: "agBytes.defaultColor",
        defaultMessage: "Default Color",
    },
    delete: { id: "agBytes.delete", defaultMessage: "-Delete" },
    density: { id: "agBytes.density", defaultMessage: "Density" },
    description: { id: "agBytes.description", defaultMessage: "Description" },
    details: { id: "agBytes.details", defaultMessage: "Details" },
    developer: {
        id: "agBytes.developer",
        defaultMessage: "{count, plural, one {Developer} other {Developers}}",
    },
    developerName: {
        id: "agBytes.developerName",
        defaultMessage: "Developer Name",
    },
    dimensionUnit: {
        id: "agBytes.dimensionUnit",
        defaultMessage: "Dimension Unit",
    },
    division: { id: "agBytes.division", defaultMessage: "Phylum/Division" },
    document: { id: "agBytes.document", defaultMessage: "Document" },
    documentFileName: {
        id: "agBytes.documentFileName",
        defaultMessage: "Document File Name",
    },
    documentType: {
        id: "agBytes.documentType",
        defaultMessage: "Document Type",
    },
    duplicateCommodity: {
        id: "agBytes.duplicateCommodity",
        defaultMessage: "Cannot duplicate existing Commodities",
    },
    dotShippingDescription: {
        id: "agBytes.dotShippingDescription",
        defaultMessage: "DOT Shipping Description",
    },
    drivetrain: { id: "agBytes.drivetrain", defaultMessage: "Drivetrain" },
    element: { id: "agBytes.element", defaultMessage: "Element" },
    elemental: { id: "agBytes.elemental", defaultMessage: "Elemental" },
    engineDisplacement: {
        id: "agBytes.engineDisplacement",
        defaultMessage: "Engine Displacement (L)",
    },
    equationFilters: {
        id: "agBytes.equationFilters",
        defaultMessage: "Equation Filters",
    },
    equationId: { id: "agBytes.equationId", defaultMessage: "Equation ID" },
    equationName: {
        id: "agBytes.equationName",
        defaultMessage: "Equation Name",
    },
    equationParameterDataType: {
        id: "agBytes.equationParameterDataType",
        defaultMessage: "Data Type",
    },
    equationParameterLabel: {
        id: "agBytes.equationParameterLabel",
        defaultMessage: "Equation Parameter Label",
    },
    equationParameterLogHistory: {
        id: "agBytes.equationParameterLogHistory",
        defaultMessage: "Equation Parameter Log History",
    },
    equationParameterName: {
        id: "agBytes.equationParameterNamee",
        defaultMessage: "Equation Parameter Name",
    },
    equationSetId: {
        id: "agBytes.equationSetId",
        defaultMessage: "Equation Set ID",
    },
    equationSetName: {
        id: "agBytes.equationSetName",
        defaultMessage: "Equation Set Name",
    },
    equationSetsLogHistory: {
        id: "agBytes.equationSetsLogHistory",
        defaultMessage: "Equation Set Log History",
    },
    equationValidConfirmation: {
        id: "agBytes.equationValidConfirmation",
        defaultMessage: "Equation is syntactically valid.",
    },
    equipmentId: { id: "agBytes.equipmentId", defaultMessage: "Equipment ID" },
    equipmentLogHistory: {
        id: "agBytes.equipmentLogHistory",
        defaultMessage: "Equipment Log History",
    },
    equipmentName: {
        id: "agBytes.equipmentName",
        defaultMessage: "Model Name",
    },
    equipmentParentType: {
        id: "agBytes.equipmentParentType",
        defaultMessage: "Equipment Parent Type",
    },
    equipmentPhotos: {
        id: "agBytes.equipmentPhotos",
        defaultMessage: "Equipment Photo(s)",
    },
    equipmentType: {
        id: "agBytes.equipmentType",
        defaultMessage: "Equipment Type Name",
    },
    equipmentTypeAlias: {
        id: "agBytes.equipmentTypeAlias",
        defaultMessage: "Equipment Type Alias",
    },
    equivalentWeight: {
        id: "agBytes.equivalentWeight",
        defaultMessage: "Equivalent Weight",
    },
    export: { id: "importExportHeader.export", defaultMessage: "Export" },
    exportValidation: {
        id: "importExportHeader.exportValidation",
        defaultMessage: "You must first select records.",
    },
    factorUnit: { id: "agBytes.factorUnit", defaultMessage: "Factor Unit" },
    failedToFetchEquationParameters: {
        id: "agBytes.failedToFetchEquationParameters",
        defaultMessage: "Failed to fetch equation parameters",
    },
    family: { id: "agBytes.family", defaultMessage: "Family" },
    farmMarketCropCode: {
        id: "agBytes.farmMarketCropCode",
        defaultMessage: "Farm Market Crop Code",
    },
    favorableConditions: {
        id: "agBytes.favorableConditions",
        defaultMessage: "Favorable Conditions",
    },
    fcicCropCode: {
        id: "agBytes.fcicCropCode",
        defaultMessage: "FCIC Crop Code",
    },
    federallyRestricted: {
        id: "agBytes.federallyRestricted",
        defaultMessage: "Federally Restricted",
    },
    fileName: { id: "agBytes.fileName", defaultMessage: "File Name" },
    formula: { id: "agBytes.formula", defaultMessage: "Formula" },
    formulationType: {
        id: "agBytes.formulationType",
        defaultMessage: "Formulation Type",
    },
    freezingTemperature: {
        id: "agBytes.freezingTemperature",
        defaultMessage: "Freezing Temperature ({unit})",
    },
    fuelCapacity: {
        id: "agBytes.fuelCapacity",
        defaultMessage: "Fuel Capacity",
    },
    fuelCapacityUnit: {
        id: "agBytes.fuelCapacityUnit",
        defaultMessage: "Fuel Capacity Unit",
    },
    fuelEfficiency: {
        id: "agBytes.fuelEfficiency",
        defaultMessage: "Fuel Efficiency",
    },
    fuelEfficiencyUnit: {
        id: "agBytes.fuelEfficiencyUnit",
        defaultMessage: "Fuel Efficiency Unit",
    },
    fuelPerformanceInfo: {
        id: "agBytes.fuelPerformanceInfo",
        defaultMessage: "Fuel/Performance Info",
    },
    fuelType: { id: "agBytes.fuelType", defaultMessage: "Fuel Type" },
    gduToMaturity: {
        id: "agBytes.gduToMaturity",
        defaultMessage: "GDU To Maturity",
    },
    geneId: { id: "agBytes.geneId", defaultMessage: "Gene ID" },
    geneLogHistory: {
        id: "agBytes.geneLogHistory",
        defaultMessage: "Gene Log History",
    },
    geneName: { id: "agBytes.geneName", defaultMessage: "Gene Name" },
    geneticallyModified: {
        id: "agBytes.geneticallyModified",
        defaultMessage: "Genetically Modified",
    },
    genus: { id: "agBytes.genus", defaultMessage: "Genus" },
    gmo: { id: "agBytes.gmo", defaultMessage: "GMO" },
    grazingRestriction: {
        id: "agBytes.grazingRestriction",
        defaultMessage: "Grazing Restrictions",
    },
    groupName: { id: "agBytes.groupName", defaultMessage: "Group Name" },
    growingDegreeDaysHigh: {
        id: "agBytes.growingDegreeDaysHigh",
        defaultMessage: "GDD High",
    },
    growingDegreeDaysHighLabel: {
        id: "agBytes.growingDegreeDaysHighLabel",
        defaultMessage: "Growing Degree Days High",
    },
    growingDegreeDaysLow: {
        id: "agBytes.growingDegreeDaysLow",
        defaultMessage: "GDD Low",
    },
    growingDegreeDaysLowLabel: {
        id: "agBytes.growingDegreeDaysLowLabel",
        defaultMessage: "Growing Degree Days Low",
    },
    growthStageGroupID: {
        id: "agBytes.growthStageGroupID",
        defaultMessage: "Growth Stage Group ID",
    },
    growthStageGroupIDName: {
        id: "agBytes.growthStageGroupIDName",
        defaultMessage: "Growth Stage Group ID/Name",
    },
    growthStageGroupName: {
        id: "agBytes.growthStageGroupName",
        defaultMessage: "Growth Stage Group Name",
    },
    growthStageGroupType: {
        id: "agBytes.growthStageGroupType",
        defaultMessage: "Growth Stage Group Type",
    },
    growthStageOrderDescription: {
        id: "agBytes.growthStageOrderDescription",
        defaultMessage: "Growth Stage Order Description",
    },
    growthStageOrderID: {
        id: "agBytes.growthStageOrderID",
        defaultMessage: "Growth Stage Order ID",
    },
    growthStageOrderIdName: {
        id: "agBytes.growthStageOrderIdName",
        defaultMessage: "Growth Stage Order ID/Name",
    },
    growthStageOrderName: {
        id: "agBytes.growthStageOrderName",
        defaultMessage: "Growth Stage Order Name",
    },
    growthStagesLogHistory: {
        id: "agBytes.growthStagesLogHistory",
        defaultMessage: "Growth Stages Log History",
    },
    gtin: { id: "agBytes.gtin", defaultMessage: "GTIN" },
    height: { id: "agBytes.height", defaultMessage: "Height" },
    herbicideTolerance: { id: "agBytes.HerbicideTolerance", defaultMessage: "Herbicide Tolerance" },
    hideInactive: {
        id: "importExportHeader.hideInactive",
        defaultMessage: "Hide Inactive Records",
    },
    high: { id: "agBytes.high", defaultMessage: "Optimal" },
    horsePower: { id: "agBytes.horsePower", defaultMessage: "Horsepower" },
    horsePowerUnit: {
        id: "agBytes.horsePowerUnit",
        defaultMessage: "Horsepower Unit",
    },
    irrigationCategory: { id: "agBytes.irrigationCategory", defaultMessage: "Irrigation Category" },
    import: { id: "importExportHeader.import", defaultMessage: "Import" },
    importAttribute: {
        id: "agBytes.importAttribute",
        defaultMessage: "Import Attribute(s)",
    },
    incorporationDepth: {
        id: "agBytes.incorporationDepth",
        defaultMessage: "Incorporation Depth",
    },
    intendedUse: { id: "agBytes.intendedUse", defaultMessage: "Intended Use" },
    intendedUseCode: { id: "agBytes.intendedUseCode", defaultMessage: "Intended Use Code Name" },
    invalidFileType: {
        id: "agBytes.invalidFileType",
        defaultMessage: "Invalid File Type",
    },
    isActive: { id: "agBytes.isActive", defaultMessage: "Is Active" },
    kingdom: { id: "agBytes.kingdom", defaultMessage: "Kingdom" },
    length: { id: "agBytes.length", defaultMessage: "Length" },
    lifespan: { id: "agBytes.lifespan", defaultMessage: "Lifespan" },
    logic: { id: "agBytes.logic", defaultMessage: "Logic" },
    logicType: { id: "agBytes.logicType", defaultMessage: "Logic Type" },
    longDescription: {
        id: "agBytes.longDescription",
        defaultMessage: "Long Description",
    },
    low: { id: "agBytes.low", defaultMessage: "Low" },
    manufacturer: {
        id: "agBytes.manufacturer",
        defaultMessage: "Manufacturer",
    },
    manufacturerName: {
        id: "agBytes.manufacturerName",
        defaultMessage: "Manufacturer Name",
    },
    manufacturerPartNumber: {
        id: "agBytes.manufacturerPartNumber",
        defaultMessage: "Manufacturer Part Number",
    },
    maxGrossWeight: {
        id: "agBytes.maxGrossWeight",
        defaultMessage: "Max Gross Weight",
    },
    maxSpeed: { id: "agBytes.maxSpeed", defaultMessage: "Max Speed" },
    maxSpeedUnit: {
        id: "agBytes.maxSpeedUnit",
        defaultMessage: "Max Speed Unit",
    },
    medicalPhoneNumber: {
        id: "agBytes.medicalPhoneNumber",
        defaultMessage: "Medical Phone Number",
    },
    medium: { id: "agBytes.medium", defaultMessage: "Medium" },
    miscellaneousInfo: {
        id: "agBytes.miscellaneousInfo",
        defaultMessage: "Miscellaneous Info",
    },
    modeOfAction: {
        id: "agBytes.modeOfAction",
        defaultMessage: "Mode Of Action",
    },
    modelName: { id: "agBytes.modelName", defaultMessage: "Model Name" },
    modifiedDate: {
        id: "agBytes.modifiedDate",
        defaultMessage: "Last Modified",
    },
    morphologyType: {
        id: "agBytes.morphologyType",
        defaultMessage: "Morphology Type",
    },
    myAgData: { id: "agBytes.myAgData", defaultMessage: "MyAgData" },
    name: { id: "agBytes.name", defaultMessage: "Name" },
    ncdaFactor: { id: "agBytes.ncdaFactor", defaultMessage: "NCDA Factor" },
    nextCrop: { id: "agBytes.nextCrop", defaultMessage: "Next Crop" },
    noResultsMatch: {
        id: "agBytes.noResultsMatch",
        defaultMessage: 'No Results Match "{value}"',
    },
    notes: { id: "agBytes.notes", defaultMessage: "Notes" },
    numericInputTitle: {
        id: "agBytes.numericInputTitle",
        defaultMessage:
            "Limited to {whole} whole {whole, plural, one {number} other {numbers}} and {decimal} decimal {decimal, plural, one {place} other {places}}.",
    },
    nutrient: { id: "agBytes.nutrient", defaultMessage: "Nutrient" },
    nutrientId: { id: "agBytes.nutrientId", defaultMessage: "Nutrient ID" },
    nutrientIdName: {
        id: "agBytes.nutrientIdName",
        defaultMessage: "Nutrient ID/Name",
    },
    nutrientName: {
        id: "agBytes.nutrientName",
        defaultMessage: "Nutrient Name",
    },
    nutrientPercent: {
        id: "agBytes.nutrientPercent",
        defaultMessage: "Nutrient Percent",
    },
    nutrientRemovalRateId: {
        id: "agBytes.nutrientRemovalRateId",
        defaultMessage: "Nutrient Removal ID",
    },
    nutrientRemovalRateLogHistory: {
        id: "agBytes.nutrientRemovalRateLogHistory",
        defaultMessage: "Nutrient Removal Log History",
    },
    nutrientTargetValue: {
        id: "agBytes.nutrientTargetValue",
        defaultMessage: "Target Value",
    },
    nutrientTargetValueId: {
        id: "agBytes.nutrientTargetValueId",
        defaultMessage: "Target Value ID",
    },
    nutrientTargetValueLogHistory: {
        id: "agBytes.nutrientTargetValueLogHistory",
        defaultMessage: "Nutrient Targets Log History",
    },
    observationId: {
        id: "agBytes.observationId",
        defaultMessage: "Observation ID",
    },
    observationLogHistory: {
        id: "agBytes.observationLogHistory",
        defaultMessage: "Observation Log History",
    },
    observationName: {
        id: "agBytes.observationName",
        defaultMessage: "Observation Name",
    },
    observationNameAlias: {
        id: "agBytes.observationNameAlias",
        defaultMessage: "Observation Name Alias",
    },
    observationParentName: {
        id: "agBytes.observationParentName",
        defaultMessage: "Observation Parent Name",
    },
    observationParentType: {
        id: "agBytes.observationParentType",
        defaultMessage: "Observation Parent Type",
    },
    observationSymptom: {
        id: "agBytes.observationSymptom",
        defaultMessage: "Description/Symptoms",
    },
    observationType: {
        id: "agBytes.observationType",
        defaultMessage: "Observation Type",
    },
    optionalMoistureAtHarvest: {
        id: "agBytes.optionalMoistureAtHarvest",
        defaultMessage: "Optional Moisture At Harvest(%)",
    },
    order: { id: "agBytes.order", defaultMessage: "Order" },
    orgLevel: { id: "agBytes.orgLevel", defaultMessage: "Location" },
    orgLevelFilter: {
        id: "agBytes.orgLevelFilter",
        defaultMessage: "Location Filter",
    },
    orgLevelList: {
        id: "agBytes.orgLevelList",
        defaultMessage: "Location Affiliations",
    },
    orgLevelName: {
        id: "agBytes.orgLevelName",
        defaultMessage: "Location Name",
    },
    organismType: {
        id: "agBytes.organismType",
        defaultMessage: "Organism Type",
    },
    oxide: { id: "agBytes.oxide", defaultMessage: "Oxide" },
    oxideBasis: { id: "agBytes.OxideBasis", defaultMessage: "Oxide Basis" },
    pH: { id: "agBytes.pH", defaultMessage: "pH" },
    packageGtin: { id: "agBytes.packageGtin", defaultMessage: "Package GTIN" },
    packageSize: { id: "agBytes.packageSize", defaultMessage: "Package Size" },
    packageUnit: { id: "agBytes.packageUnit", defaultMessage: "Package Unit" },
    packageUnitName: {
        id: "agBytes.packageUnitName",
        defaultMessage: "Package Unit Name",
    },
    parameter: { id: "agBytes.parameter", defaultMessage: "Parameter(s)" },
    parameterLabel: {
        id: "agBytes.parameterLabel",
        defaultMessage: "Equation Parameter Label",
    },
    parameterName: {
        id: "agBytes.parameterName",
        defaultMessage: "Equation Parameter Name",
    },
    parameterValue: {
        id: "agBytes.parameterValue",
        defaultMessage: "Parameter Value",
    },
    parentPicklistId: {
        id: "agBytes.parentPicklistId",
        defaultMessage: "Parent Picklist ID",
    },
    parentValue: { id: "agBytes.parentValue", defaultMessage: "Parent Value" },
    percentage: { id: "agBytes.percentage", defaultMessage: "Percentage" },
    pestInjurySymptoms: {
        id: "agBytes.pestInjurySymptoms",
        defaultMessage: "Pest Injury Symptoms",
    },
    phoneNumbers: {
        id: "agBytes.phoneNumbers",
        defaultMessage: "Phone Numbers",
    },
    photo: { id: "agBytes.photo", defaultMessage: "Photo" },
    photoPreview: { id: "agBytes.photoPreview", defaultMessage: "Preview" },
    photoTitle: { id: "agBytes.photoTitle", defaultMessage: "Photo Title" },
    photoUrl: { id: "agBytes.photoUrl", defaultMessage: "Photo Url" },
    physicalState: {
        id: "agBytes.physicalState",
        defaultMessage: "Physical State",
    },
    picklistId: { id: "agBytes.picklistId", defaultMessage: "Picklist ID" },
    picklistLogHistory: {
        id: "agBytes.picklistLogHistory",
        defaultMessage: "Picklist Log History",
    },
    picklistName: {
        id: "agBytes.picklistName",
        defaultMessage: "Picklist Name",
    },
    picklistParentValue: {
        id: "agBytes.picklistParentValue",
        defaultMessage: "Picklist Parent Value",
    },
    picklistValue: {
        id: "agBytes.picklistValue",
        defaultMessage: "Picklist Value",
    },
    picklistValueID: {
        id: "agBytes.picklistValueID",
        defaultMessage: "Picklist Value ID",
    },
    plantBackRestriction: {
        id: "importExportHeader.plantBackRestriction",
        defaultMessage: "Plant Back Restrictions",
    },
    plantHarvestInfo: {
        id: "agBytes.plantHarvestInfo",
        defaultMessage: "Plant/Harvest Info",
    },
    plantingTemperatureMinimum: {
        id: "agBytes.plantingTemperatureMinimum",
        defaultMessage: "Planting Temperature Minimum (F)",
    },
    plantPart: { id: "agBytes.plantPart", defaultMessage: "Plant Part(s)" },
    ppeInformation: {
        id: "agBytes.ppeInformation",
        defaultMessage: "PPE Information",
    },
    precision: { id: "agBytes.precision", defaultMessage: "Precision" },
    previousCrop: {
        id: "agBytes.previousCrop",
        defaultMessage: "Previous Crop",
    },
    primary: { id: "agBytes.primary", defaultMessage: "Primary" },
    primaryLocationChangeTitle: {
        id: "setup.primaryLocationChangeTitle",
        defaultMessage: "Primary Location Change",
    },
    primaryLocationChangeText: {
        id: "setup.primaryLocationChangeText",
        defaultMessage: "Are you sure you want to change the Primary Location?",
    },
    product: { id: "importExportHeader.product", defaultMessage: "Product" },
    productActionMethod: {
        id: "agBytes.productActionMethod",
        defaultMessage: "Product Action Method",
    },
    productId: { id: "agBytes.productId", defaultMessage: "Product ID" },
    productIdPlainText: {
        id: "agBytes.productIdPlainText",
        defaultMessage: "Product ID: ",
    },
    productLogHistory: {
        id: "agBytes.productLogHistory",
        defaultMessage: "Product Log History",
    },
    productLogo: { id: "agBytes.productLogo", defaultMessage: "Product Logo" },
    productName: { id: "agBytes.productName", defaultMessage: "Product Name" },
    productNameAlias: {
        id: "agBytes.productNameAlias",
        defaultMessage: "Product Name Alias",
    },
    productNamePlainText: {
        id: "agBytes.productNamePlainText",
        defaultMessage: "Product Name: {productName}",
    },
    productPackage: {
        id: "importExportHeader.productPackage",
        defaultMessage: "Product Package",
    },
    productPackageGtin: {
        id: "agBytes.productPackageGtin",
        defaultMessage: "Product Package GTIN",
    },
    productPackageId: {
        id: "agBytes.productPackageId",
        defaultMessage: "Product Package ID",
    },
    productParentTypeName: {
        id: "agBytes.productParentTypeName",
        defaultMessage: "Product Parent Type",
    },
    productType: {
        id: "agBytes.productType",
        defaultMessage: "{count, plural, one {Product Type} other {Product Types}}",
    },
    productTypeList: {
        id: "agBytes.productTypeList",
        defaultMessage: "Product Types",
    },
    productsHeader: { id: "agBytes.product", defaultMessage: "Product(s)" },
    properties: { id: "agBytes.properties", defaultMessage: "Properties" },
    rate: { id: "agBytes.rate", defaultMessage: "Rate" },
    rateUnit: { id: "agBytes.rateUnit", defaultMessage: "Rate Unit" },
    recommendedImageSize: {
        id: "agBytes.recommendedImageSize",
        defaultMessage: "Recommended Image Size: 337px x 76px",
    },
    reEntryIntervalHoursHigh: {
        id: "agBytes.reEntryIntervalHoursHigh",
        defaultMessage: "Re-Entry Interval Hours High",
    },
    reEntryIntervalHoursLow: {
        id: "agBytes.reEntryIntervalHoursLow",
        defaultMessage: "Re-Entry Interval Hours Low",
    },
    recOptions: { id: "agBytes.recOptions", defaultMessage: "Rec Options" },
    recOutput: { id: "agBytes.recOutput", defaultMessage: "Rec Output" },
    refugeDetails: {
        id: "agBytes.refugeDetails",
        defaultMessage: "Refuge Details",
    },
    refugeRequirements: {
        id: "agBytes.refugeRequirements",
        defaultMessage: "Refuge Requirements",
    },
    registrationCode: {
        id: "agBytes.registrationCode",
        defaultMessage: "Registration Code",
    },
    regulatoryInfo: {
        id: "agBytes.regulatoryInfo",
        defaultMessage: "Regulatory Information",
    },
    relativeMaturity: {
        id: "agBytes.relativeMaturity",
        defaultMessage: "Relative Maturity",
    },
    reppeInformation: {
        id: "agBytes.reppeInformation",
        defaultMessage: "Re PPE Information",
    },
    responsePhoneNumber: {
        id: "agBytes.responsePhoneNumber",
        defaultMessage: "Response Phone Number",
    },
    saltingOutTemperature: {
        id: "agBytes.saltingOutTemperature",
        defaultMessage: "Salting Out Temperature ({unit})",
    },
    sampleAttributeDescription: {
        id: "agBytes.sampleAttributeDescription",
        defaultMessage: "Attribute Description",
    },
    sampleAttributeSampleTypes: {
        id: "agBytes.sampleAttributeSampleTypes",
        defaultMessage: "Layer Type",
    },
    sampleTypeName: {
        id: "agBytes.sampleTypeName",
        defaultMessage: "Sample Type",
    },
    samplingEventRequired: {
        id: "agBytes.samplingEventRequired",
        defaultMessage: "Soil Sampling Event Required",
    },
    scientificName: {
        id: "agBytes.scientificName",
        defaultMessage: "Scientific Name",
    },
    searchForVendor: {
        id: "agBytes.searchForVendor",
        defaultMessage: "Search For Vendor",
    },
    searchVarietyHybrid: {
        id: "agBytes.searchVarietyHybrid",
        defaultMessage: "Search For Variety/Hybrid",
    },
    seedCount: { id: "agBytes.seedCount", defaultMessage: "Seed Count" },
    seedCountUnit: {
        id: "agBytes.seedCountUnit",
        defaultMessage: "Seed Count Unit",
    },
    seedInformation: {
        id: "agBytes.seedInformation",
        defaultMessage: "Seed Information",
    },
    selectValidIcoFile: {
        id: "agBytes.selectValidIcoFile",
        defaultMessage: "The Browser Tab Icon file must be an .ico or .png.",
    },
    seedSize: { id: "agBytes.seedSize", defaultMessage: "Seed Size" },
    severe: { id: "agBytes.severe", defaultMessage: "Severe" },
    shelfLife: {
        id: "agBytes.shelfLife",
        defaultMessage: "Shelf Life (Years)",
    },
    showInactive: {
        id: "importExportHeader.showInactive",
        defaultMessage: "Show Inactive Records",
    },
    sizeInfo: { id: "agBytes.sizeInfo", defaultMessage: "Size Info" },
    soilTexture: { id: "agBytes.soilTexture", defaultMessage: "Soil Texture" },
    solubility: { id: "agBytes.solubility", defaultMessage: "Solubility" },
    specialInstructions: {
        id: "agBytes.specialInstructions",
        defaultMessage: "Special Instructions",
    },
    specificGravity: {
        id: "agBytes.specificGravity",
        defaultMessage: "Specific Gravity",
    },
    startConversion: {
        id: "agBytes.startConversion",
        defaultMessage: "Start Conversion",
    },
    state: { id: "agBytes.state", defaultMessage: "State" },
    stateAbbreviation: {
        id: "agBytes.stateAbbreviation",
        defaultMessage: "State Abbreviation",
    },
    stateId: { id: "agBytes.stateId", defaultMessage: "State ID" },
    stateName: { id: "agBytes.stateName", defaultMessage: "State Name" },
    systemAttributeName: {
        id: "agBytes.systemAttributeName",
        defaultMessage: "System Attribute",
    },
    systemUnit: { id: "agBytes.systemUnit", defaultMessage: "System Unit" },
    tareWeight: { id: "agBytes.tareWeight", defaultMessage: "Tare Weight" },
    targetPh: { id: "agBytes.targetPh", defaultMessage: "Target pH" },
    taxonomicInfo: {
        id: "agBytes.taxonomicInfo",
        defaultMessage: "Taxonomic Info",
    },
    testType: { id: "agBytes.testType", defaultMessage: "Lab Test Type" },
    testValue: { id: "agBytes.testValue", defaultMessage: "Test Value" },
    testWeight: {
        id: "agBytes.testWeight",
        defaultMessage: "Test Weight (lb/bu)",
    },
    tillageMethod: {
        id: "agBytes.tillageMethod",
        defaultMessage: "Tillage Method",
    },
    tissueGrowthStageGroupName: {
        id: "agBytes.tissueGrowthStageGroupName",
        defaultMessage: "Tissue Growth Stage Group ID/Name",
    },
    towingCapacity: {
        id: "agBytes.towingCapacity",
        defaultMessage: "Towing Capacity",
    },
    towingCapacityUnit: {
        id: "agBytes.towingCapacityUnit",
        defaultMessage: "Towing Capacity Unit",
    },
    towingInfo: { id: "agBytes.towingInfo", defaultMessage: "Towing Info" },
    toxic: { id: "agBytes.toxic", defaultMessage: "Toxic" },
    traitAbbreviation: {
        id: "agBytes.traitAbbreviation",
        defaultMessage: "Trait Abbreviation",
    },
    traitCharacteristic: {
        id: "agBytes.traitCharacteristic",
        defaultMessage: "{count, plural, one {Trait Characteristic} other {Trait Characteristics}}",
    },
    traitCharacteristicName: {
        id: "agBytes.traitCharacteristicName",
        defaultMessage: "Trait Characteristic Name",
    },
    traitEvent: {
        id: "agBytes.traitEvent",
        defaultMessage: "{count, plural, one {Trait Event} other {Trait Events}}",
    },
    traitGene: {
        id: "agBytes.traitGene",
        defaultMessage: "{count, plural, one {Trait Gene} other {Trait Genes}}",
    },
    traitId: { id: "agBytes.traitId", defaultMessage: "Trait ID" },
    traitIdName: { id: "agBytes.traitIdName", defaultMessage: "Trait ID/Name" },
    traitLogHistory: {
        id: "agBytes.traitLogHistory",
        defaultMessage: "Trait Log History",
    },
    traitName: { id: "agBytes.traitName", defaultMessage: "Trait Name" },
    traitSpecific: {
        id: "agBytes.traitSpecific",
        defaultMessage: "{count, plural, one {Trait Specific} other {Trait Specifics}}",
    },
    traitSpecificName: {
        id: "agBytes.traitSpecificName",
        defaultMessage: "Trait Specific Name",
    },
    transmissionType: {
        id: "agBytes.transmissionType",
        defaultMessage: "Transmission Type",
    },
    treatment: { id: "agBytes.treatment", defaultMessage: "Treatment" },
    type: { id: "agBytes.type", defaultMessage: "Type" },
    unitId: { id: "agBytes.unitId", defaultMessage: "Unit ID" },
    unitName: { id: "agBytes.unitName", defaultMessage: "Unit Name" },
    unitSystem: { id: "agBytes.unitSystem", defaultMessage: "Unit System" },
    unitType: { id: "agBytes.unitType", defaultMessage: "Unit Type" },
    upcCode: { id: "agBytes.upcCode", defaultMessage: "UPC Code" },
    value: { id: "agBytes.value", defaultMessage: "Value" },
    varietyAttribute: {
        id: "agBytes.varietyAttribute",
        defaultMessage: "Variety Attribute",
    },
    varietyAttributeName: {
        id: "agBytes.varietyAttributeName",
        defaultMessage: "Variety Attribute Name",
    },
    varietyHybridAttributeLogHistory: {
        id: "agBytes.varietyHybridAttributeLogHistory",
        defaultMessage: "Variety/Hybrid Attribute Log History",
    },
    varietyHybridId: {
        id: "agBytes.varietyHybridId",
        defaultMessage: "Variety/Hybrid ID",
    },
    varietyHybridLogHistory: {
        id: "agBytes.varietyHybridLogHistory",
        defaultMessage: "Variety/Hybrid Log History",
    },
    varietyHybridName: {
        id: "agBytes.varietyHybridName",
        defaultMessage: "Variety/Hybrid Name",
    },
    vendorName: { id: "agBytes.vendorName", defaultMessage: "Vendor Name" },
    veryHigh: { id: "agBytes.veryHigh", defaultMessage: "Very High" },
    veryLow: { id: "agBytes.veryLow", defaultMessage: "Very Low" },
    viewLog: { id: "importExportHeader.viewLog", defaultMessage: "View Log" },
    waterPurpose: { id: "agBytes.waterPurpose", defaultMessage: "Water Purpose" },
    weightInfo: { id: "agBytes.weightInfo", defaultMessage: "Weight Info" },
    weightUnit: { id: "agBytes.weightUnit", defaultMessage: "Weight Unit" },
    width: { id: "agBytes.width", defaultMessage: "Width" },
});
