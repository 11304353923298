import React, { useEffect, useMemo, useState } from "react";
import { injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import { CustomerAPI } from "@ai360/core";

import {
    Checkbox,
    DialogBox,
    DialogBoxFooterType,
    NoLink,
    SelectInput,
    TextInput,
    ZeroToInfiniteGrid,
} from "~/core";

import { messages } from "./../i18n-messages";
import { createAddLinkLabelText } from "~/i18n-messages";
import { AgvanceUtils } from "~/admin/setup/customer/agvance-utils";
import { isValidEmail } from "~/login/components/recover-password";

import "./myagdata-registration-list.css";
import { formatDate } from "~/admin/utils";
import { getTheUserPersonalityId } from "~/login/selectors";

interface IProps {
    intl: intlShape;
    registrations: CustomerAPI.IMyAgDataRegistration[];
    crossRefList: CustomerAPI.ICustomerAgvanceCrossRef[];
    personalityId: number; // PersonalityTypes constant
    customerName: string;
    updateList: (newValue: CustomerAPI.IMyAgDataRegistration[]) => void;
}

interface IRegistrationDetails {
    status?: string;
    date?: string;
}

interface IDisplayRow extends CustomerAPI.IMyAgDataRegistration {
    fullName: string;
    customerName: string;
    registration: IRegistrationDetails;
}

function MyAgDataRegistrationList_(props: IProps): JSX.Element {
    const { formatMessage } = props.intl;
    const { registrations, personalityId, updateList, crossRefList } = props;

    const [showDialog, setShowDialog] = useState(false);
    const [editRow, setEditRow] = useState<number | null>(null);
    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [agvanceCustomerGuid, setAgvanceCustomerGuid] = useState<string>(null);
    const [resendEmail, setResendEmail] = useState(false);

    const isAgvanceConnected = AgvanceUtils.isAgvanceConnected(personalityId);

    const displayRecords = useMemo(
        () =>
            registrations?.map<IDisplayRow>((x) => ({
                ...x,
                fullName: `${x.firstName} ${x.lastName}`.trim(),
                registration: {
                    date: x.registrationDate,
                    status:
                        x.registrationStatus === "Completed"
                            ? "Yes"
                            : x.registrationStatus === "Incomplete"
                            ? "No"
                            : x.registrationStatus ?? "No",
                },
                customerName: x.agvanceGrowerName ?? props.customerName,
            })) ?? [],
        [registrations, props.customerName]
    );

    useEffect(() => {
        const filteredRegistrations = registrations?.filter((x) =>
            crossRefList.find((y) => y.agvanceCustomerGuid === x.agvanceCustomerGuid)
        );
        if (filteredRegistrations && filteredRegistrations?.length !== registrations?.length) {
            updateList(filteredRegistrations);
        }
    }, [crossRefList]);

    const filteredCrossRefOptions = useMemo(() => {
        if (!isAgvanceConnected) {
            return [];
        }
        const excludedAgvanceCustomerGuids = new Set(
            registrations
                ?.filter((reg, index) => index !== editRow)
                .map((reg) => reg.agvanceCustomerGuid)
        );
        return !isAgvanceConnected
            ? []
            : crossRefList
                  ?.filter((x) => !excludedAgvanceCustomerGuids.has(x.agvanceCustomerGuid))
                  .map((x) => ({
                      value: x.agvanceCustomerGuid,
                      label: x.agvanceGrowerName,
                  })) ?? [];
    }, [editRow, registrations, isAgvanceConnected, crossRefList]);

    const invalidData = useMemo(
        () =>
            !firstName ||
            !lastName ||
            !isValidEmail(email) ||
            (isAgvanceConnected && !agvanceCustomerGuid),
        [firstName, lastName, email, isAgvanceConnected, agvanceCustomerGuid]
    );

    const disableAddLink = useMemo(
        () =>
            (isAgvanceConnected && displayRecords.length === crossRefList.length) ||
            (!isAgvanceConnected && displayRecords.length > 0),
        [isAgvanceConnected, displayRecords, crossRefList]
    );

    const closeDialog = () => {
        setShowDialog(false);
        setEmail(null);
        setFirstName(null);
        setLastName(null);
        setEditRow(null);
        setAgvanceCustomerGuid(null);
    };

    return (
        <div className="form-section-child-stretch mini-grid">
            {displayRecords.length > 0 && (
                <ZeroToInfiniteGrid
                    records={displayRecords}
                    onEdit={(record: IDisplayRow, index) => {
                        setEmail(record.emailAddress);
                        setFirstName(record.firstName);
                        setLastName(record.lastName);
                        setEditRow(index);
                        setAgvanceCustomerGuid(record.agvanceCustomerGuid);
                        setShowDialog(true);
                    }}
                    onDelete={(record: IDisplayRow, index: number) => {
                        updateList([...registrations.filter((x, i) => index !== i)]);
                    }}
                    formatColumnKeys={["registration"]}
                    formatCellValue={(status: IRegistrationDetails) => (
                        <div title={status.date ? formatDate(status.date) : "Not Registered"}>
                            {status.status}
                        </div>
                    )}
                    columns={{
                        fullName: {
                            title: formatMessage(messages.name),
                        },
                        customerName: {
                            title: formatMessage(messages.customer),
                        },
                        registration: {
                            title: formatMessage(messages.registered),
                        },
                    }}
                />
            )}
            <div className="add-link-container">
                <NoLink
                    className="add-link"
                    label={createAddLinkLabelText(formatMessage, messages.registration)}
                    disabled={disableAddLink}
                    onClick={() => {
                        if (isAgvanceConnected && crossRefList.length === 1) {
                            setAgvanceCustomerGuid(crossRefList[0].agvanceCustomerGuid);
                        }
                        setShowDialog(true);
                    }}
                />
            </div>
            {showDialog && (
                <DialogBox
                    isOpen={true}
                    unrestricted={true}
                    className={"myagdata-registration-dialog"}
                    footerType={DialogBoxFooterType.ACTION_CANCEL}
                    action={formatMessage(messages.save)}
                    actionDisabled={invalidData}
                    title={`${formatMessage(
                        editRow !== null ? messages.edit : messages.add
                    )} ${formatMessage(messages.registration)}`}
                    onAction={() => {
                        const row: CustomerAPI.IMyAgDataRegistration = {
                            ...(editRow !== null
                                ? registrations[editRow]
                                : {
                                      myAgDataRegistrationGuid: null,
                                      registrationStatus: "Incomplete",
                                  }),
                            lastName,
                            firstName,
                            emailAddress: email,
                            agvanceCustomerGuid,
                            agvanceGrowerName: !isAgvanceConnected
                                ? null
                                : filteredCrossRefOptions.find(
                                      (x) => x.value === agvanceCustomerGuid
                                  )?.label,
                            resendEmail,
                        };
                        if (editRow !== null) {
                            registrations[editRow] = row;
                        } else {
                            registrations.push(row);
                        }
                        updateList([...registrations]);
                        closeDialog();
                    }}
                    onClose={closeDialog}
                >
                    {crossRefList.length > 1 && (
                        <SelectInput
                            containerClassNames={["connected-selection"]}
                            clearable={false}
                            placeholderText={formatMessage(messages.agvanceCustomer)}
                            value={agvanceCustomerGuid}
                            options={filteredCrossRefOptions}
                            onChange={(value) => setAgvanceCustomerGuid(value)}
                            required
                            disabled={
                                editRow !== null && registrations[editRow].queueStatusId !== null
                            }
                        />
                    )}
                    <TextInput
                        placeholderText={formatMessage(messages.firstName)}
                        required={true}
                        value={firstName}
                        onChange={(value) => setFirstName(value)}
                    />
                    <TextInput
                        placeholderText={formatMessage(messages.lastName)}
                        required={true}
                        value={lastName}
                        onChange={(value) => setLastName(value)}
                    />
                    <TextInput
                        placeholderText={formatMessage(messages.email)}
                        required={true}
                        value={email}
                        onChange={(value) => setEmail(value)}
                    />
                    {editRow !== null &&
                        registrations[editRow].myAgDataRegistrationGuid !== null &&
                        registrations[editRow].registrationStatus === "Incomplete" && (
                            <Checkbox
                                className="resend-email"
                                label={formatMessage(messages.myAgDataResendEmail)}
                                value={resendEmail}
                                onChange={(_, value) => setResendEmail(value)}
                            />
                        )}
                </DialogBox>
            )}
        </div>
    );
}

const mapStateToProps = (state): Partial<IProps> => ({
    personalityId: getTheUserPersonalityId(state),
});

export const MyAgDataRegistration = connect<any, any, IProps>(mapStateToProps)(
    injectIntl(MyAgDataRegistrationList_)
);
