import React, { useEffect, useRef, useState } from "react";
import EsriMap from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import GraphicsLayerView from "@arcgis/core/views/layers/GraphicsLayerView";
import "./portal-boundary-update.css";
import { messages } from "./i18n-messages";
import { DialogBox, DialogBoxFooterType, Loader, RadioButton, RadioButtonGroup } from "~/core";
import { useAppSelector } from "~/store/hooks";
import {
    getFieldInfoState,
    getIsPortalBoundarySyncModalOpen,
    getMapDetailsForPortalBoundarySyncModal,
    getJdBoundaryOverlapsForPortalBoundarySyncModal,
} from "~/action-panel/components/field-module/selectors";
import { injectIntl, intlShape } from "react-intl";
import { SimpleFillSymbol } from "@arcgis/core/symbols";
import Graphic from "@arcgis/core/Graphic";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import * as esriProjection from "@arcgis/core/geometry/projection";
import { useDispatch } from "react-redux";
import { actions as fieldActions } from "~/action-panel/components/field-module";
import * as mapToolsActions from "~/map/components/map-tools/actions";
import { Polygon, SpatialReference } from "@arcgis/core/geometry";
import { FieldAPI, Geometry, RecAPI } from "@ai360/core";
import { getTheSystemName, getUser } from "~/login/selectors";
import { MSGTYPE, actions as notificationActions } from "~/notifications";
import { getBasemap } from "~/map/components/map-control/selectors";

type OverlappingBoundary = {
    fieldUri: string;
    polygons: any[];
};

type BoundaryForAi360Update = {
    fieldUri: string;
    polygons: Polygon[];
};

function PortalBoundaryUpdate({ intl: { formatMessage } }) {
    const isOpen = useAppSelector(getIsPortalBoundarySyncModalOpen);
    const { latitude, longitude, zoomLevel, fieldPolygons } = useAppSelector(
        getMapDetailsForPortalBoundarySyncModal
    );
    const overlaps = useAppSelector(getJdBoundaryOverlapsForPortalBoundarySyncModal);
    const fieldInfo = useAppSelector(getFieldInfoState);
    const baseMap = useAppSelector(getBasemap);
    const systemName = useAppSelector(getTheSystemName);
    const fieldName = fieldInfo.fieldModel.fieldName;
    const fieldGuid = fieldInfo.fieldModel.fieldGuid;
    const farmName = !fieldInfo.fieldModel.farmName ? "" : fieldInfo.fieldModel.farmName + " ";

    const userGuid = useAppSelector(getUser).userGuid;

    const [boundaryRadioButtonValue, setBoundaryRadioButtonValue] = useState<string>("A");
    const [jdBoundaries, setJdBoundaries] = useState<BoundaryForAi360Update[]>(null);
    const [onlyOneOverlap, setOnlyOneOverlap] = useState<boolean>(false);
    const [jdBoundaryToUpdateAi360, setJdBoundaryToUpdateAi360] = useState<Geometry[]>(null);
    const [chosenFieldUri, setChosenFieldUri] = useState<string>(null);
    const [showLoader, setShowLoader] = useState<boolean>(false);

    const dispatch = useDispatch();
    const mapDiv = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (overlaps.overlaps) {
            const boundarysAndUris: BoundaryForAi360Update[] = [];
            let geometries = [];
            const geometriesForUpdatingAi360 = [];
            if (overlaps.overlaps.length === 1) {
                setChosenFieldUri(overlaps.overlaps[0].fieldUri);
            }
            overlaps.overlaps.forEach((overlappingField: OverlappingBoundary) => {
                const boundaryAndUri: BoundaryForAi360Update = { fieldUri: null, polygons: null };
                overlappingField.polygons.forEach((overlaps) => {
                    const geometry = new Polygon({
                        spatialReference: { wkid: 4326 },
                        rings: overlaps.coordinates,
                    });
                    const projected = esriProjection.project(
                        geometry,
                        new SpatialReference({ wkid: FieldAPI.spatialReference })
                    ) as Polygon;
                    geometries.push(projected);
                    geometriesForUpdatingAi360.push(projected);
                });
                boundaryAndUri.polygons = geometries;
                boundaryAndUri.fieldUri = overlappingField.fieldUri;
                boundarysAndUris.push(boundaryAndUri);
                geometries = [];
            });
            setJdBoundaries(boundarysAndUris);
            if (overlaps.overlaps.length === 1) {
                setJdBoundaryToUpdateAi360(geometriesForUpdatingAi360);
                setOnlyOneOverlap(true);
            }
        }
    }, [overlaps.overlaps]);

    useEffect(() => {
        let view: MapView;
        if (mapDiv.current) {
            const map = new EsriMap({
                basemap: baseMap,
            });
            const ai360FillSymbol = new SimpleFillSymbol({
                color: [227, 139, 79, 0.8],
                outline: {
                    color: [255, 255, 255],
                    width: 2,
                },
            });
            const jdOpsCenterFillSymbol = new SimpleFillSymbol({
                outline: {
                    color: [210, 46, 169],
                    width: 4,
                },
            });
            const jdOpsCenterSelectedFillSymbol = new SimpleFillSymbol({
                style: "diagonal-cross",
                outline: {
                    color: [210, 46, 169],
                    width: 8,
                },
            });

            // add the Ai360 field boundary (or boundaries if it's multi-part)
            fieldPolygons.forEach((polygon) => {
                const graphicsLayer = new GraphicsLayer();
                const polygonGraphic = new Graphic({
                    geometry: polygon,
                    symbol: ai360FillSymbol,
                    attributes: {
                        source: "ai360",
                    },
                });
                graphicsLayer.add(polygonGraphic);
                map.add(graphicsLayer);
            });

            // add the JD Ops Center boundaries that overlap, each on a separate
            // graphicsLayer (meaning, multi-polyon fields all on one graphicsLayer)
            overlaps.overlaps.forEach((overlappingField: OverlappingBoundary) => {
                const graphicsLayer = new GraphicsLayer();
                overlappingField.polygons.forEach((overlaps) => {
                    const geometry = new Polygon({
                        spatialReference: { wkid: 4326 },
                        rings: overlaps.coordinates,
                    });
                    const polygonGraphic = new Graphic({
                        geometry: geometry,
                        symbol: jdOpsCenterFillSymbol,
                        attributes: {
                            source: "jd",
                            fieldUri: overlappingField.fieldUri,
                        },
                    });
                    graphicsLayer.add(polygonGraphic);
                });
                map.add(graphicsLayer);
            });

            view = new MapView({
                map: map,
                container: mapDiv.current,
                center: [longitude, latitude],
                zoom: zoomLevel,
                constraints: {
                    rotationEnabled: false,
                    snapToZoom: true,
                },
                ui: {
                    components: ["attribution"],
                },
                navigation: {
                    momentumEnabled: false,
                },
            });

            view.on("click", (event) => {
                if (!onlyOneOverlap) {
                    // Set all jd boundaries to have a normal border (not selected)
                    const graphicsLayers = view.allLayerViews.filter(
                        (x) => x.layer.type === "graphics"
                    );
                    if (graphicsLayers.length > 2) {
                        graphicsLayers.forEach(async (g) => {
                            const glView = g as GraphicsLayerView;
                            const gr = await glView.queryGraphics();
                            gr.forEach((gra) => {
                                if (gra.attributes?.source === "jd") {
                                    gra.symbol = jdOpsCenterFillSymbol;
                                }
                            });
                        });
                        view.hitTest(event).then((response) => {
                            if (response.results.length) {
                                const jdResults = response.results.filter(
                                    (x) => x.graphic.attributes?.source === "jd"
                                );
                                if (jdResults.length > 0) {
                                    const graphic = jdResults[0].graphic;

                                    // If it was selected, and they clicked on it again, set it back to regular fill symbol,
                                    // not just for the individual graphic, but all graphics on the graphicsLayer
                                    if (graphic.attributes.fieldUri === chosenFieldUri) {
                                        const graphicLayer = jdResults[0].graphic
                                            .layer as GraphicsLayer;
                                        graphicLayer.graphics.forEach((g) => {
                                            g.symbol = jdOpsCenterFillSymbol;
                                        });
                                        setChosenFieldUri(null);
                                        setJdBoundaryToUpdateAi360(null);
                                    } else {
                                        // Conversely, if it wasn't selected, update the
                                        // fill symbols for that entire layer.
                                        const graphicLayer = jdResults[0].graphic
                                            .layer as GraphicsLayer;
                                        graphicLayer.graphics.forEach((g) => {
                                            g.symbol = jdOpsCenterSelectedFillSymbol;
                                        });
                                        setChosenFieldUri(graphic.attributes.fieldUri);
                                        const jdBoundaryUsedToUpdateAi360 = jdBoundaries
                                            .filter(
                                                (b) => b.fieldUri === graphic.attributes.fieldUri
                                            )
                                            .map((bb) => bb.polygons as Geometry[])
                                            .flat();
                                        setJdBoundaryToUpdateAi360(jdBoundaryUsedToUpdateAi360);
                                    }
                                }
                            }
                        });
                    }
                }
            });
        }
    }, [mapDiv.current]);

    const updateBoundary = async () => {
        setShowLoader(true);
        if (boundaryRadioButtonValue == "J") {
            dispatch(mapToolsActions.setChosePortalBoundary(true));
            dispatch(mapToolsActions.clearFieldEditGeometries());

            dispatch(mapToolsActions.setFieldEditGeometries(jdBoundaryToUpdateAi360));
            dispatch(
                notificationActions.pushToasterMessage(
                    formatMessage(messages.updateAi360BoundarySucceeded),
                    MSGTYPE.SUCCESS
                )
            );
        } else {
            dispatch(mapToolsActions.setChosePortalBoundary(false));
            const request = {
                fieldUri: chosenFieldUri,
                userGuid: userGuid,
            };
            const response = await RecAPI.updateJdBoundaryToMatchExisting(request, fieldGuid);
            if (response.statusCode !== 200) {
                dispatch(
                    notificationActions.pushToasterMessage(
                        formatMessage(messages.updateFailed),
                        MSGTYPE.ERROR
                    )
                );
            } else {
                dispatch(
                    notificationActions.pushToasterMessage(
                        formatMessage(messages.updateJdBoundarySucceeded),
                        MSGTYPE.SUCCESS
                    )
                );
            }
        }
        setShowLoader(false);
        dispatch(fieldActions.setPortalBoundarySyncModalOpen(false));
        dispatch(fieldActions.setJdBoundariesForBoundarySyncModal([]));
    };
    return (
        <div className="portal-boundary-update">
            <DialogBox
                action="Update"
                actionDisabled={
                    boundaryRadioButtonValue === null ||
                    jdBoundaryToUpdateAi360 === null ||
                    chosenFieldUri === null
                }
                draggable={true}
                isOpen={isOpen}
                unrestricted={true}
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                title={formatMessage(messages.title, { farm: farmName, field: fieldName })}
                onAction={() => {
                    updateBoundary();
                }}
                onClose={() => {
                    dispatch(fieldActions.setPortalBoundarySyncModalOpen(false));
                    dispatch(fieldActions.setJdBoundariesForBoundarySyncModal([]));
                    setChosenFieldUri(null);
                    setJdBoundaryToUpdateAi360(null);
                }}
            >
                {showLoader && <Loader />}
                <RadioButtonGroup
                    className={"side-by-side-radio"}
                    selectedValue={boundaryRadioButtonValue}
                    afterOnChange={(value) => {
                        setBoundaryRadioButtonValue(value.toString());
                    }}
                >
                    <div className="system-boundary-icon"></div>
                    <RadioButton
                        className={"ai-radio"}
                        label={formatMessage(messages.updateJdOpsCenterBoundary, {
                            system: systemName,
                        })}
                        value={"A"}
                    />
                    <div className="jd-boundary-icon"></div>
                    <RadioButton
                        className={"jd-radio"}
                        label={formatMessage(messages.updateAi360Boundary, { system: systemName })}
                        value={"J"}
                    />
                </RadioButtonGroup>
                <div className="portal-boundary-map-holder">
                    <div ref={mapDiv} className="portal-boundary-map-div"></div>
                </div>
            </DialogBox>
        </div>
    );
}

PortalBoundaryUpdate.propTypes = {
    intl: intlShape,
};

export default injectIntl(PortalBoundaryUpdate);
