import { LayerAPI } from "@ai360/core";
import { ILayerFilter, ILayerFilterOptions } from "./interfaces";

export const CHANGE_MANUAL_LAYER_PROPS_INIT = "layer-module/CHANGE_MANUAL_LAYER_PROPS_INIT";
export const CHANGE_MANUAL_LAYER_PROPS_SUCCESS = "layer-module/CHANGE_MANUAL_LAYER_PROPS_SUCCESS";
export const CHANGE_SURFACE_LAYER_PROPS_INIT = "layer-module/CHANGE_SURFACE_LAYER_PROPS_INIT";
export const CHANGE_SURFACE_LAYER_PROPS_SUCCESS = "layer-module/CHANGE_SURFACE_LAYER_PROPS_SUCCESS";
export const CLEAR_LAYER_FILTER = "layer-module/CLEAR_LAYER_FILTER";
export const CLEAR_TABLE_RECORDS = "layer-module/CLEAR_TABLE_RECORDS";
export const CREATE_PRINT_LAYER = "layer-module/CREATE_PRINT_LAYER";
export const CREATE_SPLIT_PRINT_LAYER = "layer-module/CREATE_SPLIT_PRINT_LAYER";
export const FETCH_COLOR_SCHEMES_SUCCESS = "layer-module/FETCH_COLOR_SCHEMES_SUCCESS";
export const FETCH_LAYER_INFO = "layer-module/FETCH_LAYER_INFO";
export const FETCH_TABLE_RECORDS = "layer-module/FETCH_TABLE_RECORDS";
export const FETCH_TABLE_RECORDS_SUCCESS = "layer-module/FETCH_TABLE_RECORDS_SUCCESS";
export const FETCH_LEGEND_INFO_SUCCESS = "layer-module/FETCH_LEGEND_INFO_SUCCESS";
export const FETCH_SYMBOLOGY_OPTIONS_SUCCESS = "layer-module/FETCH_SYMBOLOGY_OPTIONS_SUCCESS";
export const INIT_LAYERS = "layer-module/INIT_LAYERS";
export const REMOVE_LAYER_INFOS = "layer-module/REMOVE_LAYER_INFOS";
export const REMOVE_ALL_VISIBLE_SAMPLE_SITES = "layer-module/REMOVE_ALL_VISIBLE_SAMPLE_SITES";
export const REMOVE_ALL_VISIBLE_SURFACES = "layer-module/REMOVE_ALL_VISIBLE_SURFACES";
export const REMOVE_VISIBLE_SAMPLE_SITES = "layer-module/REMOVE_VISIBLE_SAMPLE_SITES";
export const REMOVE_VISIBLE_SURFACES = "layer-module/REMOVE_VISIBLE_SURFACES";
export const REPROCESS_SOIL_TYPES = "layer-module/REPROCESS_SOIL_TYPES";
export const SET_CURRENT_TAB_INDEX = "layer-module/SET_CURRENT_TAB_INDEX";
export const SET_DISPLAY_EVENT_SURFACE_STATS = "layer-module/SET_DISPLAY_EVENT_SURFACE_STATS";
export const SET_ACTIVATE_ZONE_SPLIT = "layer-module/SET_ACTIVATE_ZONE_SPLIT";
export const SET_CLEAR_DGG_STATS = "layer-module/SET_CLEAR_DGG_STATS";
export const SET_CURRENT_VIEW_MAP_COUNT = "layer-module/SET_CURRENT_VIEW_MAP_COUNT";
export const SET_EVENT_SURFACE_STATS_LOADING = "layer-module/SET_EVENT_SURFACE_STATS_LOADING";
export const SET_FIELD_GUID_TO_FIELD_MAP = "layer-module/SET_FIELD_GUID_TO_FIELD_MAP";
export const SET_LAYER_FILTER = "layer-module/SET_LAYER_FILTER";
export const SET_LAYER_FILTER_INFO = "layer-module/SET_LAYER_FILTER_INFO";
export const SET_LAYER_FILTER_OPTIONS = "layer-module/SET_LAYER_FILTER_OPTIONS";
export const SET_LAYER_INFO = "layer-module/SET_LAYER_INFO";
export const SET_ANALYSIS_LAYER_STATUS = "layer-module/SET_ANALYSIS_LAYER_STATUS";
export const SET_LAYER_STATUS = "layer-module/SET_LAYER_STATUS";
export const SET_PANEL_LOADING = "layer-module/SET_PANEL_LOADING";
export const SET_SCROLL_TOP = "layer-module/SET_SCROLL_TOP";
export const SET_SHOW_FILTERS = "layer-module/SET_SHOW_FILTERS";
export const SET_VISIBLE_SAMPLE_SITES = "layer-module/SET_VISIBLE_SAMPLE_SITES";
export const SET_VISIBLE_SURFACE = "layer-module/SET_VISIBLE_SURFACE";
export const SET_VISIBLE_SURFACES = "layer-module/SET_VISIBLE_SURFACES";
export const SET_SPLIT_SCREEN_COMPARE_TOOL = "layer-module/SET_SPLIT_SCREEN_COMPARE_TOOL";
export const UPDATE_EXPANDED_FIELD_GUID_SET = "layer-module/UPDATE_EXPANDED_FIELD_GUID_SET";
export const UPDATE_LAYERS = "layer-module/UPDATE_LAYERS";
export const UPDATE_LOADING_FIELD_GUID_SET = "layer-module/UPDATE_LOADING_FIELD_GUID_SET";
export const UPDATE_LOADING_SAMPLE_SITES_SET = "layer-module/UPDATE_LOADING_SAMPLE_SITES_SET";
export const UPDATE_LOADING_SURFACES_SET = "layer-module/UPDATE_LOADING_SURFACES_SET";
export const UPDATE_SEARCH = "layer-module/UPDATE_SEARCH";
export const UPDATE_EVENT_SURFACE_STATS = "layer-module/UPDATE_EVENT_SURFACE_STATS";
/**
 * Initializes call to change manual surface layer properties
 * @param {object} payload {
 *  fieldGuid, //field identifier
 *  itemDimIdx, //Accordion Item Dimensional Index
 *  layer, //layer information for the given manual surface layer
 *  surface, //current surface lyaer info
 *  attributeGuid, //new selected attributeGuid
 *  colorSchemeGuid //new colorSchemeGuid for selected attribute
 * }
 */
export const changeManualLayerProps = ({
    fieldGuid,
    itemDimIdx,
    layer,
    surface,
    attributeGuid,
    colorSchemeGuid,
}) => ({
    type: CHANGE_MANUAL_LAYER_PROPS_INIT,
    payload: {
        fieldGuid,
        itemDimIdx,
        layer,
        surface,
        attributeGuid,
        colorSchemeGuid,
    },
});

/**
 * Initializes call to change manual surface layer properties
 * @param {object} payload {
 *   fieldGuid, //field identifier
 *   itemDimIdx, //Accordion Item Dimensional Index
 *   layer, //layer information for the given manual surface layer
 *   surface, //current surface lyaer info
 *   attributeGuid, //new selected attributeGuid
 *   classificationMethodGuid //new classificationMethodGuid for selected attribute
 *   colorRampGuid //new colorRampGuid for selected attribute
 *   colorSchemeGuid //new colorSchemeGuid for selected attribute
 *   numberOfClasses //new numberOfClasses for selected attribute
 *   surfaceTypeGuid //new surfaceTypeGuid for selected attribute
 * }
 */
export const changeSurfaceLayerProps = ({
    fieldGuid,
    itemDimIdx,
    layer,
    surface,
    attributeGuid,
    classificationMethodGuid,
    colorRampGuid,
    colorSchemeGuid,
    numberOfClasses,
    surfaceTypeGuid,
}) => ({
    type: CHANGE_SURFACE_LAYER_PROPS_INIT,
    payload: {
        fieldGuid,
        itemDimIdx,
        layer,
        surface,
        payload: {
            attributeGuid,
            classificationMethodGuid,
            colorRampGuid,
            colorSchemeGuid,
            numberOfClasses,
            surfaceTypeGuid,
        },
    },
});
export const clearLayerFilter = () => ({
    type: CLEAR_LAYER_FILTER,
});
export const createPrintLayer = (payload) => ({
    type: CREATE_PRINT_LAYER,
    payload,
});

export const clearTableRecords = () => ({
    type: CLEAR_TABLE_RECORDS,
});

export const createSplitPrintLayer = (payload) => ({
    type: CREATE_SPLIT_PRINT_LAYER,
    payload,
});

export const setDisplayEventSurfaceStats = (displayEventSurfaceStats) => ({
    type: SET_DISPLAY_EVENT_SURFACE_STATS,
    displayEventSurfaceStats,
});

export const setActivateZoneSplit = (activateZoneSplit) => ({
    type: SET_ACTIVATE_ZONE_SPLIT,
    payload: {
        activateZoneSplit,
    },
});

export const clearDggStats = (clearDggStats) => ({
    type: SET_CLEAR_DGG_STATS,
    payload: {
        clearDggStats,
    },
});

export const SetCurrentViewMapCount = (currentViewMapCount) => ({
    type: SET_CURRENT_VIEW_MAP_COUNT,
    payload: {
        currentViewMapCount,
    },
});

export const fetchColorSchemesSucceeded = (payload) => ({
    type: FETCH_COLOR_SCHEMES_SUCCESS,
    payload,
});

export const fetchLayerInfo = (fieldGuid) => ({
    type: FETCH_LAYER_INFO,
    payload: {
        fieldGuid,
    },
});

export const fetchLegendInfoSucceeded = (payload) => ({
    type: FETCH_LEGEND_INFO_SUCCESS,
    payload,
});

export const fetchSymbologyOptionsSucceeded = (payload) => ({
    type: FETCH_SYMBOLOGY_OPTIONS_SUCCESS,
    payload,
});

export const fetchTableRecords = (fieldGuid, userDefinedPolyJson) => ({
    type: FETCH_TABLE_RECORDS,
    payload: {
        fieldGuid,
        userDefinedPolyJson,
    },
});

export const fetchTableRecordsSuccess = (records) => ({
    type: FETCH_TABLE_RECORDS_SUCCESS,
    payload: records,
});

export const initLayers = () => ({
    type: INIT_LAYERS,
});

export const removeLayerInfos = (fieldGuids) => ({
    type: REMOVE_LAYER_INFOS,
    fieldGuids,
});

export const removeAllVisibleSampleSites = () => ({
    type: REMOVE_ALL_VISIBLE_SAMPLE_SITES,
});

export const removeAllVisibleSurfaces = () => ({
    type: REMOVE_ALL_VISIBLE_SURFACES,
});

export const removeVisibleSampleSites = (fieldGuids) => ({
    type: REMOVE_VISIBLE_SAMPLE_SITES,
    fieldGuids,
});

export const removeVisibleSurfaces = (fieldGuids) => ({
    type: REMOVE_VISIBLE_SURFACES,
    fieldGuids,
});

export const reprocessSoilTypes = (fieldGuid) => ({
    type: REPROCESS_SOIL_TYPES,
    fieldGuid,
});

export const setCurrentTabIndex = (index) => ({
    type: SET_CURRENT_TAB_INDEX,
    index,
});

export const setEventSurfaceStatsLoading = (isLoading) => ({
    type: SET_EVENT_SURFACE_STATS_LOADING,
    isLoading,
});

export const setFieldGuidToFieldMap = (map) => ({
    type: SET_FIELD_GUID_TO_FIELD_MAP,
    map,
});

export const setLayerFilter = (filter: ILayerFilter) => ({
    type: SET_LAYER_FILTER,
    filter,
});

export const setLayerFilterInfo = (filterInfo) => ({
    type: SET_LAYER_FILTER_INFO,
    filterInfo,
});

export const setLayerFilterOptions = (filterOptions: ILayerFilterOptions) => ({
    type: SET_LAYER_FILTER_OPTIONS,
    filterOptions,
});

export const setLayerInfo = (fieldGuid: string, layerInfo: LayerAPI.ILayer[]) => ({
    type: SET_LAYER_INFO,
    fieldGuid,
    layerInfo,
});

export const setAnalysisLayerStatus = (analysisLayerGuid, analysisLayerStatus) => ({
    type: SET_ANALYSIS_LAYER_STATUS,
    analysisLayerGuid,
    analysisLayerStatus,
});

export const setLayerStatus = (analysisLayerGuid, status) => ({
    type: SET_LAYER_STATUS,
    analysisLayerGuid,
    status,
});

export const setPanelLoading = (panelLoading) => ({
    type: SET_PANEL_LOADING,
    panelLoading,
});

export const setScrollTop = (scrollTop) => ({
    type: SET_SCROLL_TOP,
    scrollTop,
});

export const setShowFilters = (showFilters) => ({
    type: SET_SHOW_FILTERS,
    showFilters,
});

export const setVisibleSampleSites = (fieldGuid, layer) => ({
    type: SET_VISIBLE_SAMPLE_SITES,
    fieldGuid,
    layer,
});

export const setVisibleSurface = (fieldGuid, surfaceInfo) => ({
    type: SET_VISIBLE_SURFACE,
    fieldGuid,
    surfaceInfo,
});

export const setVisibleSurfaces = (newVisibleSurfaces) => ({
    type: SET_VISIBLE_SURFACES,
    newVisibleSurfaces,
});

export const splitScreenCompareToolClose = () => ({
    type: SET_SPLIT_SCREEN_COMPARE_TOOL,
    splitScreenCompareTool: {
        fieldGuid: null,
        surfaceInfo: null,
        tableRecords: [],
    },
});

export const splitScreenCompareToolOpen = (fieldGuid, surfaceInfo) => ({
    type: SET_SPLIT_SCREEN_COMPARE_TOOL,
    splitScreenCompareTool: {
        fieldGuid,
        surfaceInfo,
        tableRecords: [],
    },
});

export const updateEventSurfaceStats = (polyJson) => ({
    type: UPDATE_EVENT_SURFACE_STATS,
    polyJson,
});

export const updateExpandedFieldGuidSet = (fieldGuids, isExpanded) => ({
    type: UPDATE_EXPANDED_FIELD_GUID_SET,
    fieldGuids,
    isExpanded,
});

export const updateLayerSurfaces = (fieldGuid) => ({
    type: UPDATE_LAYERS,
    payload: { fieldGuid },
});

export const updateLoadingFieldGuidSet = (fieldGuid, isLoading) => ({
    type: UPDATE_LOADING_FIELD_GUID_SET,
    fieldGuid,
    isLoading,
});

export const updateLoadingSampleSitesSet = (agEventGeneralGuid, isLoading) => ({
    type: UPDATE_LOADING_SAMPLE_SITES_SET,
    agEventGeneralGuid,
    isLoading,
});

export const updateLoadingSurfacesSet = (surfaceGuid, isLoading) => ({
    type: UPDATE_LOADING_SURFACES_SET,
    surfaceGuid,
    isLoading,
});

export const updateSearchValue = (searchValue) => ({
    type: UPDATE_SEARCH,
    payload: { searchValue },
});
