import { defineMessages } from "react-intl";

export default defineMessages({
    cancel: { id: "fieldBoundaryOverlap.cancel", defaultMessage: "Cancel" },
    clippedBoundaryLocked: {
        id: "fieldBoundaryOverlap.clippedBoundaryLocked",
        defaultMessage: "Clipped Boundary is Locked",
    },
    clipToOtherField: {
        id: "fieldBoundaryOverlap.clipToOtherField",
        defaultMessage: "Clip to Other Field",
    },
    lockedFieldTitle: {
        id: "fieldBoundaryOverlap.lockedFieldTitle",
        defaultMessage: "Locked Boundary Detected",
    },
    lockedFieldDescription: {
        id: "fieldBoundaryOverlap.lockedFieldDescription",
        defaultMessage: "Overlapped boundary is locked. Update will be canceled.",
    },
    promptDescription: {
        id: "fieldBoundaryOverlap.promptDescription",
        defaultMessage:
            "Overlap to existing field boundary detected. How would you like to handle this overlap?",
    },
    promptOptionOneDescription: {
        id: "fieldBoundaryOverlap.promptOptionOneDescription",
        defaultMessage:
            "The Clip to Other Field button will clip this new boundary to existing Field(s).",
    },
    promptOptionTwoDescription: {
        id: "fieldBoundaryOverlap.promptOptionTwoDescription",
        defaultMessage:
            "The Update Other Field button will update all existing Field boundaries and clip those to this new boundary.",
    },
    promptOptionCancelDescription: {
        id: "fieldBoundaryOverlap.promptOptionCancelDescription",
        defaultMessage: "The Cancel button will return to the last edit prior to overlap.",
    },
    ok: {
        id: "fieldBoundaryOverlap.ok",
        defaultMessage: "Ok",
    },
    undoOptionDescription: {
        id: "fieldBoundaryOverlap.undoOptionDescription",
        defaultMessage: "Undo can be clicked to see these options again.",
    },
    title: {
        id: "fieldBoundaryOverlap.title",
        defaultMessage: "Overlap Detected",
    },
    updateOtherField: {
        id: "fieldBoundaryOverlap.updateOtherField",
        defaultMessage: "Update Other Field",
    },
});
