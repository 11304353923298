// Model
export const PROPS_ACCOUNT = "account";
export const PROPS_ACCESS_LEVEL = "accessLevel";
export const PROPS_ACROSS_CUSTOMERS = "acrossCustomers";
export const PROPS_ACTIVE_IN_ACTIVE = "activeInactive";
export const PROPS_ACTIVE_YN = "activeYn";
export const PROPS_AGBYTES = "agBytes";
export const PROPS_AGBYTES_COUNTRY_STATE = "agBytesCountryStateSetup";
export const PROPS_AGBYTES_CROPS_SETUP = "agBytesCropsSetup";
export const PROPS_AGBYTES_EQUATION_PARAMETER_SETUP = "agBytesEquationParameterSetup";
export const PROPS_AGBYTES_EQUATION_SET_SETUP = "agBytesEquationSetSetup";
export const PROPS_AGBYTES_EQUATION_SET_PLANTING_SETUP = "agBytesEquationSetPlantingSetup";
export const PROPS_AGBYTES_GENES = "agBytesGenes";
export const PROPS_AGBYTES_GROWTH_STAGES = "agBytesGrowthStages";
export const PROPS_AGBYTES_NUTRIENTS = "agBytesNutrients";
export const PROPS_AGBYTES_NUTRIENT_REMOVE = "agBytesNutrientRemove";
export const PROPS_AGBYTES_NUTRIENT_TARGETS = "agBytesNutrientTargets";
export const PROPS_AGBYTES_OBSERVATIONS = "agBytesObservations";
export const PROPS_AGBYTES_PICKLIST_SETUP = "agBytesPicklistSetup";
export const PROPS_AGBYTES_PRODUCTS_SETUP = "agBytesProductsSetup";
export const PROPS_AGBYTES_SAMPLE_ATTRBUTES_SETUP = "agBytesSampleAttributesSetup";
export const PROPS_AGBYTES_TRAITS = "agBytesTraits";
export const PROPS_AGBYTES_UNITS_SETUP = "agBytesUnitsSetup";
export const PROPS_AGBYTES_VARIETY_HYBRID = "agBytesVarietyHybrid";
export const PROPS_AGBYTES_VARIETY_HYBRID_ATTRIBUTES = "agBytesVarietyHybridAttributes";
export const PROPS_AGEVENTS = "agEvents";
export const PROPS_AGEVENTS_APPLICATION = "agEventsApplication";
export const PROPS_AGEVENTS_CUSTOM = "agEventsCustom";
export const PROPS_AGEVENTS_EC_DATA = "agEventsEcData";
export const PROPS_AGEVENTS_HARVEST = "agEventsHarvest";
export const PROPS_AGEVENTS_IRRIGATION = "agEventsIrrigation";
export const PROPS_AGEVENTS_PLANTING = "agEventsPlanting";
export const PROPS_AGEVENTS_TILLAGE = "agEventsTillage";
export const PROPS_AGR_INELLIGENCE_REPORTS = "agrIntelligenceReports";
export const PROPS_ANALYSIS_LAYERS = "analysisLayers";
export const PROPS_ANALYTICS = "analytics";
export const PROPS_AUTO_CREATE_REPORTS = "autoCreateReports";
export const PROPS_BATCH = "manualPlanting";
export const PROPS_BATCH_EVENTS = "batchEvents";
export const PROPS_BATCH_IMAGERY_SETUP = "batchImagerySetup";
export const PROPS_BATCH_RECS = "batchRecs";
export const PROPS_CAN_DELETE = "canDelete";
export const PROPS_CHILD_PATH = ".children.";
export const PROPS_COMBINE_FIELDS = "combineFields";
export const PROPS_COMMUNITY_DATA = "communityData";
export const PROPS_COMPANY_GUID = "companyGuid";
export const PROPS_CUSTOMER_ADD = "customerAdd";
export const PROPS_CUSTOMER_DELETE = "customerDelete";
export const PROPS_CUSTOMER_EDIT = "customerEdit";
export const PROPS_CUSTOMER_SALESPERSON = "customerSalesperson";
export const PROPS_DEFAULT_GUID = "00000000-0000-0000-0000-000000000000";
export const PROPS_DESCRIPTION = "description";
export const PROPS_EQUATION_APPLICATION = "equationApplication";
export const PROPS_EQUATION_PLANTING = "equationPlanting";
export const PROPS_EQUIPMENT = "agBytesEquipment";
export const PROPS_ERROR_MESSAGE = "errorMessage";
export const PROPS_EXPORTS = "exports";
export const PROPS_EXPORT_EVENTS = "exportEvents";
export const PROPS_EXPORT_FIELD_BOUNDARY = "exportFieldBoundary";
export const PROPS_EXPORT_FIELD_DETAILS = "exportFieldDetails";
export const PROPS_EXPORT_RECS = "exportRecs";
export const PROPS_EXPORT_SAMPLING = "sampling";
export const PROPS_EXPORT_SURFACES = "exportSurfaces";
export const PROPS_FERTILIZER = "fertilizer";
export const PROPS_FIELD_REPORTS = "fieldReports";
export const PROPS_FINANCIALS = "financials";
export const PROPS_GROUP_NAME_CODE = "groupNameCode";
export const PROPS_IMAGERY_SETUP = "imagerySetup";
export const PROPS_IMPORT_EXPORT = "importExport";
export const PROPS_IMPORT_FILTERS = "importFilters";
export const PROPS_IMPORT_FILTERS_ASSIGN_TO_ALL = "importFiltersAssignToAll";
export const PROPS_IMPORT_FILTERS_ALIAS_NAME = "importFiltersAliasName";
export const PROPS_IS_ASSIGNED = "isAssigned";
export const PROPS_LAYERS = "layers";
export const PROPS_LAYERS_ANALYSIS = "layerAnalysis";
export const PROPS_LAYERS_EVENTS = "layerEvents";
export const PROPS_LAYERS_RECS = "layerRecs";
export const PROPS_MANAGE_FIELDS = "manageFields";
export const PROPS_MANUAL_APPLICATION = "manualApplication";
export const PROPS_MANUAL_PLANTING = "manualPlanting";
export const PROPS_MANURE_COMPOST = "manureCompost";
export const PROPS_NAME = "name";
export const PROPS_NEMATODE = "nematode";
export const PROPS_PERMISSION_LIST = "permissionList";
export const PROPS_PERMISSION_NAME_CODE = "permissionNameCode";
export const PROPS_PERSON_IMPORT_EXPORT = "personImportExport";
export const PROPS_PERSON_SALESPERSON = "personSalesperson";
export const PROPS_PORTAL_BOUNDARY_MANUAL_SYNC = "portalBoundaryManualSync";
export const PROPS_PORTAL_INTEGRATION = "portalIntegration";
export const PROPS_PREFERENCES = "preferences";
export const PROPS_PREFERENCES_ATTRIBUTE_COLOR_OVERRIDE = "attributeColorOverride";
export const PROPS_PREFERENCES_REAL_TIME_FIELD_UPDATES = "realTimeFieldUpdates";
export const PROPS_PRODUCT_ASSIGNMENT = "productAssignment";
export const PROPS_RECOMMENDATIONS = "recommendations";
export const PROPS_REPORT_WIZARD = "reportWizard";
export const PROPS_RESURFACING = "resurfacing";
export const PROPS_SCOUTING = "scouting";
export const PROPS_SETUP = "setup";
export const PROPS_SETUP_CUSTOMER_PROFILE = "setupCustomerProfile";
export const PROPS_SETUP_EQUIPMENT = "setupEquipment";
export const PROPS_SETUP_HIERARCHY = "setupHierarchy";
export const PROPS_SETUP_ADMIN_SCRIPTS = "setupAdminScripts";
export const PROPS_SETUP_HIERARCHY_ZAPPER = "setupHierarchyZapper";
export const PROPS_SETUP_OWNER_HIERARCHY = "setupOwnerHierarchy";
export const PROPS_SETUP_PERSON_PROFILE = "setupPersonProfile";
export const PROPS_SETUP_UI_LABLES = "setupUILabels";
export const PROPS_SETUP_USER_ROLE = "setupUserRole";
export const PROPS_SETUP_USER_SETUP = "setupUserSetup";
export const PROPS_SETUP_USER_IMPORT_EXPORT = "setupUserImportExport";
export const PROPS_SOIL = "soil";
export const PROPS_SUMMARY_REPORTS = "summaryReports";
export const PROPS_SYSTEM_OWNER = "systemOwner";
export const PROPS_SYSTEM_SETTINGS = "systemSettings";
export const PROPS_TELEMATICS = "telematics";
export const PROPS_TISSUE = "tissue";
export const PROPS_USER_ROLE_GUID = "userRoleGuid";
export const PROPS_USER_ROLE_ID = "userRoleID";
export const PROPS_USER_ROLE_LIST = "userRoleList";
export const PROPS_USER_ROLE_NAME = "userRoleName";
export const PROPS_VENDOR_SETUP = "setupVendorSetup";
export const PROPS_VENDOR_TESTING_LAB = "setupVendorTestingLab";
export const PROPS_WATER = "water";
export const PROPS_WORK_ORDERS = "workOrders";
export const PROPS_ZONE_INTERPOLATION = "zoneInterpolation";
export const PROPS_ZONE_SAMPLING = "zoneSampling";
export const PROPS_MERGE_EVENTS = "mergeEvents";
export const PROPS_ROLE_ENABLE_MYAGDATA = "enableMyAgData";
