import Immutable from "immutable";

import natsort from "natsort";

import { SearchAPI, FieldAPI } from "@ai360/core";
import { IFieldResult } from "@ai360/core/dist/4x/es/api/search";

export interface IAddUpdateFieldMessage {
    acres: number;
    activeYn: boolean;
    certifiedOrganic: boolean;
    cropGuid: string;
    customerEnrolled: boolean;
    customerGuid: string;
    eventCount: number;
    farmName: string;
    fieldBoundaryGuid: string;
    fieldGuid: string;
    irrigated: number;
    name: string;
    recCount: number;
    lockBoundary: boolean;
}

export interface IAddUpdateFieldsMessage {
    fields: IAddUpdateFieldMessage[];
}

export interface IComparableField {
    farmName: string;
    name: string;
}

export interface IComparableCustomerAndField {
    customerName: string;
    farmName: string;
    name: string;
}

export interface ICustomerFieldMapping {
    customerGuid: string;
    fieldGuid: string;
}

export type CustomerDataState = Readonly<{
    customerMap: Immutable.OrderedMap<string, CustomerInfo>;
    fieldMap: Immutable.OrderedMap<string, FieldInfo>;
    customerFieldMappings: ICustomerFieldMapping[];
    orgLevels: Map<string, OrgLevelInfo>;
    selectedFields: Immutable.Set<string>;
    nonFieldFeatureMap: Immutable.Map<any, NonFieldFeatureInfo>;
    fetchingCustomerFields: boolean;
    lastCustomerFieldPageId: SearchAPI.ICustomerFieldPageId | null;
    isDoneFetchingCustomers: boolean;
    fetchingSummary: boolean;
    quickSummary: IQuickSummary;
    fetchingFilteredFieldGuids: boolean;
    filteredFieldGuids: Immutable.Set<string>;
    autoExpandedCustomers: Immutable.Set<string>;
}>;

export class NonFieldFeatureInfo {
    feature: any;
    visible: boolean;
    selected: boolean;
    highlighted: boolean;

    constructor(
        feature: Record<string, any>,
        visible = true,
        selected = false,
        highlighted = false
    ) {
        this.feature = feature;
        this.visible = visible;
        this.selected = selected;
        this.highlighted = highlighted;
    }
}

export interface ICustomerSimpleListItem {
    activeYn: boolean;
    customerCity: string;
    customerEnrolled: boolean;
    customerGuid: string;
    customerName: string;
    agvanceGrowerName: string;
    customerStreet: string;
    customerState: string;
    customerZipCode: string;
    salespersonList: string[];
    fields: number;
    acres: number;
}

export interface IFieldSimpleListItem {
    acres: number;
    activeYn: boolean;
    lockBoundary: boolean;
    certifiedOrganic: boolean;
    classifications: FieldAPI.IAgvClassification[];
    cropGuid: string;
    customerEnrolled: boolean;
    customerGuid: string;
    customerName: string;
    events: number;
    farmName: string;
    fieldBoundaryGuid: string;
    fieldGuid: string;
    fieldName: string;
    irrigated: number;
    recs: number;
}

export interface IQuickSummary {
    customers: number;
    acres: number;
    fields: number;
    events: number;
    recs: number;
}

const naturalSorter = natsort({ insensitive: true });

export const compareCustomers = (l: FieldAPI.ICustomerInfo, r: FieldAPI.ICustomerInfo): number => {
    return naturalSorter(l.name, r.name);
};

export const compareFields = (
    l: IComparableField | undefined,
    r: IComparableField | undefined
): number => {
    if (l == null || r == null) {
        return 0;
    }
    const farmNameComp = naturalSorter(l.farmName, r.farmName);
    return farmNameComp !== 0 ? farmNameComp : naturalSorter(l.name, r.name);
};

export const compareCustomersAndFields = (
    l: IComparableCustomerAndField | undefined,
    r: IComparableCustomerAndField | undefined
): number => {
    if (l == null || r == null) {
        return 0;
    }
    const customerNameComp = naturalSorter(l.customerName, r.customerName);
    const farmNameComp = naturalSorter(l.farmName, r.farmName);
    return customerNameComp !== 0
        ? customerNameComp
        : farmNameComp !== 0
        ? farmNameComp
        : naturalSorter(l.name, r.name);
};

export const equalCustomerFieldMappings = (l: ICustomerFieldMapping, r: ICustomerFieldMapping) =>
    l.customerGuid === r.customerGuid && l.fieldGuid === r.fieldGuid;

export class CustomerInfo implements FieldAPI.ICustomerInfo {
    public static fromCustomerField(customerField: SearchAPI.ICustomerFieldResult): CustomerInfo {
        return customerField != null
            ? new CustomerInfo({
                  activeYn: customerField.isCustomerActive,
                  customerCity: customerField.city,
                  customerEnrolled: customerField.enrolled,
                  customerGuid: customerField.customerId,
                  customerName: customerField.customerName,
                  agvanceGrowerName: customerField.agvanceGrowerName,
                  customerStreet: customerField.street,
                  customerState: customerField.state,
                  customerZipCode: customerField.zipCode,
                  salespersonList: [],
                  fields: customerField.fields,
                  acres: customerField.acres,
              })
            : null;
    }

    public activeYn = false;
    public street = "";
    public city = "";
    public customerGuid = "";
    public enrolledYn = false;
    public name = "";
    public agvanceGrowerName = "";
    public state = "";
    public zipCode = "";
    public salespeople: Set<string> = new Set();
    public fields = 0;
    public acres = 0;

    constructor(customer?: ICustomerSimpleListItem) {
        if (customer == null) {
            return;
        }

        this.activeYn = customer.activeYn;
        this.city = customer.customerCity;
        this.customerGuid = customer.customerGuid;
        this.enrolledYn = customer.customerEnrolled;
        this.name = customer.customerName;
        this.agvanceGrowerName = customer.agvanceGrowerName;
        this.state = customer.customerState;
        this.street = customer.customerStreet;
        this.zipCode = customer.customerZipCode;
        this.salespeople = new Set(customer.salespersonList);
        this.fields = customer.fields;
        this.acres = customer.acres;
    }
}

export class FieldInfo implements FieldAPI.IFieldInfo {
    public static enroll(field: FieldInfo, value: boolean): FieldInfo {
        return FieldInfo._updateField(field, { customerEnrolled: value });
    }

    public static updateEventCount(field: FieldInfo, eventCount: number): Readonly<FieldInfo> {
        return FieldInfo._updateField(field, { eventCount });
    }

    public static updateRecCount(field: FieldInfo, recCount: number): Readonly<FieldInfo> {
        return FieldInfo._updateField(field, { recCount });
    }

    public static updateFieldSummary(
        field: FieldInfo,
        n: Partial<FieldAPI.IFieldInfo>
    ): Readonly<FieldInfo> {
        return FieldInfo._updateField(field, {
            ...n,
            eventCount: field.eventCount,
            recCount: field.recCount,
            activeYn: field.activeYn,
            acres: !n.acres ? field.acres : n.acres,
        });
    }

    public static fromSearch(result: IFieldResult): FieldInfo {
        return new FieldInfo({
            acres: result.acres,
            activeYn: result.activeYn,
            lockBoundary: result.lockBoundary,
            farmName: result.farmName,
            fieldBoundaryGuid: result.boundaryId,
            fieldGuid: result.id,
            fieldName: result.name,
            events: result.events,
            recs: result.recs,
            customerEnrolled: result.customerEnrolled,
            customerGuid: result.customerId,
            customerName: result.customerName,
            certifiedOrganic: false,
            classifications: [],
            cropGuid: "",
            irrigated: 0,
        });
    }

    public static fromMessage(message: IAddUpdateFieldMessage): FieldInfo {
        const info = new FieldInfo();
        (info.acres = message.acres),
            (info.activeYn = message.activeYn),
            (info.certifiedOrganic = message.certifiedOrganic),
            (info.classifications = []),
            (info.cropGuid = message.cropGuid),
            (info.customerEnrolled = message.customerEnrolled),
            (info.customerGuid = message.customerGuid),
            (info.eventCount = message.eventCount),
            (info.farmName = message.farmName),
            (info.fieldBoundaryGuid = message.fieldBoundaryGuid),
            (info.fieldGuid = message.fieldGuid),
            (info.irrigated = message.irrigated),
            (info.name = message.name),
            (info.recCount = message.recCount),
            (info.lockBoundary = message.lockBoundary);
        return info;
    }

    private static _clone = (f: Readonly<FieldInfo>): FieldInfo =>
        Object.assign(new FieldInfo(), f);

    private static _updateField(
        f: FieldInfo,
        n: Partial<FieldAPI.IFieldInfo>
    ): Readonly<FieldInfo> {
        return Object.freeze(Object.assign(FieldInfo._clone(f), n));
    }

    public acres = 0;
    public activeYn = false;
    public lockBoundary = false;
    public certifiedOrganic = false;
    public classifications: FieldAPI.IAgvClassification[] = [];
    public cropGuid = "";
    public customerEnrolled = false;
    public customerGuid = "";
    public eventCount = 0;
    public farmName = "";
    public fieldBoundaryGuid = "";
    public fieldGuid = "";
    public irrigated: number;
    public name = "";
    public recCount = 0;

    constructor(field?: IFieldSimpleListItem) {
        if (field == null) {
            return;
        }
        this.acres = field.acres;
        this.activeYn = field.activeYn;
        this.lockBoundary = field.lockBoundary;
        this.certifiedOrganic = field.certifiedOrganic;
        this.classifications = field.classifications;
        this.cropGuid = field.cropGuid;
        this.customerEnrolled = field.customerEnrolled;
        this.customerGuid = field.customerGuid;
        this.eventCount = field.events;
        this.farmName = field.farmName;
        this.fieldBoundaryGuid = field.fieldBoundaryGuid;
        this.fieldGuid = field.fieldGuid;
        this.irrigated = field.irrigated;
        this.name = field.fieldName;
        this.recCount = field.recs;
    }
}

export class OrgLevelInfo implements FieldAPI.IOrgLevelInfo {
    public static parseParentsSummary = (orgLevel: OrgLevelInfo, summary: string): OrgLevelInfo => {
        const o = orgLevel.clone();
        o.parentsSummary = summary;
        return o;
    };

    public agvanceLinked: boolean;
    public city: string;
    public enrollmentRequired: boolean;
    public lockCustomersNotEnrolled: boolean;
    public orgLevelGuid: string;
    public name: string;
    public orgLevelParents: string[];
    public parentsSummary = "";
    public state: string;

    constructor(orgLevel: FieldAPI.IOrgLevelListItem) {
        this.agvanceLinked = orgLevel.agvanceLinked;
        this.city = orgLevel.city;
        this.enrollmentRequired = orgLevel.enrollmentRequired;
        this.lockCustomersNotEnrolled = orgLevel.lockCustomersNotEnrolled;
        this.orgLevelGuid = orgLevel.orgLevelGuid;
        this.name = orgLevel.orgLevelName;
        this.orgLevelParents = orgLevel.orgLevelParents;
        this.state = orgLevel.state;
    }

    public clone = (): FieldAPI.IOrgLevelInfo => Object.assign(this);
}
